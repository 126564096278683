import { useMessage } from "../MessageContext";

const useGetMessageStatus = () => {
  const { userInfo } = useMessage();

  const getMessageStatus = (receivers) => {
    const status = receivers?.find((receiver) => receiver?.messageTo === userInfo?.id);

    return { isRead: status?.isRead, isStarred: status?.isStarred, isImportant: status?.isImportant };
  };

  return getMessageStatus;
};

export default useGetMessageStatus;
