import React, { useEffect, useRef } from "react";
import { Grid, Box, styled } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import graphics from "assets/images/home-placeholder.jpg";

const ThumbSlide = styled(Splide)(({ theme }) => ({
  "& .splide__list": {
    "& .splide__slide": {
      opacity: 0.48,

      "&.is-active": {
        opacity: 1,
        border: `3px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

const HomeCarouselDetail = (props) => {
  const { data, children } = props;

  const sliderOption = {
    type: "slide",
    perPage: 1,
    pagination: false,
    arrows: false,
  };

  const sliderThumbOption = {
    fixedWidth: 64,
    fixedHeight: 64,
    height: 64,
    type: "slide",
    perPage: 5,
    gap: 12,
    pagination: false,
    isNavigation: true,
    arrows: false,
    focus: "center",
  };

  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    if (data && data.media.length) {
      slider1.current.sync(slider2.current.splide);
    }
  }, [slider1, slider2, data]);

  return data ? (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        {data.media.length > 0 && (
          <>
            <Splide
              ref={(slider) => (slider1.current = slider)}
              options={sliderOption}
              className="overflow-hidden rounded-2xl"
            >
              {data.media.map((img) => {
                return (
                  <SplideSlide key={img.id} className="overflow-hidden rounded-2xl ">
                    <img src={img.url} alt={img.name} className="object-contain w-full h-full" />
                  </SplideSlide>
                );
              })}
            </Splide>

            <ThumbSlide
              ref={(slider) => (slider2.current = slider)}
              options={sliderThumbOption}
              className="max-w-full sm:w-[63%] overflow-hidden mt-6 mx-auto"
            >
              {data.media.map((img) => {
                return (
                  <SplideSlide key={img.id} className="overflow-hidden cursor-pointer rounded-xl">
                    <img src={img.url} alt={img.name} className="object-cover w-full h-full" />
                  </SplideSlide>
                );
              })}
            </ThumbSlide>
          </>
        )}

        {data.media.length === 0 && (
          <img src={graphics} alt={data.name} className="object-contain w-full h-full rounded-2xl" />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box py={5}>{children}</Box>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default HomeCarouselDetail;
