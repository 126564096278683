import React, { Suspense } from "react";
import { BrowserRouter, Navigate, Routes, Route, Outlet } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { LayoutSplashScreen } from "configs/LayoutSplashScreen";
import { Layout, AuthLayout } from "layouts";
import { ToastWrapper } from "ui";
import {
  Register,
  VerifyUser,
  InitialChangePassword,
  Login,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
  Logout,
} from "views/auth";
import Dashboard from "views/app/dashboard";
import Profile from "views/app/profile";
import { UserList, EditUser, ViewUser } from "views/app/user";
import { RoleList, EditRole } from "views/app/role";
import {
  CustomerList,
  EditCustomer,
  ViewCustomer,
  CustomerHome,
  CustomerHomeSummary,
  CustomerHomeDoc,
  CustomerHomeProgress,
} from "views/app/customer";
import { ConfigChoiceList, EditConfigChoice } from "views/app/config-choice";
import { ConfigChoiceCategoryList, EditConfigChoiceCategory } from "views/app/config-choice-category";
import { HomeList, HomeEdit, HomeView, Summary, HomeCustomization } from "views/app/home";
import { ProductList } from "views/app/product";
import PageNotFound from "views/PageNotFound";
import { MilestoneList } from "views/app/milestone";
import { CustomizeHome, HomeDoc, HomeProgress, HomeSummary, MyHome } from "views/app/my-home";
import { NotificationList } from "views/app/notification";
import { MessageGroupList, Messsage } from "views/app/message";

export default function Root() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: Boolean(auth.user?.id),
    }),
    shallowEqual
  );

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LayoutSplashScreen />}>
          <Routes>
            <Route path="/auth" element={!isAuthorized ? <AuthLayout /> : <Navigate from="/auth" to="/" />}>
              <Route index element={<Navigate to="/auth/login" />} />
              <Route path="/auth/register" element={<Register />} />
              <Route path="/auth/verify/:token" element={<VerifyUser />} />
              <Route path="/auth/initial-change-password/:id/:token" element={<InitialChangePassword />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/forgot-password" element={<ForgotPassword />} />
              <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
              <Route path="/auth/verify-email/:token" element={<VerifyEmail />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route path="/logout" element={<Logout />} />

            <Route path="/" element={!isAuthorized ? <Navigate from="/" to="/auth/login" /> : <Layout />}>
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user-management" element={<Outlet />}>
                <Route index element={<Navigate to="/user-management/role" />} />
                <Route path="/user-management/role" element={<RoleList />} />
                <Route path="/user-management/role/add" element={<EditRole />} />
                <Route path="/user-management/role/edit/:id" element={<EditRole />} />
                <Route path="/user-management/user" element={<UserList />} />
                <Route path="/user-management/user/add" element={<EditUser />} />
                <Route path="/user-management/user/edit/:id" element={<EditUser />} />
                <Route path="/user-management/user/view/:id" element={<ViewUser />} />
                <Route path="/user-management/customer" element={<CustomerList />} />
                <Route path="/user-management/customer/add" element={<EditCustomer />} />
                <Route path="/user-management/customer/edit/:id" element={<EditCustomer />} />
                <Route path="/user-management/customer/view/:id" element={<ViewCustomer />} />
                <Route path="/user-management/customer/view/:id/home/:userHomeId" element={<CustomerHome />} />
                <Route
                  path="/user-management/customer/view/:id/home/summary/:userHomeId"
                  element={<CustomerHomeSummary />}
                />
                <Route
                  path="/user-management/customer/view/:id/home/document/:userHomeId"
                  element={<CustomerHomeDoc />}
                />
                <Route
                  path="/user-management/customer/view/:id/home/building-progress/:userHomeId"
                  element={<CustomerHomeProgress />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="/configuration" element={<Outlet />}>
                {/* Product */}
                <Route path="/configuration/products" element={<ProductList />} />
                <Route path="/configuration/milestones" element={<MilestoneList />} />

                {/* Home */}
                <Route path="/configuration/home" element={<HomeList />} />
                <Route path="/configuration/home/add" element={<HomeEdit />} />
                <Route path="/configuration/home/edit/:id" element={<HomeEdit />} />
                <Route path="/configuration/home/view/:id" element={<HomeView />} />
                <Route path="/configuration/home/summary/:id" element={<Summary />} />
                <Route path="/configuration/home/customize/:id" element={<HomeCustomization />} />

                <Route path="*" element={<PageNotFound />} />
              </Route>

              {/* Client Route */}
              <Route path="/my-home" element={<Outlet />}>
                <Route path="/my-home" element={<MyHome />} />
                <Route path="/my-home/customize/:userHomeId" element={<CustomizeHome />} />
                <Route path="/my-home/summary/:userHomeId" element={<HomeSummary />} />
                <Route path="/my-home/document/:userHomeId" element={<HomeDoc />} />
                <Route path="/my-home/building-progress/:userHomeId" element={<HomeProgress />} />

                <Route path="*" element={<PageNotFound />} />
              </Route>

              <Route path="/system-setting" element={<Outlet />}>
                <Route index element={<Navigate to="/system-setting/config-choice-category" />} />
                <Route path="/system-setting/config-choice-category" element={<ConfigChoiceCategoryList />} />
                <Route path="/system-setting/config-choice-category/add" element={<EditConfigChoiceCategory />} />
                <Route path="/system-setting/config-choice-category/edit/:id" element={<EditConfigChoiceCategory />} />

                {/* Config Choice */}
                <Route path="/system-setting/config-choice" element={<ConfigChoiceList />} />
                <Route path="/system-setting/config-choice/add" element={<EditConfigChoice />} />
                <Route path="/system-setting/config-choice/edit/:id" element={<EditConfigChoice />} />

                <Route path="*" element={<PageNotFound />} />
              </Route>

              <Route path="/message" element={<Outlet />}>
                <Route path="/message/conversation" element={<Messsage />} />
                <Route path="/message/message-group" element={<MessageGroupList />} />
              </Route>

              <Route path="/profile" element={<Profile />} />

              <Route path="/notifications" element={<NotificationList />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>

      <ToastWrapper
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        enableMultiContainer
        containerId={"default"}
      />
    </>
  );
}
