import { Box, LinearProgress, Typography, linearProgressClasses, styled, useTheme } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
// import useAllIcons from "hooks/useAllIcons";
import useGetMilestoneProgress from "hooks/useGetMilestoneProgress";
// import useRandomColorFamily from "hooks/useRandomColorFamily";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledButton, StyledCard, StyledSpinner, StyledTabs } from "ui";
import { useDashboard } from "./DashboardContext";
import MilestoneCard from "./MilestoneCard";

const ProgressBar = styled(LinearProgress, { shouldForwardProp: (prop) => prop })(
  ({ theme, size = 3, barcolor = "#232C40" }) => ({
    height: size,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#FFFFFF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barcolor,
    },
  })
);

const HomeSplide = styled(Splide)(({ theme }) => ({
  margin: "0 -8px",
  padding: "0 25px",

  "& .splide__arrow": {
    backgroundColor: "transparent",
    "&.splide__arrow--prev": { left: 0 },
    "&.splide__arrow--next": { right: 0 },

    "& svg": { fill: "#fff" },
  },
}));

const sliderOption = {
  type: "slide",
  autoWidth: true,
  gap: "15px",
  pagination: false,
  arrows: false,
  autoplay: true,
  rewind: true,
};

const houseSliderOption = {
  type: "slide",
  autoWidth: true,
  gap: "10px",
  pagination: false,
  arrows: true,
  autoplay: false,
  rewind: false,
};

const WelcomeCard = () => {
  const theme = useTheme();
  // const iconList = useAllIcons();
  // const { referenceColors, generateRandomColor } = useRandomColorFamily();
  const { userInfo } = useDashboard();
  const { userMilestoneData, getData } = useGetMilestoneProgress();

  const { loadingList } = useSelector((state) => state.userHomeMilestone);

  const [activeSlide, setActiveSlide] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  // const getMilestoneProgress = (docs) => {
  //   if (docs && docs?.length) {
  //     const totalDocuments = docs?.length;
  //     const approved = docs.filter((doc) => doc?.status === 21).length;

  //     return (approved / totalDocuments) * 100;
  //   }
  //   return 0;
  // };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const homeID = userInfo?.userHomes?.[activeSlide]?.id;
    if (homeID) {
      getData(homeID);
    }
  }, [userInfo, activeSlide]);

  return (
    <StyledCard
      sx={{
        padding: "1.25rem 0 1.25rem 1.875rem",
        backgroundColor: "#2A4446",
      }}
    >
      <Box className="grid grid-cols-12 gap-4 lg:gap-8">
        <Box className="col-span-12 lg:col-span-4 flex flex-col gap-5">
          <Typography component="small" fontSize="14px" fontWeight="300" color="#DCFDFF">
            Hi!, {userInfo?.name}
          </Typography>
          <Typography component="p" fontSize="22px" lineHeight="32px" color="#fff">
            You can review your home statistics here. Your home progress statistics are in progress. Lorem Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do
          </Typography>

          <Typography
            component="h6"
            sx={{ display: "flex", gap: 2, alignItems: "flex-start", marginTop: 1.25, color: "#fff" }}
          >
            Select home to review milestone progress
            {loadingList && (
              <StyledSpinner
                size={18}
                trailcolor={theme.palette.grey.aAzureishWhite}
                spinnercolor={theme.palette.info.main}
              />
            )}
          </Typography>

          {!!userInfo?.userHomes?.length > 0 && (
            <HomeSplide options={houseSliderOption}>
              {userInfo?.userHomes?.map((home, index) => (
                <SplideSlide key={home.id}>
                  <StyledButton
                    variant="text"
                    sx={{
                      minHeight: "initial",
                      padding: "4px 8px",
                      backgroundColor: activeSlide === index ? "#fff" : "transparent",
                      color: activeSlide === index ? theme.palette.primary.main : "#fff",
                      border: "none",
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      setActiveSlide(index);
                    }}
                  >
                    {home?.home?.name}
                    {!!home?.guid && `(${home?.guid})`}
                  </StyledButton>
                </SplideSlide>
              ))}
            </HomeSplide>
          )}
        </Box>

        <Box className="col-span-12 lg:col-span-8">
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            {loadingList && (
              <StyledSpinner
                size={18}
                trailcolor={theme.palette.grey.aAzureishWhite}
                spinnercolor={theme.palette.info.main}
              />
            )}

            <StyledTabs
              value={activeTab}
              onChange={handleTabChange}
              sx={{
                marginBottom: 2,
                paddingRight: 2.5,
                borderBottom: "none",
                "& .MuiTabs-flexContainer": { justifyContent: "flex-end" },
                "& .MuiTab-root": { paddingTop: 0, color: "#ffffff", fontSize: 14, fontWeight: 400 },
                "& .MuiTabs-indicator": { backgroundColor: "#ffffff" },
              }}
            >
              {userMilestoneData?.map((item, index) => (
                <StyledTabs.TabItem key={index} label={item.displayName} wrapped={true} />
              ))}
            </StyledTabs>
          </Box>

          <Splide options={sliderOption} className="overflow-hidden rounded-2xl">
            {userMilestoneData?.[activeTab]?.milestones?.map((milestone, index) => (
              <SplideSlide key={milestone.id} className="w-[220px] overflow-hidden rounded-2xl ">
                <MilestoneCard milestone={milestone} />
                {/* <StyledCard
                  sx={{
                    minHeight: 210,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "15px 10px",
                    backgroundColor: generateRandomColor(referenceColors[index % referenceColors.length]),
                    color: theme.palette.blue.yankeesBlue,
                  }}
                >
                  <Typography component="span" sx={{ marginBottom: 3, fontSize: 14, fontWeight: 600 }}>
                    {milestone?.order < 9 ? `0${milestone?.order}` : milestone?.order}
                  </Typography>

                  <Box
                    component="figure"
                    sx={{
                      width: 32,
                      height: 32,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: 0,
                      borderRadius: 2.5,
                      backgroundColor: theme.palette.blue.yankeesBlue,
                      color: "#fff",
                    }}
                    className="ring-1 ring-[#7754FF]/20"
                  >
                    {milestone?.milestone?.icon &&
                      React.createElement(
                        iconList?.find((icon) => icon.iconName === milestone?.milestone?.icon)?.iconComp,
                        {}
                      )}
                  </Box>

                  <Typography component="h6" sx={{ margin: "11px 0", fontSize: 15, fontWeight: 600, lineHeight: 1.2 }}>
                    {milestone?.milestone?.name}
                  </Typography>

                  <Box sx={{ width: "100%", marginTop: "auto" }}>
                    <Typography component="p" sx={{ marginBottom: "5px", color: "#415175", fontSize: 12 }}>
                      {milestone?.documents?.length} Documents | {getMilestoneProgress(milestone?.documents)}%
                    </Typography>
                    <ProgressBar
                      className="w-full"
                      variant="determinate"
                      value={getMilestoneProgress(milestone?.documents)}
                    />
                  </Box>
                </StyledCard> */}
              </SplideSlide>
            ))}
          </Splide>

          <Box sx={{ width: "100%", marginTop: 2, paddingRight: 2.5 }}>
            <Typography component="h6" sx={{ marginBottom: "5px", color: "#ffffff", fontSize: 14, fontWeight: 400 }}>
              Construction progress | {userMilestoneData?.[activeTab]?.total?.percent.toFixed(0)}%
            </Typography>
            <ProgressBar
              className="w-full"
              size={10}
              barcolor="#268D15"
              variant="determinate"
              value={userMilestoneData?.[activeTab]?.total?.percent}
            />
          </Box>
        </Box>
      </Box>
    </StyledCard>
  );
};

export default WelcomeCard;
