import React, { useEffect, useRef } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getConfigChoiceCategory, addConfigChoiceCategory, editConfigChoiceCategory } from "reduxs/actions";
import { InputField } from "ui/form/field";
import { StyledButton, StyledCard } from "ui";

const ConfigChoiceCategoryForm = (props) => {
  const { editId } = props;
  const formikRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, configChoiceCategoryData } = useSelector((state) => state.configChoiceCategory);

  const initialValues = {
    category: editId ? configChoiceCategoryData?.category || "" : "",
    displayName: editId ? configChoiceCategoryData?.displayName || "" : "",
  };

  const schema = Yup.object().shape({
    category: Yup.string().required("Enter category"),
    displayName: Yup.string().required("Enter display name"),
  });

  const onSubmit = (values) => {
    if (!loading) {
      if (editId) {
        dispatch(editConfigChoiceCategory(editId, values, navigate));
      } else {
        dispatch(addConfigChoiceCategory(values, navigate));
      }
    }
  };

  useEffect(() => {
    if (editId) dispatch(getConfigChoiceCategory(editId));
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ resetForm }) => (
        <Form>
          <StyledCard sx={{ p: "1.5rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField name="category" type="text" label="Category*" />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputField name="displayName" type="text" label="Display Name*" />
              </Grid>
            </Grid>

            <Box
              width="100%"
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={{ sm: "flex-end" }}
              gap={2}
              mt={5}
            >
              <StyledButton type="submit" isloading={loading}>
                {editId ? "Update Category" : "Create Category"}
              </StyledButton>

              <StyledButton
                type="button"
                variant="outlined"
                color={theme.palette.grey.main}
                sx={{ color: theme.palette.text.primary }}
                component={Link}
                to={"/system-setting/config-choice-category"}
              >
                Cancel
              </StyledButton>

              <StyledButton type="button" variant="outlined" color={theme.palette.error.main} onClick={resetForm}>
                Reset
              </StyledButton>
            </Box>
          </StyledCard>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigChoiceCategoryForm;
