import React from "react";
import { Box, IconButton } from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import { useDispatch } from "react-redux";
import { getMessageList } from "reduxs/actions";
import { StyledSearch } from "ui";
import { useMessage } from "./MessageContext";

const MobileNavToolbar = () => {
  const dispatch = useDispatch();

  const { currentMenu, userInfo, isMobileNavListOpen, isMobileUserListOpen, updateContext } = useMessage();

  const handleSearch = (event) => {
    event.preventDefault();
    const val = event.target.value;
    const searchFields = `${currentMenu === "inbox" ? "messageFromUser.name:like;" : ""}${
      currentMenu === "sent" ? "messageTo.messageToUser.name:like;" : ""
    }title:like;`;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        dispatch(
          getMessageList({
            ...(currentMenu === "sent" && { messageFrom: userInfo.id }), // Get's Sent Messages
            ...(currentMenu === "inbox" && { messageTo: userInfo.id }), // Get's Inbox Messages
            search: val,
            searchFields: searchFields,
          })
        );
      }, 1000)
    );
  };

  return (
    <Box className="mb-4 sticky -top-0 z-[2] backdrop-blur" sx={{ display: "flex", gap: "10px" }}>
      <IconButton onClick={() => updateContext({ isMobileNavListOpen: !isMobileNavListOpen })}>
        <EmailRoundedIcon />
      </IconButton>

      <IconButton onClick={() => updateContext({ isMobileUserListOpen: !isMobileUserListOpen })}>
        <TextsmsRoundedIcon />
      </IconButton>

      <StyledSearch handleSearch={(event) => handleSearch(event)} />
    </Box>
  );
};

export default MobileNavToolbar;
