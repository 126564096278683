import React, { createContext, useContext, useState } from "react";

const DashboardContext = createContext({
  userInfo: null,
  userMilestones: null,
  configChoice: null,
});

export const DashboardProvider = ({ children, userInfo, userMilestones, configChoice }) => {
  const [dashboardContext, setDashboardContext] = useState({
    userInfo,
    userMilestones,
    configChoice,
  });

  const updateContext = (UpdatedDashboardContext) => {
    setDashboardContext(UpdatedDashboardContext);
  };

  return (
    <DashboardContext.Provider value={{ ...dashboardContext, updateContext }}>{children}</DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
