import React, { useMemo } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledCard, StyledButton } from "ui";
import graphics from "assets/images/graphics--001.svg";
import CustomerDashboard from "./CustomerDashboard";

const Dashboard = () => {
  const theme = useTheme();

  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser"));
  }, [localStorage.getItem("currentUser")]);

  return (
    <>
      {!!(user.role === "client") && <CustomerDashboard />}

      {/* TO DO Other Dashboard by Role */}
      {!!(user.role !== "client") && (
        <StyledCard
          sx={{
            padding: "2.5rem",
            backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : "#c8facd",
          }}
        >
          <Box display="flex" justifyContent="space-between" gap="5rem">
            <Box maxWidth="50%">
              <Typography component="h4" fontSize="2.5rem" fontWeight="700">
                Welcome back! <br /> {user?.name}
              </Typography>
              <Typography component="p" my={3}>
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout.
              </Typography>
              <StyledButton type="button" component={Link} to={"/profile"}>
                Go to Profile
              </StyledButton>
            </Box>

            <Box maxWidth="20rem" flexShrink="0" component="figure" sx={{ m: 0 }}>
              <img className="w-full" src={graphics} alt="" />
            </Box>
          </Box>
        </StyledCard>
      )}
    </>
  );
};

export default Dashboard;
