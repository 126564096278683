import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { StyledButton, StyledDialog, FileUpload } from "ui";
import { Form, Formik } from "formik";
import { editUserHomeMilestone } from "reduxs/actions";
import { useDispatch, useSelector } from "react-redux";

const UploadImageModal = (props) => {
  const { openDialog, editData, dbParams, handleClose } = props;

  const formikRef = useRef();
  const dispatch = useDispatch();

  const { frmLoading } = useSelector((state) => state.userHomeMilestone);

  const initialValues = {
    userHomeId: editData ? editData?.userHomeId || "" : "",
    milestoneId: editData ? editData?.milestoneId || "" : "",
    note: editData ? editData?.note || "" : "",
    description: editData ? editData?.description || "" : "",
    media: [],
  };

  const onSubmit = (values) => {
    if (!frmLoading) {
      if (editData) {
        dispatch(editUserHomeMilestone(editData.id, values, dbParams));
      }
    }
  };

  useEffect(() => {
    if (!frmLoading && formikRef?.current?.values?.media?.length > 0) {
      handleClose(true);
    }
  }, [frmLoading]);

  return (
    <StyledDialog
      open={openDialog}
      handleclose={() => handleClose()}
      dialogProps={{ fullScreen: false, maxWidth: "sm", sx: { "& .MuiPaper-root": { borderRadius: 4, py: 3 } } }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // validationSchema={schema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexFlow: { xs: "column-reverse", sm: "row" },
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: 3,
                mb: 4.5,
              }}
            >
              <Typography component="h4" sx={{ fontSize: 20, fontWeight: 600 }}>
                Upload images
              </Typography>
            </Box>

            <FileUpload
              maxFiles={50}
              accept="image/*"
              setMedia={(files, progress) => progress && setFieldValue("media", files)}
              multiple={true}
            />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: { sm: "flex-end" },
                alignItems: "center",
                gap: 2,
                position: "sticky",
                bottom: 0,
                mt: 2,
                py: 2,
              }}
              className="backdrop-blur-sm"
            >
              <StyledButton type="submit" disabled={values?.media?.length === 0 || frmLoading} isloading={frmLoading}>
                Upload
              </StyledButton>
            </Box>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  );
};

export default UploadImageModal;
