import React from "react";
import { useParams } from "react-router-dom";
import { BreadcrumbContainer } from "ui";
import ConfigChoiceCategoryForm from "./Form";

const EditConfigChoiceCategory = () => {
  const params = useParams();

  return (
    <>
      <BreadcrumbContainer
        title={params.id ? "Update category" : "Create a new category"}
        paths={[
          {
            title: "Config Choice Category",
            page: `/system-setting/config-choice-category`,
          },
          {
            title: params.id ? "Edit" : "Add",
          },
        ]}
      />

      <ConfigChoiceCategoryForm editId={params.id} />
    </>
  );
};

export default EditConfigChoiceCategory;
