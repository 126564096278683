import React, { createContext, useContext, useState } from "react";

const MessageContext = createContext({
  currentMenu: null,
  userInfo: null,
  selectedMessage: null,
  isMobileUserListOpen: false,
  isMobileNavListOpen: false,
});

export const MessageProvider = ({
  children,
  currentMenu,
  userInfo,
  selectedMessage,
  isMobileUserListOpen,
  isMobileNavListOpen,
}) => {
  const [messageContext, setMessageContext] = useState({
    currentMenu,
    userInfo,
    selectedMessage,
    isMobileUserListOpen,
    isMobileNavListOpen,
  });

  const updateContext = (UpdatedMessageContext) => {
    setMessageContext((prev) => ({ ...prev, ...UpdatedMessageContext }));
  };

  return <MessageContext.Provider value={{ ...messageContext, updateContext }}>{children}</MessageContext.Provider>;
};

export const useMessage = () => useContext(MessageContext);
