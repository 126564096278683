import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const MessageService = (function () {
  function _getMessageList(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/messages?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }

  function _getMessage(id) {
    return Req.get(`/api/messages/${id}`);
  }

  function _composeMessage(data) {
    return Req.post("/api/messages", data);
  }

  function _editMessage(data, id) {
    return Req.put(`/api/messages/${id}`, data);
  }

  function _updateMessageStatus(id, data) {
    return Req.put(`/api/messages/set/${id}`, data);
  }

  function _deleteMessage(id) {
    return Req.delete(`/api/messages/${id}`);
  }

  // ## Message Group Services
  function _getAllMessageGroup() {
    return Req.get("/api/message-groups/all");
  }
  function _getMessageGroup(id) {
    return Req.get(`/api/message-groups/${id}`);
  }
  function _addMessageGroup(data) {
    return Req.post("/api/message-groups", data);
  }
  function _editMessageGroup(data, id) {
    return Req.put(`/api/message-groups/${id}`, data);
  }
  function _deleteMessageGroup(id) {
    return Req.delete(`/api/message-groups/${id}`);
  }

  return {
    getMessageList: _getMessageList,
    getMessage: _getMessage,
    composeMessage: _composeMessage,
    editMessage: _editMessage,
    updateMessageStatus: _updateMessageStatus,
    deleteMessage: _deleteMessage,

    getAllMessageGroup: _getAllMessageGroup,
    getMessageGroup: _getMessageGroup,
    addMessageGroup: _addMessageGroup,
    editMessageGroup: _editMessageGroup,
    deleteMessageGroup: _deleteMessageGroup,
  };
})();
export default MessageService;
