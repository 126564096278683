import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_ALL_CONFIG_CHOICE,
  GET_CONFIG_CHOICE_LIST,
  ADD_CONFIG_CHOICE,
  GET_CONFIG_CHOICE,
  EDIT_CONFIG_CHOICE,
  DELETE_CONFIG_CHOICE,
} from "../actions";
import {
  getAllConfigChoiceSuccess,
  getAllConfigChoiceError,
  getConfigChoiceList,
  getConfigChoiceListSuccess,
  getConfigChoiceListError,
  addConfigChoiceSuccess,
  addConfigChoiceError,
  getConfigChoiceSuccess,
  getConfigChoiceError,
  editConfigChoiceSuccess,
  editConfigChoiceError,
  deleteConfigChoiceSuccess,
  deleteConfigChoiceError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import ConfigChoiceService from "services/ConfigChoiceService";
import TableDataService from "services/TableDataService";

const getMetaData = (state) => state.configChoice.metaData;

export function* watchgetAllConfigChoice() {
  yield takeEvery(GET_ALL_CONFIG_CHOICE, getAllConfigChoice);
}

const getAllConfigChoiceAsync = async () => {
  return ConfigChoiceService.getAllConfigChoice();
};

function* getAllConfigChoice() {
  try {
    const response = yield call(getAllConfigChoiceAsync);
    if (response.data) {
      yield put(getAllConfigChoiceSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllConfigChoiceError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetConfigChoiceList() {
  yield takeEvery(GET_CONFIG_CHOICE_LIST, getConfigChoiceListAc);
}

const getConfigChoiceListAsync = async (dbParam) => {
  return TableDataService.getAllData("config-choices", dbParam);
};

function* getConfigChoiceListAc({ payload }) {
  try {
    const response = yield call(getConfigChoiceListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getConfigChoiceListSuccess(response.data.data, response.data.meta));
    } else {
      toaster("", response.data.message);
      yield put(getConfigChoiceListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getConfigChoiceListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddConfigChoice() {
  yield takeEvery(ADD_CONFIG_CHOICE, addConfigChoice);
}

const addConfigChoiceAsync = async (data) => {
  return ConfigChoiceService.addConfigChoice(data);
};

function* addConfigChoice({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(addConfigChoiceAsync, payload.configChoiceData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addConfigChoiceSuccess(response.data.success));
      navigate(`/system-setting/config-choice`);
    } else {
      toaster("", response.data.message);
      yield put(addConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addConfigChoiceError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetConfigChoice() {
  yield takeEvery(GET_CONFIG_CHOICE, getConfigChoice);
}

const getConfigChoiceAsync = async (id) => {
  return ConfigChoiceService.getConfigChoice(id);
};

function* getConfigChoice({ payload }) {
  try {
    const response = yield call(getConfigChoiceAsync, payload.configChoiceId);
    if (response.data.success) {
      yield put(getConfigChoiceSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getConfigChoiceError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditConfigChoice() {
  yield takeEvery(EDIT_CONFIG_CHOICE, editConfigChoice);
}

const editConfigChoiceAsync = async (data, id) => {
  return ConfigChoiceService.editConfigChoice(data, id);
};

function* editConfigChoice({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(editConfigChoiceAsync, payload.configChoiceData, payload.configChoiceId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editConfigChoiceSuccess(response.data.success));
      navigate(`/system-setting/config-choice`);
    } else {
      toaster("", response.data.message);
      yield put(editSConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editConfigChoiceError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteConfigChoice() {
  yield takeEvery(DELETE_CONFIG_CHOICE, deleteConfigChoice);
}

const deleteConfigChoiceAsync = async (id) => {
  return ConfigChoiceService.deleteConfigChoice(id);
};

function* deleteConfigChoice({ payload }) {
  try {
    const metaData = yield select(getMetaData);
    const response = yield call(deleteConfigChoiceAsync, payload.configChoiceId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteConfigChoiceSuccess(response.data.success));
      yield put(getConfigChoiceList({ page: metaData.page, pageSize: metaData.pageSize }));
    } else {
      toaster("", response.data.message);
      yield put(deleteConfigChoiceError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteConfigChoiceError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllConfigChoice),
    fork(watchGetConfigChoiceList),
    fork(watchAddConfigChoice),
    fork(watchGetConfigChoice),
    fork(watchEditConfigChoice),
    fork(watchDeleteConfigChoice),
  ]);
}
