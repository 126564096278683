import React, { useEffect } from "react";
import { Avatar, Box, Skeleton, Typography, useTheme } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reduxs/actions";
import { StatusChip, StyledButton } from "ui";
import defaultUserAatar from "assets/images/default-user.png";

const ViewUser = (props) => {
  const { id, handleaction } = props;

  const theme = useTheme();

  const dispatch = useDispatch();

  const { userData, fetchLoading } = useSelector((state) => state.user);

  useEffect(() => {
    if (id) dispatch(getUser(id));
  }, []);

  return !fetchLoading && userData ? (
    <>
      <Box component="section" display="flex" gap={2} mt={1}>
        <Avatar
          variant="rounded"
          sx={{ width: 100, height: 100, borderRadius: "18px" }}
          src={userData?.media?.[0]?.url || defaultUserAatar}
        />

        <Box display="flex" flexDirection="column">
          <Typography component="span" color={theme.palette.info.main} textTransform="capitalize" mb={1}>
            {userData?.role || ""}
          </Typography>

          <Typography
            component="h4"
            display="flex"
            alignItems="center"
            gap={2}
            fontSize="28px"
            fontWeight="bold"
            lineHeight={1}
          >
            {userData?.name || ""}
            <VerifiedIcon sx={{ color: userData?.isVerified ? theme.palette.primary.main : theme.palette.warn.main }} />
          </Typography>

          <Typography component="p" color={theme.palette.grey.dark} mt={1.8}>
            {userData?.address || ""}
          </Typography>
        </Box>
      </Box>

      <Box
        component="section"
        className="grid grid-cols-1 md:grid-cols-3 divide-y md:divide-y-0 md:divide-x mt-8 divide-gray-[#919EAB4D]"
      >
        <Typography component="h5" fontWeight="500" textAlign="center" p={1} className="break-words">
          {userData?.email || ""}
          <Typography component="span" color={theme.palette.grey.dark} className="block font-normal mt-1">
            Email
          </Typography>
        </Typography>

        <Typography component="h5" fontWeight="500" textAlign="center" p={1}>
          {userData?.phone && "+61"} {userData?.phone || ""}
          <Typography component="span" color={theme.palette.grey.dark} className="block font-normal mt-1">
            Contact
          </Typography>
        </Typography>

        <Typography component="h5" fontWeight="500" textAlign="center" p={1}>
          <StatusChip
            status={userData?.statusConfigChoice?.configChoice || ""}
            label={userData?.statusConfigChoice?.displayName || ""}
          />
          <Typography component="span" color={theme.palette.grey.dark} className="block font-normal mt-1">
            Status
          </Typography>
        </Typography>
      </Box>

      <Box component="section" display="flex" justifyContent="center" gap="22px" mt={5.5} mb={2}>
        <StyledButton color={theme.palette.info.main} onClick={() => handleaction("update")}>
          Update User
        </StyledButton>
        <StyledButton color={theme.palette.error.main} variant="outlined" onClick={() => handleaction("delete")}>
          Delete User
        </StyledButton>
      </Box>
    </>
  ) : (
    <Box component="section" display="flex" gap={2} mt={1}>
      <Skeleton variant="rounded" width={100} height={100} className="flex-shrink-0" />

      <Box display="flex" flexDirection="column" width="100%">
        <Skeleton variant="text" width="80px" height={18} />
        <Skeleton variant="text" width="50%" height={50} />
        <Skeleton variant="text" width="60%" height={25} />
      </Box>
    </Box>
  );
};

export default ViewUser;
