import React, { useState, useEffect, useCallback } from "react";
import { Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";
import Row from "./Row";
import SimpleTable from "../SimpleTable";

const Table = (props) => {
  const {
    isinitialized = false, // Required to pre-select row after data is loaded
    classes,
    loading,
    selectionRow,
    preselectedrow,
    draggableRow,
    emptyMsg,
    handleSort,
    handleRowClick,
    handleRowDrag,
    handleRowSelect,

    //*** TableInstances
    // rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    flatRows,
    selectedFlatRows,
    toggleRowSelected,
    totalPage,
    //*** TableInstances
  } = props;

  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedRow, setSelectedRow] = useState(selectionRow);

  const onSort = (value, key) => {
    const order = key !== undefined ? (key ? "desc" : "asc") : sortOrder;
    setSortOrder(order);
    handleSort(order, value);
  };

  const setAllRowsSelected = useCallback(
    (selected, preselectedRowIds) => {
      preselectedRowIds.forEach((rowId) => {
        toggleRowSelected(rowId, selected);
      });
    },
    [toggleRowSelected]
  );

  useEffect(() => {
    setSelectedRow(selectionRow);
  }, [selectionRow]);

  useEffect(() => {
    if (selectedFlatRows && handleRowSelect) {
      handleRowSelect(flatRows, selectedFlatRows);
    }
  }, [selectedFlatRows, handleRowSelect]);

  useEffect(() => {
    if (isinitialized && preselectedrow?.length) {
      setAllRowsSelected(true, preselectedrow);
    }
  }, [isinitialized, preselectedrow]);

  // backend={isTouchDevice() ? TouchBackend : HTML5Backend}
  return (
    <DndProvider backend={HTML5Backend}>
      <SimpleTable
        {...getTableProps()}
        isloading={loading}
        wrapperProps={{ sx: { position: "relative", padding: "0 .625rem" } }}
      >
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {draggableRow && <th width="15" className="!px-0"></th>}

              {headerGroup.headers.map((column, j) => (
                <th
                  key={j}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  width={column.id === "selection" || column.id === "expander" || column.id === "order" ? "25" : ""}
                  className={`${classes?.head || ""}`}
                >
                  <Typography
                    sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                    className={`
                    ${column.render("Header") === "Actions" ? "justify-end" : ""}`}
                    onClick={() => {
                      column?.canSort && totalPage > 1 && onSort(column?.id, column.isSortedDesc);
                    }}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      <Typography component="i" lineHeight="1">
                        {column.isSortedDesc ? (
                          <ArrowDownwardIcon fontSize="16px" />
                        ) : (
                          <ArrowUpwardIcon fontSize="16px" />
                        )}
                      </Typography>
                    ) : null}
                  </Typography>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className={`${classes?.tbody || ""}`} {...getTableBodyProps()}>
          {page?.length > 0 &&
            page.map((row, index) => {
              return (
                prepareRow(row) || (
                  <Row
                    row={row}
                    index={index}
                    moveRow={handleRowDrag}
                    draggable={draggableRow}
                    selectedRow={selectedRow}
                    handleRowClick={handleRowClick}
                    {...row.getRowProps()}
                  />
                )
              );
            })}

          {!loading && page?.length === 0 && (
            <tr>
              <td
                colSpan={draggableRow ? headerGroups?.[0].headers.length + 1 : headerGroups?.[0].headers.length}
                className="text-gray-600 text-sm text-center"
              >
                {emptyMsg || "No Data"}
              </td>
            </tr>
          )}
        </tbody>
      </SimpleTable>
    </DndProvider>
  );
};

export default Table;
