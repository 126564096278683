import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_HOME_CUSTOMIZATION, ADD_HOME_CUSTOMIZATION, DELETE_HOME_CUSTOMIZATION } from "../actions";
import {
  getAllHomeCustomization,
  getAllHomeCustomizationSuccess,
  getAllHomeCustomizationError,
  addHomeCustomizationSuccess,
  addHomeCustomizationError,
  deleteHomeCustomizationSuccess,
  deleteHomeCustomizationError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import HomeCustomizationService from "services/HomeCustomizationService";

export function* watchgetAllHomeCustomization() {
  yield takeEvery(GET_ALL_HOME_CUSTOMIZATION, getAllHomeCustomizationAc);
}

const getAllHomeCustomizationAsync = async (data) => {
  return HomeCustomizationService.getAllHomeCustomization(data);
};

function* getAllHomeCustomizationAc({ payload }) {
  try {
    const response = yield call(getAllHomeCustomizationAsync, payload.filterParam);
    if (response.data) {
      yield put(getAllHomeCustomizationSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddHomeCustomization() {
  yield takeEvery(ADD_HOME_CUSTOMIZATION, addHomeCustomization);
}

const addHomeCustomizationAsync = async (data) => {
  return HomeCustomizationService.addHomeCustomization(data);
};

function* addHomeCustomization({ payload }) {
  try {
    const response = yield call(addHomeCustomizationAsync, payload.homeCustomizationData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addHomeCustomizationSuccess(response.data.success));
      yield put(
        getAllHomeCustomization({
          homeId: payload.homeCustomizationData.homeId,
        })
      );
    } else {
      toaster("", response.data.message);
      yield put(addHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteHomeCustomization() {
  yield takeEvery(DELETE_HOME_CUSTOMIZATION, deleteHomeCustomization);
}

const deleteHomeCustomizationAsync = async (id) => {
  return HomeCustomizationService.deleteHomeCustomization(id);
};

function* deleteHomeCustomization({ payload }) {
  try {
    const response = yield call(deleteHomeCustomizationAsync, payload.homeCustomizationId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteHomeCustomizationSuccess(response.data.success));
      yield put(getAllHomeCustomization({ homeId: payload.homeId }));
    } else {
      toaster("", response.data.message);
      yield put(deleteHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([fork(watchgetAllHomeCustomization), fork(watchAddHomeCustomization), fork(watchDeleteHomeCustomization)]);
}
