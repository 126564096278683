import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { StyledButton, StyledDialog, FileUpload } from "ui";

const UploadFileModal = (props) => {
  const { openDialog, handleClose } = props;

  const { values, setFieldValue } = useFormikContext();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleOnAdd = () => {
    if (uploadedFiles) {
      if (!!values.media.length) {
        setFieldValue("media", [...values.media, ...uploadedFiles]);
      } else {
        setFieldValue("media", uploadedFiles);
      }
      handleClose();
    }
  };

  useEffect(() => {
    () => {
      setUploadedFiles([]);
    };
  }, []);

  return (
    <StyledDialog
      open={openDialog.open}
      handleclose={() => handleClose()}
      dialogProps={{ fullScreen: false, maxWidth: "sm", sx: { "& .MuiPaper-root": { borderRadius: 4, py: 3 } } }}
    >
      <Box
        sx={{
          display: "flex",
          flexFlow: { xs: "column-reverse", sm: "row" },
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 3,
          mb: 4.5,
        }}
      >
        <Typography component="h4" sx={{ fontSize: 20, fontWeight: 600 }}>
          Upload file
        </Typography>
      </Box>

      <FileUpload
        maxFiles={10}
        accept={openDialog.fileType}
        setMedia={(files, progress) => progress && setUploadedFiles(files)}
        multiple={true}
      />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { sm: "flex-end" },
          alignItems: "center",
          gap: 2,
          position: "sticky",
          bottom: 0,
          mt: 2,
          py: 2,
        }}
        className="backdrop-blur-sm"
      >
        <StyledButton type="button" onClick={() => handleOnAdd()} disabled={uploadedFiles.length === 0}>
          Add file
        </StyledButton>
      </Box>
    </StyledDialog>
  );
};

export default UploadFileModal;
