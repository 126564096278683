import React, { useEffect, useMemo, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { SelectField, StyledSpinner } from "ui";

const MessageToSelect = ({ variant = "standard" }) => {
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const { users } = useSelector((state) => state.user);
  const { allMessageGroups } = useSelector((state) => state.messageGroup);

  const [openSearch, setOpenSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const allOptions = useMemo(() => {
    if (users && !!users.length) {
      return users.map((data) => ({
        id: data?.id,
        name: data?.name,
        secondayName: data?.email,
        image: data?.messageFromUser?.media?.[0]?.url,
      }));
    }

    return [];
  }, [users, values.messageGroupId]);

  let typingTimer;

  const onSearch = (value) => {
    clearTimeout(typingTimer);
    setIsSearching(true);

    if (value) {
      typingTimer = setTimeout(() => {
        setOpenSearch(true);
        setIsSearching(false);
      }, 500);
    } else {
      setOpenSearch(false);
      setIsSearching(false);
    }
  };

  useEffect(() => {
    if (values.messageGroupId) {
      const selectedGroup = allMessageGroups.find((group) => group.id === values.messageGroupId);
      const selectedUsers = selectedGroup?.messageGroupUsers?.map((grpUsers) => grpUsers.user.id);
      setFieldValue("messageTo", []);
      setFieldValue("messageTo", selectedUsers);
    } else setFieldValue("messageTo", []);
  }, [values.messageGroupId]);

  return (
    <SelectField
      name="messageTo"
      type="text"
      placeholder={!values?.messageTo?.length && "To"}
      limitTags={5}
      multiple
      hasImage={true}
      options={allOptions}
      autoCompletePros={{
        open: openSearch,
        disableClearable: true,
        popupIcon: (
          <>
            {isSearching && (
              <StyledSpinner size={18} wrapperprops={{ sx: { display: "flex", position: "relative" } }} />
            )}
          </>
        ),
        onInputChange: (_, value) => onSearch(value),
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.name === value.name,
        filterOptions: (options, { inputValue }) => {
          return options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()));
        },
        onBlur: () => setOpenSearch(false),
        sx: {
          "& .MuiInputBase-root": {
            backgroundColor: variant === "outlined" ? alpha(theme.palette.grey.cadet, 0.08) : "",
          },
        },
      }}
      allInputProps={{
        variant: variant,
        autoFocus: variant === "outlined",
      }}
    />
  );
};

export default MessageToSelect;
