import React, { useEffect, useState } from "react";
import { Box, Drawer, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloseFullscreenRoundedIcon from "@mui/icons-material/CloseFullscreenRounded";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: "600px",
  zIndex: 1300,

  "& .MuiPaper-root": {
    maxWidth: "100%",
    maxHeight: "90%",
    minHeight: useMediaQuery(theme.breakpoints.down("sm")) ? "100%" : "50%",
    width: "600px",
    height: "auto",
    flexDirection: "column",
    bottom: 0,
    top: "auto",
    margin: useMediaQuery(theme.breakpoints.down("sm")) ? "0" : "0 24px 24px 0",
    padding: "0 12px 30px 24px",
    borderRadius: "1rem",
    boxShadow:
      theme.palette.mode === "light"
        ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
        : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
    background: theme.palette.background.paper,
    overflow: "hidden",
  },
  "& .MuiBackdrop-root": {
    backgroundColor: theme.palette.backdrop,
  },

  "&.expanded": {
    width: "100%",

    "& .MuiPaper-root": {
      maxHeight: "calc(100% - 32px)",
      minHeight: "calc(100% - 32px)",
      width: "calc(100% - 32px)",
      top: 16,
      right: 16,
      margin: 0,
    },
  },
}));

const FloatingForm = (props) => {
  const { open, title, canexpand = false, handleclose, children, wrapperProps, headerProps, contentProps } = props;

  const theme = useTheme();

  const [expand, setExpand] = useState(false);

  const onClose = () => {
    setExpand(false);
    handleclose();
  };

  useEffect(() => {
    () => {
      setExpand(false);
    };
  }, []);

  return (
    <StyledDrawer variant="temporary" anchor="right" open={open} className={expand ? "expanded" : ""} {...wrapperProps}>
      <Box
        component="header"
        display="flex"
        alignItems="flex-start"
        gap={1.5}
        pt={3}
        pb={2}
        borderBottom={`1px solid ${theme.palette.slate.A100}`}
        backgroundColor={theme.palette.background.paper}
        zIndex={2}
        {...headerProps}
      >
        <Typography
          component="h4"
          sx={{
            mr: "auto",
            fontSize: 20,
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>

        {canexpand && (
          <IconButton size="small" onClick={() => setExpand(!expand)}>
            <CloseFullscreenRoundedIcon />
          </IconButton>
        )}
        <IconButton size="small" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box flex={1} pt={2} pr={1.5} overflow="auto" {...contentProps}>
        {children}
      </Box>
    </StyledDrawer>
  );
};

export default FloatingForm;
