import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { GET_ALL_HOME, GET_HOME_LIST, ADD_HOME, GET_HOME, EDIT_HOME, DELETE_HOME } from "../actions";
import {
  getAllHomeSuccess,
  getAllHomeError,
  getHomeList,
  getHomeListSuccess,
  getHomeListError,
  addHomeSuccess,
  addHomeError,
  getHomeSuccess,
  getHomeError,
  editHomeSuccess,
  editHomeError,
  deleteHomeSuccess,
  deleteHomeError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import HomeService from "services/HomeService";
import TableDataService from "services/TableDataService";

const getMetaData = (state) => state.home.metaData;

export function* watchgetAllHome() {
  yield takeEvery(GET_ALL_HOME, getAllHome);
}

const getAllHomeAsync = async () => {
  return HomeService.getAllHome();
};

function* getAllHome() {
  try {
    const response = yield call(getAllHomeAsync);
    if (response.data) {
      yield put(getAllHomeSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetHomeList() {
  yield takeEvery(GET_HOME_LIST, getHomeListAc);
}

const getHomeListAsync = async (dbParam) => {
  return TableDataService.getAllData("homes", dbParam);
};

function* getHomeListAc({ payload }) {
  try {
    const response = yield call(getHomeListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getHomeListSuccess(response.data.data, response.data.meta));
    } else {
      toaster("", response.data.message);
      yield put(getHomeListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getHomeListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddHome() {
  yield takeEvery(ADD_HOME, addHome);
}

const addHomeAsync = async (data) => {
  return HomeService.addHome(data);
};

function* addHome({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(addHomeAsync, payload.homeData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addHomeSuccess(response.data.success));
      navigate(`/configuration/home`);
    } else {
      toaster("", response.data.message);
      yield put(addHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetHome() {
  yield takeEvery(GET_HOME, getHome);
}

const getHomeAsync = async (id) => {
  return HomeService.getHome(id);
};

function* getHome({ payload }) {
  try {
    const response = yield call(getHomeAsync, payload.homeId);
    if (response.data.success) {
      yield put(getHomeSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditHome() {
  yield takeEvery(EDIT_HOME, editHome);
}

const editHomeAsync = async (data, id) => {
  return HomeService.editHome(data, id);
};

function* editHome({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(editHomeAsync, payload.homeData, payload.homeId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editHomeSuccess(response.data.success));
      navigate(`/configuration/home`);
    } else {
      toaster("", response.data.message);
      yield put(editSHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteHome() {
  yield takeEvery(DELETE_HOME, deleteHome);
}

const deleteHomeAsync = async (id) => {
  return HomeService.deleteHome(id);
};

function* deleteHome({ payload }) {
  try {
    const metaData = yield select(getMetaData);
    const response = yield call(deleteHomeAsync, payload.homeId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteHomeSuccess(response.data.success));
      yield put(getHomeList({ page: metaData.page, pageSize: metaData.pageSize }));
    } else {
      toaster("", response.data.message);
      yield put(deleteHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllHome),
    fork(watchGetHomeList),
    fork(watchAddHome),
    fork(watchGetHome),
    fork(watchEditHome),
    fork(watchDeleteHome),
  ]);
}
