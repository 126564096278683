export const GET_ALL_HOME = "GET_ALL_HOME";
export const GET_ALL_HOME_SUCCESS = "GET_ALL_HOME_SUCCESS";
export const GET_ALL_HOME_ERROR = "GET_ALL_HOME_ERROR";
export const GET_HOME_LIST = "GET_HOME_LIST";
export const GET_HOME_LIST_SUCCESS = "GET_HOME_LIST_SUCCESS";
export const GET_HOME_LIST_ERROR = "GET_HOME_LIST_ERROR";
export const ADD_HOME = "ADD_HOME";
export const ADD_HOME_SUCCESS = "ADD_HOME_SUCCESS";
export const ADD_HOME_ERROR = "ADD_HOME_ERROR";
export const GET_HOME = "GET_HOME";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const GET_HOME_ERROR = "GET_HOME_ERROR";
export const EDIT_HOME = "EDIT_HOME";
export const EDIT_HOME_SUCCESS = "EDIT_HOME_SUCCESS";
export const EDIT_HOME_ERROR = "EDIT_HOME_ERROR";
export const DELETE_HOME = "DELETE_HOME";
export const DELETE_HOME_SUCCESS = "DELETE_HOME_SUCCESS";
export const DELETE_HOME_ERROR = "DELETE_HOME_ERROR";
export const RESET_HOME = "RESET_HOME";

export const getAllHome = () => ({
  type: GET_ALL_HOME,
  payload: {},
});

export const getAllHomeSuccess = (homes) => ({
  type: GET_ALL_HOME_SUCCESS,
  payload: homes,
});

export const getAllHomeError = (error) => ({
  type: GET_ALL_HOME_ERROR,
  payload: error,
});

export const getHomeList = (dbParam) => ({
  type: GET_HOME_LIST,
  payload: { dbParam },
});

export const getHomeListSuccess = (homeList, metaData) => ({
  type: GET_HOME_LIST_SUCCESS,
  payload: { homeList, metaData },
});

export const getHomeListError = (error) => ({
  type: GET_HOME_LIST_ERROR,
  payload: error,
});

export const addHome = (homeData, navigate) => ({
  type: ADD_HOME,
  payload: { homeData, navigate },
});

export const addHomeSuccess = (success) => ({
  type: ADD_HOME_SUCCESS,
  payload: success,
});

export const addHomeError = (error) => ({
  type: ADD_HOME_ERROR,
  payload: error,
});

export const getHome = (homeId) => ({
  type: GET_HOME,
  payload: { homeId },
});

export const getHomeSuccess = (homeData) => ({
  type: GET_HOME_SUCCESS,
  payload: homeData,
});

export const getHomeError = (error) => ({
  type: GET_HOME_ERROR,
  payload: error,
});

export const editHome = (homeId, homeData, navigate) => ({
  type: EDIT_HOME,
  payload: { homeId, homeData, navigate },
});

export const editHomeSuccess = (success) => ({
  type: EDIT_HOME_SUCCESS,
  payload: success,
});

export const editHomeError = (error) => ({
  type: EDIT_HOME_ERROR,
  payload: error,
});

export const deleteHome = (homeId) => ({
  type: DELETE_HOME,
  payload: { homeId },
});

export const deleteHomeSuccess = (success) => ({
  type: DELETE_HOME_SUCCESS,
  payload: success,
});

export const deleteHomeError = (error) => ({
  type: DELETE_HOME_ERROR,
  payload: error,
});

export const resetHome = () => ({
  type: RESET_HOME,
  payload: {},
});
