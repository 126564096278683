import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reduxs/actions";
import { BreadcrumbContainer } from "ui";
import { BuildingProgress } from "shared/modules";

const CustomerHomeProgress = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);

  React.useEffect(() => {
    if (params.id) dispatch(getUser(params.id));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Building process"
          paths={[
            {
              title: "Customer",
              page: `/user-management/customer`,
            },
            {
              title: "View",
              page: `/user-management/customer/view/${userData?.id}`,
            },
            {
              title: "Building Progress",
            },
          ]}
        />
      </Box>

      <BuildingProgress />
    </>
  );
};

export default CustomerHomeProgress;
