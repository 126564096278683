import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getUser,
  getProductList,
  getAllUserHomeCustomization,
  addUserHomeCustomization,
  deleteUserHomeCustomization,
} from "reduxs/actions";
import { BreadcrumbContainer } from "ui";
import { HomeCustomizationView } from "shared/modules";

const CustomizeHome = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userHomeId = params.userHomeId;

  const { userData } = useSelector((state) => state.user);
  const { userHomeCustomizations, loading: actionLoading } = useSelector((state) => state.userHomeCustomization);

  const homeData = React.useMemo(() => userData?.userHomes?.find((x) => x.id === userHomeId).home || null, [userData]);

  const onSelect = (categoryId, parentCategoryId) => {
    dispatch(
      addUserHomeCustomization({
        userHomeId: userHomeId,
        parentProductId: parentCategoryId,
        productId: categoryId,
      })
    );
  };

  const onDelete = (id) => {
    if (id) dispatch(deleteUserHomeCustomization(id, userHomeId));
  };

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("currentUser")).id;

    // Fetch nested categories for tree view
    dispatch(getProductList());
    if (userId) dispatch(getUser(userId));

    if (userHomeId) {
      dispatch(getAllUserHomeCustomization({ userHomeId: userHomeId }));
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title={`Customizing ${userData?.userHomes?.find((x) => x.id === userHomeId)?.home?.name || ""}`}
          paths={[
            {
              title: "My Home",
              page: `/my-home`,
            },
            {
              title: "Home customization",
            },
          ]}
        />
      </Box>

      <Typography component="h3" fontSize={18} fontWeight={600} mb={2.5}>
        Let&apos;s start customization for{" "}
        <span className="text-primary-500">
          {userData?.userHomes?.find((x) => x.id === userHomeId)?.home?.name || ""}
        </span>
      </Typography>

      <HomeCustomizationView
        homeid={homeData?.id}
        selectionlist={userHomeCustomizations}
        selectionloading={actionLoading}
        onselect={onSelect}
        onremove={onDelete}
      />
    </>
  );
};

export default CustomizeHome;
