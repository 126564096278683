import React, { useEffect, useRef } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getConfigChoice, addConfigChoice, editConfigChoice, getAllConfigChoiceCategory } from "reduxs/actions";
import { InputField, SelectField } from "ui/form/field";
import { StyledButton, StyledCard, StyledSwitch } from "ui";

const ConfigChoiceForm = (props) => {
  const { editId } = props;
  const formikRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { configChoiceCategories } = useSelector((state) => state.configChoiceCategory);
  const { loading, configChoiceData } = useSelector((state) => state.configChoice);

  const initialValues = {
    configChoiceCategoryId: editId ? configChoiceData?.configChoiceCategoryId || "" : "",
    configChoice: editId ? configChoiceData?.configChoice || "" : "",
    displayName: editId ? configChoiceData?.displayName || "" : "",
    isDefault: editId ? configChoiceData?.isDefault || 1 : 1,
    isActive: editId ? configChoiceData?.isActive || 1 : 1,
    isSystemChoice: 0,
  };

  const schema = Yup.object().shape({
    configChoiceCategoryId: Yup.string().required("Select category"),
    configChoice: Yup.string().required("Enter choice"),
    displayName: Yup.string().required("Enter display name"),
  });

  const onSubmit = (values) => {
    if (!loading) {
      if (editId) {
        dispatch(editConfigChoice(editId, values, navigate));
      } else {
        dispatch(addConfigChoice(values, navigate));
      }
    }
  };

  useEffect(() => {
    dispatch(getAllConfigChoiceCategory());
    if (editId) dispatch(getConfigChoice(editId));
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, resetForm, setFieldValue }) => (
        <Form>
          <StyledCard sx={{ p: "1.5rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SelectField
                  name="configChoiceCategoryId"
                  type="text"
                  label="Category"
                  placeholder="Select Category"
                  options={
                    configChoiceCategories &&
                    configChoiceCategories.map((category) => {
                      return { id: category.id, name: category.displayName };
                    })
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputField name="configChoice" type="text" label="Choice*" />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputField name="displayName" type="text" label="Display Name*" />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={6} md={3}>
                <Typography component="label" display="block">
                  Is Default
                </Typography>
                <StyledSwitch
                  value={values.isDefault}
                  checked={values.isDefault === 1}
                  onChange={(e) => {
                    setFieldValue("isDefault", e.target.checked ? 1 : 0);
                  }}
                  name="isDefault"
                  label={values.isDefault === 1 ? "Enabled" : "Disabled"}
                />
              </Grid>

              <Grid item xs={6} md={3}>
                <Typography component="label" display="block">
                  Is Active
                </Typography>
                <StyledSwitch
                  value={values.isActive}
                  checked={values.isActive === 1}
                  onChange={(e) => {
                    setFieldValue("isActive", e.target.checked ? 1 : 0);
                  }}
                  name="isActive"
                  label={values.isActive === 1 ? "Enabled" : "Disabled"}
                />
              </Grid>

              {/* <Grid item xs={6} md={3}>
                <Typography component="label" display="block">
                  Is System Choice
                </Typography>
                <StyledSwitch
                  value={values.isSystemChoice}
                  checked={values.isSystemChoice === 1}
                  onChange={(e) => {
                    setFieldValue("isSystemChoice", e.target.checked ? 1 : 0);
                  }}
                  name="isSystemChoice"
                  label={values.isSystemChoice === 1 ? "Enabled" : "Disabled"}
                />
              </Grid> */}
            </Grid>

            <Box
              width="100%"
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={{ sm: "flex-end" }}
              gap={2}
              mt={5}
            >
              <StyledButton type="submit" isloading={loading}>
                {editId ? "Update Config Choice" : "Create Config Choice"}
              </StyledButton>

              <StyledButton
                type="button"
                variant="outlined"
                color={theme.palette.grey.main}
                sx={{ color: theme.palette.text.primary }}
                component={Link}
                to={"/system-setting/config-choice"}
              >
                Cancel
              </StyledButton>

              <StyledButton type="button" variant="outlined" color={theme.palette.error.main} onClick={resetForm}>
                Reset
              </StyledButton>
            </Box>
          </StyledCard>
        </Form>
      )}
    </Formik>
  );
};

export default ConfigChoiceForm;
