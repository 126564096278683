import React, { useEffect } from "react";
import { Box, InputAdornment, useTheme } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { login, resetAuth } from "reduxs/actions";
import { InputField, InputPasswordField } from "ui/form/field";
import { StyledButton } from "ui";
import AuthWrapper from "./shared/AuthWrapper";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { loading } = useSelector((state) => state.auth);

  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onLogin = (values) => {
    if (!loading) {
      dispatch(login(values, navigate));
    }
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  return (
    <AuthWrapper
      title="Welcome to Sierra Homes!"
      subtitle="Enter your details to get sign in to your account"
      footer={
        <>
          Don&apos;t have account?{" "}
          <Box component={Link} to={`/auth/register`} sx={{ color: theme.palette.text.primary }}>
            Sign Up
          </Box>
        </>
      }
    >
      <Formik
        initialValues={{
          email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
          password: localStorage.getItem("password") ? localStorage.getItem("password") : "",
          remember: localStorage.getItem("remember") ? parseInt(localStorage.getItem("remember")) : 0,
        }}
        validationSchema={schema}
        onSubmit={onLogin}
      >
        {({ values, setFieldValue }) => (
          <Box component={Form} sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <InputField
              shrinkLabel={true}
              name="email"
              type="email"
              label="Email"
              placeholder="Enter your email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />

            <InputPasswordField
              shrinkLabel={true}
              name="password"
              label="Password"
              placeholder="Enter your password"
              inputOptions={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Box
                component={Link}
                to={`/auth/forgot-password`}
                sx={{ color: theme.palette.info.main, textDecoration: "none" }}
              >
                Forgot password?
              </Box>
            </Box>

            <StyledButton type="submit" color={theme.palette.background.button} isloading={loading} sx={{ mt: 1 }}>
              Sign In
            </StyledButton>
          </Box>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default Login;
