import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import {
  GET_ALL_CONFIG_CHOICE_CATEGORY,
  GET_CONFIG_CHOICE_CATEGORY_LIST,
  ADD_CONFIG_CHOICE_CATEGORY,
  GET_CONFIG_CHOICE_CATEGORY,
  EDIT_CONFIG_CHOICE_CATEGORY,
  DELETE_CONFIG_CHOICE_CATEGORY,
} from "../actions";
import {
  getAllConfigChoiceCategorySuccess,
  getAllConfigChoiceCategoryError,
  getConfigChoiceCategoryList,
  getConfigChoiceCategoryListSuccess,
  getConfigChoiceCategoryListError,
  addConfigChoiceCategorySuccess,
  addConfigChoiceCategoryError,
  getConfigChoiceCategorySuccess,
  getConfigChoiceCategoryError,
  editConfigChoiceCategorySuccess,
  editConfigChoiceCategoryError,
  deleteConfigChoiceCategorySuccess,
  deleteConfigChoiceCategoryError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import ConfigChoiceCategoryService from "services/ConfigChoiceCategoryService";
import TableDataService from "services/TableDataService";

const getMetaData = (state) => state.configChoiceCategory.metaData;

export function* watchgetAllConfigChoiceCategory() {
  yield takeEvery(GET_ALL_CONFIG_CHOICE_CATEGORY, getAllConfigChoiceCategory);
}

const getAllConfigChoiceCategoryAsync = async () => {
  return ConfigChoiceCategoryService.getAllConfigChoiceCategory();
};

function* getAllConfigChoiceCategory() {
  try {
    const response = yield call(getAllConfigChoiceCategoryAsync);
    if (response.data) {
      yield put(getAllConfigChoiceCategorySuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllConfigChoiceCategoryError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllConfigChoiceCategoryError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetConfigChoiceCategoryList() {
  yield takeEvery(GET_CONFIG_CHOICE_CATEGORY_LIST, getConfigChoiceCategoryListAc);
}

const getConfigChoiceCategoryListAsync = async (dbParam) => {
  return TableDataService.getAllData("config-choice-categories", dbParam);
};

function* getConfigChoiceCategoryListAc({ payload }) {
  try {
    const response = yield call(getConfigChoiceCategoryListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getConfigChoiceCategoryListSuccess(response.data.data, response.data.meta));
    } else {
      toaster("", response.data.message);
      yield put(getConfigChoiceCategoryListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getConfigChoiceCategoryListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddConfigChoiceCategory() {
  yield takeEvery(ADD_CONFIG_CHOICE_CATEGORY, addConfigChoiceCategory);
}

const addConfigChoiceCategoryAsync = async (data) => {
  return ConfigChoiceCategoryService.addConfigChoiceCategory(data);
};

function* addConfigChoiceCategory({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(addConfigChoiceCategoryAsync, payload.configChoiceCategoryData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addConfigChoiceCategorySuccess(response.data.success));
      navigate(`/system-setting/config-choice-category`);
    } else {
      toaster("", response.data.message);
      yield put(addConfigChoiceCategoryError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addConfigChoiceCategoryError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetConfigChoiceCategory() {
  yield takeEvery(GET_CONFIG_CHOICE_CATEGORY, getConfigChoiceCategory);
}

const getConfigChoiceCategoryAsync = async (id) => {
  return ConfigChoiceCategoryService.getConfigChoiceCategory(id);
};

function* getConfigChoiceCategory({ payload }) {
  try {
    const response = yield call(getConfigChoiceCategoryAsync, payload.configChoiceCategoryId);
    if (response.data.success) {
      yield put(getConfigChoiceCategorySuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getConfigChoiceCategoryError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getConfigChoiceCategoryError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditConfigChoiceCategory() {
  yield takeEvery(EDIT_CONFIG_CHOICE_CATEGORY, editConfigChoiceCategory);
}

const editConfigChoiceCategoryAsync = async (data, id) => {
  return ConfigChoiceCategoryService.editConfigChoiceCategory(data, id);
};

function* editConfigChoiceCategory({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(
      editConfigChoiceCategoryAsync,
      payload.configChoiceCategoryData,
      payload.configChoiceCategoryId
    );
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editConfigChoiceCategorySuccess(response.data.success));
      navigate(`/system-setting/config-choice-category`);
    } else {
      toaster("", response.data.message);
      yield put(editSConfigChoiceCategoryError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editConfigChoiceCategoryError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteConfigChoiceCategory() {
  yield takeEvery(DELETE_CONFIG_CHOICE_CATEGORY, deleteConfigChoiceCategory);
}

const deleteConfigChoiceCategoryAsync = async (id) => {
  return ConfigChoiceCategoryService.deleteConfigChoiceCategory(id);
};

function* deleteConfigChoiceCategory({ payload }) {
  try {
    const metaData = yield select(getMetaData);
    const response = yield call(deleteConfigChoiceCategoryAsync, payload.configChoiceCategoryId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteConfigChoiceCategorySuccess(response.data.success));
      yield put(getConfigChoiceCategoryList({ page: metaData.page, pageSize: metaData.pageSize }));
    } else {
      toaster("", response.data.message);
      yield put(deleteConfigChoiceCategoryError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteConfigChoiceCategoryError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllConfigChoiceCategory),
    fork(watchGetConfigChoiceCategoryList),
    fork(watchAddConfigChoiceCategory),
    fork(watchGetConfigChoiceCategory),
    fork(watchEditConfigChoiceCategory),
    fork(watchDeleteConfigChoiceCategory),
  ]);
}
