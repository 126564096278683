import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "reduxs/actions";
import { StyledAccordion, StyledSpinner } from "ui";
import SummaryCard from "./SummaryCard";
import SelectionHead from "./SelectionHead";
import SelectionTable from "./SelectionTable";

const SelectionOverview = (props) => {
  const { homeId, selectionList } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { fetchLoading, homeData } = useSelector((state) => state.home);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  useEffect(() => {
    if (homeId) dispatch(getHome(homeId));
  }, [homeId]);

  return (
    <>
      <SummaryCard data={homeData}>
        <Typography component="h3" className="mb-1.5 text-xl font-bold">
          {homeData?.name || ""}
        </Typography>

        <Typography color={theme.palette.grey.dark}>110 of 115 selected</Typography>
        <Typography color={theme.palette.grey.dark}>
          <span className="text-primary-500">$12,353.00</span> Total Cost
        </Typography>

        <IconButton
          type="button"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            ml: "auto",
            color: theme.palette.text.primary,
            backgroundColor: "transparent",
          }}
        >
          <LocalPrintshopOutlinedIcon />
        </IconButton>
      </SummaryCard>

      {selectionList?.length > 0 && (
        <Box display="flex" flexDirection="column" gap={2.5} mt={5}>
          {selectionList.map((item) => {
            return (
              <StyledAccordion
                key={item.id}
                label={<SelectionHead data={item} />}
                labelprops={{
                  onClick: () => {
                    handleChange(item.id);
                  },
                  expandIcon: <ExpandMoreIcon />,
                }}
                wrapperprops={{
                  expanded: expanded === item.id,
                  sx: {
                    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
                  },
                }}
              >
                <SelectionTable options={item.childrenProduct} />
              </StyledAccordion>
            );
          })}
        </Box>
      )}

      {fetchLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default React.memo(SelectionOverview);
