import React, { useState } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { format } from "date-fns";
import { DateRange as ReactDateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { dateFormat } from "helpers";
import InputFieldStyles from "styles/form/InputField.style";
import StyledButton from "ui/button/Button";

const StyledPopper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.layout.main,
  boxShadow:
    theme.palette.mode === "light"
      ? "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px"
      : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
  borderRadius: "0.75rem",

  "& .rdrCalendarWrapper": { backgroundColor: theme.palette.layout.main },
  "& .rdrMonthAndYearPickers select": { color: theme.palette.text.primary },
  "& .rdrDayNumber span": { color: theme.palette.text.primary },
  "& .rdrDayPassive .rdrDayNumber span": { color: theme.palette.grey.brightGray },
}));

const StyledDateRange = (props) => {
  const {
    onchangerange,
    label,
    variant,
    shrinkLabel = false,
    okbutton = false,
    uistyle,
    wrapperprops,
    inputprops,
    rangeprops,
  } = props;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const FieldStyles = InputFieldStyles(theme, uistyle);

  const [openPicker, setOpenPicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [isChanges, setIsChanged] = useState(false);

  const inputVal = React.useMemo(() => {
    let val = "";
    if (dateRange) {
      const { startDate, endDate } = dateRange;

      val = `${format(new Date(startDate), dateFormat)} - ${format(new Date(endDate), dateFormat)}`;
    }

    return val;
  }, [dateRange]);

  const handleOpenPicker = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPicker((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenPicker(false);
  };

  const handleSelect = (ranges) => {
    const selectedRange = ranges.range1 || null;
    setDateRange(selectedRange);
    setIsChanged(true);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    setDateRange(null);
    onchangerange(null);
  };

  const handleClickOk = () => {
    setOpenPicker(false);
    onchangerange(dateRange);
  };

  React.useEffect(() => {
    if (!okbutton && !openPicker && isChanges) {
      onchangerange(dateRange);
      setIsChanged(false);
    }
  }, [openPicker, isChanges]);

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
      <Box sx={{ width: "100%" }} {...wrapperprops}>
        <TextField
          label={label || null}
          InputLabelProps={shrinkLabel ? { shrink: true } : {}}
          variant={variant || "outlined"}
          margin="dense"
          sx={FieldStyles}
          value={inputVal}
          fullWidth
          placeholder="Select date range"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {inputVal && (
                  <IconButton size="small" onClick={handleClear}>
                    <CloseIcon />
                  </IconButton>
                )}
                <InsertInvitationIcon />
              </InputAdornment>
            ),
          }}
          onClick={handleOpenPicker}
          {...inputprops}
        />

        <Popper open={openPicker} anchorEl={anchorEl} placement="bottom-end">
          <StyledPopper>
            <ReactDateRangePicker
              ranges={dateRange ? [dateRange] : [{ startDate: new Date(), endDate: new Date() }]}
              months={2}
              direction={matches ? "horizontal" : "vertical"}
              onChange={handleSelect}
              color={theme.palette.primary.main}
              rangeColors={[theme.palette.primary.main, theme.palette.secondary.main]}
              showDateDisplay={false}
              {...rangeprops}
            />

            {okbutton && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                <StyledButton onClick={handleClickOk}>Ok</StyledButton>
              </Box>
            )}
          </StyledPopper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default StyledDateRange;
