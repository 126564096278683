import React, { useEffect, useRef } from "react";
import { Box, Divider, Grid, List, ListItem, Paper, styled, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getRole, addRole, editRole, getModule, getSingleConfigChoice } from "reduxs/actions";
import { InputField, TextareaField } from "ui/form/field";
import { StyledButton, StyledCard, StyledSwitch, StyledCheckbox } from "ui";

const RoleItem = styled(ListItem)(({ theme }) => ({
  justifyContent: "space-between",
  padding: ".1563rem 0",
  "& .MuiListItemSecondaryAction-root": {
    position: "static",
    transform: "translate(0)",
  },
}));

const RoleForm = (props) => {
  const { editId } = props;
  const formikRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, roleData } = useSelector((state) => state.role);
  const { singleChoiceList, moduleList } = useSelector((state) => state.shared);

  const initialValues = {
    name: editId ? roleData?.name || "" : "",
    status: editId ? roleData?.status || "" : singleChoiceList?.find((val) => val.configChoice === "active")?.id || "",
    description: editId ? roleData?.description || "" : "",
    guardName: "web",
    rolePermission: editId
      ? roleData?.permissions?.map((permission) => {
          return permission.name;
        }) || []
      : [],
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Enter role name"),
    status: Yup.string().required("Select role status"),
    description: Yup.string().required("Enter role description"),
  });

  const isAllchecked = (action, permission) => {
    const checked = { full: false, partial: false };
    if (action.every((v) => permission.includes(v))) {
      checked.full = true;
    } else if (action.some((v) => permission.includes(v))) {
      checked.partial = true;
    }
    return checked;
  };

  const handlePermissionSelect = React.useCallback(
    (permission) => (event) => {
      const { values, setFieldValue } = formikRef.current;
      const { rolePermission } = values;
      const index = rolePermission.indexOf(permission);
      const arr =
        index === -1
          ? [...rolePermission, permission]
          : [...rolePermission.slice(0, index), ...rolePermission.slice(index + 1)];

      setFieldValue("rolePermission", arr);
    },
    [formikRef?.current?.values]
  );

  const onSubmit = (values) => {
    if (!loading) {
      if (editId) {
        dispatch(editRole(editId, values, navigate));
      } else {
        dispatch(addRole(values, navigate));
      }
    }
  };

  useEffect(() => {
    if (editId) dispatch(getRole(editId));
  }, []);

  useEffect(() => {
    dispatch(getSingleConfigChoice("roleStatus"));
    dispatch(getModule());
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <StyledCard sx={{ p: "1.5rem" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputField name="name" type="text" label="Name*" />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography component="label" display="block">
                  Status
                </Typography>
                <StyledSwitch
                  value={values.status}
                  checked={values.status === singleChoiceList?.find((val) => val.configChoice === "active")?.id}
                  onChange={(e) => {
                    setFieldValue(
                      "status",
                      e.target.checked
                        ? singleChoiceList?.find((val) => val.configChoice === "active")?.id
                        : singleChoiceList?.find((val) => val.configChoice === "inactive")?.id
                    );
                  }}
                  name="status"
                  label={
                    singleChoiceList && values.status
                      ? values.status === singleChoiceList?.find((val) => val.configChoice === "active")?.id
                        ? "Active"
                        : "Inactive"
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextareaField name="description" label="Description*" />
              </Grid>
            </Grid>

            <Typography
              component="h4"
              mt={5}
              mb={3}
              color={theme.palette.text.primary}
              fontSize="1.2rem"
              fontWeight="500"
            >
              Permissions
              <Typography component="small" display="block" color="#777">
                Find all the associated permissions and assign suitable permissions.
              </Typography>
            </Typography>

            <Grid container spacing={2}>
              {moduleList?.map((access, i) => {
                return (
                  <Grid key={i} item xs={12} lg={4}>
                    <Paper elevation={0} sx={{ height: "100%", p: "1rem", border: "1px solid #eee" }}>
                      <Box display="flex" alignItems="center" mb=".9375rem">
                        <Typography component="h5" fontSize="1.1rem" fontWeight="500">
                          {access.module}
                          <Divider sx={{ width: "50px", borderColor: theme.palette.primary.main }} />
                        </Typography>

                        <StyledCheckbox
                          name={access.module}
                          checkboxProps={{
                            checked: isAllchecked(access?.action, values.rolePermission).full,
                            indeterminate: isAllchecked(access?.action, values.rolePermission).partial,
                            onChange: (event) => {
                              const rolePermission = [...values.rolePermission];
                              const action = access?.action?.map((permission) => {
                                return permission;
                              });
                              action.forEach((permission) => {
                                if (event.target.checked) {
                                  if (!rolePermission.includes(permission)) {
                                    rolePermission.push(permission);
                                  }
                                } else {
                                  if (rolePermission.includes(permission)) {
                                    const index = rolePermission.indexOf(permission);
                                    rolePermission.splice(index, 1);
                                  }
                                }
                              });
                              setFieldValue("rolePermission", rolePermission);
                            },
                          }}
                        />
                      </Box>

                      <List dense sx={{ p: 0 }}>
                        {access?.action?.map((permission, k) => {
                          const [verb] = permission.split(" ");

                          return (
                            <RoleItem
                              key={k}
                              secondaryAction={
                                <Box width="7.1875rem">
                                  <StyledCheckbox
                                    name={`${permission}`}
                                    checkboxProps={{
                                      checked: values?.rolePermission?.includes(permission),
                                      onChange: handlePermissionSelect(permission),
                                    }}
                                    formControlProps={{
                                      label: verb,
                                    }}
                                  />
                                </Box>
                              }
                            >
                              <Typography sx={{ mr: ".625rem" }}>Can {permission}</Typography>
                            </RoleItem>
                          );
                        })}
                      </List>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>

            <Box
              width="100%"
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent={{ sm: "flex-end" }}
              gap={2}
              mt={5}
            >
              <StyledButton type="submit" isloading={loading}>
                {editId ? "Update Role" : "Create Role"}
              </StyledButton>

              <StyledButton
                type="button"
                variant="outlined"
                color={theme.palette.grey.main}
                sx={{ color: theme.palette.text.primary }}
                component={Link}
                to={"/user-management/role"}
              >
                Cancel
              </StyledButton>

              <StyledButton type="button" variant="outlined" color={theme.palette.error.main} onClick={resetForm}>
                Reset
              </StyledButton>
            </Box>
          </StyledCard>
        </Form>
      )}
    </Formik>
  );
};

export default RoleForm;
