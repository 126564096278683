export const Light = {
  text: { primary: "#212b36" },
  primary: {
    main: "#038C4C",
    light: "#9ad1b7",
    dark: "#77924f",
  },
  secondary: {
    main: "#101828",
  },
  layout: { main: "#ffffff", dark: "#101828" },
  grey: {
    main: "#dfe3e8",
    dark: "#637381",
    cadet: "#919EAB",
    cultured: "#f8f8f8",
    culturedLight: "#F4F7FA",
    lotion: "#fbfbfb",
    brightGray: "#E8EDF2",
    dimGray: "#646E77",
    gainsBoro: "#DADDE1",
    aAzureishWhite: "#DFE5EB",
  },
  slate: { A100: "#F1F5F9" },
  blue: { tropicalBlue: "#C5DCFA", aliceBlue: "#EFF7FF", shadowBlue: "#f4f6f8", yankeesBlue: "#232C40" },
  info: { main: "#078DEE" },
  warn: { main: "#FFAB00" },
  error: { main: "#ff5630" },
  background: { default: "#F8F9FC", paper: "#ffffff", button: "#000000" },
  backdrop: "rgba(116, 132, 132 ,0.65)",
};

export const Dark = {
  ...Light,
  text: { primary: "#ffffff" },
  layout: { main: "#161C24", dark: "#000000" },
  background: { default: "#191A19", paper: "#212B36", button: "#373D44" },
  grey: {
    main: "#637381",
    dark: "#dfe3e8",
    cadet: "#c1c9d1",
    cultured: "#393939",
    culturedLight: "#1f3336",
    lotion: "#2c2e31",
    brightGray: "#2e4642",
    dimGray: "#fbfdff",
    gainsBoro: "#DADDE1",
    aAzureishWhite: "#DFE5EB",
  },
  blue: { tropicalBlue: "#28303c", aliceBlue: "#3f4d5a", shadowBlue: "#35404B", yankeesBlue: "#232C40" },
  slate: { A100: "#404040" },
  backdrop: "rgba(22, 28, 36, 0.65)",
};
