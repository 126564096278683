import React from "react";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineItem } from "@mui/lab";
import { Avatar, Box, Button, Typography, alpha, styled, useTheme } from "@mui/material";
import LensIcon from "@mui/icons-material/Lens";
import { useDispatch } from "react-redux";
import { readNotification } from "reduxs/actions";
import { getModuleIcon } from "helpers/getModuleIcon";
import { getFileType } from "helpers";
import { IconFiles } from "icons";
import { StyledButton } from "ui";
import defaultUserAatar from "assets/images/default-user.png";

const Wrapper = styled(TimelineItem)(({ theme, showgraphic }) => ({
  gap: 15,
  "&:before": { display: "none" },

  "&.is-last": {
    "& .MuiTimelineContent-root": {
      position: "relative",

      "&:before, &:after": {
        display: showgraphic ? "block" : "none",
        height: "100%",
        content: "''",
        position: "absolute",
        borderRadius: 12,
        border: `1px solid ${alpha(theme.palette.grey.cadet, 0.1)}`,
        background: theme.palette.layout.main,
        zIndex: -1,
      },

      "&:before": {
        width: "calc(100% - 40px)",
        top: 20,
        left: 20,
      },

      "&:after": {
        width: "calc(100% - 20px)",
        top: 10,
        left: 10,
      },
    },
  },
}));

export const NotificationItem = (props) => {
  const { data, showStatus, index, ...others } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const handleReadNotfication = (id) => {
    const payload = {
      ids: [id],
    };
    dispatch(readNotification(payload));
  };

  return (
    <Wrapper {...others}>
      <TimelineSeparator>
        {index !== 0 && <TimelineConnector sx={{ height: 15, flexGrow: 0 }} />}
        <Box component={TimelineDot} sx={{ m: 0, bgcolor: theme.palette.info.main, boxShadow: "none" }}>
          {getModuleIcon(data?.module)}
        </Box>
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: index !== 0 ? 2 : 0,
          padding: "10px 15px",
          borderRadius: 3,
          border: `1px solid ${alpha(theme.palette.grey.cadet, 0.1)}`,
          background: theme.palette.layout.main,
          wordBreak: "break-all",
          "&:hover .btn-read": { p: 1, gap: 1, fontSize: 14, borderColor: theme.palette.text.primary },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 1.5, mb: 1 }}>
          <Typography component="h6" sx={{ flexShrink: 0, fontSize: 16, fontWeight: 600, lineHeight: 1 }}>
            {data?.action}
          </Typography>

          {showStatus && (
            <>
              {data?.isRead ? (
                <LensIcon
                  sx={{
                    width: 12,
                    color: theme.palette.primary.main,
                    ml: "auto",
                  }}
                />
              ) : (
                <StyledButton
                  className="btn-read"
                  sx={{
                    minWidth: "initial",
                    minHeight: "initial",
                    height: "30px !important",
                    ml: "auto",
                    p: 0,
                    gap: 0,
                    boxShadow: "none",
                    borderColor: "transparent",
                    fontSize: 0,
                  }}
                  color={theme.palette.text.primary}
                  variant="outlined"
                  onClick={() => handleReadNotfication(data.id, data?.isRead)}
                >
                  <LensIcon
                    sx={{
                      width: 12,
                      color: theme.palette.error.main,
                    }}
                  />
                  Mark as read
                </StyledButton>
              )}
            </>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: { sm: "center" }, gap: 1 }}>
          <Avatar
            alt={data?.createdBy?.name}
            src={data?.createdBy?.media?.[0]?.url || defaultUserAatar}
            sx={{ width: 26, height: 26, bgcolor: "#DFE2E7" }}
          />
          <Typography>
            {data?.createdBy?.name},{" "}
            <Typography component="span" sx={{ color: theme.palette.grey.dimGray }}>
              {data?.createdBy?.address}
            </Typography>
            <Typography component="span" sx={{ ml: 1.2, color: theme.palette.grey.cadet }}>
              {formatDistanceToNow(new Date(data?.createdAt), { addSuffix: true })} (
              {format(new Date(data?.createdAt), "dd MMMM yyyy HH:mm")})
            </Typography>
          </Typography>
        </Box>

        <Typography sx={{ my: 1.2 }}>{data?.title}</Typography>

        {data.info?.media && data.info.fileName && (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1.5 }}>
            <Button
              component={Link}
              to={data.info?.media}
              target="_blank"
              sx={{
                display: "flex",
                gap: 1,
                padding: "7px 12px",
                border: `1px solid ${alpha(theme.palette.grey.cadet, 0.3)}`,
                borderRadius: 6,
                color: theme.palette.text.primary,
                textDecoration: "none",
                textTransform: "none",
              }}
            >
              <IconFiles filetype={getFileType(data.info.fileName)} />
              {data.info.fileName}
            </Button>
          </Box>
        )}
      </TimelineContent>
    </Wrapper>
  );
};
