import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConfigChoiceList, deleteConfigChoice, resetConfigChoice } from "reduxs/actions";
import AddIcon from "@mui/icons-material/Add";
import { checkPermission } from "helpers";
import { BreadcrumbContainer } from "ui";
import { StyledCard, StatusChip, TableInstance, Table, TablePagination, Toolbar, Action, AlertDialog } from "ui";

const ConfigChoiceList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageValue, setPageValue] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [activeCol, setActiveCol] = useState("created_at");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [search, setSearch] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { configChoiceList, metaData, delLoading, loading, success } = useSelector((state) => state.configChoice);
  const { permissionList } = useSelector((state) => state.shared);
  const searchFields = "config_choice:like;display_name:like;configChoiceCategory.display_name:like;"; // Model relationship|field name

  const onChange = (search, sortOrder, page, perPage, activeCol) => {
    dispatch(
      getConfigChoiceList({
        search: search,
        searchFields: searchFields,
        sortedBy: sortOrder,
        page: page,
        pageSize: perPage,
        orderBy: activeCol,
      })
    );
  };

  const handleSort = (order, val) => {
    setSortOrder(order);
    setActiveCol(val);
    onChange(search, order, pageValue, perPage, val);
  };

  const handleChangePage = (val) => {
    setPageValue(val);
    onChange(search, sortOrder, val, perPage, activeCol);
  };

  const handleChangePerPage = (val) => {
    setPerPage(val);
    onChange(search, sortOrder, pageValue, val, activeCol);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const val = event.target.value;
    setSearch(event.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        onChange(val, sortOrder, pageValue, perPage, activeCol);
      }, 1000)
    );
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    navigate(`/system-setting/config-choice/edit/${id}`);
  };

  const handleOnDelete = () => {
    if (!delLoading && deleteId) dispatch(deleteConfigChoice(deleteId));
  };

  const columns = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Category",
      accessor: (value) => value.configChoiceCategory?.displayName || "",
      id: "config_choice_categories|display_name", // Table name| field name
    },
    {
      Header: "Choice",
      accessor: "configChoice",
      id: "config_choice",
    },
    {
      Header: "Display Name",
      accessor: "displayName",
      id: "display_name",
    },
    {
      Header: "Is Default",
      id: "is_default",
      accessor: "isDefault",
      Cell: (props) => {
        return <StatusChip status={props.value ? "active" : "inactive"} label={props.value ? "Yes" : "No"} />;
      },
    },
    {
      Header: "Is Active",
      id: "is_active",
      accessor: "isActive",
      Cell: (props) => {
        return <StatusChip status={props.value ? "active" : "inactive"} label={props.value ? "Yes" : "No"} />;
      },
    },
    // {
    //   Header: "Is System Choice",
    //   id: "is_system_choice",
    //   accessor: "isSystemChoice",
    //   Cell: (props) => {
    //     return <StatusChip status={props.value ? "active" : "inactive"} label={props.value ? "Yes" : "No"} />;
    //   },
    // },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (props) => {
        const rowIdx = props.row.values.id;

        return (
          <Action
            id={props.row.id}
            handleOnDelete={() => {
              setDeleteId(rowIdx);
              setOpenDeleteAlert(true);
            }}
            handleOnEdit={(e) => handleEditClick(e, rowIdx)}
            permission={props.permission}
            {...props}
          />
        );
      },
    },
  ]);

  const data = React.useMemo(() => configChoiceList, [configChoiceList]);

  useEffect(() => {
    dispatch(getConfigChoiceList({}));
  }, []);

  return (
    <>
      <BreadcrumbContainer
        title="Config Choice List"
        paths={[
          {
            title: "Config Choice",
            path: "/system-setting/config-choice",
          },
        ]}
      />

      <StyledCard>
        <TableInstance
          columns={columns}
          data={data || []}
          permission={{
            edit: checkPermission(permissionList, "edit choice"),
            delete: checkPermission(permissionList, "delete choice"),
          }}
          totalPage={metaData?.totalPages}
          currentPage={metaData?.page}
          total={metaData?.total}
        >
          <Toolbar
            title="Config Choice"
            subTitle="List of all available choice"
            buttonA="Add Config Choice"
            allowButtonA={checkPermission(permissionList, "add choice")}
            buttonAIcon={<AddIcon />}
            handleButtonA={() => navigate("/system-setting/config-choice/add")}
            search={search}
            handleSearch={handleSearch}
          />

          <Table handleSort={handleSort} loading={loading} />

          <TablePagination
            meta={metaData}
            goToStart={() => handleChangePage(1)}
            goToPrev={() => handleChangePage(pageValue === 0 ? 1 : pageValue - 1)}
            goToNext={() => handleChangePage(pageValue !== metaData?.totalPage ? pageValue + 1 : 1)}
            goToLast={() => handleChangePage(metaData?.totalPage)}
            handleChangePerPage={(val) => handleChangePerPage(val)}
            perPage={perPage}
            handleChangePage={handleChangePage}
          />
        </TableInstance>
      </StyledCard>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setDeleteId(null);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected choice?"
        loadingInfo="Config Choice is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => dispatch(resetConfigChoice())}
      />
    </>
  );
};

export default ConfigChoiceList;
