import React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import defaultUserAatar from "assets/images/default-user.png";

const StyledAvatar = (props) => {
  const theme = useTheme();
  const { data } = props;

  return (
    <Box display="flex" alignItems="center" gap="5px" component="span">
      <Avatar
        src={data?.media?.[0]?.url || defaultUserAatar}
        alt={data?.name || ""}
        size="small"
        sx={{ bgcolor: "#DFE2E7", "& img": { objectFit: "contain" } }}
      />
      <Typography
        component="span"
        variant="subtitle2"
        sx={{
          display: "block",
          p: "0 1rem",
          color: theme.palette.common.dark,
        }}
      >
        {data?.name || ""}
        <Typography component="small" variant="body2" display="block" color={theme.palette.grey.dark}>
          {data?.email || ""}
        </Typography>
      </Typography>
    </Box>
  );
};

export default StyledAvatar;
