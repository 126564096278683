import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography, styled, useTheme } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import { dateFormat } from "helpers";
import MediaList from "./MediaList";
import { AlertDialog } from "ui";
import { useDispatch, useSelector } from "react-redux";
import { deleteMediaById, getAllUserHomeMilestone, getUserHomeMilestoneList, resetMedia } from "reduxs/actions";

const ListWrap = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 15,
  padding: 0,
}));

const Item = styled(ListItem)(({ theme }) => ({
  alignItems: "flex-start",
  gap: 22,
  padding: 0,

  "& .MuiListItemAvatar-root": { minWidth: "initial", color: theme.palette.grey.dark },
  "& .MuiListItemText-root": { margin: 0 },
  "& .MuiListItemText-primary": {
    marginBottom: 5,
    fontSize: 12,
    color: theme.palette.grey.cadet,
    fontWeight: 400,
    lineHeight: 1,
  },
  "& .MuiListItemText-secondary": {
    display: "flex",
    flexWrap: "wrap",
    gap: 15,
    fontSize: 14,
    color: theme.palette.text.primary,
    lineHeight: "22px",
  },
}));

const ConfigItem = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 0.5,

  "& >span": { marginBottom: 5, fontSize: 12, color: theme.palette.grey.cadet, fontWeight: 400, lineHeight: 1 },
}));

const ProcessContent = (props) => {
  const {
    data,
    dbParams,
    // handleviewdocument
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { isDeleting, deleteSuccess } = useSelector((state) => state.media);

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleDeleteImage = () => {
    dispatch(deleteMediaById(deleteId));
  };

  return (
    <>
      <ListWrap>
        <Item>
          <ListItemAvatar>
            <AttachFileIcon />
          </ListItemAvatar>
          <ListItemText
            primary="Documents"
            secondary={
              data?.documents?.length ? (
                data?.documents?.map((doc) => {
                  return (
                    <Box
                      component={Link}
                      to={doc?.media?.[0]?.url}
                      target="_blank"
                      key={doc.id}
                      sx={{ color: theme.palette.info.main, textDecoration: "underline", cursor: "pointer" }}
                      // onClick={() => handleviewdocument([{ uri: doc?.media?.[0].url }])}
                    >
                      {doc?.media?.[0]?.fileName || ""}
                    </Box>
                  );
                })
              ) : (
                <Box component="span" color={theme.palette.grey.dark}>
                  No documents uploaded yet.
                </Box>
              )
            }
          />
        </Item>

        <Item components={{ secondary: "div" }}>
          <ListItemAvatar>
            <ImageOutlinedIcon />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <Typography component="span" className="!d-block MuiTypography-root MuiListItemText-primary">
                Building progress images
              </Typography>
            }
            secondary={
              data?.media?.length ? (
                <MediaList
                  ondelete={(id) => {
                    setDeleteId(id);
                    setOpenDeleteAlert(true);
                  }}
                  medias={data?.media}
                />
              ) : (
                <Box component="span" color={theme.palette.grey.dark} sx={{ display: "block" }}>
                  No building progress images uploaded yet.
                </Box>
              )
            }
          />
        </Item>

        <Item>
          <ListItemAvatar>
            <ArticleOutlinedIcon />
          </ListItemAvatar>
          <ListItemText primary="Notes" secondary={data?.note || "-"} />
        </Item>

        <Item>
          <ListItemAvatar>
            <SubjectOutlinedIcon />
          </ListItemAvatar>
          <ListItemText primary="Description" secondary={data?.description || "-"} />
        </Item>

        <Item>
          <ListItemAvatar>
            <ScheduleOutlinedIcon />
          </ListItemAvatar>
          <ListItemText
            secondary={
              <>
                <ConfigItem component="label">
                  <Typography component="span">Last updated</Typography>
                  {format(new Date(data?.updatedAt), dateFormat)}
                </ConfigItem>
                <ConfigItem component="label">
                  <Typography component="span">Completed</Typography>
                  {data?.completedAt ? format(new Date(data?.completedAt), dateFormat) : "-"}
                </ConfigItem>
              </>
            }
          />
        </Item>
      </ListWrap>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setDeleteId(null);
        }}
        handleAction={handleDeleteImage}
        title="Delete"
        info="Are you sure to permanently delete image?"
        loadingInfo="Image is deleting..."
        actionLabel="Delete"
        loading={isDeleting}
        success={deleteSuccess}
        reset={() => {
          dispatch(resetMedia());

          setOpenDeleteAlert(false);
          dispatch(getUserHomeMilestoneList(dbParams));
          dispatch(
            getAllUserHomeMilestone({
              userHomeId: dbParams.userHomeId,
            })
          );
        }}
      />
    </>
  );
};

export default ProcessContent;
