import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import emptyGraphics from "assets/images/empty-state-graphics.svg";

const Empty = (props) => {
  const { title, subcontent, icon, children } = props;

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" py={8} flex={1}>
      <Box component="figure" mb={3}>
        <img src={icon || emptyGraphics} alt="List is emppty." />
      </Box>

      <Typography component="h5" color={theme.palette.grey.dark} fontSize={22} fontWeight={600} sx={{ mb: 1.25 }}>
        {title}
      </Typography>
      <Typography component="p" color={theme.palette.grey.dark} sx={{ mb: 2.5 }}>
        {subcontent}
      </Typography>

      {children}
    </Box>
  );
};

export default Empty;
