import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addMilestone, editMilestone, getMilestone } from "reduxs/actions";
import { IconField, InputField, SelectField, StyledButton, StyledSwitch } from "ui";

const MilestoneForm = (props) => {
  const { editId, handleClose, activeTab, activePlan, multipleChoiceList, totals } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, frmLoading, milestoneData } = useSelector((state) => state.milestone);

  const [totalCount, setTotalCount] = React.useState(totals?.[activePlan?.configChoice]?.[activeTab.configChoice] || 0);
  const [currentVal, setCurrentVal] = React.useState({});

  const initialValues = {
    plan: editId ? milestoneData?.plan || "" : currentVal.plan?.id || activePlan?.id,
    name: editId ? milestoneData?.name || "" : "",
    type: editId ? milestoneData?.type || "" : currentVal.type?.id || activeTab?.id,
    order: editId ? milestoneData?.order || "" : totalCount + 1,
    icon: editId ? milestoneData?.icon || "" : "",
    status: editId
      ? milestoneData?.status || ""
      : multipleChoiceList?.milestoneStatus?.find((val) => val.configChoice === "active")?.id || "",
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    type: Yup.string().required("Type is required."),
    icon: Yup.string().required("Icon is required."),
    order: Yup.number()
      .required("Order is required.")
      .max(totalCount + 1, `The order cannot be greater than ${totalCount + 1}`),
  });

  const onSubmit = (values) => {
    if (!frmLoading) {
      if (editId) {
        dispatch(editMilestone(editId, values, navigate));
      } else {
        dispatch(addMilestone(values, navigate));
      }
    }
  };

  const calculateOrderCount = (plan, type) => {
    setTotalCount(totals?.[plan]?.[type]);
  };

  useEffect(() => {
    if (editId) dispatch(getMilestone(editId));

    return () => {
      dispatch(getMilestone(""));
    };
  }, []);

  useEffect(() => {
    if (success) {
      handleClose(true);
    }
  }, [success]);

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <InputField name="name" type="text" label="Name*" />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SelectField
                name="plan"
                type="text"
                label="Plan"
                placeholder="Select plan"
                options={
                  multipleChoiceList?.homePlan &&
                  multipleChoiceList?.homePlan.map((plan) => {
                    return { id: plan.id, name: plan.displayName, configChoice: plan.configChoice };
                  })
                }
                handleChange={(e, value) => {
                  const type = currentVal.type?.configChoice || activeTab.configChoice;
                  setCurrentVal({ ...currentVal, plan: value });
                  calculateOrderCount(value.configChoice, type);
                }}
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SelectField
                name="type"
                type="text"
                label="Type"
                placeholder="Select Type"
                options={
                  multipleChoiceList?.milestoneType &&
                  multipleChoiceList?.milestoneType.map((choice) => {
                    return { id: choice.id, name: choice.displayName, configChoice: choice.configChoice };
                  })
                }
                handleChange={(e, value) => {
                  const plan = currentVal.plan?.configChoice || activePlan.configChoice;
                  setCurrentVal({ ...currentVal, type: value });
                  calculateOrderCount(plan, value.configChoice);
                }}
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <InputField name="order" type="text" label="Order" />
            </Grid>

            <Grid item xs={12} xl={6}>
              <IconField label="Select icon" name="icon" placeholder="Select icon" />
            </Grid>

            <Grid item xs={12} xl={3}>
              <Typography component="label" fontSize="0.875rem" fontWeight="400" display="block">
                Status
              </Typography>

              <StyledSwitch
                value={values.status}
                checked={
                  values.status ===
                  multipleChoiceList?.milestoneStatus?.find((val) => val.configChoice === "active")?.id
                }
                onChange={(e) => {
                  setFieldValue(
                    "status",
                    e.target.checked
                      ? multipleChoiceList?.milestoneStatus?.find((val) => val.configChoice === "active")?.id
                      : multipleChoiceList?.milestoneStatus?.find((val) => val.configChoice === "inactive")?.id
                  );
                }}
                name="status"
                label={
                  multipleChoiceList?.milestoneStatus && values.status
                    ? values.status ===
                      multipleChoiceList?.milestoneStatus?.find((val) => val.configChoice === "active")?.id
                      ? "Active"
                      : "Inactive"
                    : ""
                }
                sx={{ mr: 0, ml: -1 }}
              />
            </Grid>
          </Grid>

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={frmLoading}>
              {editId ? "Update Milestone" : "Create Milestone"}
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              component={Link}
              onClick={() => handleClose()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.error.main}
              onClick={resetForm}
              className="shadow-none"
            >
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MilestoneForm;
