import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
// import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getHome } from "reduxs/actions";
import { BreadcrumbContainer, StyledButton, StyledSpinner, StatusChip, HomeCarouselDetail } from "ui";

const HomeView = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fetchLoading, homeData } = useSelector((state) => state.home);

  useEffect(() => {
    if (params.id) {
      dispatch(getHome(params.id));
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title={"View home detail"}
          paths={[
            {
              title: "Home",
              page: `/configuration/home`,
            },
            {
              title: "View",
            },
          ]}
        />
      </Box>

      <HomeCarouselDetail data={homeData}>
        <StatusChip label={homeData?.statusConfigChoice?.displayName || ""} />
        <Typography component="h3" fontSize={30} fontWeight="600" mb={1}>
          {homeData?.name || ""}
        </Typography>
        <Typography component="h6" fontSize={20} fontWeight="600" mb={0.8}>
          ${homeData?.basePrice || 0}
        </Typography>

        <Typography component="p" fontSize={14} fontWeight="400" color={theme.palette.grey.dark} mt={3}>
          {homeData?.description || ""}
        </Typography>

        <Box display="flex" flexWrap="wrap" alignItems="center" mt={7} gap={3}>
          {/* <StyledButton
            color={theme.palette.info.main}
            onClick={() => navigate(`/configuration/home/customize/${homeData?.id}`)}
            className="px-7"
          >
            <ImagesearchRollerIcon /> Customize
          </StyledButton> */}

          <StyledButton
            type="button"
            variant="outlined"
            color={theme.palette.info.main}
            sx={{ color: theme.palette.text.primary }}
            onClick={() => navigate(`/configuration/home/edit/${homeData.id}`)}
            className="px-7"
          >
            <BorderColorIcon sx={{ width: 18, height: 18, color: "#777777" }} /> Edit
          </StyledButton>
        </Box>
      </HomeCarouselDetail>

      {fetchLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default HomeView;
