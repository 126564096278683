import { Box, LinearProgress, Typography, linearProgressClasses, styled, useTheme } from "@mui/material";
import { format } from "date-fns";
import { dateFormat } from "helpers";
import React, { useMemo } from "react";
import { StyledCard } from "ui";
import graphics from "assets/images/home-placeholder.jpg";
import Lightbox from "yet-another-react-lightbox";

const ProgressBar = styled(LinearProgress, { shouldForwardProp: (prop) => prop })(
  ({ theme, size = 3, barcolor = "#232C40" }) => ({
    height: size,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#DBDBDB",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barcolor,
    },
  })
);

const MilestoneCard = (props) => {
  const { milestone } = props;

  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [imgIndex, setImgIndex] = React.useState(0);

  const allSlides = useMemo(() => {
    return milestone?.media.map((media) => ({ ...milestone?.media, src: media.url }));
  }, [milestone?.media]);

  const toggleOpen = (state) => () => setOpen(state);

  const getMilestoneProgress = (docs) => {
    if (docs && docs?.length) {
      const totalDocuments = docs?.length;
      const approved = docs.filter((doc) => doc?.status === 21).length;

      return (approved / totalDocuments) * 100;
    }
    return 0;
  };

  return (
    <>
      <StyledCard
        sx={{
          minHeight: 240,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "8px 8px 15px",
          borderRadius: "5px",
        }}
      >
        <Box component="figure" className="w-full h-24 grid grid-cols-12 grid-row-2 gap-1 m-0">
          {!!milestone?.media.length ? (
            milestone?.media.map(
              (media, index) =>
                !!(index < 3) && (
                  <React.Fragment key={index}>
                    <div
                      className={`rounded overflow-hidden cursor-zoom-in
                     ${
                       milestone?.media.length === 1
                         ? "col-span-12"
                         : index === 0
                           ? "col-span-8 row-span-2"
                           : milestone?.media.length > 2
                             ? "col-span-4"
                             : "col-span-4 row-span-2"
                     }
                    `}
                      onClick={() => {
                        setImgIndex(index);
                        setOpen(true);
                      }}
                    >
                      <img className="w-full h-full object-cover" src={media?.url} />
                    </div>
                  </React.Fragment>
                )
            )
          ) : (
            <div className="col-span-12 rounded overflow-hidden">
              <img src={graphics} className="w-full h-full object-cover" />
            </div>
          )}
        </Box>

        <Typography component="h6" sx={{ margin: "11px 0", fontSize: 15, fontWeight: 600, lineHeight: 1.2 }}>
          <Typography component="small" sx={{ display: "block", color: theme.palette.grey.cadet, fontSize: 12 }}>
            Completed: {milestone?.completedAt ? format(new Date(milestone?.completedAt), dateFormat) : "Not Completed"}
          </Typography>
          {milestone?.milestone?.name}
        </Typography>

        <Box sx={{ width: "100%", marginTop: "auto" }}>
          <Typography component="p" sx={{ marginBottom: "5px", color: "#415175", fontSize: 12 }}>
            {milestone?.documents?.length} Documents | {getMilestoneProgress(milestone?.documents)}%
          </Typography>
          <ProgressBar
            className="w-full"
            variant="determinate"
            value={getMilestoneProgress(milestone?.documents)}
            barcolor={theme.palette.info.main}
          />
        </Box>
      </StyledCard>

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={imgIndex}
        slides={allSlides}
        // on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ container: { backgroundColor: "rgba(33, 43, 54, 0.8)", backdropFilter: "blur(4px)" } }}
      />
    </>
  );
};

export default MilestoneCard;
