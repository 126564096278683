import React, { useEffect, useState } from "react";
import {
  Avatar,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  alpha,
  styled,
  useTheme,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllHome, getMultipleConfigChoice, getUser, resetHome, resetUser } from "reduxs/actions";
import { StyledDialog } from "ui";
import AssignHomeForm from "./AssignHomeForm";
import graphics from "assets/images/home-placeholder.jpg";

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 20,
  fontSize: 18,
  fontWeight: 600,
}));

const HomeListItem = styled(ListItemButton)(({ theme }) => ({
  height: 64,
  padding: 8,
  border: `1px solid ${theme.palette.grey.gainsBoro}`,
  borderRadius: 8,
  cursor: "pointer",

  "&.Mui-selected": { borderColor: theme.palette.info.main, backgroundColor: alpha(theme.palette.info.main, 0.05) },
}));

const AssignHome = (props) => {
  const { open, userid, homelist = false, homeid, handleclosedialog } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { multipleChoiceList } = useSelector((state) => state.shared);
  const { userData } = useSelector((state) => state.user);

  const [selectedHome, setSelectedHome] = useState(null);

  const handleDialoagOpen = () => {
    handleclosedialog();
  };

  const handleSelectHome = (home) => {
    setSelectedHome(home);
  };

  useEffect(() => {
    if (open && userid) {
      dispatch(getMultipleConfigChoice(["milestoneType", "homePlan"]));
      dispatch(getUser(userid));
      dispatch(getAllHome());
    }
  }, [open, userid]);

  useEffect(() => {
    if (homeid && userData?.userHomes) {
      setSelectedHome(userData?.userHomes.find((x) => x.id === homeid));
    }
  }, [homeid, userData]);

  useEffect(() => {
    return () => {
      setSelectedHome(null);
      dispatch(resetUser());
      dispatch(resetHome());
    };
  }, []);

  return (
    <StyledDialog
      open={open}
      handleclose={() => handleDialoagOpen(false)}
      dialogProps={{ fullScreen: false, maxWidth: "xl", sx: { "& .MuiPaper-root": { borderRadius: 0 } } }}
    >
      <Container maxWidth={"xl"} sx={{ height: "100%", pt: 4, pb: 8 }}>
        <Box
          sx={{
            display: "flex",
            flexFlow: { xs: "column-reverse", sm: "row" },
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 3,
            mb: 4.5,
          }}
        >
          <Typography component="h2" sx={{ fontSize: 30, fontWeight: 600 }}>
            Assign homes
          </Typography>
        </Box>

        <Grid container spacing={8} sx={{ height: "100%" }}>
          {homelist && userData?.userHomes?.length ? (
            <Grid item xs={12} md={3} sx={{ position: "sticky", top: 0 }}>
              <Title component="h5">{`${userData?.name?.split(" ")?.[0]}'s`} Home</Title>
              <List sx={{ display: "flex", flexDirection: "column", p: 0, gap: 1.5 }}>
                {userData?.userHomes?.map((userHome) => {
                  return (
                    <HomeListItem
                      key={userHome.id}
                      selected={selectedHome?.homeId === userHome.homeId}
                      onClick={() => handleSelectHome(userHome)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          variant="rounded"
                          sx={{ width: 44, height: 44, borderRadius: 2 }}
                          alt={userHome?.home?.name}
                          src={userHome?.home?.media?.[0]?.url || graphics}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={userHome?.home.name}
                        secondary={
                          multipleChoiceList?.homePlan?.find((plan) => plan.id === userHome?.plan)?.displayName
                        }
                        primaryTypographyProps={{ sx: { fontSize: 16, fontWeight: 500 } }}
                        secondaryTypographyProps={{
                          sx: { fontSize: 14, fontWeight: 400, color: theme.palette.grey.dark },
                        }}
                      />
                    </HomeListItem>
                  );
                })}
              </List>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} md={homelist && userData?.userHomes?.length ? 9 : 12}>
            <AssignHomeForm
              userid={userid}
              homeid={selectedHome?.id || homeid}
              handleformaction={(isSubmit) => handleDialoagOpen(isSubmit)}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledDialog>
  );
};

export default AssignHome;
