import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  // IconButton,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  TextField,
  alpha,
} from "@mui/material";
// import FilterListIcon from "@mui/icons-material/FilterList";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { useSelector } from "react-redux";
import { checkPermission } from "helpers";
import { StyledCard, StatusChip, StyledButton, StyledSpinner, StyledDialog, HomeCarouselDetail } from "ui";
import graphics from "assets/images/home-placeholder.jpg";
import InputFieldStyles from "styles/form/InputField.style";
import SelectFieldStyles from "styles/form/SelectField.style";

const Customization = (props) => {
  const {
    fetchLoading,
    // actionLoading,
    data,
    selectedProductId,
    allSelected,
    onSelect,
    onDelete,
  } = props;

  const theme = useTheme();
  const FieldStyles = InputFieldStyles(theme);
  const SelectStyles = SelectFieldStyles(theme);

  const { permissionList } = useSelector((state) => state.shared);

  const [filter] = useState(false);
  const [showDetail, setShowDetail] = useState(null);

  const selected = useMemo(() => {
    if (!allSelected?.length && !selectedProductId) return null;
    // Filter row of selected parent Product from list of all home customizations
    return allSelected?.find((v) => v?.parentProductId === selectedProductId);
  }, [allSelected, selectedProductId]);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Typography component="h6" fontSize="16px" fontWeight={500}>
          {data?.parent?.name && `${data?.parent?.name} - `}
          {data?.name || ""}

          <small className="block text-sm font-normal text-gray-400">Select an item from the list.</small>
        </Typography>

        {/* Shall be enabled in future */}
        {/* <IconButton
            type="button"
            onClick={() => setFilter(!filter)}
            sx={{
              ml: "auto",
              borderRadius: 2,
              color: theme.palette.text.primary,
              backgroundColor: "transparent",
              backgroundColor: filter ? "rgba(145, 158, 171, 0.16)" : "transparent",
            }}
          >
            <FilterListIcon />
          </IconButton> */}
      </Box>

      {filter && (
        <Box className="grid grid-cols-12 gap-3" mt={1}>
          <FormControl sx={FieldStyles} className="col-span-12 lg:col-span-3">
            <TextField placeholder="Search" />
          </FormControl>

          <FormControl sx={FieldStyles} className="col-span-12 lg:col-span-3">
            <Select
              MenuProps={{
                sx: SelectStyles.paper,
              }}
              value={""}
              displayEmpty
            >
              <MenuItem className="select-item" value="">
                Select Manufacturer
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.875, mt: 2.5 }}>
        {data?.childrenProduct?.map((item) => {
          return (
            <StyledCard
              key={item.id}
              className="relative flex gap-x-3 overflow-hidden transition-all group hover:-translate-y-2"
              sx={{
                padding: { xs: "8px", lg: "12px" },
                border: `1px solid ${selected?.productId === item.id ? theme.palette.primary.main : "transparent"}`,
              }}
            >
              {/* Product Image */}
              <Box
                component="figure"
                position="relative"
                m="0"
                sx={{ width: { xs: "80px", lg: "130px" }, height: { xs: "80px", lg: "130px" } }}
                className="rounded-xl overflow-hidden"
              >
                <img src={item?.media?.[0]?.url || graphics} alt="Home" className="w-full aspect-square rounded-xl" />

                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    background: alpha(theme.palette.grey.dark, 0.8),
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                  className="transition-all scale-75 opacity-0 backdrop-blur group-hover:scale-100 group-hover:opacity-100"
                  onClick={() => setShowDetail(item)}
                >
                  <AutoAwesomeMosaicIcon sx={{ color: "#fff", fontSize: 38 }} />
                </Box>
              </Box>

              {/* Product Contents */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", lg: "row" },
                  gap: { lg: "16px" },
                  flex: "1",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: { lg: "center" },
                  }}
                >
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={{ fontSize: { xs: "15px", lg: "20px" }, fontWeight: { xs: "500", lg: "600" } }}
                  >
                    {item?.name || ""}
                  </Typography>

                  <Typography sx={{ display: "block", color: "#417296", fontSize: "12px", fontWeight: 500 }}>
                    {item?.manufacturer && (
                      <>
                        {item.manufacturer} <CircleIcon sx={{ width: "5px", height: "5px", mx: "5px" }} />
                      </>
                    )}

                    {item.type && (
                      <>
                        {item.typeConfigChoice?.displayName}
                        <CircleIcon sx={{ width: "5px", height: "5px", mx: "5px" }} />
                      </>
                    )}

                    {item?.collection && item.collection}
                  </Typography>

                  {item?.description && (
                    <Typography
                      sx={{
                        display: { xs: "none !important", lg: "-webkit-box !important" },
                        mt: "10px",
                        color: theme.palette.grey.dark,
                        fontSize: "14px",
                      }}
                      className="line-clamp-3"
                    >
                      {item.description}
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", lg: "column" },
                    alignItems: { xs: "center", lg: "flex-end" },
                    justifyContent: { xs: "space-between", lg: "flex-start" },
                    ml: { lg: "auto" },
                    mt: { xs: 1, lg: 0 },
                  }}
                >
                  {item?.typeConfigChoice?.configChoice !== "inclusion" && (
                    <Typography
                      component="h5"
                      sx={{ fontWeight: 600, fontSize: "16px" }}
                      className="text-primary-500 text-right"
                    >
                      ${item?.price || 0}
                    </Typography>
                  )}
                  {checkPermission(permissionList, "edit customization") && (
                    <StyledButton
                      className="min-h-[1px] h-7 lg:h-9 ml-auto lg:ml-0 lg:mt-4 px-3 lg:px-[1.125rem] text-xs lg:text-sm shadow-none"
                      variant={selected?.productId === item.id ? "outlined" : "inherit"}
                      color={selected?.productId === item.id ? theme.palette.error.main : theme.palette.info.main}
                      onClick={() => {
                        selected?.productId === item.id ? onDelete(selected.id) : onSelect(item.id, data.id);
                      }}
                      // isloading={actionLoading}
                    >
                      {selected?.productId === item.id ? "Remove" : "Select"}
                      {selected?.productId === item.id ? (
                        <RemoveCircleOutlineIcon sx={{ width: 18, height: 18 }} />
                      ) : (
                        <ControlPointIcon sx={{ width: 18, height: 18 }} />
                      )}
                    </StyledButton>
                  )}
                </Box>
              </Box>

              {/* Selection Check Icon */}
              {selected?.productId === item.id && (
                <Box
                  component="span"
                  sx={{
                    width: 40,
                    height: 46,
                    position: "absolute",
                    bottom: "-10px",
                    right: { xs: "auto", lg: 0 },
                    left: { xs: 0, lg: "auto" },
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.layout.main,
                    zIndex: 1,
                  }}
                  className="rounded-tr-[26px] rounded-br-[10px] lg:rounded-none lg:rounded-tl-[26px] lg:rounded-bl-[10px]"
                >
                  <CheckCircleIcon sx={{ margin: "7px 0px 0 11px" }} />
                </Box>
              )}
            </StyledCard>
          );
        })}
      </Box>

      {fetchLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur"
        >
          <Typography component="h6" sx={{ display: "flex", alignItems: "center", gap: 2, fontSize: "16px" }}>
            <StyledSpinner size={24} />
            Fetching Data...
          </Typography>
        </Box>
      )}

      <StyledDialog
        open={showDetail?.id ? true : false}
        handleclose={() => setShowDetail(null)}
        dialogProps={{ maxWidth: "lg" }}
        contentProps={{ className: "py-8" }}
      >
        {showDetail && (
          <HomeCarouselDetail data={showDetail}>
            <StatusChip label={showDetail?.manufacturer || ""} />
            <Typography component="h3" fontSize={30} fontWeight="600" mb={1}>
              {showDetail?.name || ""}
            </Typography>
            {showDetail?.typeConfigChoice?.configChoice !== "inclusion" && (
              <Typography component="h6" fontSize={20} fontWeight="600" mb={0.8}>
                ${showDetail?.price || 0}
              </Typography>
            )}
            {showDetail?.type && (
              <Typography component="p" fontSize={14} fontWeight="400" color={theme.palette.primary.main}>
                {showDetail?.typeConfigChoice?.displayName || ""}
              </Typography>
            )}
            <Typography component="p" fontSize={14} fontWeight="400" color={theme.palette.grey.dark} mt={3}>
              {showDetail?.description || ""}
            </Typography>

            <Box display="flex" flexWrap="wrap" alignItems="center" mt={7} gap={3}>
              <StyledButton
                variant={selected?.productId === showDetail.id ? "outlined" : "inherit"}
                color={selected?.productId === showDetail.id ? theme.palette.error.main : theme.palette.info.main}
                onClick={() => {
                  selected?.productId === showDetail.id ? onDelete(selected.id) : onSelect(showDetail.id, data.id);
                }}
                className="px-7"
              >
                {selected?.productId === showDetail.id ? "Remove" : "Select"}
                {selected?.productId === showDetail.id ? (
                  <RemoveCircleOutlineIcon sx={{ width: 18, height: 18 }} />
                ) : (
                  <ControlPointIcon sx={{ width: 18, height: 18 }} />
                )}
              </StyledButton>

              <StyledButton
                type="button"
                variant="outlined"
                color={theme.palette.grey.main}
                sx={{ color: theme.palette.text.primary }}
                onClick={() => setShowDetail(null)}
                className="px-7"
              >
                Cancel
              </StyledButton>
            </Box>
          </HomeCarouselDetail>
        )}
      </StyledDialog>
    </Box>
  );
};

export default Customization;
