import React from "react";
import { Box, Typography } from "@mui/material";
import { StyledCard } from "ui";
import { NotificationList } from "views/app/notification";

const ActivityLogCard = () => {
  return (
    <StyledCard sx={{ height: 890, display: "flex", flexDirection: "column", flex: 1, padding: 2.5, overflow: "auto" }}>
      <Typography sx={{ marginBottom: 2.5, fontSize: 18, fontWeight: 600 }}>Recent Activity</Typography>

      <Box
        sx={{ width: "100%", maxHeight: "calc(100% - 45px)", marginRight: -2.5, paddingRight: 2.5, overflow: "auto" }}
      >
        <NotificationList isPage={false} />
      </Box>
    </StyledCard>
  );
};

export default ActivityLogCard;
