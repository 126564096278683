import React, { useEffect, useRef } from "react";
import { Box, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getHome, addHome, editHome, getSingleConfigChoice } from "reduxs/actions";
import { InputField, TextareaField } from "ui/form/field";
import { StyledButton, StyledCard, StyledSwitch, FileUpload } from "ui";
// import HomePorductSelection from "./HomePorductSelection";

const HomeForm = (props) => {
  const { editId } = props;
  const formikRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, homeData } = useSelector((state) => state.home);
  const { singleChoiceList } = useSelector((state) => state.shared);

  const initialValues = {
    name: editId ? homeData?.name || "" : "",
    description: editId ? homeData?.description || "" : "",
    basePrice: editId ? homeData?.basePrice || "" : "",
    status: editId ? homeData?.status || "" : singleChoiceList?.find((val) => val.configChoice === "active")?.id || "",
    media: editId ? homeData?.media || [] : [],
    products: [],
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Enter home name"),
    status: Yup.string().required("Select home status"),
    description: Yup.string().required("Enter home description"),
    basePrice: Yup.string().required("Enter price"),
  });

  const onSubmit = (values) => {
    if (!loading) {
      if (editId) {
        dispatch(editHome(editId, values, navigate));
      } else {
        dispatch(addHome(values, navigate));
      }
    }
  };

  useEffect(() => {
    dispatch(getSingleConfigChoice("homeStatus"));
    if (editId) dispatch(getHome(editId));
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({ errors, values, setFieldValue, resetForm }) => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <StyledCard sx={{ p: "1.5rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                <InputField name="name" type="text" label="Name*" />

                <TextareaField name="description" label="Description*" />

                <Box className="grid grid-cols-3 gap-7">
                  <InputField
                    className="col-span-12 xl:col-span-2"
                    name="basePrice"
                    type="number"
                    label="Price"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />

                  <Box>
                    <Typography component="label" fontSize="0.875rem" fontWeight="600" display="block">
                      Status
                    </Typography>

                    <StyledSwitch
                      value={values.status}
                      checked={values.status === singleChoiceList?.find((val) => val.configChoice === "active")?.id}
                      onChange={(e) => {
                        setFieldValue(
                          "status",
                          e.target.checked
                            ? singleChoiceList?.find((val) => val.configChoice === "active")?.id
                            : singleChoiceList?.find((val) => val.configChoice === "inactive")?.id
                        );
                      }}
                      name="status"
                      label={
                        singleChoiceList && values.status
                          ? values.status === singleChoiceList?.find((val) => val.configChoice === "active")?.id
                            ? "Active"
                            : "Inactive"
                          : ""
                      }
                    />
                  </Box>
                </Box>
              </StyledCard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <StyledCard sx={{ p: "1.5rem" }}>
                <Typography component="label" fontSize="0.875rem" fontWeight="600" display="block" mb={2}>
                  Image
                </Typography>
                <FileUpload
                  accept="image/*"
                  setMedia={(files, progress) => progress && setFieldValue("media", files)}
                  media={values.media}
                  issubmiting={loading}
                  isupdating={editId}
                />
              </StyledCard>
            </Grid>
          </Grid>

          {/* <HomePorductSelection /> */}

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={loading}>
              {editId ? "Update Home" : "Create Home"}
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              component={Link}
              to={"/configuration/home"}
            >
              Cancel
            </StyledButton>

            <StyledButton type="button" variant="outlined" color={theme.palette.error.main} onClick={resetForm}>
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default HomeForm;
