import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const DocumentService = (function () {
  function _getAllDocument() {
    return Req.get("/api/user-home-milestone-documents/all");
  }
  function _getDocumentList(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/user-home-milestone-documents?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _addDocument(data) {
    return Req.post("/api/user-home-milestone-documents", data);
  }
  function _getDocument(id) {
    return Req.get(`/api/user-home-milestone-documents/${id}`);
  }
  function _editDocument(data, id) {
    return Req.put(`/api/user-home-milestone-documents/${id}`, data);
  }
  function _deleteDocument(id) {
    return Req.delete(`/api/user-home-milestone-documents/${id}`);
  }
  return {
    getAllDocument: _getAllDocument,
    getDocumentList: _getDocumentList,
    addDocument: _addDocument,
    getDocument: _getDocument,
    editDocument: _editDocument,
    deleteDocument: _deleteDocument,
  };
})();
export default DocumentService;
