import React, { useState } from "react";
import { Box, MenuItem, styled, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import RemoveIcon from "@mui/icons-material/Remove";
import { StyledAccordion } from "ui";
import ListHead from "./ListHead";
import Detail from "./Detail";

const Content = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  gap: "8px",
  paddingLeft: "45px",

  "&:before": {
    width: "1px",
    height: "100%",
    content: "''",
    position: "absolute",
    top: 0,
    left: 24,
    borderLeft: `1px solid ${theme.palette.blue.tropicalBlue}`,
  },

  "& .MuiAccordion-root": {
    position: "relative",
    border: "none",

    "&:after": {
      width: "17px",
      height: "1px",
      content: "''",
      position: "absolute",
      top: 24,
      left: -20,
      borderBottom: `1px solid ${theme.palette.blue.tropicalBlue}`,
    },

    "& .MuiAccordionSummary-root": {
      borderRadius: "5px !important",
      boxShadow: "0 1px 5px rgba(145,158,171,0.16)",
      backgroundColor: theme.palette.blue.aliceBlue,
    },
  },
}));

const ChildItem = (props) => {
  const { children, childItem, handleAction } = props;

  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const ToggleIcon = (props) => {
    return expanded ? <RemoveIcon {...props} /> : <AddIcon {...props} />;
  };

  return (
    <StyledAccordion
      label={
        <ListHead data={childItem}>
          <MenuItem onClick={() => handleAction("edit")}>
            <DriveFileRenameOutlineOutlinedIcon />
            Edit
          </MenuItem>
          <MenuItem onClick={() => handleAction("delete")} sx={{ color: theme.palette.error.main }}>
            <DeleteOutlineOutlinedIcon />
            Delete
          </MenuItem>
        </ListHead>
      }
      labelprops={{
        sx: {
          flexDirection: "row-reverse",
          gap: "20px",
          padding: "4px 14px",
          borderRadius: expanded ? "5px" : "0 !important",
          backgroundColor: expanded ? theme.palette.blue.shadowBlue : "transparent",
          "& .MuiAccordionSummary-expandIconWrapper": {
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
          },
        },
        expandIcon: <ToggleIcon />,
        onClick: () => {
          handleChange(childItem.id);
        },
      }}
      wrapperprops={{
        expanded: expanded === childItem.id,
        sx: {
          borderRadius: expanded ? "5px" : "0 !important",
          borderBottom: expanded ? "1px solid transparent" : `1px solid ${theme.palette.blue.tropicalBlue}`,
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      contentprops={{ sx: { px: 0 } }}
    >
      {childItem?.childrenProduct?.length > 0 ? (
        <Content>{children}</Content>
      ) : (
        <Detail
          data={childItem}
          backgroundColor={theme.palette.blue.aliceBlue}
          boxShadow="0 1px 5px rgba(145,158,171,0.16)"
        />
      )}
    </StyledAccordion>
  );
};

export default ChildItem;
