import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const UserService = (function () {
  function _getAllUser(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/users/all?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _getUser(id) {
    return Req.get(`/api/users/${id}`);
  }
  function _addUser(data) {
    return Req.post("/api/users", data);
  }
  function _editUser(data, id) {
    return Req.put(`/api/users/${id}`, data);
  }
  function _addUserHome(data) {
    return Req.post("/api/users/user-homes", data);
  }
  function _editUserHome(data, id) {
    return Req.put(`/api/users/user-homes`, data);
  }
  function _resendVerificationCode(id) {
    return Req.get(`/api/users/verificationCode/${id}`);
  }
  function _deleteUserHome(id) {
    return Req.delete(`/api/users/home/${id}`);
  }
  function _deleteUser(id) {
    return Req.delete(`/api/users/${id}`);
  }
  return {
    getAllUser: _getAllUser,
    getUser: _getUser,
    addUser: _addUser,
    editUser: _editUser,
    addUserHome: _addUserHome,
    editUserHome: _editUserHome,
    resendVerificationCode: _resendVerificationCode,
    deleteUserHome: _deleteUserHome,
    deleteUser: _deleteUser,
  };
})();
export default UserService;
