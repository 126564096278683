import React from "react";
import { getYear } from "date-fns";
import { Box, Typography, styled, useTheme } from "@mui/material";
import { sideNavConfig } from "configs/LayoutConfig";
import { StyledCard } from "ui";
import authBG from "assets/images/auth-bg.svg";

const AuthContainer = styled(Box)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  overflowX: "hidden",
  background: `url(${authBG})`,
  backgroundSize: "cover",

  "@media(min-width:768px)": { padding: "5rem" },
}));

const AuthWrapper = (props) => {
  const { title, subtitle, footer, children } = props;

  const theme = useTheme();

  return (
    <AuthContainer>
      <StyledCard
        sx={{
          width: { xs: "100%", lg: "500px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: { xs: "24px", lg: "45px 80px 55px" },
        }}
      >
        <img src={sideNavConfig.primaryLogo} alt="logo" className="max-w-full w-20" />

        <Box
          component="header"
          sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1.5, mb: 3, textAlign: "center" }}
        >
          <Typography component="h5" sx={{ mt: 3, fontSize: "22px", fontWeight: "500", lineHeight: "26px" }}>
            {title}
          </Typography>

          {subtitle && (
            <Typography component="p" sx={{ fontSize: "14px", lineHeight: "20px", color: theme.palette.grey.cadet }}>
              {subtitle}
            </Typography>
          )}
        </Box>

        {children}

        {footer && (
          <Typography sx={{ color: theme.palette.grey.cadet, textAlign: "center", mt: 4 }}>{footer}</Typography>
        )}
      </StyledCard>

      <Typography component="p" sx={{ fontSize: 12, mt: 3.5 }}>
        ©{getYear(new Date())} Sierra Homes. All Right Reserved.
      </Typography>
    </AuthContainer>
  );
};

export default AuthWrapper;
