import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const UserHomeCustomizationService = (function () {
  function _getAllUserHomeCustomization(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/user-home-customizations?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _addUserHomeCustomization(data) {
    return Req.post("/api/user-home-customizations", data);
  }
  function _deleteUserHomeCustomization(id) {
    return Req.delete(`/api/user-home-customizations/${id}`);
  }
  return {
    getAllUserHomeCustomization: _getAllUserHomeCustomization,
    addUserHomeCustomization: _addUserHomeCustomization,
    deleteUserHomeCustomization: _deleteUserHomeCustomization,
  };
})();
export default UserHomeCustomizationService;
