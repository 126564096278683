import React from "react";
import { AppBar, Box, IconButton, styled, alpha } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { appBarConfig } from "configs/LayoutConfig";
import UserDropdown from "./UserDropdown";
import NotificationDropdown from "./NotificationDropdown";
import ThemeSwitcher from "./ThemeSwitcher";

const Header = styled(AppBar)(({ theme }) => ({
  height: appBarConfig.height,
  flexDirection: "row",
  alignItems: "center",
  padding: "0 2.5rem",
  boxShadow: "none",
  background: alpha(theme.palette.layout.main, 0.8),
  backdropFilter: "blur(6px)",
}));

const StyledAppBar = (props) => {
  const { handleDrawerCollapse } = props;

  return (
    <Header position={appBarConfig.style}>
      <IconButton
        onClick={handleDrawerCollapse}
        edge="start"
        sx={{
          display: { xl: "none" },
          marginRight: 5,
        }}
      >
        <MenuIcon />
      </IconButton>

      <Box display="flex" gap={1} ml="auto">
        <NotificationDropdown />

        <ThemeSwitcher />

        <UserDropdown />
      </Box>
    </Header>
  );
};

export default StyledAppBar;
