import React from "react";
import { Box } from "@mui/material";
import { StyledCard } from "ui";
import graphics from "assets/images/home-placeholder.jpg";

const SummaryCard = (props) => {
  const { data, size = 80, children } = props;

  return (
    <Box sx={{ display: "flex", gap: "24px", flexDirection: { xs: "column", md: "row" }, position: "relative" }}>
      <StyledCard sx={{ width: size, height: size, flexShrink: 0, overflow: "hidden" }}>
        <img src={data?.media?.[0]?.url || graphics} alt="card" className="w-full aspect-[4/4]" />
      </StyledCard>
      <Box>{children}</Box>
    </Box>
  );
};

export default SummaryCard;
