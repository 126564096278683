import React, { useRef } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch } from "react-redux";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { deleteMedia } from "reduxs/actions";
import { defaultMaxSize } from "helpers";
import FilePondStyles from "styles/FilePondDropzone.style";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

// Register the plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const MediaAPI = `${process.env.REACT_APP_API_URL}/api/medias`;

const FileUpload = (props) => {
  const {
    accept,
    maxSize = defaultMaxSize,
    multiple = true,
    maxFiles = 5,
    setMedia,
    media = [],
    issubmiting = false, // this is required to cleanup filpond instances
    isupdating = false,
    ...others
  } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const pondRef = useRef(null);

  const FieldPondStyle = FilePondStyles(theme);

  const [newFiles, setNewFiles] = React.useState([]);
  const [fileUpdated, setFileUpdate] = React.useState(false);
  const [isProgressComplete, setIsProgressComplete] = React.useState(false);
  const previousFilesUrl = React.useMemo(() => {
    const mediaUrl = [];
    if (!issubmiting && (media || fileUpdated)) {
      media.forEach((url) => {
        mediaUrl.push({
          source: url.url,
          options: {
            type: "local",
          },
        });
      });

      setTimeout(() => {
        setFileUpdate(false);
      }, 20);
    }

    return mediaUrl;
  }, [media, issubmiting, fileUpdated]);

  const onRemove = (file) => {
    const element = media?.find((item) => item.name === file.filename);

    if (element) {
      if (!element.id) {
        dispatch(deleteMedia(element.name));
      }
      media.splice(media.indexOf(element), 1);
      setFileUpdate(true);
      setMedia(media, true);
    }
  };

  const prepareFiles = (file) => {
    const response = JSON.parse(file.serverId);
    if (response.success) {
      const newFile = {
        id: "",
        name: file.filename,
        value: response.data.name,
        url: response.data.url,
      };

      // setMedia("media", allFiles);
      setNewFiles((prevState) => [...prevState, newFile]);
    }
  };

  React.useEffect(() => {
    // Formik setFieldValue replaces old values
    // need to update manually from parent(Form Component) after all the progress is complete
    if (newFiles && isProgressComplete) {
      setMedia(newFiles, isProgressComplete);
    }
  }, [newFiles, isProgressComplete]);

  const loadFiles = isupdating ? { files: previousFilesUrl } : {};

  return (
    <Box maxWidth="100%" sx={FieldPondStyle} {...others}>
      <FilePond
        ref={pondRef}
        allowFileTypeValidation={true}
        acceptedFileTypes={accept}
        allowFileSizeValidation={true}
        maxFileSize={`${maxSize}MB`}
        onprocessfile={(error, file) => {
          setIsProgressComplete(false);

          if (!error) {
            prepareFiles(file);
          }
        }}
        onprocessfiles={() => setIsProgressComplete(true)}
        onremovefile={(error, file) => {
          if (!error && !issubmiting) {
            onRemove(file);
          }
        }}
        onupdatefiles={(files) => console.log("update", files)} // this is needed to handle file delete issue immediately after upload
        allowMultiple={multiple}
        maxFiles={maxFiles}
        allowReorder={true}
        allowRevert={true}
        server={{
          process: MediaAPI,
          // This code is needed for file Preview
          // however CORS need to be handled from API
          // https://pqina.nl/filepond/docs/api/server/#load
          // load: (source, load, error, progress, abort, headers) => {
          //   const req = new Request(source);
          //   fetch(req).then(function (response) {
          //     response.blob().then(function (myBlob) {
          //       load(myBlob);
          //     });
          //   });
          // },
          fetch: null,
          revert: null,
        }}
        {...loadFiles}
        stylePanelLayout="compact"
        name="file"
        labelIdle='Drop or Select file <span class="content">Drop files here or click <small class="filepond--label-action">Browse File</small> as you like.</span>'
      />

      {media?.length >= maxFiles && (
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginTop: 4,
            fontSize: "0.875rem",
            color: theme.palette.grey.dark,
          }}
        >
          <InfoOutlinedIcon sx={{ color: theme.palette.info.main }} />
          Limit reached. Max number of files: {maxFiles}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(FileUpload);
