import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import enAU from "date-fns/locale/en-AU";
import { useField } from "formik";
import InputFieldStyles from "styles/form/InputField.style";
import DateTimePickerStyles from "styles/form/DateTimePicker.style";
import { format } from "date-fns";
import StyledButton from "ui/button/Button";

const InputDateTimepickerField = ({
  label,
  pickerView = DateTimePicker, // DesktopDateTimePicker/ MobileDateTimePicker / DateTimePicker / StaticDateTimePicker
  variant,
  shrinkLabel = false,
  dense = false,
  hideactions = false,
  isloading = false,
  handleSelect,
  handleCancel,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const theme = useTheme();
  const FieldStyles = InputFieldStyles(theme);
  const PickerStyles = DateTimePickerStyles(theme);

  const [selected, setSelected] = useState(null);

  const CustomActionbar = ({ onAccept, onCancel }) => {
    return (
      <Box
        className="MuiDialogActions-root MuiPickersLayout-actionBar border-t border-solid border-[#0000001f]"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { sm: "flex-end" },
          alignItems: "center",
          gap: 2,
          position: "sticky",
          bottom: 0,
          py: 2,
        }}
      >
        <StyledButton
          type="button"
          variant="outlined"
          isloading={isloading}
          color={theme.palette.grey.main}
          sx={{ color: theme.palette.text.primary }}
          onClick={() => {
            onCancel();
            handleCancel && handleCancel();
          }}
        >
          Cancel
        </StyledButton>

        <StyledButton
          type="button"
          isloading={isloading}
          disabled={!selected}
          onClick={() => {
            onAccept();
            handleSelect && handleSelect();
          }}
          sx={{ borderRadius: 2 }}
        >
          Schedule send
        </StyledButton>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <Box
        component={pickerView}
        label={label || null}
        value={field.value || null}
        sx={{
          ...FieldStyles,
          ...PickerStyles,
          "&.MuiFormControl-root": { width: "100%" },
          "& .MuiDialogActions-root": { display: hideactions ? "none" : "" },
        }}
        slots={{ actionBar: CustomActionbar }}
        InputLabelProps={shrinkLabel ? { shrink: true } : {}}
        variant={variant || "outlined"}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
        margin="dense"
        className={dense ? "densed-field" : ""}
        fullWidth
        closeOnSelect={false}
        dayOfWeekFormatter={(_day, date) => format(date, "EEE")}
        onAccept={(newValue) => helpers.setValue(newValue)}
        onChange={(value) => setSelected(!!value)}
        slotProps={{
          textField: { ...field },
          desktopPaper: { elevation: 0, sx: PickerStyles },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default InputDateTimepickerField;
