import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_DOCUMENT,
  GET_DOCUMENT_LIST,
  ADD_DOCUMENT,
  GET_DOCUMENT,
  EDIT_DOCUMENT,
  DELETE_DOCUMENT,
} from "../actions";
import {
  getAllDocumentSuccess,
  getAllDocumentError,
  getDocumentList,
  getDocumentListSuccess,
  getDocumentListError,
  addDocumentSuccess,
  addDocumentError,
  getDocumentSuccess,
  getDocumentError,
  editDocumentSuccess,
  editDocumentError,
  deleteDocumentSuccess,
  deleteDocumentError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import DocumentService from "services/DocumentService";

export function* watchgetAllDocument() {
  yield takeEvery(GET_ALL_DOCUMENT, getAllDocument);
}

const getAllDocumentAsync = async () => {
  return DocumentService.getAllDocument();
};

function* getAllDocument() {
  try {
    const response = yield call(getAllDocumentAsync);
    if (response.data) {
      yield put(getAllDocumentSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllDocumentError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllDocumentError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetDocumentList() {
  yield takeEvery(GET_DOCUMENT_LIST, getDocumentListAc);
}

const getDocumentListAsync = async (dbParam) => {
  return DocumentService.getDocumentList(dbParam);
};

function* getDocumentListAc({ payload }) {
  try {
    const response = yield call(getDocumentListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getDocumentListSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getDocumentListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getDocumentListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddDocument() {
  yield takeEvery(ADD_DOCUMENT, addDocument);
}

const addDocumentAsync = async (data) => {
  return DocumentService.addDocument(data);
};

function* addDocument({ payload }) {
  try {
    const response = yield call(addDocumentAsync, payload.documentData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addDocumentSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(addDocumentError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addDocumentError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetDocument() {
  yield takeEvery(GET_DOCUMENT, getDocument);
}

const getDocumentAsync = async (id) => {
  return DocumentService.getDocument(id);
};

function* getDocument({ payload }) {
  try {
    const response = yield call(getDocumentAsync, payload.documentId);
    if (response.data.success) {
      yield put(getDocumentSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getDocumentError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getDocumentError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditDocument() {
  yield takeEvery(EDIT_DOCUMENT, editDocument);
}

const editDocumentAsync = async (data, id) => {
  return DocumentService.editDocument(data, id);
};

function* editDocument({ payload }) {
  try {
    const response = yield call(editDocumentAsync, payload.documentData, payload.documentId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editDocumentSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(editDocumentError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editDocumentError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteDocument() {
  yield takeEvery(DELETE_DOCUMENT, deleteDocument);
}

const deleteDocumentAsync = async (id) => {
  return DocumentService.deleteDocument(id);
};

function* deleteDocument({ payload }) {
  try {
    const response = yield call(deleteDocumentAsync, payload.documentId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteDocumentSuccess(response.data.success));
      yield put(getDocumentList());
    } else {
      toaster("", response.data.message);
      yield put(deleteDocumentError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteDocumentError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllDocument),
    fork(watchGetDocumentList),
    fork(watchAddDocument),
    fork(watchGetDocument),
    fork(watchEditDocument),
    fork(watchDeleteDocument),
  ]);
}
