import {
  GET_ALL_DOCUMENT,
  GET_ALL_DOCUMENT_SUCCESS,
  GET_ALL_DOCUMENT_ERROR,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_LIST_SUCCESS,
  GET_DOCUMENT_LIST_ERROR,
  ADD_DOCUMENT,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  GET_DOCUMENT,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_ERROR,
  EDIT_DOCUMENT,
  EDIT_DOCUMENT_SUCCESS,
  EDIT_DOCUMENT_ERROR,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  RESET_DOCUMENT,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  documents: null,
  documentList: null,
  documentData: null,
  documentId: null,
  success: false,
  loading: false,
  fetchLoading: false,
  frmLoading: false,
  delLoading: false,
  error: null,
};

const documentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_DOCUMENT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        error: null,
      };
    case GET_ALL_DOCUMENT_ERROR:
      return {
        ...state,
        documents: null,
        error: action.payload,
      };
    case GET_DOCUMENT_LIST:
      return {
        ...state,
        loading: true,
        documentData: null,
        documentId: null,
        error: null,
      };
    case GET_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documentList: action.payload,
        error: null,
      };
    case GET_DOCUMENT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        documentList: null,
        error: action.payload,
      };
    case ADD_DOCUMENT:
      return { ...state, frmLoading: true, error: null };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case ADD_DOCUMENT_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case GET_DOCUMENT:
      return { ...state, fetchLoading: true, error: null };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        documentData: action.payload,
        error: null,
      };
    case GET_DOCUMENT_ERROR:
      return {
        ...state,
        fetchLoading: false,
        documentData: null,
        error: action.payload,
      };
    case EDIT_DOCUMENT:
      return { ...state, frmLoading: true, error: null };
    case EDIT_DOCUMENT_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_DOCUMENT_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_DOCUMENT:
      return { ...state, delLoading: true, error: null };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_DOCUMENT:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        frmLoading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default documentReducer;
