import React, { useEffect, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  styled,
  useMediaQuery,
  useTheme,
  alpha,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { getMessageList } from "reduxs/actions";
import { StyledButton } from "ui";
import { MessageNavOptions } from "./MessageConfig";
import { useMessage } from "./MessageContext";
import useGetMessageStatus from "./hooks/useGetMessageStatus";
import cycanBlur from "assets/images/cyan-blur.png";
import redBlur from "assets/images/red-blur.png";
import { checkPermission } from "helpers";

const MessageNavDrawer = styled(Drawer)(({ theme, open }) => ({
  minWidth: 160,
  width: 160,
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    maxHeight: useMediaQuery(theme.breakpoints.up("lg")) ? window.innerHeight * 0.85 : "inherit",
    minWidth: 160,
    width: 160,
    position: "static",
    border: "none",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    zIndex: useMediaQuery(theme.breakpoints.up("lg")) ? 1 : "",

    "@media (max-width: 1023px)": {
      padding: 14,
      borderRadius: 0,
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
      backgroundImage: `url(${cycanBlur}), url(${redBlur})`,
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "right top, left bottom",
      backgroundSize: "50%, 50%",
    },
  },
}));

const NavItem = styled(ListItem)(({ theme, open }) => ({
  fontSize: 14,

  "& .MuiListItemButton-root": {
    padding: "10px 0 10px 12px !important",
    borderRadius: 6,
    gap: 12,
    color: theme.palette.mode === "light" ? theme.palette.grey.dark : "#b9b9b9",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "initial",
    color: theme.palette.mode === "light" ? theme.palette.grey.dark : "#b9b9b9",
  },

  "& .MuiListItemText-root": {
    "& .MuiTypography-root": {
      maxWidth: "calc(100% - 24px)",
      wordBreak: "break-all",
    },
  },

  "& .MuiListItemSecondaryAction-root": {
    right: 0,
  },

  "& .MuiListItemButton-root.Mui-selected": {
    color: theme.palette.mode === "light" ? theme.palette.background.button : theme.palette.common.white,
    backgroundColor: "transparent",

    "& .MuiListItemIcon-root": {
      color: theme.palette.mode === "light" ? theme.palette.background.button : theme.palette.common.white,
    },

    "+ .MuiListItemSecondaryAction-root": {
      color: theme.palette.mode === "light" ? theme.palette.background.button : theme.palette.common.white,
    },
  },
}));

const MessageNav = (props) => {
  const { onCompose } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const getMessageStatus = useGetMessageStatus();
  const { currentMenu, userInfo, isMobileNavListOpen, updateContext } = useMessage();

  const { permissionList, singleChoiceList: messageStatusList } = useSelector((state) => state.shared);
  const { messageList } = useSelector((state) => state.message);

  const draftStatusId = useMemo(
    () => messageStatusList?.find((status) => status.configChoice === "draft")?.id,
    [messageStatusList]
  );

  const handleOnSelectMenu = (active) => {
    if (currentMenu === active) return; // Handle multiple API call if same menu clicked
    let filter = null;

    updateContext({ currentMenu: active });

    switch (active) {
      case "inbox":
        filter = {
          messageTo: userInfo.id,
        }; // Get's Sent Messages
        break;
      case "sent":
        filter = {
          messageFrom: userInfo.id,
        }; // Get's Sent Messages
        break;
      case "draft":
        filter = {
          status: draftStatusId,
          messageFrom: userInfo.id,
        }; // Get's Draft Messages
        break;
      case "schedule":
        filter = {
          messageFrom: userInfo.id,
          status: 28,
        }; // Get's Scheduled Messages
        break;
      case "important":
        filter = {
          messageTo: userInfo.id,
          isImportant: 1,
        }; // Get's Important Messages
        break;
      case "stared":
        filter = {
          messageTo: userInfo.id,
          isStarred: 1,
        }; // Get's Stared Messages
        break;

      default:
        filter = {
          messageFrom: userInfo.id, // Get's Sent
          messageTo: userInfo.id, // Get's Inbox Messages
        };
        break;
    }

    dispatch(getMessageList(filter));

    updateContext({ isMobileNavListOpen: false }); // Close Mobile NavList Menu
  };

  useEffect(() => {
    let counts = { inbox: 0, sent: 0, draft: 0, important: 0, stared: 0 };
    if (!!messageList.length) {
      const totalDrafts = messageList.filter((message) => message.status === draftStatusId).length;
      messageList.forEach((message) => {
        const status = getMessageStatus(message.messageTo);

        counts = {
          ...counts,
          inbox: counts.inbox > 100 ? 99 : counts.inbox + !status.isRead,
          draft: counts.draft > 100 ? 99 : totalDrafts,
          important: counts.important > 100 ? 99 : counts.important + status.isImportant,
          stared: counts.stared > 100 ? 99 : counts.stared + status.isStarred,
        };
      });
    }

    MessageNavOptions.map((nav) => (nav.count = counts[nav.slug]));
  }, [messageList]);

  return (
    <MessageNavDrawer
      classes={{ paper: "custom-scrollbar backdrop-blur-[20px] xl:backdrop-blur-0" }}
      variant={useMediaQuery(theme.breakpoints.down(1024)) ? "temporary" : "permanent"}
      open={isMobileNavListOpen}
      anchor="left"
      onClose={() => updateContext({ isMobileNavListOpen: false })}
    >
      {checkPermission(permissionList, "add message") && (
        <StyledButton
          color={theme.palette.mode === "light" ? theme.palette.common.black : theme.palette.common.white}
          className={`shadow-none ${theme.palette.mode === "dark" ? "!text-black" : ""}`}
          onClick={() => {
            onCompose();

            updateContext({ isMobileNavListOpen: false });
          }}
        >
          <EditIcon className="text-lg" /> Compose
        </StyledButton>
      )}

      <List component="nav">
        {MessageNavOptions.map((option, index) => (
          <NavItem
            key={index}
            disablePadding
            // ## Shall be implemented with different API
            // secondaryAction={
            //   option.count ? (
            //     <Box
            //       component="span"
            //       sx={{
            //         display: "flex",
            //         justifyContent: "center",
            //         alignItems: "center",
            //         flexShrink: 0,
            //         fontSize: 12,
            //         fontWeight: "700",
            //         borderRadius: "30px",
            //       }}
            //     >
            //       {option.count ? (option.count >= 100 ? "+99" : option.count) : ""}
            //     </Box>
            //   ) : (
            //     <></>
            //   )
            // }
          >
            <ListItemButton selected={option.slug === currentMenu} onClick={() => handleOnSelectMenu(option.slug)}>
              <ListItemIcon>
                <Box component={option.icon} />
              </ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItemButton>
          </NavItem>
        ))}
      </List>
    </MessageNavDrawer>
  );
};

export default MessageNav;
