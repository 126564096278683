export default (theme) => ({
  root: {
    "& .MuiInputBase-root": {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.grey.cultured,
        borderRadius: "0.5rem",
      },

      "& .MuiChip-root": {
        background: "transparent",
        boxShadow: "0 0px 4px 0 #e7e7e7",
        border: `1px solid ${theme.palette.grey.main}`,
      },

      "& >.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium:not(.MuiChip-root)": {
        color: theme.palette.info.main,
        fontSize: 16,
        fontWeight: 600,
      },
    },
  },

  icon: {
    color: theme.palette.primary.light,
    right: 12,
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
  },
  paper: {
    "& .MuiPaper-root": {
      padding: ".3125rem 0",
      borderRadius: 3,
      marginTop: 1,
      boxShadow:
        theme.palette.mode === "light"
          ? "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px"
          : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
      oveflow: "hidden",
    },

    "& .MuiList-root": {
      paddingTop: 0,
      paddingBottom: 0,
      background: "white",
    },

    "& .MuiButtonBase-root.MuiMenuItem-root": {
      fontWeight: 400,
      paddingTop: ".75rem",
      paddingBottom: ".75rem",
    },
    "& .MuiButtonBase-root.MuiMenuItem-root:hover": {
      background: theme.palette.primary.light,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected": {
      color: "white",
      background: theme.palette.primary.light,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root.MuiAutocomplete-option[aria-selected='true']": {
      color: "white",
      background: theme.palette.primary.light,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root.Mui-selected:hover": {
      background: theme.palette.primary.light,
    },
    "& .MuiButtonBase-root.MuiMenuItem-root.MuiAutocomplete-option[aria-selected='true']:hover": {
      background: theme.palette.primary.light,
    },
  },
});
