import React, { useEffect } from "react";
import { Box, InputAdornment, useTheme } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { initialChangePassword, resetAuth } from "reduxs/actions";
import { InputHiddenField, InputPasswordField } from "ui/form/field";
import { StyledButton } from "ui";
import AuthWrapper from "./shared/AuthWrapper";

const InitialChangePassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { loading } = useSelector((state) => state.auth);

  const schema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password should be at least 8 digits including 1 number, 1 uppercase, 1 lowercase and 1 special character"
      ),
    passwordConfirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "New passsword and Confirm password didn't match"),
  });

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  const onResetPassword = (values) => {
    if (!loading) {
      dispatch(initialChangePassword(values, navigate));
    }
  };

  return (
    <AuthWrapper title="Create new password" subtitle="Please set new password to access the account further">
      <Formik
        initialValues={{
          userId: params?.id || "",
          verificationCode: params?.token || "",
          password: "",
          passwordConfirmation: "",
        }}
        validationSchema={schema}
        onSubmit={onResetPassword}
      >
        {({ values }) => (
          <Box component={Form} sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
            <InputHiddenField name="userId" />
            <InputHiddenField name="verificationCode" />
            <InputPasswordField
              shrinkLabel={true}
              validationInfo={true}
              name="password"
              label="New password"
              placeholder="Enter your new password"
              inputOptions={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />

            <InputPasswordField
              shrinkLabel={true}
              name="passwordConfirmation"
              label="Confirm Password"
              placeholder="Enter your password"
              inputOptions={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />

            <StyledButton type="submit" color={theme.palette.background.button} isloading={loading} sx={{ mt: 1 }}>
              Reset
            </StyledButton>
          </Box>
        )}
      </Formik>
    </AuthWrapper>
  );
};

export default InitialChangePassword;
