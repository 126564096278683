import { Box, IconButton, Tooltip, styled, useTheme } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useMemo, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const sliderOption = {
  autoWidth: true,
  type: "slide",
  gap: "10px",
  pagination: false,
  arrows: true,
  autoplay: false,
  rewind: false,
};

const Slider = styled(Splide)(({ theme }) => ({
  padding: "0 35px",

  "& .splide__arrows": {
    "& .splide__arrow--prev": { left: 0 },
    "& .splide__arrow--next": { right: 0 },
  },
}));

const MediaList = (props) => {
  const { medias, ondelete } = props;

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const allSlides = useMemo(() => {
    return medias.map((media) => ({ ...media, src: media.url }));
  }, [medias]);

  const toggleOpen = (state) => () => setOpen(state);

  return (
    <>
      <Slider options={sliderOption} className="overflow-hidden rounded mt-2">
        {allSlides?.map((media, index) => (
          <SplideSlide key={index} className="overflow-hidden rounded group">
            <Box
              sx={{
                width: 80,
                height: 80,
                position: "relative",
                borderRadius: 3,
                backgroundColor: "#000000",
                cursor: "zoom-in",
              }}
              onClick={() => {
                setIndex(index);
                setOpen(true);
              }}
            >
              <Box
                component="img"
                sx={{ width: 80, height: 80, borderRadius: 3 }}
                className="aspect-square object-cover group-hover:opacity-50"
                src={media.url}
              />

              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    color: theme.palette.error.main,
                    transform: "translateX(-50%)",
                  }}
                  className="opacity-0 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    ondelete(media.id);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </SplideSlide>
        ))}
      </Slider>

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={allSlides}
        // on={{ view: updateIndex }}
        animation={{ fade: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{ container: { backgroundColor: "rgba(33, 43, 54, 0.8)", backdropFilter: "blur(4px)" } }}
      />
    </>
  );
};

export default MediaList;
