import React from "react";
import { useDispatch, useSelector } from "react-redux";
import RoofingIcon from "@mui/icons-material/Roofing";
import HouseIcon from "@mui/icons-material/House";
import { getMultipleConfigChoice, getUserHomeMilestoneList } from "reduxs/actions";

const MILESTONE_PARAMS = { orderBy: "order", sortedBy: "asc" };

const useGetMilestoneProgress = () => {
  const dispatch = useDispatch();

  const { multipleChoiceList } = useSelector((state) => state.shared);
  const { userHomeMilestoneList } = useSelector((state) => state.userHomeMilestone);

  const getData = (userHomeId, tab) => {
    const filterParams = {
      ...MILESTONE_PARAMS,
      userHomeId: userHomeId,
    };
    dispatch(getUserHomeMilestoneList(filterParams));
  };

  const totalDocuments = (selectedItems) => {
    let totalDocumentsCount = 0;
    let totalDocumentsCountWithStatus21 = 0;

    selectedItems?.forEach((item) => {
      if (item.documents && item.documents.length > 0) {
        totalDocumentsCount += item.documents.length;

        item.documents.forEach((document) => {
          if (document.status === 21) {
            totalDocumentsCountWithStatus21 += 1;
          }
        });
      }
    });

    return {
      total: totalDocumentsCount || 0,
      completed: totalDocumentsCountWithStatus21 || 0,
    };
  };

  const getTotals = (type) => {
    const selectedItems = userHomeMilestoneList?.filter((process) => process.milestone.type === type);
    const totalCompleted = selectedItems?.filter((complete) => complete.status === 25)?.length;

    return {
      items: selectedItems?.length || 0,
      completed: totalCompleted || 0,
      percent: (totalCompleted / selectedItems?.length) * 100 || 0,
      documentsCount: totalDocuments(selectedItems),
    };
  };

  const generateTypeIcons = (typeName) => {
    if (typeName === "pre-construction") {
      return <RoofingIcon />;
    } else if (typeName === "post-construction") {
      return <HouseIcon />;
    }
  };

  const userMilestoneData = React.useMemo(() => {
    let types = [];
    if (multipleChoiceList?.milestoneType?.length) {
      types = multipleChoiceList?.milestoneType?.map((type) => ({
        ...type,
        icon: generateTypeIcons(type.configChoice),
        total: getTotals(type.id),
        milestones: userHomeMilestoneList?.filter((process) => process.milestone.type === type.id),
      }));
    }

    return types;
  }, [multipleChoiceList, userHomeMilestoneList]);

  React.useEffect(() => {
    dispatch(getMultipleConfigChoice(["milestoneType", "milestoneStatus", "homeMilestoneStatus"]));
  }, []);

  return { getData, userMilestoneData };
};

export default useGetMilestoneProgress;
