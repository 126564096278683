import React from "react";
import ManageAccountsTwoToneIcon from "@mui/icons-material/ManageAccountsTwoTone";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import AddHomeTwoToneIcon from "@mui/icons-material/AddHomeTwoTone";
import DraftsTwoToneIcon from "@mui/icons-material/DraftsTwoTone";

const iconSize = "small"; // small,medium,large,string

const MenuLists = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: <DashboardTwoToneIcon fontSize={iconSize} />,
    type: "both",
  },
  { name: "My Homes", path: "/my-home", icon: <AddHomeTwoToneIcon fontSize={iconSize} />, type: "client" },
  {
    name: "User Management",
    path: "/user-management",
    icon: <ManageAccountsTwoToneIcon fontSize={iconSize} />,
    type: "system",
    submenu: [
      {
        action: "list role",
        name: "Role",
        path: "/user-management/role",
        icon: "",
      },
      {
        action: "list user",
        name: "User",
        path: "/user-management/user",
        icon: "",
      },
      {
        action: "list customer",
        name: "Customer",
        path: "/user-management/customer",
        icon: "",
      },
    ],
  },
  {
    name: "Configuration",
    path: "/configuration",
    icon: <SettingsTwoToneIcon fontSize={iconSize} />,
    type: "system",
    submenu: [
      {
        action: "list product",
        name: "Product",
        path: "/configuration/products",
        icon: "",
      },
      {
        action: "list milestone",
        name: "Milestone",
        path: "/configuration/milestones",
        icon: "",
      },
      {
        action: "list home",
        name: "Home",
        path: "/configuration/home",
        icon: "",
      },
    ],
  },
  {
    name: "System Setting",
    path: "/system-setting",
    icon: <DisplaySettingsTwoToneIcon fontSize={iconSize} />,
    type: "system",
    submenu: [
      {
        action: "list category",
        name: "Config Choice Category",
        path: "/system-setting/config-choice-category",
        icon: "",
      },
      {
        action: "list choice",
        name: "Config Choice",
        path: "/system-setting/config-choice",
        icon: "",
      },
    ],
  },
  {
    name: "Messages",
    path: "/message",
    icon: <DraftsTwoToneIcon fontSize={iconSize} />,
    type: "both",
    submenu: [
      {
        action: "list message",
        name: "Conversation",
        path: "/message/conversation",
        icon: "",
      },
      {
        action: "list message group",
        name: "Message Group",
        path: "/message/message-group",
        icon: "",
      },
    ],
  },
];

export default MenuLists;
