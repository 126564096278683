import React, { useState, useEffect } from "react";
import update from "immutability-helper";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RoofingIcon from "@mui/icons-material/Roofing";
import HouseIcon from "@mui/icons-material/House";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMilestone,
  getAllMilestone,
  getMilestoneList,
  getMultipleConfigChoice,
  resetMilestone,
  sortMilestone,
} from "reduxs/actions";
import { checkPermission, capitalizeFirstLetter } from "helpers";
import {
  StyledCard,
  BreadcrumbContainer,
  StatusChip,
  TableInstance,
  Table,
  Toolbar,
  StyledButton,
  Action,
  AlertDialog,
  StyledTabs,
  FloatingForm,
  TemplateSwitch,
} from "ui";
import MilestoneForm from "./Form";

const MILESTONE_PARAMS = { page: 1, pageSize: 500, orderBy: "order", sortedBy: "asc" };

const MilestoneList = () => {
  const dispatch = useDispatch();

  const { milestones, milestoneList, delLoading, loading, success } = useSelector((state) => state.milestone);
  const { permissionList, multipleChoiceList } = useSelector((state) => state.shared);

  const [data, setData] = useState([]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [activeTab, setActiveTab] = useState();
  const [formId, setFormId] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [dragTimeout, setDragTimeout] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState();

  const calculateTotals = React.useMemo(() => {
    const totals = {};
    if (multipleChoiceList?.homePlan?.length && multipleChoiceList?.milestoneType?.length && milestones?.length) {
      for (let i = 0; i < multipleChoiceList?.homePlan?.length; i++) {
        const plan = multipleChoiceList?.homePlan[i];
        const milstoneLists = milestones?.filter((milestone) => milestone.plan === plan.id);

        const selected = {};
        multipleChoiceList?.milestoneType.forEach((type) => {
          selected[type.configChoice] = milstoneLists?.filter((item) => item.type === type.id)?.length || 0;
        });

        totals[plan.configChoice] = selected;
      }
    }
    return totals;
  }, [multipleChoiceList, milestones]);

  const getData = (type, plan) => {
    dispatch(
      getMilestoneList({
        ...MILESTONE_PARAMS,
        plan: plan || selectedPlan?.id || multipleChoiceList?.homePlan?.[0].id,
        type: type || activeTab?.id || multipleChoiceList?.milestoneType?.[0].id,
      })
    );

    dispatch(getAllMilestone()); // Get all milestone to calculate count
  };

  const generateTypeIcons = (typeName) => {
    if (typeName === "pre-construction") {
      return <RoofingIcon />;
    } else if (typeName === "post-construction") {
      return <HouseIcon />;
    }
  };

  const onChange = (sortOrder, page, perPage, activeCol) => {
    dispatch(
      getMilestoneList({
        sortedBy: sortOrder,
        page: page,
        pageSize: perPage,
        orderBy: activeCol,
        type: activeTab?.id,
        plan: selectedPlan?.id,
      })
    );
  };

  const handleSort = (order, val) => {
    onChange(search, order, pageValue, perPage, val);
  };

  const handleOnDelete = () => {
    if (!delLoading && deleteId) dispatch(deleteMilestone(deleteId));
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(multipleChoiceList?.milestoneType.find((item) => item.id === newValue));

    getData(newValue, selectedPlan?.id);
  };

  const handlePlanChange = (event, plan) => {
    setSelectedPlan(multipleChoiceList?.homePlan.find((x) => x.id === plan));
    getData(activeTab.id, plan);
  };

  const handleEditClick = (event, id) => {
    setOpenForm(true);
    setFormId(id);
  };

  const resetAll = () => {
    setFormId("");
    setOpenForm(false);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const draggedItem = data[dragIndex];

    // Remove the dragged item from its original position
    data.splice(dragIndex, 1);

    // Insert the dragged item at the new position
    data.splice(hoverIndex, 0, draggedItem);

    // Update the order values of all items
    const updatedData = data.map((item, index) => {
      return update(item, {
        order: { $set: index + 1 },
      });
    });

    // Update the state with the updated data
    setData(updatedData);

    // This can be used as payload for updating
    if (dragTimeout) {
      clearTimeout(dragTimeout);
    }
    setDragTimeout(
      setTimeout(function () {
        const payload = updatedData.map(({ id, order }) => {
          return update(
            {},
            {
              $merge: {
                id,
                order,
              },
            }
          );
        });

        dispatch(sortMilestone({ milestoneOrder: payload }));
      }, 1000)
    );
  };

  const columns = React.useMemo(() => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Order",
      accessor: "order",
      id: "order",
    },
    {
      Header: "Name",
      accessor: (value) => capitalizeFirstLetter(value.name),
    },
    {
      Header: "Status",
      accessor: "statusConfigChoice",
      id: "status",
      Cell: (props) => {
        return <StatusChip status={props.value.configChoice} label={props.value.displayName} />;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (props) => {
        const rowIdx = props.row.values.id;

        return (
          <Action
            id={props.row.id}
            handleOnDelete={() => {
              setDeleteId(rowIdx);
              setOpenDeleteAlert(true);
            }}
            handleOnEdit={(e) => handleEditClick(e, rowIdx)}
            permission={props.permission}
            {...props}
          />
        );
      },
    },
  ]);

  const mileStoneTabs = React.useMemo(() => {
    let types = [];

    if (multipleChoiceList?.milestoneType?.length) {
      if (!activeTab) {
        setActiveTab(multipleChoiceList?.milestoneType?.[0]); // Set defulat active tab
      }

      types = multipleChoiceList?.milestoneType?.map((type) => ({
        ...type,
        icon: generateTypeIcons(type.configChoice),
        totalItem: calculateTotals,
      }));
    }

    return types;
  }, [multipleChoiceList, milestones, calculateTotals]);

  useEffect(() => {
    dispatch(getMultipleConfigChoice(["milestoneType", "milestoneStatus", "homePlan"]));
  }, []);

  useEffect(() => {
    setSelectedPlan(multipleChoiceList?.homePlan?.[0]);

    if (multipleChoiceList?.milestoneType?.length && multipleChoiceList?.homePlan?.length) {
      getData();
    }
  }, [multipleChoiceList]);

  useEffect(() => {
    setData(milestoneList);
  }, [milestoneList]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Milestones"
          paths={[
            {
              title: "Milestone",
              path: "/configuration/milestone",
            },
          ]}
        />

        <StyledButton type="button" onClick={() => setOpenForm(true)}>
          <AddIcon />
          Add Milestone
        </StyledButton>
      </Box>
      {multipleChoiceList && multipleChoiceList?.homePlan && (
        <TemplateSwitch value={selectedPlan?.id} onChange={handlePlanChange} sx={{ mb: 3 }}>
          {multipleChoiceList?.homePlan.map((plan) => {
            return (
              <TemplateSwitch.Button key={plan.id} value={plan.id}>
                {plan.displayName}
              </TemplateSwitch.Button>
            );
          })}
        </TemplateSwitch>
      )}

      {mileStoneTabs && (
        <StyledTabs value={activeTab?.id} onChange={handleTabChange} sx={{ flex: 1, mb: 3 }}>
          {mileStoneTabs?.map((type) => {
            return (
              <StyledTabs.TabItem
                key={type.id}
                value={type.id}
                count={type.totalItem?.[selectedPlan?.configChoice]?.[type.configChoice] || 0}
                icon={type.icon}
                iconPosition="start"
                label={type.displayName}
                wrapped
              />
            );
          })}
        </StyledTabs>
      )}

      <StyledCard>
        <TableInstance
          columns={columns}
          data={data || []}
          permission={{
            edit: checkPermission(permissionList, "edit milestone"),
            delete: checkPermission(permissionList, "delete milestone"),
          }}
          totalPage={1}
          currentPage={1}
          total={20}
        >
          <Toolbar title={activeTab?.displayName} subTitle="Reorder the list by dragging" enableSearch={false} />

          <Table handleSort={handleSort} loading={loading} draggableRow={true} handleRowDrag={moveRow} />
        </TableInstance>
      </StyledCard>

      {/* Floating Form */}
      <FloatingForm
        open={openForm}
        handleclose={() => setOpenForm(false)}
        title={formId ? "Edit milestone" : "Add new milestone"}
      >
        <MilestoneForm
          handleClose={(submit) => {
            resetAll();
            if (submit) {
              getData(activeTab.id, selectedPlan?.id);
            }
          }}
          activeTab={activeTab}
          activePlan={selectedPlan}
          editId={formId}
          multipleChoiceList={{ ...multipleChoiceList, milestoneType: mileStoneTabs }}
          totals={calculateTotals}
        />
      </FloatingForm>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setDeleteId(null);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected milestone?"
        loadingInfo="Milestone is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => {
          dispatch(resetMilestone());
          getData();
        }}
      />
    </>
  );
};

export default MilestoneList;
