import React from "react";
import { IconButton, MenuItem } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import DarkModeTwoToneIcon from "@mui/icons-material/DarkModeTwoTone";
import SettingsBrightnessTwoToneIcon from "@mui/icons-material/SettingsBrightnessTwoTone";
import { ColorModeContext } from "ColorModeContext";
import { PopoverDropdown } from "ui";
import { defaultThemeMode } from "configs/LayoutConfig";

const ThemeSwitcher = () => {
  const theme = useTheme();

  const { toggleColorMode } = React.useContext(ColorModeContext);

  const [icon, setIcon] = React.useState();

  const switchMode = (mode) => {
    toggleColorMode(mode);
    localStorage.setItem("theme", mode);

    setIcon(mode);
  };

  const selectedIcon = React.useCallback(
    (type) => {
      if (localStorage.getItem("theme") === "system") {
        return <SettingsBrightnessTwoToneIcon sx={{ fill: theme.palette.mode === "light" ? "#000" : "#fff" }} />;
      } else {
        if ((icon || type) === "dark") {
          return <DarkModeTwoToneIcon sx={{ fill: "#fff" }} />;
        } else if ((icon || type) === "light") {
          return <WbSunnyTwoToneIcon sx={{ fill: "#000" }} />;
        }
      }
    },
    [icon]
  );

  React.useEffect(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", defaultThemeMode);
    }
  }, []);

  return (
    <PopoverDropdown
      triggertype={IconButton}
      trigger={selectedIcon(theme.palette.mode || defaultThemeMode)}
      triggerprops={{ sx: { flexShrink: 0 } }}
    >
      <MenuItem onClick={() => switchMode("light")}>
        <WbSunnyTwoToneIcon sx={{ mr: 2 }} />
        Light
      </MenuItem>

      <MenuItem onClick={() => switchMode("dark")}>
        <DarkModeTwoToneIcon sx={{ mr: 2 }} />
        Dark
      </MenuItem>

      <MenuItem onClick={() => switchMode("system")}>
        <SettingsBrightnessTwoToneIcon sx={{ mr: 2 }} />
        System
      </MenuItem>
    </PopoverDropdown>
  );
};

export default React.memo(ThemeSwitcher);
