import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_MILESTONE,
  GET_MILESTONE_LIST,
  ADD_MILESTONE,
  GET_MILESTONE,
  EDIT_MILESTONE,
  SORT_MILESTONE,
  DELETE_MILESTONE,
} from "../actions";
import {
  getAllMilestoneSuccess,
  getAllMilestoneError,
  getMilestoneListSuccess,
  getMilestoneListError,
  addMilestoneSuccess,
  addMilestoneError,
  getMilestoneSuccess,
  getMilestoneError,
  editMilestoneSuccess,
  editMilestoneError,
  deleteMilestoneSuccess,
  deleteMilestoneError,
  sortMilestoneError,
  sortMilestoneSuccess,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import MilestoneService from "services/MilestoneService";
import TableDataService from "services/TableDataService";

export function* watchgetAllMilestone() {
  yield takeEvery(GET_ALL_MILESTONE, getAllMilestone);
}

const getAllMilestoneAsync = async (dbParam) => {
  return MilestoneService.getAllMilestone(dbParam);
};

function* getAllMilestone({ payload }) {
  try {
    const response = yield call(getAllMilestoneAsync, payload.dbParam);
    if (response.data) {
      yield put(getAllMilestoneSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetMilestoneList() {
  yield takeEvery(GET_MILESTONE_LIST, getMilestoneListAc);
}

const getMilestoneListAsync = async (dbParam) => {
  return TableDataService.getAllData("milestones", dbParam);
};

function* getMilestoneListAc({ payload }) {
  try {
    const response = yield call(getMilestoneListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getMilestoneListSuccess(response.data.data, response.data.meta));
    } else {
      toaster("", response.data.message);
      yield put(getMilestoneListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getMilestoneListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddMilestone() {
  yield takeEvery(ADD_MILESTONE, addMilestone);
}

const addMilestoneAsync = async (data) => {
  return MilestoneService.addMilestone(data);
};

function* addMilestone({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(addMilestoneAsync, payload.milestoneData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addMilestoneSuccess(response.data.success));
      navigate(`/configuration/milestones`);
    } else {
      toaster("", response.data.message);
      yield put(addMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetMilestone() {
  yield takeEvery(GET_MILESTONE, getMilestone);
}

const getMilestoneAsync = async (id) => {
  return MilestoneService.getMilestone(id);
};

function* getMilestone({ payload }) {
  try {
    const response = yield call(getMilestoneAsync, payload.milestoneId);
    if (response.data.success) {
      yield put(getMilestoneSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditMilestone() {
  yield takeEvery(EDIT_MILESTONE, editMilestone);
}

const editMilestoneAsync = async (data, id) => {
  return MilestoneService.editMilestone(data, id);
};

function* editMilestone({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(editMilestoneAsync, payload.milestoneData, payload.milestoneId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editMilestoneSuccess(response.data.success));
      navigate(`/configuration/milestones`);
    } else {
      toaster("", response.data.message);
      yield put(editMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchSortMilestone() {
  yield takeEvery(SORT_MILESTONE, sortMilestone);
}

const sortMilestoneAsync = async (data) => {
  return MilestoneService.sortMilestone(data);
};

function* sortMilestone({ payload }) {
  try {
    const response = yield call(sortMilestoneAsync, payload.sortMilestoneData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(sortMilestoneSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(sortMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(sortMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteMilestone() {
  yield takeEvery(DELETE_MILESTONE, deleteMilestone);
}

const deleteMilestoneAsync = async (id) => {
  return MilestoneService.deleteMilestone(id);
};

function* deleteMilestone({ payload }) {
  try {
    const response = yield call(deleteMilestoneAsync, payload.milestoneId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteMilestoneSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(deleteMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllMilestone),
    fork(watchGetMilestoneList),
    fork(watchAddMilestone),
    fork(watchGetMilestone),
    fork(watchEditMilestone),
    fork(watchSortMilestone),
    fork(watchDeleteMilestone),
  ]);
}
