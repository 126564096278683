export const GET_ALL_DOCUMENT = "GET_ALL_DOCUMENT";
export const GET_ALL_DOCUMENT_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENT_ERROR = "GET_ALL_DOCUMENT_ERROR";
export const GET_DOCUMENT_LIST = "GET_DOCUMENT_LIST";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_ERROR = "GET_DOCUMENT_LIST_ERROR";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";
export const EDIT_DOCUMENT = "EDIT_DOCUMENT";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const EDIT_DOCUMENT_ERROR = "EDIT_DOCUMENT_ERROR";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";
export const RESET_DOCUMENT = "RESET_DOCUMENT";

export const getAllDocument = () => ({
  type: GET_ALL_DOCUMENT,
  payload: {},
});

export const getAllDocumentSuccess = (documents) => ({
  type: GET_ALL_DOCUMENT_SUCCESS,
  payload: documents,
});

export const getAllDocumentError = (error) => ({
  type: GET_ALL_DOCUMENT_ERROR,
  payload: error,
});

export const getDocumentList = (dbParam) => ({
  type: GET_DOCUMENT_LIST,
  payload: { dbParam },
});

export const getDocumentListSuccess = (documentList) => ({
  type: GET_DOCUMENT_LIST_SUCCESS,
  payload: documentList,
});

export const getDocumentListError = (error) => ({
  type: GET_DOCUMENT_LIST_ERROR,
  payload: error,
});

export const addDocument = (documentData) => ({
  type: ADD_DOCUMENT,
  payload: { documentData },
});

export const addDocumentSuccess = (success) => ({
  type: ADD_DOCUMENT_SUCCESS,
  payload: success,
});

export const addDocumentError = (error) => ({
  type: ADD_DOCUMENT_ERROR,
  payload: error,
});

export const getDocument = (documentId) => ({
  type: GET_DOCUMENT,
  payload: { documentId },
});

export const getDocumentSuccess = (documentData) => ({
  type: GET_DOCUMENT_SUCCESS,
  payload: documentData,
});

export const getDocumentError = (error) => ({
  type: GET_DOCUMENT_ERROR,
  payload: error,
});

export const editDocument = (documentId, documentData) => ({
  type: EDIT_DOCUMENT,
  payload: { documentId, documentData },
});

export const editDocumentSuccess = (success) => ({
  type: EDIT_DOCUMENT_SUCCESS,
  payload: success,
});

export const editDocumentError = (error) => ({
  type: EDIT_DOCUMENT_ERROR,
  payload: error,
});

export const deleteDocument = (documentId) => ({
  type: DELETE_DOCUMENT,
  payload: { documentId },
});

export const deleteDocumentSuccess = (success) => ({
  type: DELETE_DOCUMENT_SUCCESS,
  payload: success,
});

export const deleteDocumentError = (error) => ({
  type: DELETE_DOCUMENT_ERROR,
  payload: error,
});

export const resetDocument = () => ({
  type: RESET_DOCUMENT,
  payload: {},
});
