import Req from "interceptors/TokenInterceptor";

const UserHomeService = (function () {
  function _getUserHome(id) {
    return Req.get(`/api/user-homes/${id}`);
  }
  function _addUserHome(data) {
    return Req.post("/api/user-homes", data);
  }
  function _editUserHome(data, id) {
    return Req.put(`/api/user-homes/${id}`, data);
  }
  function _deleteUserHome(id) {
    return Req.delete(`/api/user-homes/${id}`);
  }
  return {
    getUserHome: _getUserHome,
    addUserHome: _addUserHome,
    editUserHome: _editUserHome,
    deleteUserHome: _deleteUserHome,
  };
})();
export default UserHomeService;
