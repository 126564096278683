import React from "react";
import * as Icons from "@mui/icons-material";

const useAllIcons = () => {
  const iconList = React.useMemo(() => {
    const icons = [];

    for (const [iconComponentName, IconComponent] of Object.entries(Icons)) {
      icons.push({ iconName: iconComponentName, iconComp: IconComponent });
    }

    return icons;
  }, []);

  return iconList;
};

export default useAllIcons;
