import React, { useState, useEffect } from "react";
import { Box, Drawer, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { appBarConfig } from "configs/LayoutConfig";
import { StyledButton } from "ui";
import graphics from "assets/images/home-placeholder.jpg";

const SelectionDrawer = styled(Drawer)(({ theme, open }) => ({
  minWidth: 320,
  width: useMediaQuery(theme.breakpoints.up("lg")) ? "24.306vw" : "auto",
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    maxHeight: useMediaQuery(theme.breakpoints.up("lg")) ? window.innerHeight * 0.85 : "inherit",
    minWidth: 240,
    width: useMediaQuery(theme.breakpoints.up("lg")) ? "24.306vw" : "auto",
    position: useMediaQuery(theme.breakpoints.up("lg")) ? "sticky" : "fixed",
    top: useMediaQuery(theme.breakpoints.up("lg")) ? appBarConfig.height + 24 : "auto",
    padding: 15,
    border: "none",
    borderRadius: "16px",
    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
    backgroundColor: theme.palette.layout.main,
    boxSizing: "border-box",
    zIndex: useMediaQuery(theme.breakpoints.up("lg")) ? 1 : "",
  },
}));

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  "& .MuiTreeItem-content": {
    position: "relative",
    flexDirection: "row-reverse",
    padding: "4px 3px",
    color: theme.palette.grey.dark,

    "& .comp-img": { display: "none" },
  },

  "& .MuiTreeItem-label": { paddingLeft: 0 },

  "& .MuiTreeItem-group": {
    marginLeft: 60,
    marginTop: 3,
    borderLeft: `1px dashed ${theme.palette.grey.main}`,

    "& .MuiTreeItem-content": {
      padding: "5px 3px 5px 10px",

      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,

        "&:before": {
          width: 6,
          height: 6,
          content: '""',
          position: "absolute",
          top: "50%",
          left: -3,
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
          transform: "translateY(-50%)",
        },
      },
    },
    "& .MuiTreeItem-group": { marginLeft: 9 },
  },
}));

const Tree = (props) => {
  const { productlist, defaultExpanded, selectedProductId, setSelectedProductId } = props;

  const theme = useTheme();

  // const [drawerState, setDrawerState] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState(null);
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);

  const setChildItems = (item) => {
    return (
      <StyledTreeItem
        key={item.id}
        nodeId={item.id}
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {/* Product Image */}
            <Box
              component="figure"
              position="relative"
              m="0"
              sx={{ width: "45px", height: "45px" }}
              className="comp-img rounded overflow-hidden"
            >
              <img src={item?.media?.[0]?.url || graphics} alt="Home" className="w-full aspect-square rounded" />
            </Box>
            {item.name}
          </Box>
        }
      >
        {item?.childrenProduct?.length > 0 &&
        item?.childrenProduct?.filter((v) => v?.childrenProduct?.length > 0)?.length > 0 // Remove last level childrenProduct
          ? item.childrenProduct.map((subChild) => setChildItems(subChild))
          : null}
      </StyledTreeItem>
    );
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
    // API fetch only if parent of last level childrenProduct
    getChildNode(nodeIds, productlist);
  };

  const getChildNode = (id, allItem) => {
    for (let i = 0; i < allItem?.length; i++) {
      const item = allItem[i];
      // Check if parent of last level childrenProduct
      if (item?.childrenProduct?.filter((v) => v?.childrenProduct?.length > 0)?.length > 0) {
        getChildNode(id, item.childrenProduct);
      } else {
        // Parent of last level childrenProduct
        if (item.id === id) {
          setSelectedProductId(item.id);
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (defaultExpanded?.length && !expanded?.length) setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  useEffect(() => {
    if (selectedProductId && !selected) setSelected(selectedProductId);
  }, [selectedProductId]);

  return (
    <>
      {useMediaQuery(theme.breakpoints.up("lg")) && (
        <SelectionDrawer classes={{ paper: "custom-scrollbar" }} variant="permanent" anchor="right">
          <Typography component="h3" sx={{ mb: 2.5, fontSize: "18px", fontWeight: 600 }}>
            Component selection
            <Typography component="small" sx={{ display: "block", color: theme.palette.grey.dark, fontSize: "12px" }}>
              Select a desired module to customize with
            </Typography>
          </Typography>

          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",

              "& >.MuiTreeItem-root": {
                border: "1px solid transparent",
                borderRadius: "6px",
                backgroundColor: theme.palette.grey.culturedLight,
                overflow: "hidden",

                "& >.MuiTreeItem-content": {
                  // show image for only root parent
                  "& .comp-img": { display: "block" },

                  "&.Mui-selected,&.Mui-expanded": {
                    backgroundColor: theme.palette.grey.brightGray,
                    color: theme.palette.common.dark,
                  },
                },

                "&[aria-expanded='true']": { borderColor: theme.palette.primary.main },
              },
            }}
          >
            {productlist?.map((parent) => {
              return setChildItems(parent);
            })}
          </TreeView>
        </SelectionDrawer>
      )}

      {/* Mobile Drawer */}
      {useMediaQuery(theme.breakpoints.down("lg")) && (
        <>
          <SelectionDrawer
            classes={{ paper: "custom-scrollbar" }}
            variant="temporary"
            open={openMobileDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor="right"
            onClose={() => setOpenMobileDrawer(false)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: 0,
                py: 2.5,
                mt: "-15px",
                zIndex: 2,
              }}
              className="bg-white/75 backdrop-blur-sm"
            >
              <Typography component="h3" sx={{ fontSize: "18px", fontWeight: 600 }}>
                Component selection
                <Typography
                  component="small"
                  sx={{ display: "block", color: theme.palette.grey.dark, fontSize: "12px" }}
                >
                  Select a desired module to customize with
                </Typography>
              </Typography>

              <IconButton size="small" onClick={() => setOpenMobileDrawer(false)}>
                <HighlightOffIcon />
              </IconButton>
            </Box>

            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",

                "& >.MuiTreeItem-root": {
                  border: "1px solid transparent",
                  borderRadius: "6px",
                  backgroundColor: theme.palette.grey.culturedLight,
                  overflow: "hidden",

                  "& >.MuiTreeItem-content": {
                    // show image for only root parent
                    "& .comp-img": { display: "block" },

                    "&.Mui-selected,&.Mui-expanded": {
                      backgroundColor: theme.palette.grey.brightGray,
                      color: theme.palette.common.dark,
                    },
                  },

                  "&[aria-expanded='true']": { borderColor: theme.palette.primary.main },
                },
              }}
            >
              {productlist?.map((parent) => {
                return setChildItems(parent);
              })}
            </TreeView>
          </SelectionDrawer>

          <StyledButton
            color={theme.palette.common.dark}
            sx={{ position: "sticky", top: appBarConfig.height + 10, mb: 2.5, borderRadius: 4, zIndex: 1 }}
            onClick={() => setOpenMobileDrawer(true)}
          >
            Component selection
            <ExpandMoreIcon />
          </StyledButton>
        </>
      )}
    </>
  );
};

export default Tree;
