import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const UserHomeMilestoneService = (function () {
  function _getAllUserHomeMilestone(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/user-home-milestones/all?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _getUserHomeMilestoneList(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/user-home-milestones?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _editUserHomeMilestone(data, id) {
    return Req.put(`/api/user-home-milestones/${id}`, data);
  }
  function _deleteUserHomeMilestone(id) {
    return Req.delete(`/api/user-home-milestones/${id}`);
  }
  return {
    getAllUserHomeMilestone: _getAllUserHomeMilestone,
    getUserHomeMilestoneList: _getUserHomeMilestoneList,
    editUserHomeMilestone: _editUserHomeMilestone,
    deleteUserHomeMilestone: _deleteUserHomeMilestone,
  };
})();
export default UserHomeMilestoneService;
