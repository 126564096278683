import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography, alpha, useTheme, useMediaQuery } from "@mui/material";
import { getAllUser, getSingleConfigChoice, getMessageList, getLayoutState, getAllGroupMessage } from "reduxs/actions";
import { FloatingForm } from "ui";
import useGetCurrentUser from "hooks/useGetCurrentUser";
import { MessageProvider } from "./shared/MessageContext";
import ComposeNewMessage from "./shared/ComposeNewMessage";
import MobileNavToolbar from "./shared/MobileNavToolbar";
import MessageNav from "./shared/MessageNav";
import MessageUserList from "./shared/MessageUserList";
import MessagePreview from "./shared/MessagePreview";

const Messsage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useGetCurrentUser();

  const [openComposeForm, setOpenComposeForm] = useState(false);
  const [messageId, setMessageId] = useState(null);

  useEffect(() => {
    if (currentUser && currentUser?.id) {
      // ## Load inbox messages default
      dispatch(
        getMessageList({
          // messageTo: currentUser.id, // Get's Inbox Messages
          messageFrom: currentUser.id,
          messageTo: currentUser.id,
        })
      );

      dispatch(getAllUser({ role: "client", action: currentUser?.role === "client" ? "exclude" : "include" })); // Exclude client list for client roles

      dispatch(getAllGroupMessage()); // Get all message groups
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(getSingleConfigChoice("messageStatus"));
  }, []);

  useEffect(() => {
    // Modify Layout for better UX
    dispatch(getLayoutState(true));
    return () => {
      dispatch(getLayoutState(false));
    };
  }, []);

  return (
    <>
      <Typography
        component="h4"
        variant="h4"
        mb={{ xs: 2, xxl: 4 }}
        mt={{ xs: -2, xxl: 0 }}
        fontSize="1.5rem"
        fontWeight="700"
        lineHeight="1.5"
      >
        Conversation
      </Typography>

      <MessageProvider currentMenu="all" userInfo={currentUser || JSON.parse(localStorage.getItem("currentUser"))}>
        <Box
          sx={{
            height: { lg: "76vh" },
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "8px",
            flex: 1,
            padding: { xs: "10px", lg: "8px 8px 8px 16px" },
            borderRadius: "12px",
            backgroundColor:
              theme.palette.mode === "light"
                ? theme.palette.grey.aAzureishWhite
                : alpha(theme.palette.grey.aAzureishWhite, 0.11),
          }}
        >
          {useMediaQuery(theme.breakpoints.down(1024)) && <MobileNavToolbar />}

          <MessageNav onCompose={() => setOpenComposeForm(true)} />

          <MessageUserList
            onCompose={(id) => {
              setMessageId(id);
              setOpenComposeForm(true);
            }}
          />

          <MessagePreview />

          <FloatingForm
            open={openComposeForm}
            handleclose={() => {
              setOpenComposeForm(false);
              setMessageId(null);
            }}
            title={messageId ? "Update message" : "New message"}
            wrapperProps={{ sx: { "& .MuiPaper-root": { padding: "0 0 16px", borderRadius: { xs: 0, sm: "1rem" } } } }}
            headerProps={{ sx: { padding: "19px 16px", backgroundColor: theme.palette.blue.shadowBlue } }}
            contentProps={{ sx: { p: 0, display: "flex", flexDirection: "column" } }}
            canexpand={true}
          >
            <ComposeNewMessage
              messageId={messageId}
              onsuccess={() => {
                setOpenComposeForm(false);
                setMessageId(null);
              }}
            />
          </FloatingForm>
        </Box>
      </MessageProvider>
    </>
  );
};

export default Messsage;
