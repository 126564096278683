import React, { useEffect, useMemo, useRef, useState } from "react";
import update from "immutability-helper";
import { Box, Grid, Typography, styled, useTheme } from "@mui/material";
import RoofingIcon from "@mui/icons-material/Roofing";
import HouseIcon from "@mui/icons-material/House";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addUserHome, editUserHome, getAllMilestone, getUserHome, resetMilestone, resetUserHome } from "reduxs/actions";
import { capitalizeFirstLetter } from "helpers";
import { SelectField, StyledButton, StyledSpinner, StyledTabs, Table, TableInstance } from "ui";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 600,
}));

// Initialize need be declared outside to
// handle formik rerender issue
const initialValues = {
  userId: "",
  homeId: null,
  plan: null,
  milestoneIds: [],
};

const AssignHomeForm = (props) => {
  const { userid, homeid, handleformaction } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const formikRef = useRef();

  const { multipleChoiceList } = useSelector((state) => state.shared);
  const { homes } = useSelector((state) => state.home);
  const { userHomeData, loading, assignFormLoading } = useSelector((state) => state.userHome);
  const { milestones, loading: tblLoading } = useSelector((state) => state.milestone);

  const [activeTab, setActiveTab] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedMilestone, setSelectedMilestone] = useState([]);
  const [rowSelection, setRowSelection] = useState({ flat: [], selected: [] });
  const [hasSelectedMilesontes, setHasSelectedMilesontes] = useState(true);

  const initilizeTable = useMemo(() => {
    return !!(!tblLoading && milestones?.length && activeTab);
  }, [tblLoading, milestones, activeTab, setActiveTab]);

  const allIds = useMemo(() => {
    // Filter all the selected milestones id to
    // update form value milestoneIds
    let ids = [];
    if (selectedMilestone) {
      ids = [...selectedMilestone];

      if (rowSelection?.flat.length > 0 && rowSelection?.selected.length > 0) {
        rowSelection?.flat.forEach(({ id, isSelected }) => {
          if (isSelected && !ids.includes(id)) {
            ids = [...ids, id];
          } else if (!isSelected) {
            ids = ids.filter((item) => item !== id);
          }
        });
      }

      setSelectedMilestone((prev) => {
        if (prev?.length !== ids?.length) {
          return ids;
        }
        return prev;
      });
    }

    return ids;
  }, [rowSelection, selectedMilestone, activeTab, setActiveTab, selectedPlan]);

  const schema = Yup.object().shape({
    userId: Yup.string().required("User is required."),
    homeId: Yup.string().required("House is required."),
    plan: Yup.string().required("House plan is required"),
    milestoneIds: Yup.array().required("Milestone is required."),
  });

  const fetchMilestone = (plan) => {
    if (plan !== selectedPlan) {
      dispatch(getAllMilestone({ plan: plan }));
    }
  };

  const handlePreselectedRow = () => {
    if (milestones || userHomeData?.userHomeMilestones?.length) {
      const preSelectedList = (userHomeData?.plan === selectedPlan && userHomeData?.userHomeMilestones) || milestones;
      const ids = update(preSelectedList, { $apply: (arr) => arr?.map((obj) => obj?.milestoneId || obj.id) });

      setSelectedMilestone(ids);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(multipleChoiceList?.milestoneType.find((item) => item.id === newValue));
    setSelectedMilestone(allIds); // This need be done because table is not updating itself
  };

  const handleSelectRow = (flat, selected) => {
    // To watch un/check each row
    // and it's value should be stored seprately because this renders continuously
    setRowSelection((prevSelectedRows) => {
      if (prevSelectedRows?.selected?.length !== selected?.length) {
        return { flat, selected };
      } else {
        return prevSelectedRows;
      }
    });
  };

  const onSubmit = (values) => {
    if (rowSelection?.selected?.length === 0) {
      setHasSelectedMilesontes(false);
      return;
    } else {
      setHasSelectedMilesontes(true);
    }

    const updatedValues = {
      ...values,
      milestoneIds: allIds,
    };

    if (!assignFormLoading) {
      if (homeid) {
        dispatch(editUserHome(homeid, updatedValues));
      } else {
        dispatch(addUserHome(updatedValues));
      }

      handleformaction(true);
    }
  };

  const generateTypeIcons = (typeName) => {
    if (typeName === "pre-construction") {
      return <RoofingIcon />;
    } else if (typeName === "post-construction") {
      return <HouseIcon />;
    }
  };

  const mileStoneTabs = useMemo(() => {
    let types = [];

    if (multipleChoiceList?.milestoneType?.length) {
      if (!activeTab) {
        setActiveTab(multipleChoiceList?.milestoneType?.[0]); // Set defulat active tab
      }

      types = multipleChoiceList?.milestoneType?.map((type) => ({
        ...type,
        icon: generateTypeIcons(type?.configChoice),
      }));
    }

    return types;
  }, [multipleChoiceList]);

  const activeData = useMemo(() => {
    return milestones?.filter((val) => Number(val.type) === Number(activeTab?.id)) || [];
  }, [milestones, activeTab]);

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Order",
        accessor: "order",
        id: "order",
      },
      {
        Header: "Name",
        accessor: (value) => capitalizeFirstLetter(value.name),
      },
      {
        Header: "Type",
        accessor: "typeConfigChoice",
        id: "type",
        Cell: (props) => {
          return props.value.displayName;
        },
      },
    ],
    [activeData, rowSelection, activeTab, selectedMilestone]
  );

  useEffect(() => {
    // Reset selected milestone values
    // before loading new data
    setSelectedMilestone([]);
    setRowSelection({ flat: [], selected: [] });

    if (homeid) {
      dispatch(getUserHome(homeid));
    } else {
      dispatch(resetUserHome());
    }
  }, [homeid]);

  useEffect(() => {
    // Get milestone as plan is changed
    const { values } = formikRef?.current;
    if ((formikRef?.current && values?.plan) || multipleChoiceList?.homePlan) {
      const plan = values?.plan || multipleChoiceList?.homePlan?.[0]?.id;

      setSelectedPlan(plan);
      fetchMilestone(plan);
    }
  }, [formikRef, multipleChoiceList]);

  /* #### This useEffect adds the milestone id for preselect either it from userData or milestones ### */
  useEffect(() => handlePreselectedRow(), [milestones, userHomeData]);

  useEffect(() => {
    return () => {
      setSelectedPlan(null);
      setSelectedMilestone([]);
      setRowSelection({ flat: [], selected: [] });
      dispatch(resetUserHome());
      dispatch(resetMilestone());
    };
  }, []);

  useEffect(() => {
    initialValues.userId = userid;
    initialValues.homeId = userHomeData?.home?.id || null;
    initialValues.plan = userHomeData?.plan || multipleChoiceList?.homePlan?.[0]?.id || null;
    initialValues.milestoneIds = selectedMilestone;
  }, [homeid, multipleChoiceList, selectedMilestone, userHomeData]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ values, resetForm, errors, setFieldValue }) => (
        <Box component={Form} sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexFlow: { xs: "column", lg: "row" },
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Title>{userHomeData?.home?.name || "New home"} Preview</Title>

            {loading && <StyledSpinner size={20} />}
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SelectField
                name="plan"
                label="Plan"
                placeholder="Select Plan"
                disableClearable={true}
                handleChange={(e, selected) => {
                  setSelectedPlan(selected?.id);
                  fetchMilestone(selected?.id);
                }}
                options={
                  multipleChoiceList?.homePlan &&
                  multipleChoiceList?.homePlan.map((item) => {
                    return { id: item.id, name: item.displayName };
                  })
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SelectField
                name="homeId"
                label="House"
                placeholder="Select House"
                disableClearable={true}
                disabled={!!homeid}
                options={
                  homes &&
                  homes.map((item) => {
                    return { id: item.id, name: item.name };
                  })
                }
              />
            </Grid>
          </Grid>

          <Box sx={{ flex: 1, my: 3.75 }}>
            <Title sx={{ marginBottom: 2 }}>Choose Milestone</Title>

            {mileStoneTabs && (
              <StyledTabs value={activeTab?.id} onChange={handleTabChange} sx={{ flex: 1, mb: 3 }}>
                {mileStoneTabs?.map((type) => {
                  return (
                    <StyledTabs.TabItem
                      key={type.id}
                      value={type.id}
                      icon={type.icon}
                      iconPosition="start"
                      label={type.displayName}
                      wrapped
                    />
                  );
                })}
              </StyledTabs>
            )}

            {!loading && (
              <TableInstance
                columns={columns}
                selectionColumn={true}
                data={activeData}
                totalPage={1}
                currentPage={1}
                total={20}
              >
                <Table
                  isinitialized={initilizeTable}
                  loading={tblLoading}
                  draggableRow={false}
                  preselectedrow={selectedMilestone}
                  handleRowSelect={(flat, selected) => handleSelectRow(flat, selected)}
                />
              </TableInstance>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { sm: "flex-end" },
              alignItems: "center",
              gap: 2,
              position: "sticky",
              bottom: 0,
              mt: "auto",
              py: 4,
            }}
            className="backdrop-blur-sm"
          >
            {!hasSelectedMilesontes && (
              <Typography component="p" sx={{ color: theme.palette.error.main }}>
                Atleast one milestone should be selected.
              </Typography>
            )}

            <StyledButton type="submit" isloading={assignFormLoading}>
              Assign Home
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              onClick={() => handleformaction(false)}
            >
              Cancel
            </StyledButton>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

export default AssignHomeForm;
