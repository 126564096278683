export const GET_ALL_MILESTONE = "GET_ALL_MILESTONE";
export const GET_ALL_MILESTONE_SUCCESS = "GET_ALL_MILESTONE_SUCCESS";
export const GET_ALL_MILESTONE_ERROR = "GET_ALL_MILESTONE_ERROR";
export const GET_MILESTONE_LIST = "GET_MILESTONE_LIST";
export const GET_MILESTONE_LIST_SUCCESS = "GET_MILESTONE_LIST_SUCCESS";
export const GET_MILESTONE_LIST_ERROR = "GET_MILESTONE_LIST_ERROR";
export const ADD_MILESTONE = "ADD_MILESTONE";
export const ADD_MILESTONE_SUCCESS = "ADD_MILESTONE_SUCCESS";
export const ADD_MILESTONE_ERROR = "ADD_MILESTONE_ERROR";
export const GET_MILESTONE = "GET_MILESTONE";
export const GET_MILESTONE_SUCCESS = "GET_MILESTONE_SUCCESS";
export const GET_MILESTONE_ERROR = "GET_MILESTONE_ERROR";
export const EDIT_MILESTONE = "EDIT_MILESTONE";
export const EDIT_MILESTONE_SUCCESS = "EDIT_MILESTONE_SUCCESS";
export const EDIT_MILESTONE_ERROR = "EDIT_MILESTONE_ERROR";
export const SORT_MILESTONE = "SORT_MILESTONE";
export const SORT_MILESTONE_SUCCESS = "SORT_MILESTONE_SUCCESS";
export const SORT_MILESTONE_ERROR = "SORT_MILESTONE_ERROR";
export const DELETE_MILESTONE = "DELETE_MILESTONE";
export const DELETE_MILESTONE_SUCCESS = "DELETE_MILESTONE_SUCCESS";
export const DELETE_MILESTONE_ERROR = "DELETE_MILESTONE_ERROR";
export const RESET_MILESTONE = "RESET_MILESTONE";

export const getAllMilestone = (dbParam) => ({
  type: GET_ALL_MILESTONE,
  payload: { dbParam },
});

export const getAllMilestoneSuccess = (milestones) => ({
  type: GET_ALL_MILESTONE_SUCCESS,
  payload: milestones,
});

export const getAllMilestoneError = (error) => ({
  type: GET_ALL_MILESTONE_ERROR,
  payload: error,
});

export const getMilestoneList = (dbParam) => ({
  type: GET_MILESTONE_LIST,
  payload: { dbParam },
});

export const getMilestoneListSuccess = (milestoneList) => ({
  type: GET_MILESTONE_LIST_SUCCESS,
  payload: milestoneList,
});

export const getMilestoneListError = (error) => ({
  type: GET_MILESTONE_LIST_ERROR,
  payload: error,
});

export const addMilestone = (milestoneData, navigate) => ({
  type: ADD_MILESTONE,
  payload: { milestoneData, navigate },
});

export const addMilestoneSuccess = (success) => ({
  type: ADD_MILESTONE_SUCCESS,
  payload: success,
});

export const addMilestoneError = (error) => ({
  type: ADD_MILESTONE_ERROR,
  payload: error,
});

export const getMilestone = (milestoneId) => ({
  type: GET_MILESTONE,
  payload: { milestoneId },
});

export const getMilestoneSuccess = (milestoneData) => ({
  type: GET_MILESTONE_SUCCESS,
  payload: milestoneData,
});

export const getMilestoneError = (error) => ({
  type: GET_MILESTONE_ERROR,
  payload: error,
});

export const editMilestone = (milestoneId, milestoneData, navigate) => ({
  type: EDIT_MILESTONE,
  payload: { milestoneId, milestoneData, navigate },
});

export const editMilestoneSuccess = (success) => ({
  type: EDIT_MILESTONE_SUCCESS,
  payload: success,
});

export const editMilestoneError = (error) => ({
  type: EDIT_MILESTONE_ERROR,
  payload: error,
});

export const sortMilestone = (sortMilestoneData) => ({
  type: SORT_MILESTONE,
  payload: { sortMilestoneData },
});

export const sortMilestoneSuccess = (success) => ({
  type: SORT_MILESTONE_SUCCESS,
  payload: success,
});

export const sortMilestoneError = (error) => ({
  type: SORT_MILESTONE_ERROR,
  payload: error,
});

export const deleteMilestone = (milestoneId) => ({
  type: DELETE_MILESTONE,
  payload: { milestoneId },
});

export const deleteMilestoneSuccess = (success) => ({
  type: DELETE_MILESTONE_SUCCESS,
  payload: success,
});

export const deleteMilestoneError = (error) => ({
  type: DELETE_MILESTONE_ERROR,
  payload: error,
});

export const resetMilestone = () => ({
  type: RESET_MILESTONE,
  payload: {},
});
