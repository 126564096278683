import React from "react";
import { useParams } from "react-router-dom";
import { SelectionOverview } from "shared/modules";
import { BreadcrumbContainer } from "ui";

const customizations = [
  {
    id: "d5f4s6d7rw843dsf3d5f498w4s",
    name: "Brickwork",
    childrenProduct: [
      {
        name: "Face Brickwork",
        category: { name: "Access - Ash", manufacturer: "Austral Bricks" },
        total: "Inclusion",
      },
      {
        name: "Feature Brick Mortar Colour",
        category: { name: "Access - Nouveau Grey", manufacturer: "Dulux" },
        total: "$85.30",
      },
    ],
  },
  {
    id: "d5f4s6d7rw843d64645f498w4s",
    name: "External Features",
    childrenProduct: [
      {
        name: "Facade Main Render",
        category: { name: "Grey Pail", manufacturer: "Dulux" },
        total: "Inclusion",
      },
      {
        name: "Cladding Colour",
        category: { name: "Grey Pebble", manufacturer: "Dulux" },
        total: "Inclusion",
      },
    ],
  },
];

const Summary = () => {
  const params = useParams();

  const homeId = React.useMemo(() => params.id, []);

  return (
    <>
      <BreadcrumbContainer
        title="View home selection"
        paths={[
          {
            title: "Home",
            page: `/configuration/home`,
          },
          {
            title: "Summary",
          },
        ]}
      />

      <SelectionOverview homeId={homeId} selectionList={customizations} />
    </>
  );
};

export default Summary;
