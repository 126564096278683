import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reduxs/actions";
import { checkPermission } from "helpers";
import { Document } from "shared/modules";
import { BreadcrumbContainer, StyledButton } from "ui";

const HomeDoc = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.user);
  const { permissionList } = useSelector((state) => state.shared);

  const [openForm, setOpenForm] = useState();

  React.useEffect(() => {
    if (params.id) dispatch(getUser(params.id));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Document List"
          paths={[
            {
              title: "My Home",
              page: `/my-home`,
            },
            {
              title: "Documents",
            },
          ]}
        />

        {!loading && checkPermission(permissionList, "add document") && (
          <StyledButton type="button" onClick={() => setOpenForm(true)}>
            <DriveFolderUploadIcon />
            Add Document
          </StyledButton>
        )}
      </Box>

      <Document addnew={openForm} handleFormClose={(state) => setOpenForm(state)} />
    </>
  );
};

export default HomeDoc;
