import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
// import SummarizeIcon from "@mui/icons-material/Summarize";
// import ColorLensIcon from "@mui/icons-material/ColorLens";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser, deleteUserHome, resetUser } from "reduxs/actions";
import { BreadcrumbContainer, StyledButton, StyledSpinner, StyledCard, Empty, Action, AlertDialog } from "ui";
import { checkPermission } from "helpers";
import graphics from "assets/images/home-placeholder.jpg";
import UserDetailCard from "./shared/UserDetailCard";
import AssignHome from "./shared/AssignHome";

const ViewCustomer = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { fetchLoading, userData } = useSelector((state) => state.user);
  const { delLoading, delSuccess } = useSelector((state) => state.userHome);
  const { permissionList } = useSelector((state) => state.shared);

  const [homeId, setHomeId] = useState(null);
  const [openAssigningForm, setOpenAssigningForm] = useState(false);

  const handleViewClick = (e, id) => {
    e.preventDefault();
    navigate(`/configuration/home/view/${id}`);
  };

  // const handleCustomizeClick = (e, userId, userHomeId) => {
  //   e.preventDefault();
  //   navigate(`/user-management/customer/view/${userId}/home/${userHomeId}`);
  // };

  // const handleSelectionClick = (e, userId, userHomeId) => {
  //   e.preventDefault();
  //   navigate(`/user-management/customer/view/${userId}/home/summary/${userHomeId}`);
  // };

  const handleDocumentClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/user-management/customer/view/${userId}/home/document/${userHomeId}`);
  };

  const handleBuildingProgressClick = (e, userId, userHomeId) => {
    e.preventDefault();
    navigate(`/user-management/customer/view/${userId}/home/building-progress/${userHomeId}`);
  };

  const handleAssignHome = (e, userHomeId) => {
    e.preventDefault();
    setOpenAssigningForm(true);
    setHomeId(userHomeId);
  };

  const handleOnDelete = () => {
    if (!delLoading && deleteId) dispatch(deleteUserHome(params.id, deleteId));
  };

  useEffect(() => {
    if (params.id) dispatch(getUser(params.id));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title={"View customer detail"}
          paths={[
            {
              title: "Customer",
              page: `/user-management/customer`,
            },
            {
              title: "View",
            },
          ]}
        />

        {checkPermission(permissionList, "add user home") && (
          <StyledButton onClick={(e) => handleAssignHome(e, null)}>Assign New Home</StyledButton>
        )}
      </Box>

      <UserDetailCard data={userData} />

      {userData?.userHomes?.length > 0 ? (
        <>
          <Typography component="h3" fontSize={18} fontWeight={600} mt={5.63} mb={2.5}>
            List of available homes ready for customization.
          </Typography>

          <Grid container spacing={3}>
            {userData?.userHomes?.map((item, index) => {
              return (
                <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                  <StyledCard className="h-full">
                    <Box
                      component="figure"
                      sx={{ width: "100%", m: "0", p: "8px", cursor: "pointer" }}
                      onClick={(e) => handleViewClick(e, item.home.id)}
                    >
                      <img
                        src={item?.home?.media?.[0]?.url || graphics}
                        alt="Home"
                        className="w-full aspect-[4/3] rounded-xl"
                      />
                    </Box>

                    <Box sx={{ px: 3, pb: 3 }}>
                      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Typography component="h3" variant="body1" fontWeight="600" className="self-center">
                          {item?.home?.name || ""} ({item?.guid || ""})
                        </Typography>
                        <Action
                          handleOnDelete={() => {
                            setDeleteId(item.id);
                            setOpenDeleteAlert(true);
                          }}
                          handleOnView={(e) => handleViewClick(e, item.home.id)}
                          permission={{
                            delete: checkPermission(permissionList, "delete user home"),
                            view: checkPermission(permissionList, "view home"),
                          }}
                        >
                          {checkPermission(permissionList, "edit user home") && (
                            <Action.ActionItem onClick={(e) => handleAssignHome(e, item.id)}>
                              <HolidayVillageIcon />
                              Edit home
                            </Action.ActionItem>
                          )}

                          {/* {checkPermission(permissionList, "list selection") && (
                            <Action.ActionItem onClick={(e) => handleSelectionClick(e, userData.id, item.home.id)}>
                              <SummarizeIcon />
                              Selection
                            </Action.ActionItem>
                          )}

                          {checkPermission(permissionList, "list customization") && (
                            <Action.ActionItem onClick={(e) => handleCustomizeClick(e, userData.id, item.id)}>
                              <ColorLensIcon />
                              Customize
                            </Action.ActionItem>
                          )} */}

                          {checkPermission(permissionList, "list document") && (
                            <Action.ActionItem onClick={(e) => handleDocumentClick(e, userData.id, item.id)}>
                              <FileCopyOutlinedIcon />
                              Documents
                            </Action.ActionItem>
                          )}

                          {checkPermission(permissionList, "list progress") && (
                            <Action.ActionItem onClick={(e) => handleBuildingProgressClick(e, userData.id, item.id)}>
                              <DataUsageRoundedIcon />
                              Building Progress
                            </Action.ActionItem>
                          )}
                        </Action>
                      </Box>
                    </Box>
                  </StyledCard>
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <Empty title="There is no home available!" subcontent={`Start by selecting home for ${userData?.name}.`}>
          <StyledButton onClick={(e) => handleAssignHome(e, null)}>Assign Home</StyledButton>
        </Empty>
      )}

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setDeleteId(null);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete assigned home?"
        loadingInfo="Home is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={delSuccess}
        reset={() => dispatch(resetUser())}
      />

      {fetchLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}

      {params.id && (
        <AssignHome
          open={openAssigningForm}
          userid={params.id}
          homeid={homeId}
          handleclosedialog={() => {
            setHomeId(null);
            setOpenAssigningForm(false);
          }}
        />
      )}
    </>
  );
};

export default ViewCustomer;
