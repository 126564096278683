import React from "react";
import { alpha, Button, CircularProgress, lighten, styled } from "@mui/material";

const ButtonRoot = styled(Button, { shouldForwardProp: (prop) => prop !== "color" })(({ theme, color, variant }) => ({
  minHeight: "2.8125rem",
  gap: 8,
  padding: ".375rem 1.125rem",
  border: `1px solid ${color || theme.palette.primary.main}`,
  borderRadius: 8,
  boxShadow: `${variant === "outlined" ? "0px 3px 10px 0px" : "0px 8px 16px 0px"} ${alpha(
    color ? color : theme.palette.primary.main,
    0.24
  )}`,
  backgroundColor: variant === "outlined" ? "inherit" : color || theme.palette.primary.main,
  color: variant === "outlined" ? color || theme.palette.primary.main : lighten(color || theme.palette.primary.main, 1),
  fontSize: "0.875rem",
  fontWeight: "500",
  lineHeight: "1",
  textTransform: "none",

  "&:hover": {
    borderColor: `${color || theme.palette.primary.main}`,
    boxShadow: "none",
    backgroundColor: alpha(color ? color : theme.palette.primary.main, 0.16),
    color: color || theme.palette.primary.main,
  },

  "&.Mui-disabled": {
    opacity: "0.65",
    color:
      variant === "outlined" ? color || theme.palette.primary.main : lighten(color || theme.palette.primary.main, 1),
    boxShadow: "none",
  },

  "& .MuiCircularProgress-root": {
    color:
      variant === "outlined" ? color || theme.palette.primary.main : lighten(color || theme.palette.primary.main, 1),
  },
}));

const StyledButton = (props) => {
  const { isloading, children, ...others } = props;

  return (
    <ButtonRoot {...others} disabled={props?.disabled || isloading}>
      {children} {isloading ? <CircularProgress size={18} /> : ""}
    </ButtonRoot>
  );
};

export default StyledButton;
