import React, { useEffect, useMemo, useRef } from "react";
import { Box, Grid, List, ListItem, ListItemText, ListSubheader, Typography, styled, useTheme } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import graphics from "assets/images/home-placeholder.jpg";
import useGetMilestoneProgress from "hooks/useGetMilestoneProgress";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { StyledButton } from "ui";
import { checkPermission } from "helpers";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ThumbSlide = styled(Splide)(({ theme }) => ({
  "& .splide__list": {
    "& .splide__slide": {
      opacity: 0.48,

      "&.is-active": {
        opacity: 1,
        border: `3px solid ${theme.palette.primary.main}`,
      },
    },
  },
}));

const ListHead = styled(ListSubheader)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 5,
  padding: 0,
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "16px",
}));

const Item = styled(ListItem)(({ theme }) => ({
  padding: 0,
  gap: 10,

  "&:before": { width: 5, height: 5, content: '""', borderRadius: "50%", backgroundColor: theme.palette.grey.dark },

  "& .MuiListItemText-root": {
    margin: 0,
    flex: "0 1 auto",

    "& > *": { lineHeight: 1 },

    "& .MuiListItemText-primary": {
      color: theme.palette.text.primary,
      fontSize: 14,
      fontWeight: "500",
    },

    "& .MuiListItemText-secondary": { color: theme.palette.grey.dark, fontSize: 14 },
  },
}));

const UserHomeView = (props) => {
  const { userHome } = props;

  const theme = useTheme();
  const slider1 = useRef();
  const slider2 = useRef();

  const { userMilestoneData, getData } = useGetMilestoneProgress();
  const navigate = useNavigate();

  const { permissionList } = useSelector((state) => state.shared);

  const homeData = useMemo(() => userHome?.home, [userHome]);

  const sliderOption = {
    type: "slide",
    perPage: 1,
    pagination: false,
    arrows: true,
  };

  const sliderThumbOption = {
    fixedWidth: 64,
    fixedHeight: 64,
    height: 64,
    type: "slide",
    perPage: 5,
    gap: 12,
    pagination: false,
    isNavigation: true,
    arrows: false,
    focus: "center",
  };

  useEffect(() => {
    if (userHome?.id) getData(userHome?.id);
  }, [userHome?.id]);

  return (
    <>
      {userHome && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {homeData?.media?.length > 0 && (
              <>
                <Splide
                  ref={(slider) => (slider1.current = slider)}
                  options={sliderOption}
                  className="overflow-hidden rounded-2xl"
                >
                  {homeData?.media.map((img) => {
                    return (
                      <SplideSlide key={img.id} className="overflow-hidden rounded-2xl ">
                        <img src={img.url} alt={img.name} className="object-contain w-full h-full" />
                      </SplideSlide>
                    );
                  })}
                </Splide>

                <ThumbSlide
                  ref={(slider) => (slider2.current = slider)}
                  options={sliderThumbOption}
                  className="max-w-full sm:w-[63%] overflow-hidden mt-6 mx-auto"
                >
                  {homeData?.media.map((img) => {
                    return (
                      <SplideSlide key={img.id} className="overflow-hidden cursor-pointer rounded-xl">
                        <img src={img.url} alt={img.name} className="object-cover w-full h-full" />
                      </SplideSlide>
                    );
                  })}
                </ThumbSlide>
              </>
            )}

            {homeData?.media.length === 0 && (
              <img src={graphics} alt={homeData?.name} className="h-[350px] object-cover w-full rounded-2xl" />
            )}

            <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" mt={7} gap={3}>
              <StyledButton
                color={theme.palette.info.main}
                onClick={() => navigate(`/my-home/customize/${userHome?.id}`)}
                className="px-7"
              >
                <ColorLensIcon /> Customize
              </StyledButton>

              <StyledButton
                type="button"
                variant="outlined"
                color={theme.palette.info.main}
                sx={{ color: theme.palette.text.primary }}
                onClick={() => navigate(`/my-home/summary/${userHome.homeId}`)}
                className="px-7"
              >
                <SummarizeIcon sx={{ width: 18, height: 18, color: "#777777" }} />
                Selection
              </StyledButton>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
            <Typography component="h3" fontSize={30} fontWeight="600">
              {homeData?.name || ""} ({userHome?.guid || ""})
            </Typography>
            <Typography component="h6" fontSize={20} fontWeight="600">
              ${homeData?.basePrice || 0}
            </Typography>

            <Typography component="p" fontSize={14} fontWeight="400" color={theme.palette.grey.dark}>
              {homeData?.description || ""}
            </Typography>

            <List sx={{ display: "flex", flexDirection: "column", gap: 1.25, padding: 0 }}>
              <ListHead>General information</ListHead>
              <Item>
                <ListItemText primary="Model :" />
                <ListItemText secondary={userHome?.guid || "-"} />
              </Item>
              <Item>
                <ListItemText primary="Home plan :" />
                <ListItemText secondary={userHome?.planConfigChoice?.displayName || "-"} />
              </Item>
              <Item>
                <ListItemText primary="Home Storey :" />
                <ListItemText secondary={homeData?.storey || "-"} />
              </Item>
              <Item>
                <ListItemText primary="Address :" />
                <ListItemText secondary={homeData?.address || "-"} />
              </Item>
            </List>

            <List sx={{ display: "flex", flexDirection: "column", gap: 1.25, padding: 0 }}>
              <ListHead>
                Documents progress
                {checkPermission(permissionList, "list document") && (
                  <StyledButton
                    variant="outlined"
                    color={theme.palette.info.main}
                    sx={{ minHeight: "initial", boxShadow: "none", borderColor: "transparent !important" }}
                    onClick={(e) => navigate(`/my-home/document/${userHome.id}`)}
                  >
                    View all
                  </StyledButton>
                )}
              </ListHead>
              {userMilestoneData?.map((progress) => (
                <Item key={progress.id}>
                  <ListItemText primary={`${progress.displayName} :`} />
                  <ListItemText
                    secondary={`${progress.total?.documentsCount?.completed}/${progress.total?.documentsCount?.total} approved`}
                  />
                </Item>
              ))}
            </List>

            <List sx={{ display: "flex", flexDirection: "column", gap: 1.25, padding: 0 }}>
              <ListHead>
                Buildings progress
                {checkPermission(permissionList, "list progress") && (
                  <StyledButton
                    variant="outlined"
                    color={theme.palette.info.main}
                    sx={{ minHeight: "initial", boxShadow: "none", borderColor: "transparent !important" }}
                    onClick={(e) => navigate(`/my-home/building-progress/${userHome.id}`)}
                  >
                    View all
                  </StyledButton>
                )}
              </ListHead>

              {userMilestoneData?.map((progress) => (
                <Item key={progress.id}>
                  <ListItemText primary={`${progress.displayName} :`} />
                  <ListItemText secondary={`${progress.total?.percent.toFixed(0)}%`} />
                </Item>
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserHomeView;
