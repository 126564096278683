import React from "react";
import { Box, Breadcrumbs, styled, Link, Typography } from "@mui/material";

const Item = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",

  "&:hover": {
    textDecoration: "underline",
  },
}));

const BreadcrumbContainer = (props) => {
  return (
    <Box display="flex" flexDirection="column" mb={5} className={`${props.classes?.root || ""}`}>
      <Typography component="h4" variant="h4" mb={1} fontSize="1.5rem" fontWeight="700" lineHeight="1.5">
        {props.title}
      </Typography>

      <Breadcrumbs separator="›" sx={{ fontSize: "0.875rem" }}>
        <Item href="/dashboard">Dashboard</Item>

        {props.paths?.map((item, index) => {
          return (
            <span key={index}>
              {index === props.paths.length - 1 ? (
                item?.title || ""
              ) : (
                <Item key={index} href={index === props.paths.length - 1 ? null : item.page ? item.page : "/"}>
                  {item?.title}
                </Item>
              )}
            </span>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
export default BreadcrumbContainer;
