import React from "react";
import { useTheme } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useGetStatusColor = (type) => {
  const theme = useTheme();

  const [statusColor, setStatusColor] = React.useState("");
  const [statusIcon, setStatusIcon] = React.useState(<></>);

  React.useEffect(() => {
    if (type === "active" || type === "approved" || type === "completed") {
      setStatusColor("#038C4C");
      setStatusIcon(<TaskAltOutlinedIcon />);
    } else if (type === "inactive" || type === "pending") {
      setStatusColor(theme.palette.warn.main);
      setStatusIcon(<PendingOutlinedIcon />);
    } else if (type === "error" || type === "rejected") {
      setStatusColor(theme.palette.error.main);
      setStatusIcon(<ErrorOutlineOutlinedIcon />);
    } else {
      setStatusColor(theme.palette.info.main);
      setStatusIcon(<InfoOutlinedIcon />);
    }
  }, [type]);

  return [statusColor, statusIcon];
};

export default useGetStatusColor;
