import React, { useMemo } from "react";
import { Avatar, Divider, IconButton, MenuItem, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { PopoverDropdown } from "ui";
import defaultUserAatar from "assets/images/default-user.png";

const UserDropdown = () => {
  const theme = useTheme();

  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser"));
  }, [localStorage.getItem("currentUser")]);

  return (
    <PopoverDropdown
      triggertype={IconButton}
      trigger={
        <Avatar
          src={user?.media?.[0]?.url || defaultUserAatar}
          alt={user?.name || ""}
          size="small"
          sx={{ width: 32, height: 32, bgcolor: "#DFE2E7", "& img": { objectFit: "cover" } }}
        />
      }
    >
      <Typography
        component="span"
        variant="subtitle2"
        sx={{
          display: "block",
          mt: "-0.5rem",
          p: "0.75rem 1.25rem",
          color: theme.palette.common.dark,
        }}
      >
        {user?.name || ""}
        <Typography component="small" variant="body2" display="block" color={theme.palette.grey.dark}>
          {user?.email || ""}
        </Typography>
      </Typography>

      <Divider sx={{ mb: "0.5rem", borderBottom: "thin dashed rgba(145, 158, 171, 0.24)" }} />

      <MenuItem component={Link} to="/profile">
        Profile
      </MenuItem>

      <Divider sx={{ mt: "0.5rem", borderBottom: "thin dashed rgba(145, 158, 171, 0.24)" }} />

      <MenuItem component={Link} to="/logout">
        Logout
      </MenuItem>
    </PopoverDropdown>
  );
};

export default UserDropdown;
