import { handleResponseErrorMessage, parseMessage, toaster } from "helpers";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import MessageService from "services/MessageService";
import TableDataService from "services/TableDataService";
import {
  ADD_MESSAGE_GROUP,
  DELETE_MESSAGE_GROUP,
  EDIT_MESSAGE_GROUP,
  GET_ALL_MESSAGE_GROUP,
  GET_MESSAGE_GROUP,
  GET_MESSAGE_GROUP_LIST,
} from "../actions";
import {
  addMessageGroupError,
  addMessageGroupSuccess,
  deleteMessageGroupError,
  deleteMessageGroupSuccess,
  editMessageGroupError,
  editMessageGroupSuccess,
  getAllMessageGroupError,
  getAllMessageGroupSuccess,
  getMessageGroupError,
  getMessageGroupList,
  getMessageGroupListError,
  getMessageGroupListSuccess,
  getMessageGroupSuccess,
} from "./action";

const getMetaData = (state) => state.messageGroup.metaData;

export function* watchGetAllMessageGroup() {
  yield takeEvery(GET_ALL_MESSAGE_GROUP, getAllMessageGroup);
}

const getAllMessageGroupAsync = async (dbParam) => {
  return MessageService.getAllMessageGroup(dbParam);
};

function* getAllMessageGroup({ payload }) {
  try {
    const response = yield call(getAllMessageGroupAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getAllMessageGroupSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllMessageGroupError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllMessageGroupError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetMessageGroupList() {
  yield takeEvery(GET_MESSAGE_GROUP_LIST, getMessageGroupListAc);
}

const getMessageGroupListAsync = async (dbParam) => {
  return TableDataService.getAllData("message-groups", dbParam);
};

function* getMessageGroupListAc({ payload }) {
  try {
    const response = yield call(getMessageGroupListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getMessageGroupListSuccess(response.data.data, response.data.meta));
    } else {
      toaster("", response.data.message);
      yield put(getMessageGroupListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getMessageGroupListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddMessageGroup() {
  yield takeEvery(ADD_MESSAGE_GROUP, addMessageGroup);
}

const addMessageGroupAsync = async (data) => {
  return MessageService.addMessageGroup(data);
};

function* addMessageGroup({ payload }) {
  try {
    const response = yield call(addMessageGroupAsync, payload.messageGroupData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addMessageGroupSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(addMessageGroupError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addMessageGroupError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetMessageGroup() {
  yield takeEvery(GET_MESSAGE_GROUP, getMessageGroup);
}

const getMessageGroupAsync = async (id) => {
  return MessageService.getMessageGroup(id);
};

function* getMessageGroup({ payload }) {
  try {
    const response = yield call(getMessageGroupAsync, payload.messageGroupId);
    if (response.data.success) {
      yield put(getMessageGroupSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getMessageGroupError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getMessageGroupError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditMessageGroup() {
  yield takeEvery(EDIT_MESSAGE_GROUP, editMessageGroup);
}

const editMessageGroupAsync = async (data, id) => {
  return MessageService.editMessageGroup(data, id);
};

function* editMessageGroup({ payload }) {
  try {
    const response = yield call(editMessageGroupAsync, payload.messageGroupData, payload.messageGroupId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editMessageGroupSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(editMessageGroupError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editMessageGroupError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteMessageGroup() {
  yield takeEvery(DELETE_MESSAGE_GROUP, deleteMessageGroup);
}

const deleteMessageGroupAsync = async (id) => {
  return MessageService.deleteMessageGroup(id);
};

function* deleteMessageGroup({ payload }) {
  try {
    const metaData = yield select(getMetaData);
    const response = yield call(deleteMessageGroupAsync, payload.messageGroupId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteMessageGroupSuccess(response.data.success));
      yield put(getMessageGroupList({ page: metaData.page, pageSize: metaData.pageSize }));
    } else {
      toaster("", response.data.message);
      yield put(deleteMessageGroupError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteMessageGroupError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllMessageGroup),
    fork(watchGetMessageGroupList),
    fork(watchAddMessageGroup),
    fork(watchGetMessageGroup),
    fork(watchEditMessageGroup),
    fork(watchDeleteMessageGroup),
  ]);
}
