import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_USER_HOME, ADD_USER_HOME, EDIT_USER_HOME, DELETE_USER_HOME, getUser, getUserList } from "reduxs/actions";
import {
  getUserHomeSuccess,
  getUserHomeError,
  addUserHomeSuccess,
  addUserHomeError,
  editUserHomeSuccess,
  editUserHomeError,
  deleteUserHomeSuccess,
  deleteUserHomeError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import UserHomeService from "services/UserHomeService";

export function* watchGetUserHome() {
  yield takeEvery(GET_USER_HOME, getUserHomeAc);
}

const getUserHomeAsync = async (id) => {
  return UserHomeService.getUserHome(id);
};

function* getUserHomeAc({ payload }) {
  try {
    const response = yield call(getUserHomeAsync, payload.homeId);
    if (response.data.success) {
      yield put(getUserHomeSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getUserHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getUserHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddUserHome() {
  yield takeEvery(ADD_USER_HOME, addUserHome);
}

const addUserHomeAsync = async (data) => {
  return UserHomeService.addUserHome(data);
};

function* addUserHome({ payload }) {
  try {
    const response = yield call(addUserHomeAsync, payload.userHomeData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addUserHomeSuccess(response.data.success));
      yield put(getUserList({ role: "client" }));
    } else {
      toaster("", response.data.message);
      yield put(addUserHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addUserHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditUserHome() {
  yield takeEvery(EDIT_USER_HOME, editUserHome);
}

const editUserHomeAsync = async (data, id) => {
  return UserHomeService.editUserHome(data, id);
};

function* editUserHome({ payload }) {
  try {
    const response = yield call(editUserHomeAsync, payload.userHomeData, payload.homeId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editUserHomeSuccess(response.data.success));
    } else {
      toaster("", response.data.message);
      yield put(editUserHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editUserHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteUserHome() {
  yield takeEvery(DELETE_USER_HOME, deleteUserHome);
}

const deleteUserHomeAsync = async (id) => {
  return UserHomeService.deleteUserHome(id);
};

function* deleteUserHome({ payload }) {
  try {
    const response = yield call(deleteUserHomeAsync, payload.userHomeId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteUserHomeSuccess(response.data.success));
      yield put(getUser(payload.userId));
    } else {
      toaster("", response.data.message);
      yield put(deleteUserHomeError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteUserHomeError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetUserHome), fork(watchAddUserHome), fork(watchEditUserHome), fork(watchDeleteUserHome)]);
}
