import Req from "interceptors/TokenInterceptor";

const NotificaionService = (function () {
  function _getNotification(id) {
    return Req.get(`/api/notifications/${id}`);
  }
  function _readNotification(data) {
    return Req.post("/api/notifications/read", data);
  }
  function _deleteNotification(id) {
    return Req.delete(`/api/notifications/${id}`);
  }
  return {
    getNotification: _getNotification,
    readNotification: _readNotification,
    deleteNotification: _deleteNotification,
  };
})();
export default NotificaionService;
