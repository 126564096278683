import React from "react";
import { Avatar, Box, Typography, useTheme } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import VerifiedIcon from "@mui/icons-material/Verified";
import defaultUserAatar from "assets/images/default-user.png";
import { StatusChip } from "ui";

const UserDetailCard = (props) => {
  const { data } = props;

  const theme = useTheme();

  return (
    <Box component="section" display="flex" gap={2} mt={1}>
      <Avatar
        variant="square"
        sx={{ width: 120, height: 120, borderRadius: "18px", backgroundColor: theme.palette.secondary.main }}
        classes={{ img: "object-contain" }}
        src={data?.media?.[0]?.url || defaultUserAatar}
      />

      <Box display="flex" flexDirection="column">
        <Box
          component="span"
          display="flex"
          alignItems="center"
          gap={2}
          color={theme.palette.info.main}
          textTransform="capitalize"
          mb={0.65}
        >
          {data?.role || ""}

          <StatusChip
            status={data?.statusConfigChoice?.configChoice || ""}
            label={data?.statusConfigChoice?.displayName || ""}
          />
        </Box>

        <Typography
          component="h4"
          display="flex"
          alignItems="center"
          gap={2}
          fontSize="28px"
          fontWeight="bold"
          lineHeight={1}
        >
          {data?.name || ""}
          <VerifiedIcon sx={{ color: data?.isVerified ? theme.palette.primary.main : theme.palette.warn.main }} />
        </Typography>

        <Typography component="p" color={theme.palette.grey.dark} mt={1.5}>
          {data?.address || ""}
        </Typography>

        <Box display="flex" alignItems="center" gap={2.5} mt={0.65}>
          <Typography component="p" display="flex" alignItems="center" gap={1.25}>
            <MailIcon sx={{ color: "#637381" }} /> {data?.email || ""}
          </Typography>

          <Typography component="p" display="flex" alignItems="center" gap={1.25}>
            <PhoneIcon sx={{ color: "#637381" }} /> {data?.phone && "+61"} {data?.phone || ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserDetailCard;
