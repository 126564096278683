import React from "react";
import { Box, alpha, styled, useTheme } from "@mui/material";
import StyledSpinner from "ui/spinner/Spinner";

const TableResponsive = styled(Box)(({ theme }) => ({
  minHeight: "10rem",
  width: "100%",
  position: "relative",
  overflow: "auto",
}));

const StyledTable = styled(Box)(({ theme, striped }) => ({
  width: "100%",
  borderCollapse: "collapse",
  borderSpacing: "0",

  "& tr:nth-of-type(even)": {
    "& td": {
      backgroundColor: striped ? alpha(theme.palette.grey.cadet, 0.16) : "transparent",

      "&:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "&:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
    },

    "&.divider td": {
      backgroundColor: "transparent",
    },
  },

  "& td, th": { padding: "1rem", fontSize: ".875rem", lineHeight: "1.5rem" },

  "& thead": {
    width: "100%",
    "& tr th": {
      backgroundColor: theme.palette.blue.shadowBlue,
      color: theme.palette.grey.dark,
      fontWeight: "600",
      textAlign: "left",

      "&:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "&:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
    },
  },
}));

const SimpleTable = (props) => {
  const { wrapperProps, tableProps, isloading, children } = props;

  const theme = useTheme();

  return (
    <TableResponsive {...wrapperProps}>
      <StyledTable component="table" {...tableProps}>
        {children}
      </StyledTable>

      {isloading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm"
        >
          <StyledSpinner />
        </Box>
      )}
    </TableResponsive>
  );
};

export default SimpleTable;
