export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";
export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_ERROR = "READ_NOTIFICATION_ERROR";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";

export const getNotificationList = (dbParam) => ({
  type: GET_NOTIFICATION_LIST,
  payload: { dbParam },
});

export const getNotificationListSuccess = (notificationList, metaData, unread, isPagination = false) => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload: { notificationList, metaData, unread, isPagination },
});

export const getNotificationListError = (error) => ({
  type: GET_NOTIFICATION_LIST_ERROR,
  payload: error,
});

export const readNotification = (notificationData) => ({
  type: READ_NOTIFICATION,
  payload: { notificationData },
});

export const readNotificationSuccess = (success) => ({
  type: READ_NOTIFICATION_SUCCESS,
  payload: success,
});

export const readNotificationError = (error) => ({
  type: READ_NOTIFICATION_ERROR,
  payload: error,
});

export const resetNotification = () => ({
  type: RESET_NOTIFICATION,
  payload: {},
});
