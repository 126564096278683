export const GET_MESSAGE_LIST = "GET_MESSAGE_LIST";
export const GET_MESSAGE_LIST_SUCCESS = "GET_MESSAGE_LIST_SUCCESS";
export const GET_MESSAGE_LIST_ERROR = "GET_MESSAGE_LIST_ERROR";
export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_ERROR = "GET_MESSAGE_ERROR";
export const COMPOSE_MESSAGE = "COMPOSE_MESSAGE";
export const COMPOSE_MESSAGE_SUCCESS = "COMPOSE_MESSAGE_SUCCESS";
export const COMPOSE_MESSAGE_ERROR = "COMPOSE_MESSAGE_ERROR";
export const EDIT_MESSAGE = "EDIT_MESSAGE";
export const EDIT_MESSAGE_SUCCESS = "EDIT_MESSAGE_SUCCESS";
export const EDIT_MESSAGE_ERROR = "EDIT_MESSAGE_ERROR";
export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const UPDATE_MESSAGE_STATUS_SUCCESS = "UPDATE_MESSAGE_STATUS_SUCCESS";
export const UPDATE_MESSAGE_STATUS_ERROR = "UPDATE_MESSAGE_STATUS_ERROR";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_ERROR = "DELETE_MESSAGE_ERROR";
export const RESET_MESSAGE = "RESET_MESSAGE";

export const getMessageList = (dbParam) => ({
  type: GET_MESSAGE_LIST,
  payload: { dbParam },
});

export const getMessageListSuccess = (messageList) => ({
  type: GET_MESSAGE_LIST_SUCCESS,
  payload: messageList,
});

export const getMessageListError = (error) => ({
  type: GET_MESSAGE_LIST_ERROR,
  payload: error,
});

export const getMessage = (messageId) => ({
  type: GET_MESSAGE,
  payload: { messageId },
});

export const getMessageSuccess = (messageData) => ({
  type: GET_MESSAGE_SUCCESS,
  payload: messageData,
});

export const getMessageError = (error) => ({
  type: GET_MESSAGE_ERROR,
  payload: error,
});

export const composeMessage = (messageData, filters) => ({
  type: COMPOSE_MESSAGE,
  payload: { messageData, filters },
});

export const composeMessageSuccess = (success) => ({
  type: COMPOSE_MESSAGE_SUCCESS,
  payload: success,
});

export const composeMessageError = (error) => ({
  type: COMPOSE_MESSAGE_ERROR,
  payload: error,
});

export const editMessage = (id, messageData, filters) => ({
  type: EDIT_MESSAGE,
  payload: { id, messageData, filters },
});

export const editMessageSuccess = (success) => ({
  type: EDIT_MESSAGE_SUCCESS,
  payload: success,
});

export const editMessageError = (error) => ({
  type: EDIT_MESSAGE_ERROR,
  payload: error,
});

export const updateMessageStatus = (messageId, updateMessageStatusData, filters) => ({
  type: UPDATE_MESSAGE_STATUS,
  payload: { messageId, updateMessageStatusData, filters },
});

export const updateMessageStatusSuccess = (success) => ({
  type: UPDATE_MESSAGE_STATUS_SUCCESS,
  payload: success,
});

export const updateMessageStatusError = (error) => ({
  type: UPDATE_MESSAGE_STATUS_ERROR,
  payload: error,
});

export const deleteMessage = (messageId, filters) => ({
  type: DELETE_MESSAGE,
  payload: { messageId, filters },
});

export const deleteMessageSuccess = (success) => ({
  type: DELETE_MESSAGE_SUCCESS,
  payload: success,
});

export const deleteMessageError = (error) => ({
  type: DELETE_MESSAGE_ERROR,
  payload: error,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
  payload: {},
});
