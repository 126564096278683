import React, { useEffect, useRef } from "react";
import { Box, FormHelperText, Grid, Typography, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addDocument, editDocument, getDocument, getUserHomeMilestoneList } from "reduxs/actions";
import { FileUpload, InputField, SelectField, StyledButton } from "ui";
import { useParams } from "react-router-dom";

const DocumentForm = (props) => {
  const { hashomeselection, editId, handleClose, milestone } = props;

  const formikRef = useRef();
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.user);
  const { userSelectedHomeMilestoneList, loading } = useSelector((state) => state.userHomeMilestone);
  const { success, frmLoading, documentData } = useSelector((state) => state.document);

  const isMultipleUpload = React.useMemo(() => !editId && !milestone, [editId, milestone]);

  const initialValues = {
    name: editId ? documentData?.name || "" : "",
    selectedHome:
      userData?.userHomes?.find((item) => item.id === params.userHomeId)?.id || userData?.userHomes?.[0]?.id || "",
    userHomeMilestoneId: editId ? documentData?.userHomeMilestoneId || "" : milestone || "",
    media: editId ? documentData?.media || [] : [],
  };

  const schema = Yup.object().shape({
    // name: Yup.string().required("Name is required."),
    userHomeMilestoneId: Yup.string().required("Milestone is required."),
  });

  const onSubmit = (values) => {
    delete values.selectedHome;

    if (!frmLoading && values.media.length) {
      if (editId) {
        dispatch(editDocument(editId, values));
      } else {
        dispatch(addDocument(values));
      }
    }
  };

  useEffect(() => {
    if (editId) dispatch(getDocument(editId));

    return () => {
      dispatch(getDocument(""));
    };
  }, []);

  useEffect(() => {
    if (success) handleClose(true);
  }, [success]);

  useEffect(() => {
    if (params.userHomeId || userData?.userHomes) {
      const homeId = params.userHomeId || userData?.userHomes?.[0]?.id;
      dispatch(getUserHomeMilestoneList({ userHomeId: homeId, homeFilter: true }));
    }
  }, [params, userData]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            {editId ? (
              <Grid item xs={12} xl={12}>
                <InputField name="name" type="text" label="Name*" />
              </Grid>
            ) : null}

            {hashomeselection && (
              <Grid item xs={12} xl={6}>
                <SelectField
                  name="selectedHome"
                  type="text"
                  label="Home"
                  placeholder="Select home"
                  options={
                    userData?.userHomes &&
                    userData?.userHomes.map((data) => {
                      return { id: data.id, name: `${data.home.name}(${data.guid})` };
                    })
                  }
                  handleChange={(e, selected) =>
                    dispatch(getUserHomeMilestoneList({ userHomeId: selected.id, homeFilter: true }))
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} xl={6}>
              <SelectField
                name="userHomeMilestoneId"
                type="text"
                label="Milestone"
                placeholder="Select milestone"
                options={
                  userSelectedHomeMilestoneList &&
                  userSelectedHomeMilestoneList?.map((data) => {
                    return { id: data.id, name: data.milestone.name };
                  })
                }
                isloading={loading}
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Typography component="label" fontSize="0.875rem" fontWeight="600" display="block" mb={1}>
              Upload file
            </Typography>
            <FileUpload
              setMedia={(files, progress) => progress && setFieldValue("media", files)}
              media={values.media}
              multiple={isMultipleUpload}
              isupdating={editId}
            />
            {values?.media?.length === 0 && <FormHelperText error>File is required</FormHelperText>}
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={frmLoading}>
              {editId ? "Update Document" : "Upload Document"}
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              onClick={() => handleClose()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.error.main}
              onClick={resetForm}
              className="shadow-none"
            >
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default DocumentForm;
