export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_ERROR = "GET_ALL_PRODUCT_ERROR";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_ERROR = "EDIT_PRODUCT_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";
export const RESET_PRODUCT = "RESET_PRODUCT";

export const getAllProduct = () => ({
  type: GET_ALL_PRODUCT,
  payload: {},
});

export const getAllProductSuccess = (products) => ({
  type: GET_ALL_PRODUCT_SUCCESS,
  payload: products,
});

export const getAllProductError = (error) => ({
  type: GET_ALL_PRODUCT_ERROR,
  payload: error,
});

export const getProductList = () => ({
  type: GET_PRODUCT_LIST,
  payload: {},
});

export const getProductListSuccess = (productList) => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: productList,
});

export const getProductListError = (error) => ({
  type: GET_PRODUCT_LIST_ERROR,
  payload: error,
});

export const addProduct = (productData, navigate) => ({
  type: ADD_PRODUCT,
  payload: { productData, navigate },
});

export const addProductSuccess = (success) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: success,
});

export const addProductError = (error) => ({
  type: ADD_PRODUCT_ERROR,
  payload: error,
});

export const getProduct = (productId) => ({
  type: GET_PRODUCT,
  payload: { productId },
});

export const getProductSuccess = (productData) => ({
  type: GET_PRODUCT_SUCCESS,
  payload: productData,
});

export const getProductError = (error) => ({
  type: GET_PRODUCT_ERROR,
  payload: error,
});

export const editProduct = (productId, productData, navigate) => ({
  type: EDIT_PRODUCT,
  payload: { productId, productData, navigate },
});

export const editProductSuccess = (success) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: success,
});

export const editProductError = (error) => ({
  type: EDIT_PRODUCT_ERROR,
  payload: error,
});

export const deleteProduct = (productId) => ({
  type: DELETE_PRODUCT,
  payload: { productId },
});

export const deleteProductSuccess = (success) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: success,
});

export const deleteProductError = (error) => ({
  type: DELETE_PRODUCT_ERROR,
  payload: error,
});

export const resetProduct = () => ({
  type: RESET_PRODUCT,
  payload: {},
});
