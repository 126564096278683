import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";

const PageNotFound = (props) => {
  return (
    <section>
      <Container maxWidth="md">
        <Typography component="h2" variant="h2" className="text-center mb-3" style={{ fontSize: "4vh" }}>
          SORRY!!
        </Typography>
        <Typography component="p" variant="subtitle1" className="text-center mb-3">
          The requested page was not found. It seems you have ventured onto a broken link.
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button href="/" color="primary" className="btn-default" variant="contained">
            Go Back
          </Button>
        </Box>
      </Container>
    </section>
  );
};
export default PageNotFound;
