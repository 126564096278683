export const GET_ALL_USER_HOME_CUSTOMIZATION = "GET_ALL_USER_HOME_CUSTOMIZATION";
export const GET_ALL_USER_HOME_CUSTOMIZATION_SUCCESS = "GET_ALL_USER_HOME_CUSTOMIZATION_SUCCESS";
export const GET_ALL_USER_HOME_CUSTOMIZATION_ERROR = "GET_ALL_USER_HOME_CUSTOMIZATION_ERROR";
export const ADD_USER_HOME_CUSTOMIZATION = "ADD_USER_HOME_CUSTOMIZATION";
export const ADD_USER_HOME_CUSTOMIZATION_SUCCESS = "ADD_USER_HOME_CUSTOMIZATION_SUCCESS";
export const ADD_USER_HOME_CUSTOMIZATION_ERROR = "ADD_USER_HOME_CUSTOMIZATION_ERROR";
export const DELETE_USER_HOME_CUSTOMIZATION = "DELETE_USER_HOME_CUSTOMIZATION";
export const DELETE_USER_HOME_CUSTOMIZATION_SUCCESS = "DELETE_USER_HOME_CUSTOMIZATION_SUCCESS";
export const DELETE_USER_HOME_CUSTOMIZATION_ERROR = "DELETE_USER_HOME_CUSTOMIZATION_ERROR";
export const RESET_USER_HOME_CUSTOMIZATION = "RESET_USER_HOME_CUSTOMIZATION";

export const getAllUserHomeCustomization = (filterParam) => ({
  type: GET_ALL_USER_HOME_CUSTOMIZATION,
  payload: { filterParam },
});

export const getAllUserHomeCustomizationSuccess = (userHomeCustomizations) => ({
  type: GET_ALL_USER_HOME_CUSTOMIZATION_SUCCESS,
  payload: userHomeCustomizations,
});

export const getAllUserHomeCustomizationError = (error) => ({
  type: GET_ALL_USER_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const addUserHomeCustomization = (userHomeCustomizationData) => ({
  type: ADD_USER_HOME_CUSTOMIZATION,
  payload: { userHomeCustomizationData },
});

export const addUserHomeCustomizationSuccess = (success) => ({
  type: ADD_USER_HOME_CUSTOMIZATION_SUCCESS,
  payload: success,
});

export const addUserHomeCustomizationError = (error) => ({
  type: ADD_USER_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const deleteUserHomeCustomization = (userHomeCustomizationId, userHomeId) => ({
  type: DELETE_USER_HOME_CUSTOMIZATION,
  payload: { userHomeCustomizationId, userHomeId },
});

export const deleteUserHomeCustomizationSuccess = (success) => ({
  type: DELETE_USER_HOME_CUSTOMIZATION_SUCCESS,
  payload: success,
});

export const deleteUserHomeCustomizationError = (error) => ({
  type: DELETE_USER_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const resetUserHomeCustomization = () => ({
  type: RESET_USER_HOME_CUSTOMIZATION,
  payload: {},
});
