import { Box, Grid, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageGroup,
  editMessageGroup,
  getAllUser,
  getMessageGroup,
  resetMessageGroup,
  resetUser,
} from "reduxs/actions";
import { InputField, SelectField, StyledButton, TextareaField } from "ui";

const MessageGroupForm = (props) => {
  const { editId, handleClose } = props;

  const formikRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.user);
  const { success, frmLoading, messageGroupData } = useSelector((state) => state.messageGroup);

  const initialValues = {
    name: editId ? messageGroupData?.name || "" : "",
    description: editId ? messageGroupData?.description || "" : "",
    userIds: editId ? messageGroupData?.messageGroupUsers?.map((element) => element.user.id) || [] : [],
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Enter name"),
    userIds: Yup.array().required("Select atleast 1 user"),
  });

  const onSubmit = (values) => {
    if (!frmLoading) {
      if (editId) {
        dispatch(editMessageGroup(editId, values));
      } else {
        dispatch(addMessageGroup(values));
      }
    }
  };

  useEffect(() => {
    if (editId) dispatch(getMessageGroup(editId));

    dispatch(getAllUser({ role: "client", action: "include" }));

    return () => {
      dispatch(resetUser());
      dispatch(resetMessageGroup());
    };
  }, []);

  useEffect(() => {
    if (success) handleClose(true);
  }, [success]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <InputField name="name" type="text" label="Name*" />
            </Grid>

            <Grid item xs={12} xl={12}>
              <SelectField
                name="userIds"
                type="text"
                placeholder="Select users"
                limitTags={10}
                multiple
                hasImage={true}
                options={users}
                autoCompletePros={{ disableCloseOnSelect: true }}
              />
            </Grid>

            <Grid item xs={12} xl={12}>
              <TextareaField name="description" label="Description" placeholder="Group description" />
            </Grid>
          </Grid>

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={frmLoading}>
              {editId ? "Update Group" : "Create Group"}
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              onClick={() => handleClose()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.error.main}
              onClick={resetForm}
              className="shadow-none"
            >
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default MessageGroupForm;
