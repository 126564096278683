import React from "react";
import ArticleIcon from "@mui/icons-material/Article";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import PersonIcon from "@mui/icons-material/Person";
import InsightsTwoToneIcon from "@mui/icons-material/InsightsTwoTone";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import TextsmsRoundedIcon from "@mui/icons-material/TextsmsRounded";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import HouseIcon from "@mui/icons-material/House";
import CategoryIcon from "@mui/icons-material/Category";
import TaskIcon from "@mui/icons-material/Task";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import CottageIcon from "@mui/icons-material/Cottage";

const iconMapping = {
  user: <PersonIcon />,
  customer: <InsertEmoticonIcon />,
  role: <PrivacyTipIcon />,
  document: <ArticleIcon />,
  progress: <InsightsTwoToneIcon />,
  home: <HouseIcon />,
  product: <CategoryIcon />,
  milestone: <TaskIcon />,
  customization: <FormatPaintIcon />,
  user_home: <CottageIcon />,
  message: <TextsmsRoundedIcon />,
};

export const getModuleIcon = (module) => {
  return iconMapping[module] || <NotificationsActiveTwoToneIcon />;
};
