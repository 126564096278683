import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import DocViewer from "@cyntler/react-doc-viewer";
import { Box, Typography, useTheme } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useDispatch, useSelector } from "react-redux";
import { editDocument } from "reduxs/actions";
import { checkPermission, getFileType } from "helpers";
import { Action, InstantActionAlert, PopoverDropdown, SimpleTable, StatusChip, StyledButton, StyledDialog } from "ui";
import { IconFiles } from "icons";

const DocumentListTable = (props) => {
  const { documents, handleedit, handledelete } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { permissionList } = useSelector((state) => state.shared);

  const [viewDocument, setViewDocument] = React.useState([]);
  const [currentUser] = React.useState(() => {
    return JSON.parse(localStorage.getItem("currentUser")) || "";
  });

  const updateStatus = (data, status) => {
    const payload = {
      name: data.name,
      userHomeMilestoneId: data.userHomeMilestoneId,
      media: data.media,
      status,
    };

    dispatch(editDocument(data.id, payload));
  };

  const getColor = (docStatus) => {
    if (docStatus === "approved") {
      return "#038C4C";
    } else if (docStatus === "rejected") {
      return "#ff5630";
    } else {
      return "#FFAB00";
    }
  };

  return (
    <Box width="100%" overflow="auto">
      {documents?.length ? (
        <SimpleTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Modified</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Box
                      component={Link}
                      to={doc?.media?.[0]?.url}
                      target="_blank"
                      // onClick={() => setViewDocument([{ uri: doc?.media?.[0].url }])}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        color: theme.palette.text.primary,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <IconFiles filetype={getFileType(doc?.media?.[0]?.fileName || "")} />
                      {doc?.name || ""}
                    </Box>
                  </td>
                  <td>{doc?.media?.[0]?.size}</td>
                  <td>{format(new Date(doc.updatedAt), "dd MMM yyyy")}</td>
                  <td>
                    {checkPermission(permissionList, "change document status") ? (
                      <PopoverDropdown
                        isFixedWidth={true}
                        dropdownWidth="21.875rem"
                        trigger={
                          <StyledButton
                            color={getColor(doc.statusConfigChoice.configChoice)}
                            sx={{
                              minHeight: 24,
                              padding: "0px 8px",
                              boxShadow: "none",
                              fontSize: "0.75rem",
                              fontWeight: 700,
                            }}
                          >
                            {doc.statusConfigChoice.displayName}
                            <UnfoldMoreIcon sx={{ mr: -1 }} />
                          </StyledButton>
                        }
                        triggertype="div"
                        triggerprops={{
                          className: `inline-block`,
                        }}
                        caret={false}
                      >
                        <InstantActionAlert
                          title="Update status"
                          content="Are you sure you want to change the status of document?"
                          wrapperProps={{
                            sx: {
                              py: 0.5,
                              px: 1.75,
                              backgroundColor: "transparent",
                              boxShadow: "none",
                              borderRadius: 0,
                            },
                          }}
                        >
                          <StyledButton
                            sx={{ minHeight: 35, flex: 1 }}
                            onClick={() => updateStatus(doc, 21)}
                            disabled={doc.status === 21}
                          >
                            Approve
                          </StyledButton>
                          <StyledButton
                            variant={doc.status !== 22 ? "outlined" : ""}
                            color="#FF5630"
                            sx={{ minHeight: 35, flex: 1 }}
                            onClick={() => updateStatus(doc, 22)}
                            disabled={doc.status === 22}
                          >
                            Reject
                          </StyledButton>
                        </InstantActionAlert>
                      </PopoverDropdown>
                    ) : (
                      <StatusChip
                        status={doc.statusConfigChoice.configChoice}
                        label={doc.statusConfigChoice.displayName}
                      />
                    )}
                  </td>
                  <td className="rounded-r">
                    {currentUser?.role === "client" ? (
                      doc.statusConfigChoice.configChoice !== "approved" && (
                        <Action
                          handleOnDelete={() => handledelete(doc.id)}
                          handleOnEdit={() => handleedit(doc.id)}
                          permission={{
                            delete: checkPermission(permissionList, "delete document"),
                            edit: checkPermission(permissionList, "edit document"),
                          }}
                        />
                      )
                    ) : (
                      <Action
                        handleOnDelete={() => handledelete(doc.id)}
                        handleOnEdit={() => handleedit(doc.id)}
                        permission={{
                          delete: checkPermission(permissionList, "delete document"),
                          edit: checkPermission(permissionList, "edit document"),
                        }}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </SimpleTable>
      ) : (
        <Typography sx={{ color: theme.palette.grey.cadet, textAlign: "center" }}>No data</Typography>
      )}

      <StyledDialog
        open={!!viewDocument?.length}
        handleclose={() => setViewDocument([])}
        dialogProps={{
          fullWidth: true,
          maxWidth: "lg",
        }}
      >
        <DocViewer documents={viewDocument} />
      </StyledDialog>
    </Box>
  );
};

export default React.memo(DocumentListTable);
