import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import configChocieCategorySagas from "./config-choice-category/saga";
import configChoiceSagas from "./config-choice/saga";
import documentSagas from "./document/saga";
import homeCustomizationSagas from "./home-customization/saga";
import homeSagas from "./home/saga";
import mediaSagas from "./media/saga";
import messageGroupSagas from "./message-group/saga";
import messageSagas from "./message/saga";
import milestoneSagas from "./milestone/saga";
import notificationSagas from "./notification/saga";
import productSagas from "./product/saga";
import roleSagas from "./role/saga";
import sharedSagas from "./shared/saga";
import userHomeCustomizationSagas from "./user-home-customization/saga";
import userHomeMilestoneSagas from "./user-home-milestone/saga";
import userHomeSagas from "./user-home/saga";
import userSagas from "./user/saga";
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    mediaSagas(),
    userSagas(),
    sharedSagas(),
    roleSagas(),
    configChoiceSagas(),
    configChocieCategorySagas(),
    productSagas(),
    milestoneSagas(),
    homeSagas(),
    homeCustomizationSagas(),
    userHomeSagas(),
    userHomeCustomizationSagas(),
    userHomeMilestoneSagas(),
    documentSagas(),
    notificationSagas(),
    messageSagas(),
    messageGroupSagas(),
  ]);
}
