import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  useTheme,
  alpha,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useGetColorByLetter from "hooks/useGetColorByLetter";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { getFileType } from "helpers";
import { IconFiles } from "icons";

const Wrapper = styled(Accordion)(({ theme }) => ({
  width: "100%",
  margin: "0 !important",
  padding: 0,
  boxShadow: "none",
  background: "none",
  "&:before": { display: "none" },
}));

const SenderInfo = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "initial",
  width: "100%",
  display: "flex",
  position: "relative",
  gap: 16,
  padding: 0,

  "& .MuiAccordionSummary-content": { gap: 16, margin: 0, "&.Mui-expanded": { margin: 0 } },
  "&.Mui-expanded": { minHeight: "initial" },
}));

const SenderContent = styled(AccordionDetails)(({ theme }) => ({
  width: "100%",
  padding: 0,
}));

const UserMessage = (props) => {
  const { message, shouldExpandDefault = true, senderprops, detailprops } = props;

  const theme = useTheme();
  const getLetterColor = useGetColorByLetter();

  const [expandAttachment, setExpandAttachment] = useState(false);

  return (
    <Wrapper defaultExpanded={shouldExpandDefault}>
      <SenderInfo {...senderprops}>
        <Avatar
          src={message?.messageFromUser?.media?.[0]?.url || "abc.jpg"}
          alt={message?.messageFromUser?.name || ""}
          size="small"
          sx={{
            width: 42,
            height: 42,
            bgcolor: getLetterColor(message?.messageFromUser?.name.charAt(0).toUpperCase()),
            "& img": { objectFit: "cover" },
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography component="h5" variant="body1" sx={{ fontWeight: 500 }}>
            {message?.messageFromUser?.name}{" "}
            <Typography
              component="span"
              sx={{ color: theme.palette.grey.cadet, fontWeight: 400 }}
            >{`<${message?.messageFromUser?.email}>`}</Typography>
          </Typography>

          <Typography component="h5" variant="body1" sx={{ color: theme.palette.grey.cadet, wordBreak: "break-all" }}>
            To:{" "}
            {message?.messageTo?.map((reciver, index) => (
              <React.Fragment key={index}>
                {reciver?.messageToUser?.email}
                {message?.messageTo.length - 1 !== index && ", "}
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      </SenderInfo>

      <SenderContent {...detailprops}>
        <Box dangerouslySetInnerHTML={{ __html: message?.description }} />

        {!!message?.media?.length && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              py: 1,
              px: 2,
              mt: 2,
              borderRadius: 1,
              backgroundColor: theme.palette.blue.shadowBlue,
            }}
          >
            <Box
              component="span"
              sx={{ width: "100%", display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
              onClick={() => setExpandAttachment(!expandAttachment)}
            >
              <AttachFileIcon />
              {message.media.length} attachments
              <KeyboardArrowDownRoundedIcon className={expandAttachment ? "rotate-180" : ""} />
            </Box>

            <Box
              sx={{
                width: "100%",
                display: expandAttachment ? "flex" : "none",
                flexWrap: "wrap",
                gap: 1.75,
                mt: 1.625,
                p: 0,
                listStyle: "none",
              }}
              component="div"
            >
              {message.media.map((file, index) => (
                <Box
                  component={Link}
                  to={file.url}
                  target="_blank"
                  // onClick={() => setViewDocument([{ uri: doc?.media?.[0].url }])}
                  key={index}
                  sx={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.blue.yankeesBlue, 0.25),
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {getFileType(file?.fileName || "") === "image" ? (
                    <img src={file.url} className="w-full h-full object-cover" />
                  ) : (
                    <IconFiles filetype={getFileType(file?.fileName || "")} />
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </SenderContent>
    </Wrapper>
  );
};

export default UserMessage;
