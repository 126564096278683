import React from "react";
import { alpha, Chip, lighten, styled } from "@mui/material";
import useGetStatusColor from "hooks/useGetStatusColor";

const StyledChip = styled(Chip, { shouldForwardProp: (prop) => prop !== "color" })(({ theme, color, variant }) => ({
  minWidth: "22px",
  height: "24px",
  gap: "8px",
  padding: "0px 8px",
  border: `1px solid ${color || theme.palette.primary.main}`,
  borderRadius: "6px",
  boxShadow: `${variant === "outlined" ? "0px 3px 10px 0px" : "0"} ${alpha(color || theme.palette.primary.main, 0.24)}`,
  fontSize: "0.75rem",
  fontWeight: 700,
  backgroundColor: variant === "outlined" ? "transparent" : alpha(color || theme.palette.primary.main, 1),
  color: variant === "outlined" ? color || theme.palette.primary.main : lighten(color || theme.palette.primary.main, 1),

  "& .MuiChip-label": { padding: 0, "&:empty": { display: "none" } },
  "& .MuiChip-icon": {
    width: "18px",
    margin: 0,
    color:
      variant === "outlined" ? color || theme.palette.primary.main : lighten(color || theme.palette.primary.main, 1),
  },
}));

const StatusChip = (props) => {
  const { uitype = "default", label, status, ...others } = props;

  const [statusColor, statusIcon] = useGetStatusColor(status);

  return (
    <StyledChip
      icon={uitype === "with icon" || uitype === "only icon" ? statusIcon : <></>}
      label={uitype !== "only icon" && label ? label : ""}
      color={statusColor}
      {...others}
    />
  );
};
export default StatusChip;
