import React from "react";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
// import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

export const MessageNavOptions = [
  {
    icon: MailRoundedIcon,
    name: "All",
    slug: "all",
    count: 0,
    status: 1,
  },
  {
    icon: AllInboxIcon,
    name: "Conversation",
    slug: "inbox",
    count: 0,
    status: 1,
  },
  {
    icon: SendRoundedIcon,
    name: "Sent",
    slug: "sent",
    count: 0,
    status: 26,
  },
  {
    icon: ArticleRoundedIcon,
    name: "Draft",
    slug: "draft",
    count: 0,
    status: 27,
  },
  {
    icon: WatchLaterIcon,
    name: "Scheduled",
    slug: "schedule",
    count: 0,
    status: 28,
  },
  {
    icon: LabelRoundedIcon,
    name: "Important",
    slug: "important",
    count: 0,
    status: 1,
  },
  {
    icon: StarRoundedIcon,
    name: "Stared",
    slug: "stared",
    count: 0,
    status: 1,
  },
];

export const messageSendOptions = [
  { name: "Save as draft", icon: <ArticleRoundedIcon /> },
  { name: "Schedule send", icon: <WatchLaterIcon /> },
];
