import React from "react";
import { Box } from "@mui/material";
import { BreadcrumbContainer } from "ui";
import HomeCustomizationView from "shared/modules/home-customization";
import { useDispatch, useSelector } from "react-redux";
import { addHomeCustomization, deleteHomeCustomization, getAllHomeCustomization } from "reduxs/actions";
import { useParams } from "react-router-dom";

const HomeCustomization = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { homeCustomizations, loading: actionLoading } = useSelector((state) => state.homeCustomization);

  const onSelect = (productId, parentproductId) => {
    dispatch(addHomeCustomization({ homeId: params.id, parentProductId: parentproductId, productId: productId }));
  };

  const onDelete = (id) => {
    if (id) dispatch(deleteHomeCustomization(id, params.id));
  };

  React.useEffect(() => {
    if (params.id) {
      // Fetch all customizations respective to home
      dispatch(getAllHomeCustomization({ homeId: params.id }));
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Customize Home"
          paths={[
            {
              title: "Home",
              page: `/configuration/home`,
            },
            {
              title: "Customize",
            },
          ]}
        />
      </Box>

      <HomeCustomizationView
        homeid={params.id}
        selectionlist={homeCustomizations}
        selectionloading={actionLoading}
        onselect={onSelect}
        onremove={onDelete}
      />
    </>
  );
};

export default HomeCustomization;
