// import { alpha } from "@mui/material";

export default (theme, style) => ({
  "&.MuiPaper-root": {
    marginTop: 1.5,
    backgroundColor: theme.palette.layout.main,
    boxShadow:
      theme.palette.mode === "light"
        ? "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px"
        : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
    borderRadius: "0.75rem",

    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      borderTop: "1px solid rgba(145, 158, 171, 0.12)",
      borderLeft: "1px solid rgba(145, 158, 171, 0.12)",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },

  "& .MuiDateCalendar-root": {
    "& .MuiDayCalendar-header": {
      "& .MuiDayCalendar-weekDayLabel": {
        width: 46,
        fontWeight: "400",
        lineHeight: "2.667em",
        color: theme.palette.grey.cadet,
      },
    },

    "& .MuiDayCalendar-weekContainer": {
      "& .MuiPickersDay-root": {
        width: 46,
        height: 30,
        borderRadius: 30,
        border: "1px solid transparent",
        lineHeight: "1",

        "&:not(.Mui-selected)": {
          color: theme.palette.text.primary,
        },

        "&:hover": {
          borderColor: theme.palette.primary.main,
        },

        "&.MuiPickersDay-today": {
          border: 0,

          "&:after": {
            width: 18,
            height: 2,
            content: '""',
            position: "absolute",
            bottom: 4,
            left: "50%",
            backgroundColor: theme.palette.info.main,
            borderRadius: 2,
            transform: "translateX(-50%)",
          },
        },

        "&.Mui-disabled": {
          color: theme.palette.grey.brightGray,
        },
      },
    },
  },

  "& .MuiMultiSectionDigitalClock-root": {
    width: 320,
    justifyContent: "center",
    border: 0,
    padding: "18px 0",

    "& .MuiList-root": {
      "& .MuiMenuItem-root": {
        height: 30,
        borderRadius: 30,
        border: "1px solid transparent",
        lineHeight: "1",

        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
});
