import {
  GET_ALL_USER_HOME_CUSTOMIZATION,
  GET_ALL_USER_HOME_CUSTOMIZATION_SUCCESS,
  GET_ALL_USER_HOME_CUSTOMIZATION_ERROR,
  ADD_USER_HOME_CUSTOMIZATION,
  ADD_USER_HOME_CUSTOMIZATION_SUCCESS,
  ADD_USER_HOME_CUSTOMIZATION_ERROR,
  DELETE_USER_HOME_CUSTOMIZATION,
  DELETE_USER_HOME_CUSTOMIZATION_SUCCESS,
  DELETE_USER_HOME_CUSTOMIZATION_ERROR,
  RESET_USER_HOME_CUSTOMIZATION,
} from "reduxs/actions";

const INIT_STATE = {
  filterParam: null,
  userHomeCustomizations: null,
  userHomeCustomizationData: null,
  userHomeCustomizationId: null,
  userHomeId: null,
  success: false,
  loading: false,
  delLoading: false,
  error: null,
};

const userHomeCustomizationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USER_HOME_CUSTOMIZATION:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_USER_HOME_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        userHomeCustomizations: action.payload,
        error: null,
      };
    case GET_ALL_USER_HOME_CUSTOMIZATION_ERROR:
      return {
        ...state,
        userHomeCustomizations: null,
        error: action.payload,
      };
    case ADD_USER_HOME_CUSTOMIZATION:
      return { ...state, loading: true, error: null };
    case ADD_USER_HOME_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case ADD_USER_HOME_CUSTOMIZATION_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_USER_HOME_CUSTOMIZATION:
      return { ...state, delLoading: true, error: null };
    case DELETE_USER_HOME_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_USER_HOME_CUSTOMIZATION_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_USER_HOME_CUSTOMIZATION:
      return {
        ...state,
        loading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default userHomeCustomizationReducer;
