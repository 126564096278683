import React, { useEffect } from "react";
import { alpha, Dialog, DialogActions, DialogContent, styled, Typography, useTheme } from "@mui/material";
import StyledButton from "../button/Button";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "1rem",
    boxShadow:
      theme.palette.mode === "light"
        ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
        : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
  },

  "& .MuiBackdrop-root": {
    backgroundColor: alpha(theme.palette.backdrop, 0.8),
  },
}));

const AlertDialog = (props) => {
  const { open, handleCancel, handleAction, title, info, loadingInfo, actionLabel, loading, success, reset } = props;

  const theme = useTheme();

  const onClose = (event, reason) => {
    if (reason === "backdropClick") {
      // Dont Close PopUp on Backdrop Click
      return false;
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    if (success) {
      onClose();
      reset();
    }
  }, [success]);

  return (
    <StyledDialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={"xs"}
      keepMounted
    >
      <Typography component="h2" variant="h2" sx={{ p: "1.5rem 1.5rem 1rem", fontSize: "1.125rem", fontWeight: 700 }}>
        {title || ""}
      </Typography>
      <DialogContent sx={{ p: "0 1.5rem" }}>
        <Typography component="p">{loading ? loadingInfo : info}</Typography>
      </DialogContent>

      <DialogActions sx={{ p: "1.5rem" }}>
        <StyledButton
          isloading={loading}
          color={theme.palette.error.main}
          onClick={handleAction}
          sx={{ minHeight: "initial", mr: 1 }}
        >
          {actionLabel}
        </StyledButton>

        <StyledButton
          color={theme.palette.grey.main}
          variant="outlined"
          onClick={handleCancel}
          sx={{ minHeight: "initial", color: theme.palette.text.primary }}
        >
          Cancel
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default AlertDialog;
