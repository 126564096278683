import React from "react";
import { Box, Typography } from "@mui/material";

const Detail = (props) => {
  const { data, ...others } = props;

  return (
    <Box {...others} className="px-5 py-3 -mt-2 rounded-b-md">
      <Box className="grid grid-cols-4 gap-4">
        <Typography component="h4" className="text-sm font-semibold">
          Price
          <Typography component="small" className="block mt-1 font-normal">
            {data?.price ? `$ ${data.price}` : "-"}
          </Typography>
        </Typography>

        <Typography component="h4" className="text-sm font-semibold">
          Manufacturer
          <Typography component="small" className="block mt-1 font-normal">
            {data?.manufacturer || "-"}
          </Typography>
        </Typography>

        <Typography component="h4" className="text-sm font-semibold">
          Collection
          <Typography component="small" className="block mt-1 font-normal">
            {data?.collection || "-"}
          </Typography>
        </Typography>

        <Typography component="h4" className="text-sm font-semibold">
          Type
          <Typography component="small" className="block mt-1 font-normal">
            {data?.typeConfigChoice?.displayName || "-"}
          </Typography>
        </Typography>
      </Box>

      <Typography component="h4" className="text-sm font-semibold mt-4">
        Description
      </Typography>
      <Typography className="block mt-1 font-normal">{data?.description || "-"}</Typography>
    </Box>
  );
};

export default Detail;
