/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { Suspense } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import store from "reduxs/store";
import ErrorBoundary from "views/ErrorBoundry";
import { LayoutSplashScreen } from "configs/LayoutSplashScreen";
import Root from "./Root";

import { ColorModeContext } from "ColorModeContext";
import useThemeSwitcher from "hooks/useThemeSwitcher";

const App = () => {
  const { newTheme, themeMode } = useThemeSwitcher();
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <ErrorBoundary>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <Suspense fallback={<LayoutSplashScreen />}>
          {/* Render routes with provided `Layout`. */}

          <StyledEngineProvider injectFirst>
            <ColorModeContext.Provider value={themeMode}>
              <ThemeProvider theme={newTheme}>
                <CssBaseline />
                <Root />
              </ThemeProvider>
            </ColorModeContext.Provider>
          </StyledEngineProvider>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
