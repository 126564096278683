import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_USER_HOME_CUSTOMIZATION,
  ADD_USER_HOME_CUSTOMIZATION,
  DELETE_USER_HOME_CUSTOMIZATION,
} from "../actions";
import {
  getAllUserHomeCustomization,
  getAllUserHomeCustomizationSuccess,
  getAllUserHomeCustomizationError,
  addUserHomeCustomizationSuccess,
  addUserHomeCustomizationError,
  deleteUserHomeCustomizationSuccess,
  deleteUserHomeCustomizationError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import UserHomeCustomizationService from "services/UserHomeCustomizationService";

export function* watchgetAllUserHomeCustomization() {
  yield takeEvery(GET_ALL_USER_HOME_CUSTOMIZATION, getAllUserHomeCustomizationAc);
}

const getAllUserHomeCustomizationAsync = async (data) => {
  return UserHomeCustomizationService.getAllUserHomeCustomization(data);
};

function* getAllUserHomeCustomizationAc({ payload }) {
  try {
    const response = yield call(getAllUserHomeCustomizationAsync, payload.filterParam);
    if (response.data) {
      yield put(getAllUserHomeCustomizationSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllUserHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllUserHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddUserHomeCustomization() {
  yield takeEvery(ADD_USER_HOME_CUSTOMIZATION, addUserHomeCustomization);
}

const addUserHomeCustomizationAsync = async (data) => {
  return UserHomeCustomizationService.addUserHomeCustomization(data);
};

function* addUserHomeCustomization({ payload }) {
  try {
    const response = yield call(addUserHomeCustomizationAsync, payload.userHomeCustomizationData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addUserHomeCustomizationSuccess(response.data.success));
      yield put(
        getAllUserHomeCustomization({
          userHomeId: payload.userHomeCustomizationData.userHomeId,
        })
      );
    } else {
      toaster("", response.data.message);
      yield put(addUserHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addUserHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteUserHomeCustomization() {
  yield takeEvery(DELETE_USER_HOME_CUSTOMIZATION, deleteUserHomeCustomization);
}

const deleteUserHomeCustomizationAsync = async (id) => {
  return UserHomeCustomizationService.deleteUserHomeCustomization(id);
};

function* deleteUserHomeCustomization({ payload }) {
  try {
    const response = yield call(deleteUserHomeCustomizationAsync, payload.userHomeCustomizationId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteUserHomeCustomizationSuccess(response.data.success));
      yield put(getAllUserHomeCustomization({ userHomeId: payload.userHomeId }));
    } else {
      toaster("", response.data.message);
      yield put(deleteUserHomeCustomizationError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteUserHomeCustomizationError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllUserHomeCustomization),
    fork(watchAddUserHomeCustomization),
    fork(watchDeleteUserHomeCustomization),
  ]);
}
