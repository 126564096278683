import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProductList, getProduct, getLayoutState } from "reduxs/actions";
import Tree from "./Tree";
import Customization from "./Customization";
import SelectionSummary from "./SelectionSummary";

const HomeCustomizationView = (props) => {
  const { homeid, selectionlist, selectionloading, onselect, onremove } = props;

  const dispatch = useDispatch();

  const [selectedProductId, setSelectedProductId] = useState(null);
  const [defaultExpanded, setDefaultExpanded] = useState([]);

  const { productData, fetchLoading: fetchProductLoading, productList } = useSelector((state) => state.product);

  const getDefaultChildNode = (allItem, parentId = null, arr = []) => {
    const item = allItem[0];
    if (item?.childrenProduct?.length > 0) {
      // Check until last level childrenProduct
      arr.push(item.id);
      getDefaultChildNode(item.childrenProduct, item.id, arr);
    } else {
      // If last level childrenProduct
      if (parentId && item?.parent?.id === parentId) {
        setSelectedProductId(parentId);
        setDefaultExpanded(arr);
      }
    }
  };

  useEffect(() => {
    // Fetch nested categories for tree view
    dispatch(getProductList());
  }, []);

  useEffect(() => {
    // Set default Product that is parent of last level childrenProduct
    if (productList?.length > 0) getDefaultChildNode(productList);
  }, [productList]);

  useEffect(() => {
    // Fetch childrenProduct of selected parent Product
    if (selectedProductId) dispatch(getProduct(selectedProductId));
  }, [selectedProductId]);

  useEffect(() => {
    // Modify Layout for better UX
    dispatch(getLayoutState(true));
    return () => {
      dispatch(getLayoutState(false));
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: { xs: "column", lg: "row" },
          gap: { lg: "12px", xl: "24px" },
        }}
      >
        <Tree
          productlist={productList}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
          defaultExpanded={defaultExpanded}
        />

        <Customization
          fetchLoading={fetchProductLoading}
          actionLoading={selectionloading}
          data={productData}
          selectedProductId={selectedProductId}
          allSelected={selectionlist}
          onSelect={onselect}
          onDelete={onremove}
        />

        <SelectionSummary homeid={homeid} productlist={productList} selectionList={selectionlist} />
      </Box>
    </>
  );
};

export default React.memo(HomeCustomizationView);
