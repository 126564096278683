import React from "react";
import { Box, List, ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { StyledAccordion } from "ui";

const SelectionItemList = (props) => {
  const theme = useTheme();

  const { productlist, selectionList } = props;

  const [selectedParents, setSelectedParents] = React.useState([]);

  const getSelected = (parentId, products) => {
    const isSelected = selectionList?.find((v) => v?.productId === products.id);

    // Check set root parentId as active if any of its child is set as selected
    if (isSelected && !selectedParents.includes(parentId)) {
      setSelectedParents((old) => [...old, parentId]);
    }

    return (
      <React.Fragment key={products.id}>
        {isSelected ? (
          <ListItem
            className="p-0 py-1"
            secondaryAction={`$${products.price}`}
            sx={{ "& .MuiListItemSecondaryAction-root": { right: 0, fontWeight: 600 } }}
          >
            <ListItemText
              primary={products.name}
              secondary={products.parent.name}
              className="m-0"
              primaryTypographyProps={{ sx: { fontSize: "14px", fontWeight: 700 } }}
              secondaryTypographyProps={{
                sx: { color: theme.palette.grey.dark, fontSize: "14px" },
              }}
            />
          </ListItem>
        ) : (
          products.childrenProduct.map((child) => {
            return getSelected(parentId, child);
          })
        )}
      </React.Fragment>
    );
  };

  return (
    <Box className="flex flex-col gap-5">
      {productlist?.length &&
        productlist.map((parent) => {
          return (
            <React.Fragment key={parent.id}>
              <StyledAccordion
                label={
                  <Box component="h5" className="flex-1 flex item-center justify-between m-0">
                    <Typography component="span" sx={{ color: theme.palette.info.main }} className="font-semibold">
                      {parent.name}
                    </Typography>
                    <Typography component="span" className="text-sm font-semibold">
                      {/* ${parent.selectedItems.reduce((next, current) => next.price + current.price, 0)} */}
                    </Typography>
                  </Box>
                }
                labelprops={{
                  expandIcon: <ExpandCircleDownOutlinedIcon />,
                  sx: { p: 0, flexDirection: "row-reverse", gap: "12px" },
                }}
                contentprops={{ sx: { padding: "0 0 0 36px" } }}
                wrapperprops={{
                  defaultExpanded: true,
                  sx: {
                    display: !selectedParents.includes(parent.id) ? "none" : "", // Show only parents with selected childs
                    boxShadow: "none",
                  },
                }}
              >
                <List dense={true} className="p-0">
                  {parent.childrenProduct.map((child) => {
                    return getSelected(parent.id, child);
                  })}
                </List>
              </StyledAccordion>
            </React.Fragment>
          );
        })}
    </Box>
  );
};

export default React.memo(SelectionItemList);
