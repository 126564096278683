import React from "react";
import { Box, MenuItem, IconButton, styled, useTheme, alpha } from "@mui/material";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PopoverDropdown } from "ui";

const ListTable = styled("table")(({ theme }) => ({
  width: "100%",
  borderCollapse: "separate",
  borderSpacing: "0 8px",

  "th,td": {
    padding: "0 14px",
    textAlign: "left",
  },
  td: {
    paddingTop: "12px",
    paddingBottom: "12px",
    backgroundColor: alpha(theme.palette.grey.cadet, 0.16),
    fontSize: "16px",
  },
}));

const SelectionTable = (props) => {
  const theme = useTheme();
  const { options } = props;

  return (
    <Box width="100%" overflow="auto">
      <ListTable>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th className="text-right">Total Cost</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {options.map((option, i) => {
            return (
              <tr key={i}>
                <td className="rounded-l">
                  <Box display="flex" gap="15px" alignItems="center">
                    {option.image ? (
                      <img className="w-[38px] h-[38px] rounded object-cover" src="" alt="" />
                    ) : (
                      <WallpaperIcon sx={{ width: "38px", height: "38px" }} />
                    )}
                    {option?.category?.name}
                  </Box>
                </td>
                <td>
                  {option.name}
                  <span className="block text-gray-400">{option?.category?.manufacturer}</span>
                </td>
                <td className="text-right">{option.total}</td>
                <td className="rounded-r">
                  <PopoverDropdown dropdownWidth="150px" triggertype={IconButton} trigger={<MoreHorizIcon />}>
                    <MenuItem sx={{ color: theme.palette.error.main }}>
                      <DeleteOutlineOutlinedIcon />
                      Delete
                    </MenuItem>
                  </PopoverDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </ListTable>
    </Box>
  );
};

export default SelectionTable;
