import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import StyledButton from "./Button";
import PopoverDropdown from "ui/popover-dropdown/PopoverDropdown";
import { MenuItem } from "@mui/material";

const AdvButton = (props) => {
  const { children, options, isloading, styleButtonProps, handleClickOptions, wrapperProps } = props;

  return (
    <>
      <ButtonGroup
        variant="contained"
        disableElevation={true}
        sx={{ "& .MuiButtonGroup-grouped": { boxShadow: "inherit" } }}
        disabled={isloading}
        {...wrapperProps}
      >
        <StyledButton {...styleButtonProps} isloading={isloading}>
          {children}
        </StyledButton>

        <PopoverDropdown
          triggertype={StyledButton}
          trigger={<ArrowDropDownIcon />}
          triggerprops={{
            size: "sm",
            sx: { flexShrink: 0, padding: ".375rem 0.525rem" },
          }}
        >
          {options?.map((action, index) => (
            <MenuItem key={index} onClick={() => handleClickOptions(action)}>
              {action?.icon}
              {action.name}
            </MenuItem>
          ))}
        </PopoverDropdown>
      </ButtonGroup>
    </>
  );
};

export default AdvButton;
