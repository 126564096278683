import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_MESSAGE_LIST,
  GET_MESSAGE,
  COMPOSE_MESSAGE,
  EDIT_MESSAGE,
  UPDATE_MESSAGE_STATUS,
  DELETE_MESSAGE,
} from "../actions";
import {
  getMessageListSuccess,
  getMessageListError,
  getMessage,
  getMessageSuccess,
  getMessageError,
  composeMessageSuccess,
  composeMessageError,
  editMessageSuccess,
  editMessageError,
  updateMessageStatusSuccess,
  updateMessageStatusError,
  deleteMessageSuccess,
  deleteMessageError,
} from "./action";
import { getMessageList } from "reduxs/actions";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import MessageService from "services/MessageService";

const activeFilter = (userId, activeMenu) => {
  let getParams = null;

  switch (activeMenu) {
    case "inbox":
      getParams = {
        messageTo: userId,
        status: 26,
      }; // Get's inbox Messages
      break;
    case "sent":
      getParams = {
        messageFrom: userId,
        status: 26,
      }; // Get's Sent Messages
      break;
    case "draft":
      getParams = {
        messageFrom: userId,
        status: 27,
      }; // Get's Draft Messages
      break;
    case "schedule":
      getParams = {
        messageFrom: userId,
        status: 28,
      }; // Get's scheduled Messages
      break;
    case "important":
      getParams = {
        messageTo: userId,
        isImportant: 1,
      }; // Get's Important Messages
      break;
    case "stared":
      getParams = {
        messageTo: userId,
        isStarred: 1,
      }; // Get's Stared Messages
      break;

    default:
      getParams = {
        messageFrom: userId, // Get's Sent
        messageTo: userId, // Get's Inbox Messages
      };
      break;
  }

  return getParams;
};

export function* watchGetMessageList() {
  yield takeEvery(GET_MESSAGE_LIST, getMessageListAc);
}

const getMessageListAsync = async (dbParam) => {
  return MessageService.getMessageList(dbParam);
};

function* getMessageListAc({ payload }) {
  try {
    const response = yield call(getMessageListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(getMessageListSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getMessageListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getMessageListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetMessage() {
  yield takeEvery(GET_MESSAGE, getMessageAc);
}

const getMessageAsync = async (id) => {
  return MessageService.getMessage(id);
};

function* getMessageAc({ payload }) {
  try {
    const response = yield call(getMessageAsync, payload.messageId);
    if (response.data.success) {
      yield put(getMessageSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getMessageError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getMessageError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchComposeMessage() {
  yield takeEvery(COMPOSE_MESSAGE, composeMessage);
}

const composeMessageAsync = async (data) => {
  return MessageService.composeMessage(data);
};

function* composeMessage({ payload }) {
  try {
    const response = yield call(composeMessageAsync, payload.messageData);
    const filters = payload.filters;

    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(composeMessageSuccess(response.data.success));

      yield put(getMessageList(activeFilter(filters.userId, filters.currentMenu))); //## Fetch message List

      if (!!payload.messageData.parent) yield put(getMessage(payload.messageData.parent));
    } else {
      toaster("", response.data.message);
      yield put(composeMessageError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(composeMessageError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditMessage() {
  yield takeEvery(EDIT_MESSAGE, editMessage);
}

const editMessageAsync = async (data, id) => {
  return MessageService.editMessage(data, id);
};

function* editMessage({ payload }) {
  try {
    const response = yield call(editMessageAsync, payload.messageData, payload.id);
    const filters = payload.filters;

    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editMessageSuccess(response.data.success));

      yield put(getMessageList(activeFilter(filters.userId, filters.currentMenu))); //## Fetch message List

      if (!!payload.messageData.parent) yield put(getMessage(payload.messageData.parent));
    } else {
      toaster("", response.data.message);
      yield put(editMessageError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editMessageError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchUpdateMessageStatus() {
  yield takeEvery(UPDATE_MESSAGE_STATUS, updateMessageStatus);
}

const updateMessageStatusAsync = async (id, data) => {
  return MessageService.updateMessageStatus(id, data);
};

function* updateMessageStatus({ payload }) {
  try {
    const response = yield call(updateMessageStatusAsync, payload.messageId, payload.updateMessageStatusData);
    // const filters = payload.filters;

    if (response.data.success) {
      yield put(updateMessageStatusSuccess(response.data.success));

      // yield put(getMessageList(activeFilter(filters.userId, filters.currentMenu))); //## Fetch message List

      yield put(getMessage(payload.messageId)); //## Fetch message data
    } else {
      yield put(updateMessageStatusError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(updateMessageStatusError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteMessage() {
  yield takeEvery(DELETE_MESSAGE, deleteMessage);
}

const deleteMessageAsync = async (id) => {
  return MessageService.deleteMessage(id);
};

function* deleteMessage({ payload }) {
  try {
    const response = yield call(deleteMessageAsync, payload.messageId);
    const filters = payload.filters;

    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteMessageSuccess(response.data.success));

      yield put(getMessageList(activeFilter(filters.userId, filters.currentMenu)));
    } else {
      toaster("", response.data.message);
      yield put(deleteMessageError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteMessageError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMessageList),
    fork(watchGetMessage),
    fork(watchComposeMessage),
    fork(watchEditMessage),
    fork(watchUpdateMessageStatus),
    fork(watchDeleteMessage),
  ]);
}
