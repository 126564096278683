export const GET_ALL_MESSAGE_GROUP = "GET_ALL_MESSAGE_GROUP";
export const GET_ALL_MESSAGE_GROUP_SUCCESS = "GET_ALL_MESSAGE_GROUP_SUCCESS";
export const GET_ALL_MESSAGE_GROUP_ERROR = "GET_ALL_MESSAGE_GROUP_ERROR";
export const GET_MESSAGE_GROUP_LIST = "GET_MESSAGE_GROUP_LIST";
export const GET_MESSAGE_GROUP_LIST_SUCCESS = "GET_MESSAGE_GROUP_LIST_SUCCESS";
export const GET_MESSAGE_GROUP_LIST_ERROR = "GET_MESSAGE_GROUP_LIST_ERROR";
export const ADD_MESSAGE_GROUP = "ADD_MESSAGE_GROUP";
export const ADD_MESSAGE_GROUP_SUCCESS = "ADD_MESSAGE_GROUP_SUCCESS";
export const ADD_MESSAGE_GROUP_ERROR = "ADD_MESSAGE_GROUP_ERROR";
export const GET_MESSAGE_GROUP = "GET_MESSAGE_GROUP";
export const GET_MESSAGE_GROUP_SUCCESS = "GET_MESSAGE_GROUP_SUCCESS";
export const GET_MESSAGE_GROUP_ERROR = "GET_MESSAGE_GROUP_ERROR";
export const EDIT_MESSAGE_GROUP = "EDIT_MESSAGE_GROUP";
export const EDIT_MESSAGE_GROUP_SUCCESS = "EDIT_MESSAGE_GROUP_SUCCESS";
export const EDIT_MESSAGE_GROUP_ERROR = "EDIT_MESSAGE_GROUP_ERROR";
export const DELETE_MESSAGE_GROUP = "DELETE_MESSAGE_GROUP";
export const DELETE_MESSAGE_GROUP_SUCCESS = "DELETE_MESSAGE_GROUP_SUCCESS";
export const DELETE_MESSAGE_GROUP_ERROR = "DELETE_MESSAGE_GROUP_ERROR";
export const RESET_MESSAGE_GROUP = "RESET_MESSAGE_GROUP";

export const getAllGroupMessage = (dbParam) => ({
  type: GET_ALL_MESSAGE_GROUP,
  payload: { dbParam },
});

export const getAllMessageGroupSuccess = (users) => ({
  type: GET_ALL_MESSAGE_GROUP_SUCCESS,
  payload: users,
});

export const getAllMessageGroupError = (error) => ({
  type: GET_ALL_MESSAGE_GROUP_ERROR,
  payload: error,
});

export const getMessageGroupList = (dbParam) => ({
  type: GET_MESSAGE_GROUP_LIST,
  payload: { dbParam },
});

export const getMessageGroupListSuccess = (messageGroupList, metaData) => ({
  type: GET_MESSAGE_GROUP_LIST_SUCCESS,
  payload: { messageGroupList, metaData },
});

export const getMessageGroupListError = (error) => ({
  type: GET_MESSAGE_GROUP_LIST_ERROR,
  payload: error,
});

export const addMessageGroup = (messageGroupData, navigate) => ({
  type: ADD_MESSAGE_GROUP,
  payload: { messageGroupData, navigate },
});

export const addMessageGroupSuccess = (success) => ({
  type: ADD_MESSAGE_GROUP_SUCCESS,
  payload: success,
});

export const addMessageGroupError = (error) => ({
  type: ADD_MESSAGE_GROUP_ERROR,
  payload: error,
});

export const getMessageGroup = (messageGroupId) => ({
  type: GET_MESSAGE_GROUP,
  payload: { messageGroupId },
});

export const getMessageGroupSuccess = (messageGroupData) => ({
  type: GET_MESSAGE_GROUP_SUCCESS,
  payload: messageGroupData,
});

export const getMessageGroupError = (error) => ({
  type: GET_MESSAGE_GROUP_ERROR,
  payload: error,
});

export const editMessageGroup = (messageGroupId, messageGroupData, navigate) => ({
  type: EDIT_MESSAGE_GROUP,
  payload: { messageGroupId, messageGroupData, navigate },
});

export const editMessageGroupSuccess = (success) => ({
  type: EDIT_MESSAGE_GROUP_SUCCESS,
  payload: success,
});

export const editMessageGroupError = (error) => ({
  type: EDIT_MESSAGE_GROUP_ERROR,
  payload: error,
});

export const deleteMessageGroup = (messageGroupId) => ({
  type: DELETE_MESSAGE_GROUP,
  payload: { messageGroupId },
});

export const deleteMessageGroupSuccess = (success) => ({
  type: DELETE_MESSAGE_GROUP_SUCCESS,
  payload: success,
});

export const deleteMessageGroupError = (error) => ({
  type: DELETE_MESSAGE_GROUP_ERROR,
  payload: error,
});

export const resetMessageGroup = () => ({
  type: RESET_MESSAGE_GROUP,
  payload: {},
});
