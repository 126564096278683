import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTheme, Box, Container, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useWidth from "hooks/useWidth";
import { getPermission } from "reduxs/actions";
import { appBarConfig, sideNavConfig } from "configs/LayoutConfig";
import Sidenav from "./sidenav";
import StyledAppBar from "./appbar";

const Layout = () => {
  const theme = useTheme();
  const width = useWidth();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { permissionList, isCustomizationView } = useSelector((state) => state.shared);

  const [open, setOpen] = React.useState(useMediaQuery(theme.breakpoints.down(sideNavConfig.collapseStateBelow)));

  const handleDrawerState = () => {
    setOpen(!open);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (width !== sideNavConfig.collapseStateBelow || isCustomizationView) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [width, isCustomizationView]);

  useEffect(() => {
    if (user) dispatch(getPermission());
  }, [user]);

  return (
    <Box display="flex">
      <Sidenav
        permissionList={permissionList}
        drawerOpen={isCustomizationView && width >= sideNavConfig.collapseStateBelow ? false : open}
        handleOnCloseDrawer={handleCloseDrawer}
        handleDrawerCollapse={handleDrawerState}
      />

      <Box
        component="main"
        sx={{
          maxWidth: {
            xl: open
              ? `calc(100% - ${sideNavConfig.drawerWidth.large}px)`
              : `calc(100% - ${sideNavConfig.drawerWidth.small}px)`,
          },
          minHeight: "100vh",
          width: "100%",
          flex: 1,
          ml: "auto",
          pt: appBarConfig.style === "fixed" ? `${appBarConfig.height}px` : "",
        }}
      >
        <StyledAppBar handleDrawerCollapse={handleDrawerState} />

        <Container maxWidth={isCustomizationView ? false : "xl"} sx={{ pt: 4, pb: 8 }}>
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
