export const GET_USER_HOME = "GET_USER_HOME";
export const GET_USER_HOME_SUCCESS = "GET_USER_HOME_SUCCESS";
export const GET_USER_HOME_ERROR = "GET_USER_HOME_ERROR";
export const ADD_USER_HOME = "ADD_USER_HOME";
export const ADD_USER_HOME_SUCCESS = "ADD_USER_HOME_SUCCESS";
export const ADD_USER_HOME_ERROR = "ADD_USER_HOME_ERROR";
export const EDIT_USER_HOME = "EDIT_USER_HOME";
export const EDIT_USER_HOME_SUCCESS = "EDIT_USER_HOME_SUCCESS";
export const EDIT_USER_HOME_ERROR = "EDIT_USER_HOME_ERROR";
export const DELETE_USER_HOME = "DELETE_USER_HOME";
export const DELETE_USER_HOME_SUCCESS = "DELETE_USER_HOME_SUCCESS";
export const DELETE_USER_HOME_ERROR = "DELETE_USER_HOME_ERROR";
export const RESET_USER_HOME = "RESET_USER_HOME";

export const getUserHome = (homeId) => ({
  type: GET_USER_HOME,
  payload: { homeId },
});

export const getUserHomeSuccess = (userHomeData) => ({
  type: GET_USER_HOME_SUCCESS,
  payload: userHomeData,
});

export const getUserHomeError = (error) => ({
  type: GET_USER_HOME_ERROR,
  payload: error,
});

export const addUserHome = (userHomeData) => ({
  type: ADD_USER_HOME,
  payload: { userHomeData },
});

export const addUserHomeSuccess = (success) => ({
  type: ADD_USER_HOME_SUCCESS,
  payload: success,
});

export const addUserHomeError = (error) => ({
  type: ADD_USER_HOME_ERROR,
  payload: error,
});

export const editUserHome = (homeId, userHomeData) => ({
  type: EDIT_USER_HOME,
  payload: { homeId, userHomeData },
});

export const editUserHomeSuccess = (success) => ({
  type: EDIT_USER_HOME_SUCCESS,
  payload: success,
});

export const editUserHomeError = (error) => ({
  type: EDIT_USER_HOME_ERROR,
  payload: error,
});

export const deleteUserHome = (userId, userHomeId) => ({
  type: DELETE_USER_HOME,
  payload: { userId, userHomeId },
});

export const deleteUserHomeSuccess = (success) => ({
  type: DELETE_USER_HOME_SUCCESS,
  payload: success,
});

export const deleteUserHomeError = (error) => ({
  type: DELETE_USER_HOME_ERROR,
  payload: error,
});

export const resetUserHome = () => ({
  type: RESET_USER_HOME,
  payload: {},
});
