import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_USER_HOME_MILESTONE, EDIT_USER_HOME_MILESTONE, DELETE_USER_HOME_MILESTONE } from "../actions";
import {
  getAllUserHomeMilestoneSuccess,
  getAllUserHomeMilestoneError,
  getUserHomeMilestoneList,
  getUserHomeMilestoneListSuccess,
  getUserHomeMilestoneListError,
  editUserHomeMilestoneSuccess,
  editUserHomeMilestoneError,
  deleteUserHomeMilestoneSuccess,
  deleteUserHomeMilestoneError,
  GET_USER_HOME_MILESTONE_LIST,
  getAllUserHomeMilestone,
  getSelectedUserHomeMilestoneListSuccess,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import UserHomeMilestoneService from "services/UserHomeMilestoneService";

export function* watchgetAllUserHomeMilestone() {
  yield takeEvery(GET_ALL_USER_HOME_MILESTONE, getAllUserHomeMilestoneAc);
}

const getAllUserHomeMilestoneAsync = async (filterParam) => {
  return UserHomeMilestoneService.getAllUserHomeMilestone(filterParam);
};

function* getAllUserHomeMilestoneAc({ payload }) {
  try {
    const response = yield call(getAllUserHomeMilestoneAsync, payload.filterParam);
    if (response.data) {
      yield put(getAllUserHomeMilestoneSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllUserHomeMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllUserHomeMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchgetUserHomeMilestoneList() {
  yield takeEvery(GET_USER_HOME_MILESTONE_LIST, getUserHomeMilestoneListAc);
}

const getUserHomeMilestoneListAsync = async (dbParam) => {
  return UserHomeMilestoneService.getUserHomeMilestoneList(dbParam);
};

function* getUserHomeMilestoneListAc({ payload }) {
  try {
    const response = yield call(getUserHomeMilestoneListAsync, payload.dbParam);
    if (response.data.success) {
      if (payload.dbParam.homeFilter) {
        yield put(getSelectedUserHomeMilestoneListSuccess(response.data.data));
      } else {
        yield put(getUserHomeMilestoneListSuccess(response.data.data));
      }
    } else {
      toaster("", response.data.message);
      yield put(getUserHomeMilestoneListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getUserHomeMilestoneListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditUserHomeMilestone() {
  yield takeEvery(EDIT_USER_HOME_MILESTONE, editUserHomeMilestone);
}

const editUserHomeMilestoneAsync = async (data, id) => {
  return UserHomeMilestoneService.editUserHomeMilestone(data, id);
};

function* editUserHomeMilestone({ payload }) {
  try {
    const response = yield call(editUserHomeMilestoneAsync, payload.userHomeMilestoneData, payload.userHomeMilestoneId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editUserHomeMilestoneSuccess(response.data.success));

      yield put(getUserHomeMilestoneList(payload.dbParam));
      yield put(
        getAllUserHomeMilestone({
          userHomeId: payload.dbParam.userHomeId,
        })
      );
    } else {
      toaster("", response.data.message);
      yield put(editUserHomeMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editUserHomeMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteUserHomeMilestone() {
  yield takeEvery(DELETE_USER_HOME_MILESTONE, deleteUserHomeMilestone);
}

const deleteUserHomeMilestoneAsync = async (id) => {
  return UserHomeMilestoneService.deleteUserHomeMilestone(id);
};

function* deleteUserHomeMilestone({ payload }) {
  try {
    const response = yield call(deleteUserHomeMilestoneAsync, payload.userHomeMilestoneId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteUserHomeMilestoneSuccess(response.data.success));
      yield put(getUserHomeMilestoneList({ userHomeId: payload.userHomeId }));
    } else {
      toaster("", response.data.message);
      yield put(deleteUserHomeMilestoneError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteUserHomeMilestoneError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllUserHomeMilestone),
    fork(watchgetUserHomeMilestoneList),
    fork(watchEditUserHomeMilestone),
    fork(watchDeleteUserHomeMilestone),
  ]);
}
