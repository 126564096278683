import React from "react";
import { Dialog, DialogContent, IconButton, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalStyles from "styles/Modal.style";

const StyledDialog = (props) => {
  const { open, handleclose, hideclose = false, children, contentProps, dialogProps } = props;

  const theme = useTheme();

  const ModalStyle = ModalStyles(theme);

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={handleclose}
      fullWidth={true}
      sx={ModalStyle}
      {...dialogProps}
    >
      {!hideclose && (
        <IconButton
          size="small"
          sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 2 }}
          onClick={() => handleclose()}
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent sx={{ p: "0 1.5rem" }} {...contentProps}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
export default StyledDialog;
