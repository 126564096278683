import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { StatusChip, PopoverDropdown } from "ui";

const ListHead = (props) => {
  const { data, children } = props;

  return (
    <Box sx={{ width: "100%", alignItems: "center" }} className="flex gap-3">
      <Box display="flex" alignItems="center" gap="18px" className="w-1/2 mr-auto">
        {data?.media?.[0]?.url ? (
          <img className="w-[38px] h-[38px] rounded object-cover" src={data?.media?.[0]?.url} alt={data?.name || ""} />
        ) : (
          <WallpaperIcon sx={{ width: "38px", height: "38px" }} />
        )}

        <Typography component="h5" fontSize="1rem">
          {data?.name || ""}
        </Typography>
      </Box>

      {data?.status ? (
        <StatusChip
          status={data?.statusConfigChoice?.configChoice || ""}
          label={data?.statusConfigChoice?.displayName || ""}
          sx={{ height: "30px" }}
        />
      ) : null}

      {data?.price && <StatusChip status="active" label={`$${data.price}`} sx={{ height: "30px" }} />}

      <Box className="text-right">
        <PopoverDropdown dropdownWidth="150px" triggertype={IconButton} trigger={<MoreHorizIcon />}>
          {children}
        </PopoverDropdown>
      </Box>
    </Box>
  );
};

export default ListHead;
