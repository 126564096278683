import React, { useMemo, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { SelectField, StyledSpinner } from "ui";

const MessageGroupSelect = ({ variant = "standard", handleSelectChange }) => {
  const theme = useTheme();
  const { values } = useFormikContext();
  const { allMessageGroups } = useSelector((state) => state.messageGroup);

  const [isSearching, setIsSearching] = useState(false);

  const allOptions = useMemo(() => {
    if (allMessageGroups && !!allMessageGroups.length) {
      return allMessageGroups.map((data) => ({
        id: data?.id,
        name: data?.name,
      }));
    }

    return [];
  }, [allMessageGroups]);

  let typingTimer;

  const onSearch = (value) => {
    clearTimeout(typingTimer);
    setIsSearching(true);

    if (value) {
      typingTimer = setTimeout(() => {
        setIsSearching(false);
      }, 500);
    } else {
      setIsSearching(false);
    }
  };

  return (
    <SelectField
      name="messageGroupId"
      type="text"
      placeholder={!values?.messageGroupId?.length && "Message group"}
      limitTags={10}
      //   multiple
      options={allOptions}
      handleChange={(event, selectedOption, reason) =>
        handleSelectChange && handleSelectChange(event, selectedOption, reason)
      }
      autoCompletePros={{
        disableClearable: false,
        popupIcon: (
          <>
            {isSearching && (
              <StyledSpinner size={18} wrapperprops={{ sx: { display: "flex", position: "relative" } }} />
            )}
          </>
        ),
        onInputChange: (_, value) => onSearch(value),
        getOptionLabel: (option) => option.name,
        isOptionEqualToValue: (option, value) => option.name === value.name,
        filterOptions: (options, { inputValue }) => {
          return options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()));
        },
        sx: {
          "& .MuiInputBase-root": {
            backgroundColor: variant === "outlined" ? alpha(theme.palette.grey.cadet, 0.08) : "",
          },
        },
      }}
      allInputProps={{
        variant: variant,
        autoFocus: variant === "outlined",
      }}
    />
  );
};

export default MessageGroupSelect;
