import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "reduxs/actions";

const useGetCurrentUser = () => {
  const dispatch = useDispatch();
  const { userData, fetchLoading: userLoading } = useSelector((state) => state.user);

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("currentUser"))?.id;
    if (userId) {
      dispatch(getUser(userId));
    }
  }, []);

  useEffect(() => {
    if (!userLoading && userData) setCurrentUser(userData);
  }, [userLoading, userData]);

  return currentUser;
};

export default useGetCurrentUser;
