import React, { useEffect, useMemo } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserHomeMilestone, getSingleConfigChoice, getUser } from "reduxs/actions";
import { DashboardProvider } from "./shared/DashboardContext";
import WelcomeCard from "./shared/WelcomeCard";
import { StyledSpinner } from "ui";
import HomeSlideCard from "./shared/HomeSlideCard";
import DocumentListCard from "./shared/DocumentListCard";
import ActivityLogCard from "./shared/ActivityLogCard";

const CustomerDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { singleChoiceList } = useSelector((state) => state.shared);
  const { userData, fetchLoading: userLoading } = useSelector((state) => state.user);
  const { userHomeMilestones, loading: milestoneLoading } = useSelector((state) => state.userHomeMilestone);

  const isAllFetched = useMemo(() => !userLoading && !milestoneLoading, [userLoading, milestoneLoading]);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("currentUser"))?.id;
    if (userId) {
      dispatch(getUser(userId));
      dispatch(getAllUserHomeMilestone());
      dispatch(getSingleConfigChoice("homePlan"));
    }
  }, []);

  return (
    <>
      {isAllFetched && (
        <DashboardProvider userInfo={userData} userMilestones={userHomeMilestones} configChoice={singleChoiceList}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
            <WelcomeCard />

            <Grid component="section" container spacing={2.5} alignItems="start">
              <Grid item xs={12} lg={8} className="flex flex-wrap gap-5">
                <HomeSlideCard />

                <DocumentListCard />
              </Grid>

              <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                <ActivityLogCard />
              </Grid>
            </Grid>
          </Box>
        </DashboardProvider>
      )}

      {!isAllFetched && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default CustomerDashboard;
