import React, { useMemo } from "react";
import { Box, Avatar, Typography, Link, useTheme } from "@mui/material";
import defaultUserAatar from "assets/images/default-user.png";

const UserInfoCard = (props) => {
  const { drawerOpen } = props;
  const theme = useTheme();

  const user = useMemo(() => {
    return JSON.parse(localStorage.getItem("currentUser"));
  }, [localStorage.getItem("currentUser")]);

  return (
    <Box
      className="userinfo-box"
      component={Link}
      href="/profile"
      underline="none"
      display="flex"
      alignItems="center"
      gap={2}
      mb={3}
      p={drawerOpen ? "1rem 1.25rem" : "1rem 0.5rem"}
      borderRadius=".75rem"
      bgcolor={drawerOpen ? "rgba(145, 158, 171, 0.12)" : "transparent"}
    >
      <Avatar
        src={user?.media?.[0]?.url || defaultUserAatar}
        alt={user?.name || ""}
        size="small"
        sx={{ bgcolor: "#DFE2E7", "& img": { objectFit: "cover" } }}
      />

      <Typography
        component="span"
        variant="subtitle2"
        color={theme.palette.common.dark}
        sx={{ display: drawerOpen ? "block" : "none" }}
      >
        {user?.name}
        <Typography component="small" variant="body2" display="block" color={theme.palette.grey.dark}>
          {user?.role}
        </Typography>
      </Typography>
    </Box>
  );
};

export default UserInfoCard;
