import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { Box, Button, Typography, useTheme } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import LensIcon from "@mui/icons-material/Lens";
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
} from "@mui/lab";
import { BreadcrumbContainer, StyledDateRange, StyledSpinner, StyledTabs } from "ui";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationList, resetNotification } from "reduxs/actions";
import { NotificationItem } from "./shared/NotificationItem";
import { format } from "date-fns";

const NotificationList = (props) => {
  const { isPage = true } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { notificationList, metaData, loading, unread, isReadLoading, reset } = useSelector(
    (state) => state.notification
  );

  const [allNotification, setAllNotification] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [filter, setFilter] = useState({
    pageValue: 1,
    fromDate: "",
    toDate: "",
    readFilter: "",
  });

  const loadData = useCallback(() => {
    const { pageValue, fromDate, toDate, readFilter } = filter;
    dispatch(
      getNotificationList({
        page: pageValue,
        pageSize: 50,
        fromDate,
        toDate,
        isRead: readFilter.toString(),
        isPagination: true,
      })
    );
  }, [dispatch, filter]);

  const handleLoadMore = useCallback(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      pageValue: metaData?.page + 1,
    }));
  }, [metaData?.page]);

  const handleTabChange = useCallback(
    (tab) => {
      setAllNotification([]);
      setActiveTab(tab === "all" ? 0 : 1);

      setFilter((prevFilter) => ({
        ...prevFilter,
        pageValue: 1,
        readFilter: tab === "all" ? "" : tab,
      }));
      dispatch(resetNotification());
    },
    [dispatch]
  );

  const handleChangeDate = useCallback(
    (dateRange) => {
      let start = "";
      let end = "";

      if (dateRange) {
        start = format(new Date(dateRange?.startDate), "yyyy-MM-dd");
        end = format(new Date(dateRange?.endDate), "yyyy-MM-dd");

        dispatch(resetNotification());
      }

      setFilter((prevFilter) => ({
        ...prevFilter,
        fromDate: start,
        toDate: end,
      }));
    },
    [dispatch]
  );

  useEffect(() => {
    loadData();
  }, [loadData, isReadLoading]);

  useEffect(() => {
    if (notificationList?.length && !reset) {
      const newItems = notificationList.map((newItem) => {
        const oldItemIndex = allNotification.findIndex((oldItem) => oldItem.id === newItem.id);

        if (oldItemIndex !== -1) {
          // Replace oldItem with newItem
          // to update isRead status in list
          return newItem;
        }

        return newItem;
      });

      setAllNotification((prevNotification) => update(prevNotification, { $set: newItems }));
    }
  }, [notificationList, reset]);

  return (
    <>
      {isPage && (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <BreadcrumbContainer
              title={"Notifications"}
              paths={[
                {
                  title: "Notification",
                },
              ]}
            />
          </Box>

          <Box
            component="section"
            sx={{ display: "flex", flexFlow: { xs: "column", sm: "row wrap" }, mb: 3, gap: { xs: 2, sm: 8 } }}
          >
            <StyledTabs value={activeTab} variant="boxed" sx={{ flex: 1 }}>
              <StyledTabs.TabItem label="All" value={0} variant="boxed" onClick={() => handleTabChange("all")} />
              <StyledTabs.TabItem
                label="Unread"
                value={1}
                variant="boxed"
                count={unread}
                onClick={() => handleTabChange("unread")}
              />

              {loading && allNotification?.length === 0 && (
                <Box sx={{ position: "absolute", top: 15, right: 15 }}>
                  <StyledSpinner size={20} />
                </Box>
              )}
            </StyledTabs>

            <StyledDateRange
              uistyle="stacked"
              okbutton={true}
              wrapperprops={{ sx: { width: "auto" } }}
              onchangerange={(range) => handleChangeDate(range)}
            />
          </Box>
        </>
      )}

      {allNotification?.length ? (
        <Timeline
          position="right"
          sx={{
            m: 0,
            p: 0,
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {allNotification?.map((notify, i) => {
            return (
              <NotificationItem
                key={notify.id}
                index={i}
                showgraphic={metaData?.totalPages > 1 && metaData?.totalPages !== metaData?.page ? 1 : 0}
                className={i === allNotification.length - 1 ? "is-last" : ""}
                data={notify}
                showStatus={isPage}
              />
            );
          })}

          {metaData?.totalPages > 1 && metaData?.totalPages !== metaData?.page && (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector sx={{ height: 80, m: 0 }} />
                <TimelineDot style={{ height: 0, margin: 0, opacity: 0 }}>
                  <LensIcon />
                </TimelineDot>
              </TimelineSeparator>

              <TimelineContent
                sx={{
                  mt: 3.5,
                }}
              >
                <Box
                  component="svg"
                  width={29}
                  height={14}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  sx={{
                    position: "absolute",
                    left: 17,
                    top: 40,
                  }}
                >
                  <path d="M1.197.54S6 9 13.5 11C21 13 29 13 29 13" stroke="#919EAB" />
                </Box>
                <Button
                  color="info"
                  sx={{ display: "flex", alignItems: "center", gap: 1.5, textTransform: "none" }}
                  onClick={handleLoadMore}
                >
                  <UnfoldMoreIcon
                    sx={{
                      border: `1px solid ${theme.palette.grey.dimGray}`,
                      borderRadius: "50%",
                    }}
                  />
                  Load more notifications
                  {loading && <StyledSpinner size={20} />}
                </Button>
              </TimelineContent>
            </TimelineItem>
          )}
        </Timeline>
      ) : (
        <></>
      )}

      {!loading && allNotification?.length === 0 && (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "12px 20px",
            fontSize: 16,
            lineHeight: 2,
            color: theme.palette.grey.cadet,
            textAlign: "center",
          }}
        >
          <NotificationsPausedIcon sx={{ width: 38, height: 38 }} />
          There are no new notifications.
        </Typography>
      )}
    </>
  );
};

export default NotificationList;
