import Req from "interceptors/TokenInterceptor";

const ProductService = (function () {
  function _getAllProduct() {
    return Req.get("/api/products/all");
  }
  function _getProductList() {
    return Req.get("/api/products");
  }
  function _addProduct(data) {
    return Req.post("/api/products", data);
  }
  function _getProduct(id) {
    return Req.get(`/api/products/${id}`);
  }
  function _editProduct(data, id) {
    return Req.put(`/api/products/${id}`, data);
  }
  function _deleteProduct(id) {
    return Req.delete(`/api/products/${id}`);
  }
  return {
    getAllProduct: _getAllProduct,
    getProductList: _getProductList,
    addProduct: _addProduct,
    getProduct: _getProduct,
    editProduct: _editProduct,
    deleteProduct: _deleteProduct,
  };
})();
export default ProductService;
