import React from "react";
import { Chip, styled, darken, alpha } from "@mui/material";

const Chipped = styled(Chip, { shouldForwardProp: (prop) => prop !== "color" })(({ theme, color, variant }) => ({
  borderRadius: 4,
  backgroundColor: variant === "outlined" ? "inherit" : alpha(color || theme.palette.primary.main, 0.6),
  color: variant === "outlined" ? color || theme.palette.primary.main : darken(color || theme.palette.primary.main, 1),
}));

const StyledChip = (props) => {
  return <Chipped {...props} />;
};

export default StyledChip;
