import {
  GET_ALL_HOME,
  GET_ALL_HOME_SUCCESS,
  GET_ALL_HOME_ERROR,
  GET_HOME_LIST,
  GET_HOME_LIST_SUCCESS,
  GET_HOME_LIST_ERROR,
  ADD_HOME,
  ADD_HOME_SUCCESS,
  ADD_HOME_ERROR,
  GET_HOME,
  GET_HOME_SUCCESS,
  GET_HOME_ERROR,
  EDIT_HOME,
  EDIT_HOME_SUCCESS,
  EDIT_HOME_ERROR,
  DELETE_HOME,
  DELETE_HOME_SUCCESS,
  DELETE_HOME_ERROR,
  RESET_HOME,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  homes: null,
  homeList: null,
  metaData: null,
  homeData: null,
  homeId: null,
  success: false,
  loading: false,
  fetchLoading: false,
  delLoading: false,
  error: null,
};

const homeReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_HOME:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_HOME_SUCCESS:
      return {
        ...state,
        homes: action.payload,
        error: null,
      };
    case GET_ALL_HOME_ERROR:
      return {
        ...state,
        homes: null,
        error: action.payload,
      };
    case GET_HOME_LIST:
      return {
        ...state,
        loading: true,
        homeData: null,
        homeId: null,
        error: null,
      };
    case GET_HOME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        homeList: action.payload.homeList,
        metaData: action.payload.metaData,
        error: null,
      };
    case GET_HOME_LIST_ERROR:
      return {
        ...state,
        loading: false,
        homeList: null,
        error: action.payload,
      };
    case ADD_HOME:
      return { ...state, loading: true, error: null };
    case ADD_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case ADD_HOME_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_HOME:
      return { ...state, fetchLoading: true, error: null };
    case GET_HOME_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        homeData: action.payload,
        error: null,
      };
    case GET_HOME_ERROR:
      return {
        ...state,
        fetchLoading: false,
        homeData: null,
        error: action.payload,
      };
    case EDIT_HOME:
      return { ...state, loading: true, error: null };
    case EDIT_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_HOME_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_HOME:
      return { ...state, delLoading: true, error: null };
    case DELETE_HOME_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_HOME_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_HOME:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default homeReducer;
