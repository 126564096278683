import Req from "interceptors/TokenInterceptor";

const HomeService = (function () {
  function _getAllHome() {
    return Req.get("/api/homes/all");
  }
  function _addHome(data) {
    return Req.post("/api/homes", data);
  }
  function _getHome(id) {
    return Req.get(`/api/homes/${id}`);
  }
  function _editHome(data, id) {
    return Req.put(`/api/homes/${id}`, data);
  }
  function _deleteHome(id) {
    return Req.delete(`/api/homes/${id}`);
  }
  return {
    getAllHome: _getAllHome,
    addHome: _addHome,
    getHome: _getHome,
    editHome: _editHome,
    deleteHome: _deleteHome,
  };
})();
export default HomeService;
