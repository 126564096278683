import {
  GET_ALL_PRODUCT,
  GET_ALL_PRODUCT_SUCCESS,
  GET_ALL_PRODUCT_ERROR,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_ERROR,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  RESET_PRODUCT,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  products: null,
  productList: null,
  productData: null,
  productId: null,
  success: false,
  loading: false,
  fetchLoading: false,
  frmLoading: false,
  delLoading: false,
  error: null,
};

const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCT:
      return {
        ...state,
        error: null,
      };
    case GET_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        error: null,
      };
    case GET_ALL_PRODUCT_ERROR:
      return {
        ...state,
        products: null,
        error: action.payload,
      };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
        productData: null,
        productId: null,
        error: null,
      };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: action.payload,
        error: null,
      };
    case GET_PRODUCT_LIST_ERROR:
      return {
        ...state,
        loading: false,
        productList: null,
        error: action.payload,
      };
    case ADD_PRODUCT:
      return { ...state, frmLoading: true, error: null };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case ADD_PRODUCT_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case GET_PRODUCT:
      return { ...state, fetchLoading: true, error: null };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        productData: action.payload,
        error: null,
      };
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        fetchLoading: false,
        productData: null,
        error: action.payload,
      };
    case EDIT_PRODUCT:
      return { ...state, frmLoading: true, error: null };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_PRODUCT_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_PRODUCT:
      return { ...state, delLoading: true, error: null };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_PRODUCT:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        frmLoading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default productReducer;
