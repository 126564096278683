import React, { useEffect, useRef } from "react";
import { Box, Grid, InputAdornment, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getMultipleConfigChoice, getProduct, addProduct, editProduct, getAllProduct } from "reduxs/actions";
import { InputField, TextareaField, SelectField } from "ui/form/field";
import { StyledButton, FileUpload, StyledSwitch } from "ui";

const ProductForm = (props) => {
  const { editId, handleClose } = props;
  const formikRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success, frmLoading, productData, products } = useSelector((state) => state.product);
  const { multipleChoiceList } = useSelector((state) => state.shared);

  const initialValues = {
    name: editId ? productData?.name || "" : "",
    parentProductId: editId ? productData?.parentProductId || "" : "",
    description: editId ? productData?.description || "" : "",
    type: editId ? productData?.type || "" : "",
    manufacturer: editId ? productData?.manufacturer || "" : "",
    collection: editId ? productData?.collection || "" : "",
    price: editId ? productData?.price || "" : "",
    status: editId
      ? productData?.status || ""
      : multipleChoiceList?.productStatus?.find((val) => val.configChoice === "active")?.id || "",
    isRemovable: editId ? productData?.isRemovable || 0 : 0,
    media: editId ? productData?.media || [] : [],
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Enter name"),
  });

  const onSubmit = (values) => {
    if (!frmLoading) {
      if (editId) {
        dispatch(editProduct(editId, values, navigate));
      } else {
        dispatch(addProduct(values, navigate));
      }
    }
  };

  useEffect(() => {
    dispatch(getAllProduct());
    dispatch(getMultipleConfigChoice(["productStatus", "productType"]));
    if (editId) dispatch(getProduct(editId));

    return () => {
      dispatch(getProduct(""));
    };
  }, []);

  useEffect(() => {
    if (success) {
      handleClose(true);
    }
  }, [success]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      innerRef={formikRef}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <InputField name="name" type="text" label="Name*" />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SelectField
                name="type"
                type="text"
                label="Type"
                placeholder="Select Type"
                disableClearable={false}
                options={
                  multipleChoiceList?.productType &&
                  multipleChoiceList?.productType.map((choice) => {
                    return { id: choice.id, name: choice.displayName };
                  })
                }
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <SelectField
                name="parentProductId"
                type="text"
                label="Parent product"
                placeholder="Select product"
                disableClearable={false}
                options={
                  products &&
                  products.map((product) => {
                    return { id: product.id, name: product.name };
                  })
                }
              />
            </Grid>

            <Grid item xs={12} xl={6}>
              <InputField name="manufacturer" type="text" label="Manufacturer" />
            </Grid>

            <Grid item xs={12} xl={6}>
              <InputField name="collection" type="text" label="Collection" />
            </Grid>

            <Grid item xs={12} xl={6}>
              <InputField
                name="price"
                type="number"
                label="Price"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item xs={12} xl={3}>
              <Typography component="label" fontSize="0.875rem" fontWeight="400" display="block">
                Status
              </Typography>

              <StyledSwitch
                value={values.status}
                checked={
                  values.status === multipleChoiceList?.productStatus?.find((val) => val.configChoice === "active")?.id
                }
                onChange={(e) => {
                  setFieldValue(
                    "status",
                    e.target.checked
                      ? multipleChoiceList?.productStatus?.find((val) => val.configChoice === "active")?.id
                      : multipleChoiceList?.productStatus?.find((val) => val.configChoice === "inactive")?.id
                  );
                }}
                name="status"
                label={
                  multipleChoiceList?.productStatus && values.status
                    ? values.status ===
                      multipleChoiceList?.productStatus?.find((val) => val.configChoice === "active")?.id
                      ? "Active"
                      : "Inactive"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12} xl={3}>
              <Typography component="label" fontSize="0.875rem" fontWeight="400" display="block">
                Is Removable
              </Typography>

              <StyledSwitch
                value={values.isRemovable}
                onChange={(e) => {
                  setFieldValue("isRemovable", e.target.checked);
                }}
                name="isRemovable"
                label={values.isRemovable ? "Yes" : "No"}
              />
            </Grid>

            <Grid item xs={12} xl={12}>
              <TextareaField name="description" label="Description*" />
            </Grid>
          </Grid>

          <Box mt={2}>
            <Typography component="label" fontSize="0.875rem" fontWeight="600" display="block" mb={1}>
              Image
            </Typography>
            <FileUpload
              accept="image/*"
              setMedia={(files, progress) => progress && setFieldValue("media", files)}
              media={values.media}
              isupdating={editId}
            />
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={frmLoading}>
              {editId ? "Update Product" : "Create Product"}
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              component={Link}
              onClick={() => handleClose()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.error.main}
              onClick={resetForm}
              className="shadow-none"
            >
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(ProductForm);
