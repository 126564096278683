import React from "react";
import { Box, Typography } from "@mui/material";
import WallpaperIcon from "@mui/icons-material/Wallpaper";

const SelectionHead = (props) => {
  const { data } = props;
  return (
    <Box sx={{ width: "100%", alignItems: "center" }} className="flex gap-3">
      <Box display="flex" alignItems="center" gap="18px" className="w-1/2 mr-auto">
        {data?.media?.[0]?.url ? (
          <img className="w-[38px] h-[38px] rounded object-cover" src={data?.media?.[0]?.url} alt={data?.name || ""} />
        ) : (
          <WallpaperIcon sx={{ width: "38px", height: "38px" }} />
        )}

        <Typography component="h5" fontSize="1rem">
          {data?.name || ""} <span className="text-gray-400">({data?.childrenProduct.length || 0} item)</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default SelectionHead;
