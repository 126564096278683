import React, { useRef } from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton, TextareaField } from "ui";
import { editUserHomeMilestone } from "reduxs/actions";

const BuildingProgressForm = (props) => {
  const { editData, dbParams, handleClose } = props;

  const formikRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { frmLoading } = useSelector((state) => state.userHomeMilestone);

  const initialValues = {
    userHomeId: editData ? editData?.userHomeId || "" : "",
    milestoneId: editData ? editData?.milestoneId || "" : "",
    note: editData ? editData?.note || "" : "",
    description: editData ? editData?.description || "" : "",
  };

  const schema = Yup.object().shape({});

  const onSubmit = (values) => {
    if (!frmLoading) {
      if (editData) {
        dispatch(editUserHomeMilestone(editData.id, values, dbParams));
        handleClose(true);
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={12}>
              <TextareaField name="note" label="Notes" />
            </Grid>

            <Grid item xs={12} xl={12}>
              <TextareaField name="description" label="Description" />
            </Grid>
          </Grid>

          <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={{ sm: "flex-end" }}
            gap={2}
            mt={5}
          >
            <StyledButton type="submit" isloading={frmLoading}>
              Save
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.grey.main}
              sx={{ color: theme.palette.text.primary }}
              disabled={frmLoading}
              onClick={() => handleClose()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="button"
              variant="outlined"
              color={theme.palette.error.main}
              onClick={resetForm}
              disabled={frmLoading}
              className="shadow-none"
            >
              Reset
            </StyledButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default BuildingProgressForm;
