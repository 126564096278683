import React from "react";
import { IconButton, MenuItem, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ViewCozyOutlinedIcon from "@mui/icons-material/ViewCozyOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PopoverDropdown from "../../popover-dropdown/PopoverDropdown";

const Action = (props) => {
  const { children, permission, buttonText, handleOnDelete, handleOnEdit, handleOnView } = props;

  const theme = useTheme();

  return (
    <PopoverDropdown dropdownWidth="150px" triggertype={IconButton} trigger={<MoreVertIcon />} iconColor="#888888">
      {/* This will load additional action buttons */}
      {children}

      {permission?.view ? (
        <MenuItem onClick={handleOnView}>
          <ViewCozyOutlinedIcon />
          {buttonText || "View"}
        </MenuItem>
      ) : null}

      {permission?.edit ? (
        <MenuItem onClick={handleOnEdit}>
          <DriveFileRenameOutlineOutlinedIcon />
          {buttonText || "Edit"}
        </MenuItem>
      ) : null}

      {permission?.delete ? (
        <MenuItem onClick={handleOnDelete} sx={{ color: theme.palette.error.main }}>
          <DeleteOutlineOutlinedIcon />
          {buttonText || "Delete"}
        </MenuItem>
      ) : null}
    </PopoverDropdown>
  );
};

const ActionItem = ({ children, ...props }) => {
  return <MenuItem {...props}>{children}</MenuItem>;
};

Action.ActionItem = ActionItem;
export default Action;
