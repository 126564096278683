import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import SummarizeIcon from "@mui/icons-material/Summarize";
// import ColorLensIcon from "@mui/icons-material/ColorLens";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHomeList, deleteHome, resetHome } from "reduxs/actions";
import {
  StyledCard,
  BreadcrumbContainer,
  AlertDialog,
  TablePagination,
  Toolbar,
  StyledSpinner,
  Action,
  StatusChip,
  StyledButton,
  Empty,
} from "ui";
import { checkPermission } from "helpers";
import graphics from "assets/images/home-placeholder.jpg";

const HomeList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sortOrder = "desc";
  const activeCol = "created_at";
  const [pageValue, setPageValue] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [search, setSearch] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { homeList, metaData, delLoading, loading, success } = useSelector((state) => state.home);
  const { permissionList } = useSelector((state) => state.shared);
  const searchFields = "name:like;statusConfigChoice.display_name:like;";

  const onChange = (search, sortOrder, page, perPage, activeCol) => {
    dispatch(
      getHomeList({
        search: search,
        searchFields: searchFields,
        sortedBy: sortOrder,
        page: page,
        pageSize: perPage,
        orderBy: activeCol,
      })
    );
  };

  const handleChangePage = (val) => {
    setPageValue(val);
    onChange(search, sortOrder, val, perPage, activeCol);
  };

  const handleChangePerPage = (val) => {
    setPerPage(val);
    onChange(search, sortOrder, pageValue, val, activeCol);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const val = event.target.value;
    setSearch(event.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        onChange(val, sortOrder, pageValue, perPage, activeCol);
      }, 1000)
    );
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    navigate(`/configuration/home/edit/${id}`);
  };

  const handleViewClick = (e, id) => {
    e.preventDefault();
    navigate(`/configuration/home/view/${id}`);
  };

  // const handleSelectionClick = (e, id) => {
  //   e.preventDefault();
  //   navigate(`/configuration/home/summary/${id}`);
  // };

  // const handleCustomizeClick = (e, id) => {
  //   e.preventDefault();
  //   navigate(`/configuration/home/customize/${id}`);
  // };

  const handleOnDelete = () => {
    if (!delLoading && deleteId) dispatch(deleteHome(deleteId));
  };

  const data = React.useMemo(() => homeList, [homeList]);

  useEffect(() => {
    dispatch(getHomeList({}));
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Home List"
          paths={[
            {
              title: "Home",
              path: "/configuration/home",
            },
          ]}
        />

        {!loading && checkPermission(permissionList, "add home") && metaData?.total > 0 ? (
          <StyledButton onClick={() => navigate("/configuration/home/add")}>
            <AddIcon />
            Add Home
          </StyledButton>
        ) : null}
      </Box>

      <Toolbar
        search={search}
        handleSearch={handleSearch}
        classes={{ root: "p-0 mb-4", wrapper: "w-full m-0 p-0", innerFilterWrap: "ml-0" }}
      />

      {metaData?.total > 0 ? (
        <Grid container spacing={3}>
          {data?.map((item, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                <StyledCard className="h-full">
                  <Box
                    component="figure"
                    sx={{ width: "100%", m: "0", p: "8px", cursor: "pointer" }}
                    onClick={(e) => handleViewClick(e, item.id)}
                  >
                    <img
                      src={item?.media?.[0]?.url || graphics}
                      alt="Home"
                      className="w-full aspect-[4/3] rounded-xl"
                    />
                  </Box>

                  <Box sx={{ px: 3, pb: 3 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
                      <Typography component="h3" variant="body1" fontWeight="600" className="self-center">
                        {item.name}
                      </Typography>
                      <Action
                        handleOnDelete={() => {
                          setDeleteId(item.id);
                          setOpenDeleteAlert(true);
                        }}
                        handleOnEdit={(e) => handleEditClick(e, item.id)}
                        handleOnView={(e) => handleViewClick(e, item.id)}
                        permission={{
                          edit: checkPermission(permissionList, "edit home"),
                          delete: checkPermission(permissionList, "delete home"),
                          view: checkPermission(permissionList, "view home"),
                        }}
                      >
                        {/* {checkPermission(permissionList, "list selection") && (
                          <Action.ActionItem onClick={(e) => handleSelectionClick(e, item.id)}>
                            <SummarizeIcon />
                            Selection
                          </Action.ActionItem>
                        )}

                        {checkPermission(permissionList, "list customization") && (
                          <Action.ActionItem onClick={(e) => handleCustomizeClick(e, item.id)}>
                            <ColorLensIcon />
                            Customize
                          </Action.ActionItem>
                        )} */}
                      </Action>
                    </Box>

                    <StatusChip
                      status={item?.statusConfigChoice?.configChoice}
                      label={item?.statusConfigChoice?.displayName || ""}
                    />
                  </Box>
                </StyledCard>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      <TablePagination
        meta={metaData}
        goToStart={() => handleChangePage(1)}
        goToPrev={() => handleChangePage(pageValue === 0 ? 1 : pageValue - 1)}
        goToNext={() => handleChangePage(pageValue !== metaData?.totalPage ? pageValue + 1 : 1)}
        goToLast={() => handleChangePage(metaData?.totalPage)}
        handleChangePerPage={(val) => handleChangePerPage(val)}
        perPage={perPage}
        handleChangePage={handleChangePage}
        classes={{ root: "mt-8 rounded-2xl" }}
        wrapperprops={{ sx: { background: theme.palette.grey.lotion, boder: `1px solid ${theme.palette.slate.A100}` } }}
      />

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setDeleteId(null);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected home?"
        loadingInfo="Home is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => dispatch(resetHome())}
      />

      {!loading && metaData?.total === 0 && (
        <Empty title="There are no items here!" subcontent="Start by adding new items to the list.">
          {checkPermission(permissionList, "add home") ? (
            <StyledButton onClick={() => navigate("/configuration/home/add")}>
              <AddIcon />
              Add Home
            </StyledButton>
          ) : null}
        </Empty>
      )}

      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default HomeList;
