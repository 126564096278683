import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  styled,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useField } from "formik";
import InputFieldStyles from "styles/form/InputField.style";

const validations = [
  {
    regex: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}/,
    message: "8 character minimum",
  },
  { regex: /[a-z]/, message: "One lowercase character" },
  { regex: /[A-Z]/, message: "One uppercase character" },
  { regex: /(?=.*\d)([0-9])/, message: "One number" },
  { regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, message: "One special character" },
];

const ValidationItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== "isValid" })(
  ({ theme, hasValue, isValid }) => ({
    alignItems: "flex-start",
    padding: 0,
    gap: 5,
    color: hasValue ? (isValid ? theme.palette.primary.main : theme.palette.error.main) : theme.palette.grey.dark,

    "& .MuiListItemIcon-root": {
      minWidth: "initial",

      "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
        color: hasValue ? (isValid ? theme.palette.primary.main : theme.palette.error.main) : theme.palette.grey.dark,
      },
    },

    "& .MuiListItemText-root": { margin: 0 },
    "& .MuiListItemText-root .MuiListItemText-primary": { fontSize: 12 },
  })
);

const InputPasswordField = ({
  label,
  variant,
  shrinkLabel = false,
  inputOptions,
  validationInfo = false,
  ...props
}) => {
  const theme = useTheme();
  const FieldStyles = InputFieldStyles(theme);

  const [field, meta] = useField(props);
  const [type, setType] = useState("password");

  const pwdToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <TextField
        type={type}
        label={label || null}
        InputLabelProps={shrinkLabel ? { shrink: true } : {}}
        InputProps={{
          ...inputOptions,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={pwdToggle} edge="end">
                {type === "password" ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant={variant || "outlined"}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
        margin="dense"
        fullWidth
        sx={FieldStyles}
        {...field}
        {...props}
      />

      {validationInfo && (
        <List sx={{ p: 0 }} className="grid grid-cols-1 lg:grid-cols-2 gap-1.5">
          {validations.map((item) => {
            return (
              <ValidationItem key={item.regex} hasValue={field.value} isValid={item.regex.test(field.value)}>
                <ListItemIcon>
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText primary={item.message} />
              </ValidationItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

export default InputPasswordField;
