import React, { useState, useEffect } from "react";
import { alpha, Box, MenuItem, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { deleteProduct, getProductList, resetProduct } from "reduxs/actions";
import { checkPermission } from "helpers";
import {
  BreadcrumbContainer,
  StyledAccordion,
  StyledCard,
  StyledButton,
  StyledSpinner,
  Empty,
  FloatingForm,
  AlertDialog,
} from "ui";
import ListHead from "./shared/ListHead";
import ChildItem from "./shared/ChildItem";
import Detail from "./shared/Detail";
import ProductForm from "./Form";

const ProductList = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { productList, loading, success, delLoading } = useSelector((state) => state.product);
  const { permissionList } = useSelector((state) => state.shared);

  const [expanded, setExpanded] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [formId, setFormId] = useState();

  const handleChange = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const handleOnDelete = () => {
    if (!delLoading && formId) {
      dispatch(deleteProduct(formId));
      resetAll();
    }
  };

  const setChildItems = (child) => {
    return (
      <ChildItem key={child.id} childItem={child} handleAction={(type) => handleActionClick(type, child.id)}>
        {child?.childrenProduct?.length > 0 ? child.childrenProduct.map((subChild) => setChildItems(subChild)) : null}
      </ChildItem>
    );
  };

  const handleActionClick = (type, id) => {
    if (type === "edit") {
      setFormId(id);
      setOpenForm(true);
    } else if (type === "delete") {
      setFormId(id);
      setOpenDeleteAlert(true);
    }
  };

  const resetAll = () => {
    setFormId("");
    setOpenForm(false);
  };

  useEffect(() => {
    dispatch(getProductList());
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title="Product List"
          paths={[
            {
              title: "Product",
              path: "/configuration/products",
            },
          ]}
        />
        {!loading && checkPermission(permissionList, "add product") && productList?.length ? (
          <StyledButton type="button" onClick={() => setOpenForm(true)}>
            <AddIcon />
            Add Product
          </StyledButton>
        ) : null}
      </Box>

      {productList?.length ? (
        <Box className="flex flex-col gap-5">
          {productList.map((parent, i) => {
            return (
              <StyledAccordion
                key={parent.id}
                label={
                  <ListHead data={parent}>
                    <MenuItem onClick={() => handleActionClick("edit", parent.id)}>
                      <DriveFileRenameOutlineOutlinedIcon />
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleActionClick("delete", parent.id)}
                      sx={{ color: theme.palette.error.main }}
                    >
                      <DeleteOutlineOutlinedIcon />
                      Delete
                    </MenuItem>
                  </ListHead>
                }
                labelprops={{
                  onClick: () => {
                    handleChange(parent.id);
                  },
                  expandIcon: <ExpandMoreIcon />,
                }}
                wrapperprops={{
                  expanded: expanded === parent.id,
                  sx: {
                    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
                  },
                }}
              >
                {parent?.childrenProduct?.length > 0 && (
                  <StyledCard
                    sx={{
                      p: "19px",
                      mx: "-5px",
                      backgroundColor: alpha(theme.palette.blue.tropicalBlue, 0.5),
                      border: `1px solid ${theme.palette.blue.tropicalBlue}`,
                    }}
                    className="flex flex-col gap-3"
                  >
                    {parent.childrenProduct.map((child) => setChildItems(child))}
                  </StyledCard>
                )}

                {parent?.childrenProduct?.length === 0 && <Detail data={parent} />}
              </StyledAccordion>
            );
          })}
        </Box>
      ) : null}

      {!loading && productList?.length === 0 && (
        <Empty title="There are no items here!" subcontent="Start by adding new items to the list.">
          {checkPermission(permissionList, "add product") ? (
            <StyledButton type="button" onClick={() => setOpenForm(true)}>
              <AddIcon />
              Add Product
            </StyledButton>
          ) : null}
        </Empty>
      )}

      {/* Floating Form */}
      <FloatingForm
        open={openForm}
        handleclose={() => setOpenForm(false)}
        title={formId ? "Edit product" : "Add product"}
      >
        <ProductForm
          handleClose={(submit) => {
            resetAll();
            if (submit) {
              dispatch(getProductList());
            }
          }}
          editId={formId}
        />
      </FloatingForm>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected product?"
        loadingInfo="product is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => dispatch(resetProduct())}
      />

      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default ProductList;
