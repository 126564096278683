import React from "react";
import { InputAdornment, Popover, TextField, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useField } from "formik";
import InputFieldStyles from "styles/form/InputField.style";
import IconList from "./IconList";
import useAllIcons from "hooks/useAllIcons";

const styles = {
  dropdown: {
    maxWidth: "100%",
    minWidth: "initial",
    width: 500,
    marginTop: 1.5,
    boxShadow: "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
    borderRadius: "0.75rem",
    overflow: "visible",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      borderTop: "1px solid rgba(145, 158, 171, 0.12)",
      borderLeft: "1px solid rgba(145, 158, 171, 0.12)",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
  items: {
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    "& .MuiMenuItem-root": {
      margin: "0 0.5rem",
      padding: "0.375rem 1rem",
      borderRadius: 1.5,
      fontSize: "0.875rem",
      lineHeight: "1.57143",

      "& svg": {
        width: "1.25rem",
        height: "1.25rem",
        marginRight: "1rem",
        flexShrink: 0,
      },
    },
  },
};

const IconField = ({ label, variant, shrinkLabel = false, dense = false, ...props }) => {
  const theme = useTheme();
  const iconList = useAllIcons();

  const [field, meta, helpers] = useField(props);

  const FieldStyles = InputFieldStyles(theme);

  const [selectedVal, setSelectedVal] = React.useState("");
  const [selected, setSelected] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openIconSelect = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    helpers.setValue(item.iconName || null);
    setSelectedVal(item.iconName);
    setSelected(item);

    handleClose();
  };

  React.useEffect(() => {
    const valueFrom = field.value;
    setSelectedVal(valueFrom);
    setSelected(iconList.find((x) => x.iconName === valueFrom));
  }, [field.value]);

  return (
    <>
      <TextField
        value={selectedVal}
        label={label || null}
        InputLabelProps={shrinkLabel ? { shrink: true } : {}}
        variant={variant || "outlined"}
        helperText={meta.touched && meta.error ? meta.error : null}
        error={meta.touched && meta.error ? true : false}
        margin="dense"
        fullWidth
        sx={FieldStyles}
        InputProps={{
          onClick: openIconSelect,
          startAdornment: (
            <InputAdornment position="start">
              {selected && selected.iconComp && React.createElement(selected.iconComp, {})}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <KeyboardArrowDownIcon />
            </InputAdornment>
          ),
        }}
        className={dense ? "densed-field" : ""}
        {...field}
        {...props}
      />

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: { ...styles.dropdown },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <IconList handleIconSelect={(selected) => handleSelect(selected)} />
      </Popover>
    </>
  );
};

export default React.memo(IconField);
