import React, { useEffect, useRef } from "react";
import { Box, InputAdornment, useTheme } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetAuth } from "reduxs/actions";
import { InputField } from "ui/form/field";
import { StyledButton } from "ui";
import AuthWrapper from "./shared/AuthWrapper";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { loading, success } = useSelector((state) => state.auth);
  const formikRef = useRef();

  const [email, setEmail] = React.useState("");

  const schema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const onForgotPassword = (values) => {
    if (!loading) {
      setEmail(values.email);

      dispatch(forgotPassword(values));
    }
  };

  useEffect(() => {
    resetAuth();
  }, []);

  useEffect(() => {
    if (success) {
      formikRef.current?.resetForm();
      resetAuth();
    }
  }, [success]);

  return (
    <AuthWrapper
      title={!loading && success ? "Reset complete!" : "Forgot password?"}
      subtitle={
        !loading && success
          ? `All done! We have sent an email to ${email} to confirm`
          : "No worries we'll send you reset instructions"
      }
      footer={
        !loading &&
        !success && (
          <>
            Don&apos;t have account?{" "}
            <Box component={Link} to={`/auth/register`} sx={{ color: theme.palette.text.primary }}>
              Sign Up
            </Box>
          </>
        )
      }
    >
      {!loading && success ? (
        <StyledButton
          type="button"
          color={theme.palette.background.button}
          sx={{ width: "100%", mt: 2 }}
          onClick={() => navigate("/auth/login")}
        >
          Return to login
        </StyledButton>
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={schema}
          onSubmit={onForgotPassword}
          innerRef={formikRef}
        >
          {({ values }) => (
            <Box component={Form} sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
              <InputField
                shrinkLabel={true}
                name="email"
                type="email"
                label="Email"
                placeholder="Enter your email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row", gap: 24, marginTop: 8 } }}>
                {!loading && (
                  <StyledButton
                    type="button"
                    variant="outlined"
                    color={theme.palette.background.button}
                    sx={{ boxShadow: "none" }}
                    onClick={() => navigate("/auth/login")}
                  >
                    <KeyboardBackspaceOutlinedIcon />
                    Cancel
                  </StyledButton>
                )}

                <StyledButton
                  type="submit"
                  color={theme.palette.background.button}
                  isloading={loading}
                  sx={{ flex: 1 }}
                >
                  Reset password
                </StyledButton>
              </Box>
            </Box>
          )}
        </Formik>
      )}
    </AuthWrapper>
  );
};

export default ForgotPassword;
