import React from "react";
import { Box, IconButton, TextField, styled, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { VirtuosoGrid } from "react-virtuoso";
import useAllIcons from "hooks/useAllIcons";

const ListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));

const ListItem = styled(Box)(({ theme }) => ({
  width: 40,
}));

const Search = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  position: "relative",
  marginLeft: 0,
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  pointerEvents: "none",
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  flex: 1,
  color: "inherit",
  "& .MuiInputBase-input": {
    width: "100%",
    height: "1.875rem",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

const IconList = (props) => {
  const { handleIconSelect } = props;

  const theme = useTheme();
  const iconList = useAllIcons();

  const [searchText, setSearchText] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [typingTimeout, setTypingTimeout] = React.useState(0);

  const handleSearch = (event) => {
    event.preventDefault();
    const searchText = event.target.value;
    setSearchText(searchText);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        const filteredItems = iconList.filter(({ iconName }) =>
          iconName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredItems(filteredItems);
      }, 1000)
    );
  };

  React.useEffect(() => {
    () => {
      setSearchText("");
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: 510,
        display: "flex",
        flexDirection: "column",
        gap: 3,
        padding: 2,
      }}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon color={theme.palette.grey.cadet} />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={handleSearch}
          value={searchText}
          placeholder="Search…"
          inputProps={{ "aria-label": "search", type: "search" }}
          autoFocus={true}
        />
      </Search>

      <VirtuosoGrid
        style={{ height: 400 }}
        totalCount={filteredItems.length || iconList.length}
        overscan={200}
        components={{
          List: ListContainer,
          Item: ListItem,
        }}
        itemContent={(index) => {
          const icon = filteredItems[index] || iconList[index];
          return (
            <IconButton onClick={() => handleIconSelect(icon)}>{React.createElement(icon.iconComp, {})}</IconButton>
          );
        }}
      />
    </Box>
  );
};

export default React.memo(IconList);
