import React, { useMemo, useState, useRef, useEffect } from "react";
import { Box, IconButton, Typography, styled, alpha, useTheme, Skeleton, Tooltip, Grid } from "@mui/material";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import MarkEmailUnreadRoundedIcon from "@mui/icons-material/MarkEmailUnreadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SpeakerNotesOffRoundedIcon from "@mui/icons-material/SpeakerNotesOffRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import LabelRoundedIcon from "@mui/icons-material/LabelRounded";
import ReplyAllRoundedIcon from "@mui/icons-material/ReplyAllRounded";
import { format, parseISO } from "date-fns";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { composeMessage, deleteMessage, resetMessage, updateMessageStatus } from "reduxs/actions";
import { checkPermission, convertUTCToLocal, getFileType } from "helpers";
import { AlertDialog, Editor, StyledButton, StyledCard, StyledSpinner } from "ui";
import { IconFiles } from "icons";
import { useMessage } from "./MessageContext";
import UserMessage from "./UserMessage";
import UploadFileModal from "./UploadFileModal";
import useGetMessageStatus from "./hooks/useGetMessageStatus";
import MessageToSelect from "./MessageToSelect";
import emptyChat from "assets/images/Chat-empty.svg";
// import MessageGroupSelect from "./MessageGroupSelect";

const PreviewContents = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  margin: 0,
  padding: 16,
  boxShadow: "none",
}));

const MessagePreview = () => {
  const theme = useTheme();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const getMessageStatus = useGetMessageStatus();
  const { currentMenu, userInfo, selectedMessage, updateContext } = useMessage();

  const { permissionList, singleChoiceList: messageStatusList } = useSelector((state) => state.shared);
  const {
    frmSucess,
    success,
    frmLoading,
    fetchLoading,
    loading: isMessageLoading,
    isUpdateLoading,
    delLoading,
  } = useSelector((state) => state.message);

  const [openDialog, setOpenDialog] = useState({ open: false, fileType: undefined });
  const [expandAttachment, setExpandAttachment] = useState(true);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const [loadingAction, setLoadingAction] = useState({ isStarred: false, isImportant: false, isRead: false });

  const filters = { currentMenu: currentMenu, userId: userInfo?.id };
  const hasReplies = useMemo(() => newMessage && newMessage?.childrenMessage?.length > 0, [newMessage]); //*** Check for replies
  const shouldShowMessageActions = useMemo(
    () => !isMessageLoading && currentMenu !== "sent" && currentMenu !== "draft",
    [currentMenu, isMessageLoading]
  );
  const isDraft = useMemo(
    () => selectedMessage?.status === 27 || currentMenu === "draft",
    [selectedMessage, currentMenu]
  );
  const isSchedule = useMemo(
    () => selectedMessage?.status === 28 || currentMenu === "schedule",
    [selectedMessage, currentMenu]
  );

  const messageDate = useMemo(() => {
    let date = null;
    const sentMessageStatusId = messageStatusList?.find((status) => status.configChoice === "scheduled")?.id;

    if (sentMessageStatusId && sentMessageStatusId === newMessage?.status && newMessage?.scheduledTime) {
      date = convertUTCToLocal(newMessage?.scheduledTime);
    } else if (newMessage?.updatedAt) {
      date = newMessage?.updatedAt;
    } else {
      date = newMessage?.createdAt;
    }

    return date;
  }, [messageStatusList, newMessage]);

  const setActionLoader = (action) => {
    switch (action) {
      case "isStarred":
        setLoadingAction({ ...loadingAction, isStarred: true });
        break;
      case "isImportant":
        setLoadingAction({ ...loadingAction, isImportant: true });
        break;
      case "isRead":
        setLoadingAction({ ...loadingAction, isRead: true });
        break;

      default:
        setLoadingAction({ isStarred: false, isImportant: false, isRead: false });
        break;
    }
  };

  const handleUpdateStatus = (statusName) => {
    const prevStatus = getMessageStatus(newMessage?.messageTo);
    const payload = { ...prevStatus, messageTo: userInfo.id };
    payload[statusName] = !prevStatus[statusName];

    dispatch(updateMessageStatus(newMessage?.id, payload, filters));

    setActionLoader(statusName);
  };

  const initialValues = {
    parent: "",
    title: "",
    description: "",
    messageFrom: userInfo?.id,
    messageTo: [],
    messageGroupId: "",
    media: [],
    status: "",
  };

  const schema = Yup.object().shape({
    description: Yup.string().required("Message is required."),
    // messageTo: Yup.array().test({
    //   test: function (value) {
    //     const messageGroupId = this.resolve(Yup.ref("messageGroupId"));
    //     if ((!value || value.length === 0) && (!messageGroupId || messageGroupId.length === 0)) {
    //       return this.createError({
    //         path: this.path,
    //         message: "Please specify at least one recipient recipient.",
    //       });
    //     }

    //     return true;
    //   },
    // }),
    // messageGroupId: Yup.string().test({
    //   test: function (value) {
    //     const messageTo = this.resolve(Yup.ref("messageTo"));
    //     if (!messageTo || messageTo.length === 0) {
    //       return this.createError({
    //         path: this.path,
    //         message: "Please specify at least one recipient group.",
    //       });
    //     }

    //     return true;
    //   },
    // }),
  });

  const handleOnDelete = () => {
    dispatch(deleteMessage(newMessage?.id, filters));
  };

  const scrollToContent = () => {
    const content = document.getElementById("messagePreviewArea");
    content.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const handleOnForward = () => {
    const messageFrom = newMessage?.messageFromUser;
    const messageTo = newMessage?.messageTo?.map(
      (receiver) => `${receiver?.messageToUser?.name} &lt;${receiver?.messageToUser?.email}&gt;`
    );

    const forwardMessage = `
    ---------- Forwarded message --------- <br>
    From: ${messageFrom.name} <${messageFrom.email}><br>
    Date: ${format(parseISO(messageFrom.createdAt), "EEE, MMM dd, yyyy hh:mm hh:mm aa")}<br>
    Subject: ${newMessage?.title} Reply/Forward Concept<br>
    To: ${messageTo ? messageTo.join(", ") : ""}
    <br><br>

    ${newMessage?.description}
    `;

    formikRef.current.setFieldValue("title", `FWD - ${newMessage?.title}`);
    formikRef.current.setFieldValue("description", forwardMessage);
    formikRef.current.setFieldValue("media", newMessage?.media);

    setIsForward(true);
    scrollToContent();
  };

  const handleDiscardMessage = () => {
    formikRef.current.resetForm();
    setIsForward(false);
  };

  const onSubmit = (values) => {
    if (!isForward) {
      // ### This should be defined only for reply
      values.parent = newMessage?.id;
      values.title = `RE - ${newMessage?.title}`;
      values.messageTo = [newMessage.messageFrom];
      values.isForward = 0;
    } else {
      values.isForward = 1;
      delete values.parent;
    }

    values.status = messageStatusList.find((status) => status.configChoice === "sent").id;

    if (!frmLoading) {
      dispatch(composeMessage(values, { currentMenu: currentMenu, userId: userInfo?.id }));
    }
  };

  useEffect(() => {
    if (!frmLoading && frmSucess) {
      formikRef.current.resetForm();
      setIsForward(false);
    }
  }, [frmLoading, frmSucess]);

  useEffect(() => {
    if (!delLoading && success && openDeleteAlert) {
      updateContext({ newMessage: null });
    }
  }, [delLoading, openDeleteAlert]);

  useEffect(() => handleDiscardMessage(), [newMessage]);

  useEffect(() => {
    if (!isUpdateLoading && !fetchLoading) {
      setActionLoader(false);
    }
  }, [isUpdateLoading, fetchLoading]);

  useEffect(() => {
    //## This code handles the flicker issue after
    //## updating the message status like starred
    if (selectedMessage) {
      setNewMessage((prev) => {
        if (prev?.id !== selectedMessage?.id) return selectedMessage;

        return selectedMessage;
      });
    } else {
      setNewMessage(null);
    }
  }, [selectedMessage]);

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ values, setFieldValue }) => (
          <Box component={Form} sx={{ maxHeight: "100%", display: "flex", flex: 1 }}>
            <StyledCard
              id="messagePreviewArea"
              className="custom-scrollbar"
              sx={{
                maxHeight: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                padding: 0,
                overflow: "auto",
              }}
            >
              {/* ### Skeleton Loader */}
              {(isMessageLoading || fetchLoading) && (
                <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  <PreviewContents
                    sx={{ alignItems: "center", borderBottom: `1px dashed ${theme.palette.grey.cadet}` }}
                  >
                    <Box sx={{ display: "flex", gap: 0.625, marginLeft: "auto" }}>
                      {[...Array(4)].map((_, index) => (
                        <Skeleton
                          key={index}
                          animation="wave"
                          variant="rounded"
                          width={24}
                          height={24}
                          sx={{ flexShrink: 0 }}
                        />
                      ))}
                    </Box>
                  </PreviewContents>

                  <PreviewContents sx={{ py: 1, borderBottom: `1px dashed ${theme.palette.grey.cadet}` }}>
                    <Skeleton animation="wave" height={18} width="80%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={12} width="40%" />

                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-end",
                        marginLeft: "auto",
                        textAlign: "right",
                        gap: 2,
                      }}
                    >
                      {[...Array(2)].map((_, index) => (
                        <Skeleton
                          key={index}
                          animation="wave"
                          variant="rounded"
                          width={24}
                          height={24}
                          sx={{ flexShrink: 0 }}
                        />
                      ))}
                    </Box>
                  </PreviewContents>

                  <PreviewContents>
                    <Box sx={{ width: "100%", display: "flex", padding: "8px 6px" }}>
                      <Skeleton animation="wave" variant="circular" width={42} height={42} sx={{ flexShrink: 0 }} />
                      <Box sx={{ width: "100%", ml: 1.85 }}>
                        <Skeleton animation="wave" height={18} width="50%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={12} width="20%" />
                      </Box>
                    </Box>
                    <Skeleton animation="wave" height={18} width="100%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={18} width="100%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={18} width="100%" style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={18} width="40%" />
                  </PreviewContents>
                </Box>
              )}

              {newMessage && !isDraft && (
                <>
                  {!isMessageLoading && !fetchLoading && (
                    <>
                      <PreviewContents
                        sx={{ alignItems: "center", borderBottom: `1px dashed ${theme.palette.grey.cadet}` }}
                      >
                        {/*### TODo: Message Type */}
                        {/* <StyledChip label="Information" variant="filled" color={theme.palette.info.main} /> */}

                        <Box sx={{ display: "flex", gap: 0.625, marginLeft: "auto" }}>
                          {/*### Menu actions should be only visible for inbox */}
                          {shouldShowMessageActions && (
                            <>
                              {/*### Hide for sent messages  */}
                              {newMessage?.messageFrom !== userInfo?.id && (
                                <>
                                  <Tooltip
                                    title={
                                      getMessageStatus(newMessage?.messageTo)?.isStarred
                                        ? "Remove stared"
                                        : "Set stared"
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      type="button"
                                      onClick={() => handleUpdateStatus("isStarred")}
                                      sx={{
                                        color: getMessageStatus(newMessage?.messageTo)?.isStarred
                                          ? theme.palette.warn.main
                                          : theme.palette.grey.cadet,
                                      }}
                                      className={`relative ${loadingAction.isImportant ? "pointer-events-none" : ""}`}
                                    >
                                      <StarRateRoundedIcon />
                                      {loadingAction.isStarred && (
                                        <StyledSpinner
                                          wrapperprops={{ className: "absolute top-1.5 left-1.5" }}
                                          size={22}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>

                                  {checkPermission(permissionList, "delete message") && (
                                    <Tooltip
                                      title={
                                        getMessageStatus(newMessage?.messageTo)?.isImportant
                                          ? "Remove important"
                                          : "Set important"
                                      }
                                    >
                                      <IconButton
                                        size="small"
                                        type="button"
                                        onClick={() => handleUpdateStatus("isImportant")}
                                        sx={{
                                          color: getMessageStatus(newMessage?.messageTo)?.isImportant
                                            ? theme.palette.warn.main
                                            : theme.palette.grey.cadet,
                                        }}
                                        className={`relative ${loadingAction.isImportant ? "pointer-events-none" : ""}`}
                                      >
                                        <LabelRoundedIcon />
                                        {loadingAction.isImportant && (
                                          <StyledSpinner
                                            wrapperprops={{ className: "absolute top-1.5 left-1.5" }}
                                            size={22}
                                          />
                                        )}
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                  <Tooltip
                                    title={
                                      getMessageStatus(newMessage?.messageTo)?.isRead
                                        ? "Mark as unread"
                                        : "Mark as read"
                                    }
                                  >
                                    <IconButton
                                      size="small"
                                      type="button"
                                      onClick={() => handleUpdateStatus("isRead")}
                                      sx={{
                                        color: getMessageStatus(newMessage?.messageTo)?.isRead
                                          ? theme.palette.grey.cadet
                                          : theme.palette.info.main,
                                      }}
                                      className={`relative ${loadingAction.isImportant ? "pointer-events-none" : ""}`}
                                    >
                                      <MarkEmailUnreadRoundedIcon />
                                      {loadingAction.isRead && (
                                        <StyledSpinner
                                          wrapperprops={{ className: "absolute top-1.5 left-1.5" }}
                                          size={22}
                                        />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          )}

                          {checkPermission(permissionList, "delete message") && (
                            <Tooltip title="Delete">
                              <IconButton
                                size="small"
                                type="button"
                                onClick={() => setOpenDeleteAlert(true)}
                                sx={{ color: theme.palette.error.main }}
                              >
                                <DeleteForeverRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </PreviewContents>

                      <PreviewContents sx={{ py: 1, borderBottom: `1px dashed ${theme.palette.grey.cadet}` }}>
                        <Typography component="h5" variant="body1" sx={{ fontSize: 16, fontWeight: 500 }}>
                          {newMessage?.title}
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "flex-end",
                            marginLeft: "auto",
                            textAlign: "right",
                          }}
                        >
                          {/* <IconButton size="small" onClick={() => scrollToContent()}>
                        <ReplyAllRoundedIcon />
                      </IconButton> */}

                          {!isSchedule && (
                            <Tooltip title="Forward">
                              <IconButton
                                size="small"
                                sx={{ transform: "rotateY(180deg)" }}
                                onClick={() => handleOnForward()}
                              >
                                <ReplyAllRoundedIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {messageDate && (
                            <Typography
                              component="small"
                              variant="body2"
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                color: theme.palette.grey.cadet,
                              }}
                            >
                              <span>{!!newMessage?.scheduledTime && "Scheduled to"}</span>
                              {format(parseISO(messageDate), "dd MMM yyyy HH:mm")}
                            </Typography>
                          )}
                        </Box>
                      </PreviewContents>

                      <PreviewContents>
                        <UserMessage message={newMessage} shouldExpandDefault={!hasReplies} />
                      </PreviewContents>

                      {hasReplies && (
                        <>
                          <Box sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
                            <Typography
                              sx={{
                                position: "relative",
                                px: 2.5,
                                backgroundColor: theme.palette.background.paper,
                                border: `1px dashed ${theme.palette.grey.cadet}`,
                                borderRadius: 3,
                                color: theme.palette.grey.cadet,
                                fontWeight: "medium",
                                zIndex: 1,
                              }}
                            >
                              Replies
                            </Typography>
                            <Box
                              component="hr"
                              sx={{
                                width: "100%",
                                position: "absolute",
                                top: "50%",
                                margin: 0,
                                borderBottom: `1px dashed ${theme.palette.grey.cadet}`,
                                transform: "translateY(-50%)",
                              }}
                            />
                          </Box>

                          <Box className="divide-y divide-[#919EAB] divide-opacity-[0.15]">
                            {newMessage?.childrenMessage.map((reply, index) => (
                              <PreviewContents key={reply.id}>
                                <UserMessage
                                  message={reply}
                                  shouldExpandDefault={newMessage?.childrenMessage.length - 1 === index} // ### Expand last reply
                                />
                              </PreviewContents>
                            ))}
                          </Box>
                        </>
                      )}

                      {!isSchedule && (
                        <PreviewContents sx={{ mt: "auto" }}>
                          {isForward && (
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                              <Grid item xs={12} xl={6}>
                                <MessageToSelect variant="outlined" />
                              </Grid>

                              {/* <Grid item xs={12} xl={6}>
                              {checkPermission(permissionList, "list message group") && <MessageGroupSelect />}
                            </Grid> */}
                            </Grid>
                          )}
                          <Editor name="description" />
                        </PreviewContents>
                      )}

                      {!!values?.media?.length && (
                        <PreviewContents
                          sx={{
                            width: "auto",
                            display: "flex",
                            flexWrap: "wrap",
                            mt: 3,
                            py: 1,
                            px: 2,
                            mx: 2,
                            borderRadius: 1,
                            backgroundColor: theme.palette.blue.shadowBlue,
                          }}
                        >
                          <Box
                            component="span"
                            sx={{ width: "100%", display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
                            onClick={() => setExpandAttachment(!expandAttachment)}
                          >
                            <AttachFileIcon />
                            {values?.media?.length} attachments
                            <KeyboardArrowDownRoundedIcon className={expandAttachment ? "rotate-180" : ""} />
                          </Box>

                          <Box
                            sx={{
                              width: "100%",
                              display: expandAttachment ? "flex" : "none",
                              flexWrap: "wrap",
                              gap: 1.75,
                              mt: 1.625,
                              p: 0,
                              listStyle: "none",
                            }}
                            component="div"
                          >
                            {values.media.map((file, index) => (
                              <Box
                                // onClick={() => setViewDocument([{ uri: doc?.media?.[0].url }])}
                                key={index}
                                sx={{
                                  width: "40px",
                                  height: "40px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "relative",
                                  borderRadius: 1,
                                  backgroundColor: alpha(theme.palette.blue.yankeesBlue, 0.25),
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                                className="group"
                              >
                                {getFileType(file?.name || "") === "image" ? (
                                  <img src={file.url} className="w-full h-full object-cover" />
                                ) : (
                                  <IconFiles filetype={getFileType(file?.name || "")} />
                                )}

                                <IconButton
                                  type="button"
                                  size="sm"
                                  sx={{ position: "absolute", top: 0, right: 0, color: theme.palette.error.main }}
                                  className="opacity-0 group-hover:opacity-80 group-hover:bg-white/80"
                                  onClick={() => {
                                    setFieldValue(
                                      "media",
                                      values.media.filter((_, i) => i !== index)
                                    );
                                  }}
                                >
                                  <DeleteRoundedIcon />
                                </IconButton>
                              </Box>
                            ))}
                          </Box>
                        </PreviewContents>
                      )}

                      {!isSchedule && (
                        <PreviewContents component="footer">
                          <IconButton
                            type="button"
                            size="small"
                            sx={{
                              width: "35px",
                              height: "35px",
                              position: "sticky",
                              bottom: 0,
                              flexShrink: 0,
                              color: theme.palette.text.main,
                            }}
                            onClick={() => setOpenDialog({ open: true, fileType: "image/*" })}
                          >
                            <AddPhotoAlternateRoundedIcon />
                          </IconButton>

                          <IconButton
                            type="button"
                            size="small"
                            sx={{ width: "35px", height: "35px", flexShrink: 0, color: theme.palette.text.main }}
                            onClick={() => setOpenDialog({ open: true, fileType: undefined })}
                          >
                            <AttachFileIcon />
                          </IconButton>

                          <Box sx={{ display: "flex", gap: 2, marginLeft: "auto" }}>
                            {!!values.description && (
                              <StyledButton
                                type="button"
                                color={theme.palette.error.main}
                                variant="outlined"
                                size="sm"
                                disabled={frmLoading}
                                onClick={() => handleDiscardMessage()}
                              >
                                Discard <SpeakerNotesOffRoundedIcon sx={{ width: 18 }} />
                              </StyledButton>
                            )}

                            <StyledButton type="submit" size="sm" isloading={frmLoading}>
                              Send <SendRoundedIcon sx={{ width: 18 }} />
                            </StyledButton>
                          </Box>
                        </PreviewContents>
                      )}
                    </>
                  )}
                </>
              )}

              {/* ### Empty State */}
              {((!isMessageLoading && !fetchLoading && !newMessage) || isDraft) && (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <img src={emptyChat} />
                  <Typography component="h5" sx={{ mt: "15px", fontSize: "18px", fontWeight: "medium" }}>
                    No Messages
                  </Typography>
                  <Typography color={theme.palette.grey.cadet}>Your message will appear here.</Typography>
                </Box>
              )}
            </StyledCard>

            {openDialog && (
              <UploadFileModal
                openDialog={openDialog}
                handleClose={() => setOpenDialog({ open: false, fileType: undefined })}
              />
            )}
          </Box>
        )}
      </Formik>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => setOpenDeleteAlert(false)}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected message?"
        loadingInfo="Message is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => dispatch(resetMessage())}
      />
    </>
  );
};

export default React.memo(MessagePreview);
