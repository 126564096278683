import React from "react";
import { alpha, Box, Drawer, IconButton, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getHome } from "reduxs/actions";
import { appBarConfig } from "configs/LayoutConfig";
import { StyledButton } from "ui";
import SelectionItemList from "./SelectionItemList";
import graphics from "assets/images/home-placeholder.jpg";
import { useDispatch, useSelector } from "react-redux";

const SummaryDrawer = styled(Drawer)(({ theme, open }) => ({
  minWidth: 280,
  width: useMediaQuery(theme.breakpoints.up("lg")) ? "24.306vw" : "100%",
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    maxHeight: window.innerHeight * 0.85,
    minWidth: 280,
    width: useMediaQuery(theme.breakpoints.up("lg")) ? "24.306vw" : "100%",
    position: useMediaQuery(theme.breakpoints.up("lg")) ? "sticky" : "fixed",
    top: useMediaQuery(theme.breakpoints.up("lg")) ? appBarConfig.height + 24 : "auto",
    padding: 15,
    border: "none",
    borderRadius: useMediaQuery(theme.breakpoints.up("lg")) ? "16px" : "16px 16px 0 0",
    boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
    backgroundColor: theme.palette.layout.main,
    boxSizing: "border-box",
    zIndex: useMediaQuery(theme.breakpoints.up("lg")) ? 1 : "",
  },
}));

const SelectionSummary = (props) => {
  const { homeid, productlist, selectionList } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const { homeData } = useSelector((state) => state.home);

  const [openMobileDrawer, setOpenMobileDrawer] = React.useState(false);

  React.useEffect(() => {
    if (homeid) {
      dispatch(getHome(homeid));
    }
  }, [homeid]);

  return (
    <>
      {/* Desktop Drawer */}
      {useMediaQuery(theme.breakpoints.up("lg")) && (
        <SummaryDrawer classes={{ paper: "custom-scrollbar" }} variant="permanent">
          {homeData && (
            <Box component="figure" className="w-full aspect-video flex-shrink-0 m-0 rounded-lg overflow-hidden">
              <img
                src={homeData?.media?.[0]?.url || graphics}
                alt={homeData?.name}
                className="w-full object-cover aspect-video"
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              top: "-15px",
              mb: 1.5,
              mx: -1.875,
              py: 1.5,
              px: 1.875,
              borderBottom: `1px solid ${theme.palette.slate.A100}`,
              backgroundColor: alpha(theme.palette.layout.main, 0.75),
              zIndex: 2,
            }}
            className="rounded-t-2xl"
          >
            <Typography component="h4" className="text-base font-bold">
              Selection Summary
            </Typography>
          </Box>

          <SelectionItemList productlist={productlist} selectionList={selectionList} />
        </SummaryDrawer>
      )}

      {/* Mobile Drawer */}
      {useMediaQuery(theme.breakpoints.down("lg")) && selectionList?.length && (
        <>
          <SummaryDrawer
            classes={{ paper: "custom-scrollbar" }}
            variant="temporary"
            open={openMobileDrawer}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            anchor="bottom"
            onClose={() => setOpenMobileDrawer(false)}
          >
            {homeData && (
              <Box component="figure" className="w-full aspect-video flex-shrink-0 m-0 overflow-hidden rounded-lg">
                <img
                  src={homeData?.media?.[0]?.url || graphics}
                  alt={homeData?.name}
                  className="w-full object-cover aspect-video"
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "sticky",
                top: "-15px",
                mb: 1.5,
                mx: -1.875,
                py: 1.5,
                px: 1.875,
                zIndex: 2,
              }}
              className="bg-white/75 backdrop-blur-sm border-b border-solid border-slate-100 rounded-t-2xl"
            >
              <Typography component="h4" className="text-black text-base font-bold">
                Selection Summary
              </Typography>

              <IconButton size="small" onClick={() => setOpenMobileDrawer(false)}>
                <HighlightOffIcon />
              </IconButton>
            </Box>

            <SelectionItemList productlist={productlist} selectionList={selectionList} />
          </SummaryDrawer>

          <StyledButton
            color={theme.palette.common.dark}
            sx={{ justifyContent: "flex-start", position: "sticky", bottom: 10, mt: 2.5, borderRadius: 4, zIndex: 1 }}
            onClick={() => setOpenMobileDrawer(true)}
          >
            <ImagesearchRollerIcon />
            {selectionList.length || 0} items
            <span className="ml-auto">View summary</span>
          </StyledButton>
        </>
      )}
    </>
  );
};

export default SelectionSummary;
