import React from "react";
import { Box, CircularProgress, circularProgressClasses, useTheme } from "@mui/material";

const StyledSpinner = (props) => {
  const { size = 40, thickness = 4, trailcolor, spinnercolor, wrapperprops, ...others } = props;

  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }} {...wrapperprops}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: trailcolor || theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        }}
        size={size}
        thickness={thickness}
        value={100}
        {...others}
      />

      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: spinnercolor || (theme.palette.mode === "light" ? "#038C4C" : "#308fe8"),
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={thickness}
        {...others}
      />
    </Box>
  );
};

export default StyledSpinner;
