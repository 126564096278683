import React, { useRef } from "react";
import { useField } from "formik";
import { Box, Typography, useTheme, styled, alpha } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Quill = styled(ReactQuill)(({ theme }) => ({
  height: "calc(100% - 40px)",
  display: "flex",
  flexDirection: "column",
  // border: `1px solid ${alpha(theme.palette.grey.cadet, 0.2)}`,
  borderRadius: 12,

  "& .ql-toolbar.ql-snow": {
    border: `1px solid ${alpha(theme.palette.grey.cadet, 0.2)}`,
    borderBottom: 0,
    borderRadius: "12px 12px 0 0",
    backgroundColor: theme.palette.background.paper,

    "& .ql-picker-label": {
      color: theme.palette.text.primary,
      "&:hover": { color: theme.palette.primary.main, "& .ql-stroke": { stroke: theme.palette.primary.main } },
    },
    "& .ql-stroke": { stroke: theme.palette.text.primary },
    "& .ql-fill": { fill: theme.palette.text.primary },

    "& .ql-picker.ql-expanded .ql-picker-label": { border: "none" },

    "& .ql-picker-options": {
      padding: 0,
      borderColor: `${alpha(theme.palette.grey.cadet, 0.2)} !important`,
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,

      "& .ql-picker-item": {
        padding: "8px 12px",
        color: theme.palette.text.primary,

        "&:not(:last-child)": {
          borderBottom: `1px solid ${alpha(theme.palette.grey.cadet, 0.2)}`,
        },
      },
    },

    "& .ql-formats button": {
      "&:hover": {
        "& .ql-stroke": { stroke: theme.palette.primary.main },
        "& .ql-fill": { fill: theme.palette.primary.main },
      },
    },
  },
  "& .ql-container.ql-snow": {
    flex: 1,
    border: "none",
    "& .ql-editor": {
      minHeight: 200,
      height: "100%",
      borderRadius: "0 0 12px 12px",
      border: `1px solid ${alpha(theme.palette.grey.cadet, 0.2)}`,
      borderTop: 0,
      backgroundColor: alpha(theme.palette.grey.cadet, 0.08),

      "&:before": { fontStyle: "normal", color: theme.palette.grey.cadet },
      "&::-webkit-scrollbar": {
        width: 4,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#e8e8e8",
        borderRadius: 100,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: 100,
      },
    },
  },
}));

const Editor = ({ name, label, wrapperProps, ...props }) => {
  const theme = useTheme();
  const quillRef = useRef(null);

  const [field, meta, helpers] = useField(name);

  const handleChange = (html) => {
    let currentVal = html;
    const isEmpty = /^<p>(<br>)*<\/p>+$/.test(html); // Check if the html only contains empty <p> tags with <br> elements

    // Clear the existing HTML content in the Quill editor if it's empty
    if (isEmpty && quillRef.current) {
      quillRef.current.getEditor().setText("");
      currentVal = null;
    }

    helpers.setValue(currentVal);
  };

  return (
    <Box
      component="fieldset"
      sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "1px", padding: 0, overflow: "hidden" }}
      {...wrapperProps}
    >
      {label && (
        <Typography component="label" variant="body1" sx={{ color: theme.palette.text.primary }}>
          {label}
        </Typography>
      )}

      <Quill
        ref={quillRef}
        theme={theme}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link"], //"image", "video"
            ["clean"],
          ],
        }}
        value={field.value || ""}
        placeholder="Write something awesome..."
        onChange={handleChange}
        onBlur={() => helpers.setTouched(true)}
        {...props}
      />

      {meta.touched && meta.error ? (
        <Box sx={{ mt: 1, mx: 1.75, color: theme.palette.error.main, fontSize: 12 }}>{meta.error}</Box>
      ) : null}
    </Box>
  );
};

export default Editor;
