import { useEffect, useState } from "react";
import { startOfDay, subDays, isSameDay } from "date-fns";

const useGetNotificationByDate = (notifications) => {
  const [separatedData, setSeparatedData] = useState({
    todayData: [],
    yesterdayData: [],
    olderData: [],
  });

  useEffect(() => {
    if (notifications && notifications?.length) {
      const today = startOfDay(new Date());
      const yesterday = startOfDay(subDays(new Date(), 1));

      const todayData = [];
      const yesterdayData = [];
      const olderData = [];

      notifications.forEach((item) => {
        const createdAt = startOfDay(new Date(item.createdAt));

        if (isSameDay(createdAt, today)) {
          todayData.push(item);
        } else if (isSameDay(createdAt, yesterday)) {
          yesterdayData.push(item);
        } else {
          olderData.push(item);
        }
      });

      setSeparatedData([
        { title: "Today", notification: todayData },
        { title: "Yesterday", notification: yesterdayData },
        { title: "Older", notification: olderData },
      ]);
    }
  }, [notifications]);

  return separatedData;
};

export default useGetNotificationByDate;
