import React from "react";
import { Box, LinearProgress, Typography, styled, useTheme } from "@mui/material";
import { pink, purple, deepPurple } from "@mui/material/colors";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";

const MilestoneType = styled(Box, { shouldForwardProp: (prop) => prop !== "color" })(({ theme, color, active }) => ({
  minWidth: 300,
  position: "relative",
  padding: "15px 18px",
  border: `1px solid ${active ? color[500] : "transparent"}`,
  borderRadius: 16,
  background: theme.palette.background.paper,
  cursor: "pointer",
  mixBlendMode: active ? "" : "luminosity",

  "&:after": {
    content: '""',
    display: active ? "block" : "none",
    position: "absolute",
    top: "100%",
    left: 42,
    width: 10,
    height: 10,
    backgroundColor: color[500],
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: -1,
  },

  "& .MuiLinearProgress-root": {
    backgroundColor: "#F1F3F3",
    "& .MuiLinearProgress-bar": { backgroundColor: color[500] },
  },
}));

const MilestoneTypeCard = (props) => {
  const { data, isactive, ...other } = props;

  const theme = useTheme();

  const selectColor = React.useMemo(() => {
    if (data.id === 16) {
      return purple;
    } else if (data.id === 17) {
      return pink;
    } else {
      return deepPurple;
    }
  }, [data]);

  return (
    <MilestoneType color={selectColor} active={isactive} {...other}>
      <Box sx={{ display: "flex", gap: 1.5, mb: 2 }}>
        <Typography component="h4" sx={{ flex: 1, fontSize: 16, fontWeight: 700 }}>
          {data?.displayName}
        </Typography>

        <Box
          sx={{
            width: 36,
            height: 36,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            backgroundColor: selectColor[500],
            color: "#fff",
          }}
        >
          {data?.icon}
        </Box>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mb: 0.625, gap: 1.5 }}>
        <Box sx={{ display: "flex", alignItems: "center", flex: 1, gap: 0.625, fontSize: 12 }}>
          <BuildRoundedIcon sx={{ width: 12, height: 12 }} />
          {data?.total?.completed}/{data?.total?.items}
          <Typography component="span" sx={{ color: theme.palette.grey.cadet }}>
            Completed
          </Typography>
        </Box>

        <Typography component="span" sx={{ fontSize: 12, fontWeight: 500 }}>
          {data?.total?.percent.toFixed(0)}%
        </Typography>
      </Box>
      <LinearProgress variant="determinate" value={data?.total?.percent || 0} sx={{ borderRadius: 2 }} />
    </MilestoneType>
  );
};

export default MilestoneTypeCard;
