const useGetColorByLetter = () => {
  const getLetterColor = (letter) => {
    // Convert the letter to a numeric value
    const charCode = letter.charCodeAt(0);

    // Use the numeric value to determine the hue (0-360)
    const hue = (charCode % 26) * 15;

    // Convert the hue to an HSL color
    return `hsl(${hue}, 70%, 50%)`;
  };

  return getLetterColor;
};

export default useGetColorByLetter;
