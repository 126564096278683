import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import RoofingIcon from "@mui/icons-material/Roofing";
import HouseIcon from "@mui/icons-material/House";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument, getDocumentList, getMultipleConfigChoice, resetDocument } from "reduxs/actions";
import { AlertDialog, FloatingForm, StyledAccordion, StyledSpinner, StyledTabs } from "ui";
import DocumentHead from "./DocumentHead";
import DocumentListTable from "./DocumentListTable";
import DocumentForm from "./Form";

const Document = (props) => {
  const { addnew, handleFormClose } = props;

  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();

  const { documentList, loading, delLoading, success } = useSelector((state) => state.document);
  const { multipleChoiceList } = useSelector((state) => state.shared);

  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [formId, setFormId] = useState();
  const [showForm, setShowForm] = useState();
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [deleteId, setDeleteId] = useState(null);

  const openForm = React.useMemo(() => showForm || addnew, [addnew, showForm]);

  const generateTypeIcons = (typeName) => {
    if (typeName === "pre-construction") {
      return <RoofingIcon />;
    } else if (typeName === "post-construction") {
      return <HouseIcon />;
    }
  };

  const getDocuments = (tab) => {
    dispatch(
      getDocumentList({
        userHomeId: params.userHomeId,
        type: tab.id || multipleChoiceList?.milestoneType?.[0].id,
      })
    );
  };

  const handleExpandToggle = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const handleTabChange = (event, newValue) => {
    const active = multipleChoiceList?.milestoneType.find((item) => item.id === newValue);
    setActiveTab(active);
    getDocuments(active);
  };

  const mileStoneTabs = React.useMemo(() => {
    let types = [];
    if (multipleChoiceList?.milestoneType?.length) {
      if (!activeTab) {
        setActiveTab(multipleChoiceList?.milestoneType?.[0]); // Set defulat active tab
      }

      types = multipleChoiceList?.milestoneType?.map((type) => ({
        ...type,
        icon: generateTypeIcons(type.configChoice),
      }));
    }

    return types;
  }, [multipleChoiceList]);

  const resetAll = () => {
    setFormId("");
    handleFormClose(false);
    setShowForm(false);
    setSelectedMilestone("");
  };

  useEffect(() => {
    if (multipleChoiceList?.milestoneType?.length) {
      getDocuments(activeTab);
    }
  }, [multipleChoiceList]);

  useEffect(() => {
    dispatch(getMultipleConfigChoice(["milestoneType", "milestoneStatus"]));
  }, []);

  return (
    <>
      {mileStoneTabs && (
        <StyledTabs value={activeTab?.id} onChange={handleTabChange} sx={{ mb: 5 }}>
          {mileStoneTabs?.map((type) => {
            return (
              <StyledTabs.TabItem
                key={type.id}
                value={type.id}
                count={type.totalItem}
                icon={type.icon}
                iconPosition="start"
                label={type.displayName}
                wrapped
              />
            );
          })}
        </StyledTabs>
      )}

      <Box display="flex" flexDirection="column" gap={2.5} mt={5}>
        {documentList?.map((userHomeMilestone) => {
          return (
            <StyledAccordion
              key={userHomeMilestone.id}
              label={
                <DocumentHead
                  data={userHomeMilestone}
                  showbutton={expanded === userHomeMilestone.id}
                  handleclickadd={(e) => {
                    setShowForm(true);
                    setSelectedMilestone(userHomeMilestone.id);
                  }}
                />
              }
              labelprops={{
                onClick: () => {
                  handleExpandToggle(userHomeMilestone.id);
                },
                expandIcon: <ExpandMoreIcon />,
              }}
              wrapperprops={{
                expanded: expanded === userHomeMilestone.id,
                sx: {
                  boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
                },
              }}
            >
              <DocumentListTable
                documents={userHomeMilestone?.documents}
                handleedit={(id) => {
                  setShowForm(true);
                  setFormId(id);
                }}
                handledelete={(id) => setDeleteId(id)}
              />
            </StyledAccordion>
          );
        })}
      </Box>

      {/* Floating Form */}
      <FloatingForm
        open={openForm}
        handleclose={() => {
          handleFormClose(false);
          setShowForm(false);
        }}
        title={formId ? "Edit document" : "Upload document"}
      >
        <DocumentForm
          handleClose={(submit) => {
            resetAll();
            if (submit) {
              getDocuments(activeTab);
            }
          }}
          activeTab={activeTab?.id}
          editId={formId}
          milestone={selectedMilestone}
        />
      </FloatingForm>

      <AlertDialog
        open={!!deleteId}
        handleCancel={() => setDeleteId(null)}
        handleAction={() => {
          if (!delLoading && deleteId) dispatch(deleteDocument(deleteId));
        }}
        title="Delete"
        info="Are you sure to permanently delete selected document?"
        loadingInfo="Document is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => {
          dispatch(resetDocument());
          getDocuments(activeTab);
        }}
      />

      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default Document;
