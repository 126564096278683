import React from "react";
import { TextField, useTheme } from "@mui/material";
import { useField } from "formik";
import InputFieldStyles from "styles/form/InputField.style";

const InputField = ({ label, variant, shrinkLabel = false, dense = false, ...props }) => {
  const [field, meta] = useField(props);

  const theme = useTheme();
  const FieldStyles = InputFieldStyles(theme);

  return (
    <TextField
      label={label || null}
      InputLabelProps={shrinkLabel ? { shrink: true } : {}}
      variant={variant || "outlined"}
      helperText={meta.touched && meta.error ? meta.error : null}
      error={meta.touched && meta.error ? true : false}
      margin="dense"
      fullWidth
      sx={FieldStyles}
      className={dense ? "densed-field" : ""}
      {...field}
      {...props}
    />
  );
};

export default InputField;
