import React from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useDrag, useDrop } from "react-dnd";

const DND_ITEM_TYPE = "row";

const Row = ({ row, index, draggable = false, selectedRow, moveRow, handleRowClick }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item?.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > (hoverBoundingRect.bottom - hoverBoundingRect.top) / 1.5) {
          return;
        }
        // Time to actually perform the action

        moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    }),
    [row]
  );

  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: DND_ITEM_TYPE,
      // item: row?.original,
      item: { type: DND_ITEM_TYPE, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      canDrag: draggable,
    }),
    [row]
  );

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr
      ref={dropRef}
      style={{ opacity }}
      key={row.id}
      {...row.getRowProps()}
      // eslint-disable-next-line react/no-unknown-property
      index={row.id}
      className={`
      ${row.values?.groupName ? "group" : "columns"} ${row.isExpanded ? "expanded" : ""} 
      ${!row.values?.groupName && row.values?.groupName !== "" ? "group-child" : ""}
      ${selectedRow?.includes(row?.id) ? "bg-green-500 text-white svg-white" : ""}
      `}
      onClick={() => {
        handleRowClick && handleRowClick(row);
      }}
    >
      {draggable && (
        <td ref={dragRef} className="!px-0 cursor-grab">
          <DragIndicatorIcon />
        </td>
      )}

      {row.cells.map((cell, k) => {
        return (
          <td
            key={k}
            {...cell.getCellProps()}
            className={`
          ${cell?.column?.Header === "Actions" ? "text-right" : "text-left"}`}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};

export default React.memo(Row);
