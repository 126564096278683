import {
  GET_MESSAGE_LIST,
  GET_MESSAGE_LIST_SUCCESS,
  GET_MESSAGE_LIST_ERROR,
  GET_MESSAGE,
  GET_MESSAGE_SUCCESS,
  GET_MESSAGE_ERROR,
  COMPOSE_MESSAGE,
  COMPOSE_MESSAGE_SUCCESS,
  COMPOSE_MESSAGE_ERROR,
  EDIT_MESSAGE,
  EDIT_MESSAGE_SUCCESS,
  EDIT_MESSAGE_ERROR,
  UPDATE_MESSAGE_STATUS,
  UPDATE_MESSAGE_STATUS_SUCCESS,
  UPDATE_MESSAGE_STATUS_ERROR,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_ERROR,
  RESET_MESSAGE,
} from "reduxs/actions";

const INIT_STATE = {
  messageList: [],
  messageData: null,
  success: false,
  frmSucess: false,
  loading: false,
  frmLoading: false,
  delLoading: false,
  error: null,
};

const messageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MESSAGE_LIST:
      return {
        ...state,
        loading: true,
        messageList: [],
        error: null,
      };
    case GET_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        messageList: action.payload,
        error: null,
      };
    case GET_MESSAGE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        messageList: [],
        error: action.payload,
      };
    case GET_MESSAGE:
      return { ...state, fetchLoading: true, error: null };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        messageData: action.payload,
        error: null,
      };
    case GET_MESSAGE_ERROR:
      return {
        ...state,
        fetchLoading: false,
        messageData: null,
        error: action.payload,
      };
    case COMPOSE_MESSAGE:
      return { ...state, frmLoading: true, error: null };
    case COMPOSE_MESSAGE_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        frmSucess: action.payload,
        error: null,
      };
    case COMPOSE_MESSAGE_ERROR:
      return {
        ...state,
        frmLoading: false,
        frmSucess: false,
        error: action.payload,
      };
    case EDIT_MESSAGE:
      return { ...state, frmLoading: true, error: null };
    case EDIT_MESSAGE_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        frmSucess: action.payload,
        error: null,
      };
    case EDIT_MESSAGE_ERROR:
      return {
        ...state,
        frmLoading: false,
        frmSucess: false,
        error: action.payload,
      };
    case UPDATE_MESSAGE_STATUS:
      return { ...state, isUpdateLoading: true, error: null };
    case UPDATE_MESSAGE_STATUS_SUCCESS:
      return {
        ...state,
        isUpdateLoading: false,
        success: action.payload,
        error: null,
      };
    case UPDATE_MESSAGE_STATUS_ERROR:
      return {
        ...state,
        isUpdateLoading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_MESSAGE:
      return { ...state, delLoading: true, error: null };
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_MESSAGE_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        frmLoading: false,
        isUpdateLoading: false,
        delLoading: false,
        success: false,
        error: null,
        frmSucess: false,
      };
    default:
      return { ...state };
  }
};
export default messageReducer;
