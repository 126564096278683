import {
  GET_ALL_MILESTONE,
  GET_ALL_MILESTONE_SUCCESS,
  GET_ALL_MILESTONE_ERROR,
  GET_MILESTONE_LIST,
  GET_MILESTONE_LIST_SUCCESS,
  GET_MILESTONE_LIST_ERROR,
  ADD_MILESTONE,
  ADD_MILESTONE_SUCCESS,
  ADD_MILESTONE_ERROR,
  GET_MILESTONE,
  GET_MILESTONE_SUCCESS,
  GET_MILESTONE_ERROR,
  EDIT_MILESTONE,
  EDIT_MILESTONE_SUCCESS,
  EDIT_MILESTONE_ERROR,
  SORT_MILESTONE,
  SORT_MILESTONE_SUCCESS,
  SORT_MILESTONE_ERROR,
  DELETE_MILESTONE,
  DELETE_MILESTONE_SUCCESS,
  DELETE_MILESTONE_ERROR,
  RESET_MILESTONE,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  milestones: null,
  milestoneList: null,
  milestoneData: null,
  milestoneId: null,
  success: false,
  loading: false,
  fetchLoading: false,
  frmLoading: false,
  delLoading: false,
  error: null,
};

const milestoneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MILESTONE:
      return {
        ...state,
        loading: true,
        milestoneData: null,
        milestoneId: null,
        error: null,
      };
    case GET_ALL_MILESTONE_SUCCESS:
      return {
        ...state,
        loading: false,
        milestones: action.payload,
        error: null,
      };
    case GET_ALL_MILESTONE_ERROR:
      return {
        ...state,
        loading: false,
        milestones: null,
        error: action.payload,
      };
    case GET_MILESTONE_LIST:
      return {
        ...state,
        loading: true,
        milestoneData: null,
        milestoneId: null,
        error: null,
      };
    case GET_MILESTONE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        milestoneList: action.payload,
        metaData: action.payload.metaData,
        error: null,
      };
    case GET_MILESTONE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        milestoneList: null,
        error: action.payload,
      };
    case ADD_MILESTONE:
      return { ...state, frmLoading: true, error: null };
    case ADD_MILESTONE_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case ADD_MILESTONE_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case GET_MILESTONE:
      return { ...state, fetchLoading: true, error: null };
    case GET_MILESTONE_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        milestoneData: action.payload,
        error: null,
      };
    case GET_MILESTONE_ERROR:
      return {
        ...state,
        fetchLoading: false,
        milestoneData: null,
        error: action.payload,
      };
    case EDIT_MILESTONE:
      return { ...state, frmLoading: true, error: null };
    case EDIT_MILESTONE_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_MILESTONE_ERROR:
      return {
        ...state,
        frmLoading: false,
        success: false,
        error: action.payload,
      };
    case SORT_MILESTONE:
      return { ...state, error: null };
    case SORT_MILESTONE_SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
      };
    case SORT_MILESTONE_ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case DELETE_MILESTONE:
      return { ...state, delLoading: true, error: null };
    case DELETE_MILESTONE_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_MILESTONE_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_MILESTONE:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        frmLoading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default milestoneReducer;
