import {
  GET_USER_HOME,
  GET_USER_HOME_SUCCESS,
  GET_USER_HOME_ERROR,
  ADD_USER_HOME,
  ADD_USER_HOME_SUCCESS,
  ADD_USER_HOME_ERROR,
  EDIT_USER_HOME,
  EDIT_USER_HOME_SUCCESS,
  EDIT_USER_HOME_ERROR,
  DELETE_USER_HOME,
  DELETE_USER_HOME_SUCCESS,
  DELETE_USER_HOME_ERROR,
  RESET_USER_HOME,
} from "reduxs/actions";

const INIT_STATE = {
  userHomeData: null,
  success: false,
  loading: false,
  assignFormLoading: false,
  delLoading: false,
  delSuccess: false,
  error: null,
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_HOME:
      return { ...state, loading: true, error: null };
    case GET_USER_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        userHomeData: action.payload,
        error: null,
      };
    case GET_USER_HOME_ERROR:
      return {
        ...state,
        loading: false,
        userHomeData: null,
        error: action.payload,
      };

    case ADD_USER_HOME:
      return { ...state, assignFormLoading: true, error: null };
    case ADD_USER_HOME_SUCCESS:
      return {
        ...state,
        assignFormLoading: false,
        success: action.payload,
        error: null,
      };
    case ADD_USER_HOME_ERROR:
      return {
        ...state,
        assignFormLoading: false,
        success: false,
        error: action.payload,
      };
    case EDIT_USER_HOME:
      return { ...state, assignFormLoading: true, error: null };
    case EDIT_USER_HOME_SUCCESS:
      return {
        ...state,
        assignFormLoading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_USER_HOME_ERROR:
      return {
        ...state,
        assignFormLoading: false,
        success: false,
        error: action.payload,
      };

    case DELETE_USER_HOME:
      return { ...state, delLoading: true, error: null };
    case DELETE_USER_HOME_SUCCESS:
      return {
        ...state,
        delLoading: false,
        delSuccess: action.payload,
        error: null,
      };
    case DELETE_USER_HOME_ERROR:
      return {
        ...state,
        delLoading: false,
        delSuccess: false,
        error: action.payload,
      };
    case RESET_USER_HOME:
      return {
        ...state,
        loading: false,
        userHomeData: null,
        assignFormLoading: false,
        delLoading: false,
        delSuccess: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default userReducer;
