import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";

const IconFiles = (props) => {
  const { filetype = "txt" } = props;
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      {filetype === "pdf" && (
        <g id="PDF">
          <path
            d="M6.3,0h8.4l7.1,7.4v14.4c0,1.8-1.5,3.3-3.2,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#B71D18"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
          <path
            fill="#FFFFFF"
            d="M6.6,18.7v-4.6h1.9c0.5,0,0.9,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1
		C9.4,16.9,9,17,8.6,17H7.8v1.6H6.6z M7.8,16h0.6c0.2,0,0.3,0,0.4-0.1C8.9,15.8,9,15.7,9,15.5s0-0.3-0.1-0.3
		c-0.1-0.1-0.2-0.1-0.4-0.1H7.8V16z M10.6,18.7v-4.6h1.6c0.3,0,0.6,0,0.9,0.1c0.3,0.1,0.5,0.2,0.8,0.4c0.2,0.2,0.4,0.4,0.5,0.7
		c0.1,0.3,0.2,0.6,0.2,1c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.6,0.1-0.9,0.1H10.6z
		 M11.8,17.7h0.3c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
		c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.3V17.7z M15.2,18.7v-4.6h3.2v1h-2.1v0.7H18v1h-1.7
		v1.9H15.2z"
          />
        </g>
      )}

      {filetype === "image" && (
        <g id="IMG">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#00AB55"
            d="M6.3,0h8.4l7.1,7.4v14.3c0,1.8-1.5,3.3-3.3,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M15.5,12.5H9.2c-0.5,0-0.9,0.4-0.9,0.9v4c0,0.5,0.4,0.9,0.9,0.9h6.3c0.5,0,0.9-0.4,0.9-0.9v-4
		C16.4,12.9,16,12.5,15.5,12.5z M10.9,13.6c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
		C10,14,10.4,13.6,10.9,13.6z M15.8,17.4c0,0.2-0.1,0.3-0.3,0.3H9.2c-0.2,0-0.3-0.1-0.3-0.3v-0.2l1.2-1.2l1,1c0.1,0.1,0.3,0.1,0.4,0
		l2.4-2.4l2,2V17.4z"
          />
        </g>
      )}

      {filetype === "excel" && (
        <g id="XLS">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#1B806A"
            d="M6.3,0h8.4l7.1,7.4v14.3c0,1.8-1.4,3.3-3.2,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
          <path
            fill="#FFFFFF"
            d="M10.8,18.2H9.6l-0.8-1.4l-0.8,1.4H6.7l1.4-2.4l-1.3-2.1h1.2l0.7,1.1l0.6-1.1h1.2l-1.3,2.1L10.8,18.2z
		 M11.2,18.2v-4.5h1.2v3.5h2v1H11.2z M16.3,18.3c-0.5,0-0.9-0.1-1.2-0.4c-0.3-0.3-0.5-0.6-0.6-1l1-0.3c0,0.2,0.1,0.4,0.3,0.6
		c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.1,0-0.3-0.1-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.4,0.2-0.7,0.5-0.9c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.5,0.5,0.6,0.8l-1,0.4c0-0.2-0.1-0.3-0.3-0.5
		c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.3,0.1,0.4,0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.2,0.5,0.2,0.7c0,0.4-0.2,0.8-0.5,1
		C17.2,18.1,16.8,18.3,16.3,18.3z"
          />
        </g>
      )}

      {filetype === "docs" && (
        <g id="Doc">
          <path
            fill="#00AB55"
            d="M6.3,0h8.4l7.1,7.4v14.4c0,1.8-1.5,3.3-3.3,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
          <path
            fill="#FFFFFF"
            d="M6,17.8v-4.1h1.5c0.3,0,0.6,0,0.8,0.1C8.6,13.9,8.8,14,9,14.2c0.2,0.2,0.4,0.4,0.5,0.6
		c0.1,0.3,0.2,0.6,0.2,0.9c0,0.4-0.1,0.7-0.2,0.9C9.4,17,9.2,17.2,9,17.3c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.5,0.1-0.8,0.1H6z
		 M7.1,16.9h0.3c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.1-0.3,0.1-0.6c0-0.2,0-0.4-0.1-0.6
		c-0.1-0.2-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.5-0.1H7.1V16.9z M12.2,17.9c-0.6,0-1.1-0.2-1.5-0.6
		c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5
		c0,0.6-0.2,1.1-0.6,1.5C13.3,17.7,12.8,17.9,12.2,17.9z M11.4,16.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3
		c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.6-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9
		C11.1,16.1,11.2,16.4,11.4,16.6z M16.8,17.9c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.6c0-0.7,0.2-1.2,0.6-1.6
		c0.4-0.4,0.9-0.6,1.5-0.6c0.5,0,1,0.1,1.3,0.4c0.3,0.3,0.5,0.6,0.6,1.1l-1,0.2c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.1-0.4-0.2-0.6-0.2
		c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.9s0.1,0.7,0.3,0.9c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2
		c0.2-0.1,0.2-0.3,0.3-0.5l1.1,0.2c-0.1,0.4-0.3,0.7-0.7,1C17.7,17.8,17.3,17.9,16.8,17.9z"
          />
        </g>
      )}

      {filetype === "other" && (
        <g id="TXT">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={theme.palette.grey.dark}
            d="M6.3,0h8.4l7.1,7.4v14.4c0,1.8-1.5,3.3-3.2,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill={theme.palette.grey.culturedLight}
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
          <path
            fill={theme.palette.grey.culturedLight}
            d="M7.7,18.3v-3.6H6.4v-1h3.8v1H8.9v3.6H7.7z M14.4,18.3h-1.2l-0.8-1.4l-0.8,1.4h-1.2l1.4-2.4l-1.3-2.1h1.2
		l0.7,1.1l0.6-1.1h1.2L13,15.8L14.4,18.3z M15.8,18.3v-3.6h-1.3v-1h3.8v1H17v3.6H15.8z"
          />
        </g>
      )}

      {filetype === "media" && (
        <g id="Media">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#078DEE"
            d="M6.3,0h8.4l7.1,7.4v14.4c0,1.8-1.5,3.3-3.2,3.3H6.3C4.5,25,3,23.5,3,21.7V3.3C3,1.5,4.5,0,6.3,0z"
          />
          <path
            fill="#FFFFFF"
            d="M15.3,14.1l-5.4-3.6c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.2-0.4,0.4h0v7.5h0c0,0.2,0.2,0.4,0.4,0.4
		c0.1,0,0.1,0,0.2-0.1l5.4-3.6c0.1-0.1,0.2-0.3,0.2-0.5C15.5,14.4,15.4,14.2,15.3,14.1z"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            enableBackground="new"
            d="M14.6,0v7.3h7.1L14.6,0z"
          />
        </g>
      )}
    </SvgIcon>
  );
};

export default IconFiles;
