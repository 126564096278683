import React, { useMemo, useState } from "react";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { format } from "date-fns";
import { useDashboard } from "./DashboardContext";
import { getFileType } from "helpers";
import { IconFiles } from "icons";
import { FloatingForm, SimpleTable, StatusChip, StyledButton, StyledCard } from "ui";
import { Link } from "react-router-dom";
import DocumentForm from "shared/modules/document/Form";
import { useDispatch } from "react-redux";
import { getUser, resetDocument } from "reduxs/actions";

const DocumentListCard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const { userData, fetchLoading: userLoading } = useSelector((state) => state.user);
  const { userInfo, userMilestones, configChoice, updateContext } = useDashboard();

  const [openForm, setOpenForm] = useState(false);
  const allDocuments = useMemo(
    () =>
      userInfo?.userHomes?.flatMap((home) =>
        home?.userHomeMilestones?.flatMap((milestone) =>
          milestone?.documents?.map((document) => ({
            ...document,
            homeName: home?.home?.name,
            milestoneName: milestone?.milestone?.name,
          }))
        )
      ),
    [userInfo]
  );

  const resetAll = () => {
    setOpenForm(false);
    dispatch(resetDocument());
  };

  return (
    <>
      <StyledCard sx={{ height: "580px", width: "100%", padding: 2.5 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 12,
            flexWrap: "wrap",
            marginBottom: 3,
          }}
        >
          <Typography component="h3" sx={{ fontSize: 18, fontWeight: 500 }}>
            All Home&apos;s Document
          </Typography>

          <StyledButton
            sx={{ minHeight: "30px", padding: "7px 10px" }}
            color={theme.palette.background.button}
            onClick={(e) => {
              e.stopPropagation();
              setOpenForm(true);
            }}
          >
            <FileUploadOutlinedIcon />
            Upload
          </StyledButton>
        </Box>

        <SimpleTable wrapperProps={{ sx: { maxHeight: "calc(100% - 60px)" } }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Home</th>
              <th>Milestone</th>
              <th width={150}>Modified at</th>
              <th>Status</th>
            </tr>
          </thead>
          <Box
            component="tbody"
            sx={{
              "& tr:nth-of-type(even)": {
                "& td": {
                  backgroundColor: alpha(theme.palette.grey.cadet, 0.16),
                },
              },
            }}
          >
            {allDocuments?.map((doc) => (
              <tr key={doc.id}>
                <td>
                  <Box
                    component={Link}
                    to={doc?.media?.[0]?.url}
                    target="_blank"
                    // onClick={() => setViewDocument([{ uri: doc?.media?.[0].url}])}
                    sx={{
                      maxWidth: 200,
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      color: theme.palette.text.primary,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    <IconFiles filetype={getFileType(doc?.media?.[0]?.fileName)} />
                    <div className="line-clamp-1">{doc?.name}</div>
                  </Box>
                </td>
                <td>{doc?.homeName}</td>
                <td>{doc?.milestoneName}</td>
                <td>{format(new Date(doc.updatedAt), "dd MMM yyyy")}</td>
                <td>
                  <StatusChip
                    status={doc.statusConfigChoice?.configChoice}
                    label={doc.statusConfigChoice?.displayName}
                  />
                </td>
              </tr>
            ))}
          </Box>
        </SimpleTable>
      </StyledCard>

      {/* Floating Form */}
      <FloatingForm
        open={openForm}
        handleclose={() => {
          setOpenForm(false);
        }}
        title="Upload document"
      >
        <DocumentForm
          hashomeselection={true}
          handleClose={(submit) => {
            resetAll();

            if (submit) {
              const userId = JSON.parse(localStorage.getItem("currentUser"))?.id;
              dispatch(getUser(userId));
              updateContext({ userInfo, userMilestones, configChoice });
            }
          }}
        />
      </FloatingForm>
    </>
  );
};

export default DocumentListCard;
