import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const MilestoneService = (function () {
  function _getAllMilestone(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/milestones/all?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _addMilestone(data) {
    return Req.post("/api/milestones", data);
  }
  function _getMilestone(id) {
    return Req.get(`/api/milestones/${id}`);
  }
  function _editMilestone(data, id) {
    return Req.put(`/api/milestones/${id}`, data);
  }
  function _sortMilestone(data) {
    return Req.put(`/api/milestones/sort`, data);
  }
  function _deleteMilestone(id) {
    return Req.delete(`/api/milestones/${id}`);
  }
  return {
    getAllMilestone: _getAllMilestone,
    addMilestone: _addMilestone,
    getMilestone: _getMilestone,
    editMilestone: _editMilestone,
    sortMilestone: _sortMilestone,
    deleteMilestone: _deleteMilestone,
  };
})();
export default MilestoneService;
