export const GET_ALL_USER_HOME_MILESTONE = "GET_ALL_USER_HOME_MILESTONE";
export const GET_ALL_USER_HOME_MILESTONE_SUCCESS = "GET_ALL_USER_HOME_MILESTONE_SUCCESS";
export const GET_ALL_USER_HOME_MILESTONE_ERROR = "GET_ALL_USER_HOME_MILESTONE_ERROR";
export const GET_USER_HOME_MILESTONE_LIST = "GET_USER_HOME_MILESTONE_LIST";
export const GET_USER_HOME_MILESTONE_LIST_SUCCESS = "GET_USER_HOME_MILESTONE_LIST_SUCCESS";
export const GET_SELECTED_USER_HOME_MILESTONE_LIST_SUCCESS = "GET_SELECTED_USER_HOME_MILESTONE_LIST_SUCCESS";
export const GET_USER_HOME_MILESTONE_LIST_ERROR = "GET_USER_HOME_MILESTONE_LIST_ERROR";
export const EDIT_USER_HOME_MILESTONE = "EDIT_USER_HOME_MILESTONE";
export const EDIT_USER_HOME_MILESTONE_SUCCESS = "EDIT_USER_HOME_MILESTONE_SUCCESS";
export const EDIT_USER_HOME_MILESTONE_ERROR = "EDIT_USER_HOME_MILESTONE_ERROR";
export const DELETE_USER_HOME_MILESTONE = "DELETE_USER_HOME_MILESTONE";
export const DELETE_USER_HOME_MILESTONE_SUCCESS = "DELETE_USER_HOME_MILESTONE_SUCCESS";
export const DELETE_USER_HOME_MILESTONE_ERROR = "DELETE_USER_HOME_MILESTONE_ERROR";
export const RESET_USER_HOME_MILESTONE = "RESET_USER_HOME_MILESTONE";
export const RESET_USER_HOME_MILESTONE_SUCCESS = "RESET_USER_HOME_MILESTONE_SUCCESS";

export const getAllUserHomeMilestone = (filterParam) => ({
  type: GET_ALL_USER_HOME_MILESTONE,
  payload: { filterParam },
});

export const getAllUserHomeMilestoneSuccess = (userHomeMilestones) => ({
  type: GET_ALL_USER_HOME_MILESTONE_SUCCESS,
  payload: userHomeMilestones,
});

export const getAllUserHomeMilestoneError = (error) => ({
  type: GET_ALL_USER_HOME_MILESTONE_ERROR,
  payload: error,
});

export const getUserHomeMilestoneList = (dbParam) => ({
  type: GET_USER_HOME_MILESTONE_LIST,
  payload: { dbParam },
});

export const getUserHomeMilestoneListSuccess = (userHomeMilestoneList) => ({
  type: GET_USER_HOME_MILESTONE_LIST_SUCCESS,
  payload: userHomeMilestoneList,
});

export const getSelectedUserHomeMilestoneListSuccess = (data) => ({
  type: GET_SELECTED_USER_HOME_MILESTONE_LIST_SUCCESS,
  payload: data,
});

export const getUserHomeMilestoneListError = (error) => ({
  type: GET_USER_HOME_MILESTONE_LIST_ERROR,
  payload: error,
});

export const editUserHomeMilestone = (userHomeMilestoneId, userHomeMilestoneData, dbParam) => ({
  type: EDIT_USER_HOME_MILESTONE,
  payload: { userHomeMilestoneId, userHomeMilestoneData, dbParam },
});

export const editUserHomeMilestoneSuccess = (success) => ({
  type: EDIT_USER_HOME_MILESTONE_SUCCESS,
  payload: success,
});

export const editUserHomeMilestoneError = (error) => ({
  type: EDIT_USER_HOME_MILESTONE_ERROR,
  payload: error,
});

export const deleteUserHomeMilestone = (userHomeMilestoneId, userHomeId) => ({
  type: DELETE_USER_HOME_MILESTONE,
  payload: { userHomeMilestoneId, userHomeId },
});

export const deleteUserHomeMilestoneSuccess = (success) => ({
  type: DELETE_USER_HOME_MILESTONE_SUCCESS,
  payload: success,
});

export const deleteUserHomeMilestoneError = (error) => ({
  type: DELETE_USER_HOME_MILESTONE_ERROR,
  payload: error,
});

export const resetUserHomeMilestone = () => ({
  type: RESET_USER_HOME_MILESTONE,
  payload: {},
});
