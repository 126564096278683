import React, { useState, useEffect } from "react";
import { FormHelperText, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useField } from "formik";
import useGetColorByLetter from "hooks/useGetColorByLetter";
import InputFieldStyles from "styles/form/InputField.style";
import SelectFieldStyles from "styles/form/SelectField.style";

const SelectField = ({
  options = [],
  label,
  multiple = false,
  dense = false,
  disableClearable = true,
  limitTags = 2,
  hasImage = false,
  hasIcon = false,
  handleChange,
  autoCompletePros,
  allInputProps,
  ...props
}) => {
  const theme = useTheme();
  const getLetterColor = useGetColorByLetter();

  const FieldStyles = InputFieldStyles(theme);
  const SelectStyles = SelectFieldStyles(theme);

  const [field, meta, helpers] = useField(props);
  const [selectedVal, setSelectedVal] = useState(multiple ? [] : null);

  const handleSelectChange = (event, selectedOption, reason) => {
    if (multiple) {
      helpers.setValue(selectedOption?.map((option) => option?.id) || []);
    } else {
      helpers.setValue(selectedOption?.id || null);
    }

    if (handleChange) {
      handleChange(event, selectedOption, reason);
    }
  };

  useEffect(() => {
    if (options?.length > 0) {
      if (multiple) {
        const val = [];
        if (options?.length > 0 && field.value) {
          options?.forEach((element) => {
            if (field.value.indexOf(element.id) !== -1) {
              val.push(element);
            }
          });
          setSelectedVal(val);
        }
      } else {
        setSelectedVal(options?.find((x) => x.id === field?.value) || null);
      }
    }
  }, [options, field.value]);

  return (
    <>
      <Autocomplete
        options={options || []}
        multiple={multiple}
        limitTags={multiple && limitTags ? limitTags : 0}
        autoHighlight
        autoComplete={true}
        disableClearable={!multiple && disableClearable}
        getOptionLabel={(option) => option?.name || ""}
        value={selectedVal}
        onChange={handleSelectChange}
        componentsProps={{ popper: { sx: SelectStyles.paper } }}
        renderOption={(props, option) => (
          <ListItem className="select-item" {...props} key={props.id}>
            {(hasImage || hasIcon) && (
              <ListItemAvatar>
                {hasImage && (
                  <Avatar
                    src={option?.image || "abc.jpg"}
                    alt={option?.name || ""}
                    size="small"
                    sx={{
                      width: 42,
                      height: 42,
                      bgcolor: getLetterColor(option?.name.charAt(0).toUpperCase()),
                      "& img": { objectFit: "cover" },
                    }}
                  />
                )}

                {hasIcon && <Avatar>{option?.icon}</Avatar>}
              </ListItemAvatar>
            )}
            <ListItemText primary={option?.name || ""} secondary={option?.secondayName || ""} />
          </ListItem>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={props?.disabled}
        sx={SelectStyles.root}
        renderInput={(params) => (
          <TextField
            {...params}
            error={meta.touched && meta.error ? true : false}
            placeholder={props?.placeholder || ""}
            inputProps={{
              ...params.inputProps,
              autoComplete: "nofill",
            }}
            sx={FieldStyles}
            margin="dense"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label={label}
            className={dense ? "densed-field" : ""}
            {...allInputProps}
          />
        )}
        {...autoCompletePros}
      />
      {meta.touched && meta.error && (
        <FormHelperText sx={{ mt: 1, mx: 1.75, color: theme.palette.error.main, fontSize: 12 }} error>
          {meta.touched && meta.error ? meta.error : null}
        </FormHelperText>
      )}
    </>
  );
};

export default SelectField;
