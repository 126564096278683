import React from "react";
import { Badge, Tab, styled } from "@mui/material";

const boxVariantItem = (theme) => ({
  padding: "5px 6px",
  borderRadius: 4,
  zIndex: 1,
});

const TabsItemRoot = styled(Tab, { shouldForwardProp: (prop) => prop !== "variant" })(({ theme, variant }) => ({
  minHeight: 30,
  minWidth: 110,
  justifyContent: "flex-start",
  padding: "10px 0 10px",
  fontSize: "1rem",
  textTransform: "none",
  color: theme.palette.text.primary,
  opacity: 0.5,

  "&.Mui-selected": { opacity: 1 },

  ...(variant === "boxed" && { ...boxVariantItem(theme) }),
}));

const TabCount = styled(Badge, { shouldForwardProp: (prop) => prop })(({ theme, badgeContent }) => ({
  "& .MuiBadge-badge": {
    position: badgeContent > 0 ? "static" : "",
    marginLeft: 10,
    borderRadius: 6,
    transform: badgeContent > 0 ? "translate(0)" : "",
  },
}));

const TabItem = (props) => {
  const { label, count, ...others } = props;

  return (
    <TabsItemRoot
      label={
        <TabCount badgeContent={count} color="info">
          {label}
        </TabCount>
      }
      {...others}
    />
  );
};

export default TabItem;
