import React, { useEffect } from "react";
import { Box, InputAdornment, useTheme } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetAuth, register } from "reduxs/actions";
import { InputField, InputHiddenField, InputGoogleField, InputPasswordField } from "ui/form/field";
import { StyledButton } from "ui";
import AuthWrapper from "./shared/AuthWrapper";

const Register = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { loading, success } = useSelector((state) => state.auth);

  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Please provide your full name")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
      .required("Contact No is required")
      .min(8, "Atleast 8 digits")
      .max(10, "Cannot exceed 10 digits"),
    address: Yup.string().required("Enter a valid address"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password should be at least 8 digits including 1 number, 1 uppercase, 1 lowercase and 1 special character"
      ),
    passwordConfirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passsword and Confirm Password didn't match"),
  });

  const onRegister = (values) => {
    if (!loading) {
      dispatch(register(values));
    }
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  return (
    <AuthWrapper
      title={!loading && success ? "🥳 Welcome aboard." : "Create Account"}
      subtitle={
        !loading && success
          ? "You're almost there. Please check your email, we just sent you a verficaiton mail with a link to confirm your account"
          : "Register to create your account and start customizing home of your dream."
      }
      footer={
        !loading &&
        !success && (
          <>
            Already have account?{" "}
            <Box component={Link} to={`/auth/login`} sx={{ color: theme.palette.text.primary }}>
              Sign in
            </Box>
          </>
        )
      }
    >
      {!loading && !success && (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            address: "",
            password: "",
            passwordConfirmation: "",
            role: "client",
          }}
          validationSchema={schema}
          onSubmit={onRegister}
        >
          {({ values }) => (
            <Box component={Form} sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "16px" }}>
              <InputHiddenField name="role_id" />

              <InputField
                shrinkLabel={true}
                name="name"
                type="text"
                label="Full Name*"
                placeholder="Enter your fullname"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <InputField
                shrinkLabel={true}
                name="email"
                type="email"
                label="Email*"
                placeholder="Enter your email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <InputField
                shrinkLabel={true}
                name="phone"
                type="number"
                label="Phone*"
                placeholder="Enter your phone number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+61</InputAdornment>,
                }}
              />

              <InputGoogleField
                shrinkLabel={true}
                name="address"
                label="Address*"
                placeholder="Enter a Location"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PlaceOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <InputPasswordField
                shrinkLabel={true}
                name="password"
                label="Password*"
                placeholder="Enter your password"
                inputOptions={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <InputPasswordField
                shrinkLabel={true}
                name="passwordConfirmation"
                label="Confirm Password*"
                placeholder="Enter your password again"
                inputOptions={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <StyledButton type="submit" color={theme.palette.background.button} isloading={loading} sx={{ mt: 1 }}>
                Sign Up
              </StyledButton>
            </Box>
          )}
        </Formik>
      )}
    </AuthWrapper>
  );
};

export default Register;
