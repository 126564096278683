import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_NOTIFICATION_LIST, READ_NOTIFICATION } from "../actions";
import {
  getNotificationListSuccess,
  getNotificationListError,
  readNotificationSuccess,
  readNotificationError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import NotificationService from "services/NotificationService";
import TableDataService from "services/TableDataService";

export function* watchGetNotificationList() {
  yield takeEvery(GET_NOTIFICATION_LIST, getNotificationListAc);
}

const getNotificationListAsync = async (dbParam) => {
  return TableDataService.getAllData("notifications", dbParam);
};

function* getNotificationListAc({ payload }) {
  try {
    const response = yield call(getNotificationListAsync, payload.dbParam);
    if (response.data.success) {
      yield put(
        getNotificationListSuccess(
          response.data.data,
          response.data.meta,
          response.data.unread,
          payload.dbParam.isPagination
        )
      );
    } else {
      toaster("", response.data.message);
      yield put(getNotificationListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getNotificationListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchReadNotification() {
  yield takeEvery(READ_NOTIFICATION, readNotification);
}

const readNotificationAsync = async (data) => {
  return NotificationService.readNotification(data);
};

function* readNotification({ payload }) {
  try {
    const response = yield call(readNotificationAsync, payload.notificationData);
    if (response.data.success) {
      yield put(readNotificationSuccess(response.data.success));
    } else {
      yield put(readNotificationError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(readNotificationError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([fork(watchGetNotificationList), fork(watchReadNotification)]);
}
