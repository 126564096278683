import { combineReducers } from "redux";
import auth from "./auth/reducer";
import media from "./media/reducer";
import user from "./user/reducer";
import role from "./role/reducer";
import configChoice from "./config-choice/reducer";
import configChoiceCategory from "./config-choice-category/reducer";
import product from "./product/reducer";
import document from "./document/reducer";
import milestone from "./milestone/reducer";
import home from "./home/reducer";
import homeCustomization from "./home-customization/reducer";
import userHome from "./user-home/reducer";
import userHomeCustomization from "./user-home-customization/reducer";
import userHomeMilestone from "./user-home-milestone/reducer";
import notification from "./notification/reducer";
import message from "./message/reducer";
import messageGroup from "./message-group/reducer";
import shared from "./shared/reducer";
const reducers = combineReducers({
  auth,
  media,
  user,
  role,
  configChoice,
  configChoiceCategory,
  product,
  milestone,
  home,
  homeCustomization,
  userHome,
  userHomeCustomization,
  userHomeMilestone,
  document,
  notification,
  message,
  messageGroup,
  shared,
});

export default reducers;
