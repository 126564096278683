import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_ERROR,
  READ_NOTIFICATION,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_ERROR,
  RESET_NOTIFICATION,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  notificationList: null,
  notificationData: null,
  success: false,
  loading: false,
  fetchLoading: false,
  isReadLoading: false,
  metaData: null,
  unread: 0,
  dropdownNotificationList: null,
  error: null,
  reset: false,
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        loading: true,
        error: null,
        reset: false,
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationList: action.payload.isPagination ? action.payload.notificationList : null,
        dropdownNotificationList: action.payload.isPagination
          ? state.dropdownNotificationList
          : action.payload.notificationList,
        metaData: action.payload.isPagination ? action.payload.metaData : null,
        unread: action.payload.unread,
        error: null,
      };
    case GET_NOTIFICATION_LIST_ERROR:
      return {
        ...state,
        loading: false,
        notificationList: null,
        unread: null,
        error: action.payload,
      };
    case READ_NOTIFICATION:
      return { ...state, isReadLoading: true, error: null };
    case READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isReadLoading: false,
        success: action.payload,
        error: null,
      };
    case READ_NOTIFICATION_ERROR:
      return {
        ...state,
        isReadLoading: false,
        success: false,
        error: action.payload,
      };

    case RESET_NOTIFICATION:
      return {
        ...state,
        loading: false,
        fetchLoading: false,
        isReadLoading: false,
        success: false,
        notificationList: null,
        error: null,
        reset: true,
      };
    default:
      return { ...state };
  }
};
export default notificationReducer;
