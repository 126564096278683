import {
  GET_ALL_USER_HOME_MILESTONE,
  GET_ALL_USER_HOME_MILESTONE_SUCCESS,
  GET_ALL_USER_HOME_MILESTONE_ERROR,
  GET_USER_HOME_MILESTONE_LIST,
  GET_USER_HOME_MILESTONE_LIST_SUCCESS,
  GET_SELECTED_USER_HOME_MILESTONE_LIST_SUCCESS,
  GET_USER_HOME_MILESTONE_LIST_ERROR,
  EDIT_USER_HOME_MILESTONE,
  EDIT_USER_HOME_MILESTONE_SUCCESS,
  EDIT_USER_HOME_MILESTONE_ERROR,
  DELETE_USER_HOME_MILESTONE,
  DELETE_USER_HOME_MILESTONE_SUCCESS,
  DELETE_USER_HOME_MILESTONE_ERROR,
  RESET_USER_HOME_MILESTONE,
} from "reduxs/actions";

const INIT_STATE = {
  filterParam: null,
  dbParam: null,
  userHomeMilestones: null,
  userHomeMilestoneList: null,
  userSelectedHomeMilestoneList: null,
  userHomeMilestoneData: null,
  userHomeMilestoneId: null,
  userHomeId: null,
  success: false,
  successUpdateUserMilestone: false,
  loading: false,
  loadingList: false,
  delLoading: false,
  error: null,
};

const userHomeMilestoneReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_USER_HOME_MILESTONE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ALL_USER_HOME_MILESTONE_SUCCESS:
      return {
        ...state,
        loading: false,
        userHomeMilestones: action.payload,
        error: null,
      };
    case GET_ALL_USER_HOME_MILESTONE_ERROR:
      return {
        ...state,
        loading: false,
        userHomeMilestones: null,
        error: action.payload,
      };
    case GET_USER_HOME_MILESTONE_LIST:
      return {
        ...state,
        loadingList: true,
        userHomeMilestoneData: null,
        milestoneId: null,
        success: false,
        error: null,
      };
    case GET_USER_HOME_MILESTONE_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        userHomeMilestoneList: action.payload,
        userHomeMilestoneData: null,
        error: null,
      };
    case GET_SELECTED_USER_HOME_MILESTONE_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        userSelectedHomeMilestoneList: action.payload,
        error: null,
      };
    case GET_USER_HOME_MILESTONE_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
        userHomeMilestoneList: null,
        userSelectedHomeMilestoneList: null,
        error: action.payload,
      };
    case EDIT_USER_HOME_MILESTONE:
      return { ...state, frmLoading: true, error: null };
    case EDIT_USER_HOME_MILESTONE_SUCCESS:
      return {
        ...state,
        frmLoading: false,
        successUpdateUserMilestone: action.payload,
        error: null,
      };
    case EDIT_USER_HOME_MILESTONE_ERROR:
      return {
        ...state,
        frmLoading: false,
        successUpdateUserMilestone: false,
        error: action.payload,
      };
    case DELETE_USER_HOME_MILESTONE:
      return { ...state, delLoading: true, error: null };
    case DELETE_USER_HOME_MILESTONE_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_USER_HOME_MILESTONE_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_USER_HOME_MILESTONE:
      return {
        ...state,
        loading: false,
        delLoading: false,
        success: false,
        error: null,
      };

    default:
      return { ...state };
  }
};
export default userHomeMilestoneReducer;
