import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMessageGroupList, deleteMessageGroup, resetMessageGroup } from "reduxs/actions";
import AddIcon from "@mui/icons-material/Add";
import { checkPermission, capitalizeFirstLetter } from "helpers";
import {
  StyledCard,
  BreadcrumbContainer,
  TableInstance,
  Table,
  TablePagination,
  Toolbar,
  Action,
  AlertDialog,
  FloatingForm,
} from "ui";
import MessageGroupForm from "./MessageGroupForm";
import { Avatar, AvatarGroup, Tooltip, useTheme } from "@mui/material";

const MessageGroupList = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [pageValue, setPageValue] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("desc");
  const [activeCol, setActiveCol] = useState("created_at");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [search, setSearch] = useState("");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [formId, setFormId] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const { messageGroupList, metaData, delLoading, loading, success } = useSelector((state) => state.messageGroup);
  const { permissionList } = useSelector((state) => state.shared);
  const searchFields = "name:like;statusConfigChoice.display_name:like;";

  const onChange = (search, sortOrder, page, perPage, activeCol) => {
    dispatch(
      getMessageGroupList({
        search: search,
        searchFields: searchFields,
        sortedBy: sortOrder,
        page: page,
        pageSize: perPage,
        orderBy: activeCol,
      })
    );
  };

  const handleSort = (order, val) => {
    setSortOrder(order);
    setActiveCol(val);
    onChange(search, order, pageValue, perPage, val);
  };

  const handleChangePage = (val) => {
    setPageValue(val);
    onChange(search, sortOrder, val, perPage, activeCol);
  };

  const handleChangePerPage = (val) => {
    setPerPage(val);
    onChange(search, sortOrder, pageValue, val, activeCol);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const val = event.target.value;
    setSearch(event.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        onChange(val, sortOrder, pageValue, perPage, activeCol);
      }, 1000)
    );
  };

  const handleEditClick = (e, id) => {
    e.preventDefault();
    setFormId(id);
    setOpenForm(true);
  };

  const handleOnDelete = () => {
    if (!delLoading && formId) dispatch(deleteMessageGroup(formId));
  };

  const data = React.useMemo(() => messageGroupList, [messageGroupList]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: (value) => capitalizeFirstLetter(value.name),
      },
      {
        Header: "Members",
        accessor: "messageGroupUsers",
        disableSortBy: true,
        Cell: (props) => {
          return (
            <AvatarGroup
              renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
              total={props.row?.values?.messageGroupUsers.length}
              sx={{ justifyContent: "flex-end" }}
            >
              {props.row?.values?.messageGroupUsers?.map((user) => (
                <Tooltip key={user.id} title={user?.user?.name}>
                  <Avatar
                    alt={user?.user?.name}
                    src={user?.user?.media?.[0]?.url || "/static/images/avatar/1.jpg"}
                    size="small"
                    sx={{
                      width: 24,
                      height: 24,
                      fontSize: 14,
                      fontWeight: "500",
                      bgcolor: theme.palette.info.main,
                      "& img": { objectFit: "cover" },
                    }}
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        accessor: "actions",
        disableSortBy: true,
        Cell: (props) => {
          const rowIdx = props.row.values.id;

          return (
            <Action
              id={props.row.id}
              handleOnDelete={() => {
                setFormId(rowIdx);
                setOpenDeleteAlert(true);
              }}
              handleOnEdit={(e) => handleEditClick(e, rowIdx)}
              permission={props.permission}
              {...props}
            />
          );
        },
      },
    ],
    [data]
  );

  const resetAll = () => {
    setFormId("");
    setOpenForm(false);
  };

  useEffect(() => {
    dispatch(getMessageGroupList({}));
  }, []);

  return (
    <>
      <BreadcrumbContainer
        title="Message group List"
        paths={[
          {
            title: "Message group",
          },
        ]}
      />

      <StyledCard>
        <TableInstance
          columns={columns}
          data={data || []}
          permission={{
            edit: checkPermission(permissionList, "edit message group"),
            delete: checkPermission(permissionList, "delete message group"),
          }}
          totalPage={metaData?.totalPages}
          currentPage={metaData?.page}
          total={metaData?.total}
        >
          <Toolbar
            title="Message group"
            subTitle="List of all available message group"
            buttonA="Add Message group"
            allowButtonA={checkPermission(permissionList, "add message group")}
            buttonAIcon={<AddIcon />}
            handleButtonA={() => {
              setOpenForm(true);
            }}
            search={search}
            handleSearch={handleSearch}
          />

          <Table handleSort={handleSort} loading={loading} />

          <TablePagination
            meta={metaData}
            goToStart={() => handleChangePage(1)}
            goToPrev={() => handleChangePage(pageValue === 0 ? 1 : pageValue - 1)}
            goToNext={() => handleChangePage(pageValue !== metaData?.totalPage ? pageValue + 1 : 1)}
            goToLast={() => handleChangePage(metaData?.totalPage)}
            handleChangePerPage={(val) => handleChangePerPage(val)}
            perPage={perPage}
            handleChangePage={handleChangePage}
          />
        </TableInstance>
      </StyledCard>

      {/* Floating Form */}
      <FloatingForm open={openForm} handleclose={() => setOpenForm(false)} title={formId ? "Edit group" : "Add group"}>
        <MessageGroupForm
          handleClose={(submit) => {
            resetAll();
            if (submit) {
              dispatch(getMessageGroupList({}));
            }
          }}
          editId={formId}
        />
      </FloatingForm>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => {
          setOpenDeleteAlert(false);
          setFormId(null);
        }}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected message group?"
        loadingInfo="Message group is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => dispatch(resetMessageGroup())}
      />
    </>
  );
};

export default MessageGroupList;
