import React from "react";
import { Tabs, styled } from "@mui/material";
import TabItem from "./TabItem";

const boxVariant = (theme) => ({
  gap: 15,
  padding: "10px 12px",
  border: `1px solid ${theme.palette.grey.brightGray}`,
  borderRadius: 12,
  backgroundColor: theme.palette.layout.main,
});

const TabsRoot = styled(Tabs, { shouldForwardProp: (prop) => prop !== "variant" })(({ theme, variant }) => ({
  minHeight: "initial",
  borderBottom: variant !== "boxed" ? `1px solid ${theme.palette.grey.cadet}` : "",

  "& .MuiTabs-flexContainer": { gap: 30, ...(variant === "boxed" && { ...boxVariant(theme) }) },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.text.primary,
    ...(variant === "boxed" && {
      height: 35,
      bottom: 9,
      borderRadius: 6,
      backgroundColor: theme.palette.grey.brightGray,
    }),
  },
}));

const StyledTabs = ({ children, ...props }) => {
  return (
    <TabsRoot textColor="inherit" visibleScrollbar={true} {...props}>
      {children}
    </TabsRoot>
  );
};

StyledTabs.TabItem = TabItem;
export default StyledTabs;
