import React from "react";
import { TextField, styled, Box, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  position: "relative",
  marginLeft: 0,
  backgroundColor: theme.palette.background.paper,
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  pointerEvents: "none",
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  flex: 1,
  color: "inherit",
  "& .MuiInputBase-input": {
    width: "100%",
    height: "1.875rem",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

const styledSearch = (props) => {
  const { handleSearch, wraperProps } = props;

  const theme = useTheme();

  return (
    <Search {...wraperProps}>
      <SearchIconWrapper>
        <SearchIcon color={theme.palette.grey.cadet} />
      </SearchIconWrapper>
      <StyledInputBase
        onChange={handleSearch}
        // value={searchText}
        placeholder="Search…"
        inputProps={{ "aria-label": "search", type: "search" }}
        autoFocus={false}
      />
    </Search>
  );
};

export default styledSearch;
