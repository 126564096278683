import Req from "interceptors/TokenInterceptor";
import qs from "query-string";
import { cleanQueryObj } from "helpers";

const HomeCustomizationService = (function () {
  function _getAllHomeCustomization(params) {
    const sentParams = { ...cleanQueryObj(params) };
    return Req.get(`/api/home-customizations?${qs.stringify(sentParams, { arrayFormat: "bracket" })}`);
  }
  function _addHomeCustomization(data) {
    return Req.post("/api/home-customizations", data);
  }
  function _deleteHomeCustomization(id) {
    return Req.delete(`/api/home-customizations/${id}`);
  }
  return {
    getAllHomeCustomization: _getAllHomeCustomization,
    addHomeCustomization: _addHomeCustomization,
    deleteHomeCustomization: _deleteHomeCustomization,
  };
})();
export default HomeCustomizationService;
