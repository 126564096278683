import { alpha } from "@mui/material";

export default (theme, style) => ({
  "& .MuiInputLabel-root": {
    "&:not(.MuiInputLabel-shrink)": {
      color: theme.palette.grey.cadet,
    },
    "&.Mui-focused": { color: theme.palette.text.primary || "inherit" },
  },

  "& .MuiInput-underline": {
    minHeight: "48px",
    padding: "0 16px",

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.grey.cadet, 0.15),
    },
  },

  "& .MuiInputBase-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.grey.cadet, 0.32),
      borderRadius: "0.5rem",
    },

    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.text.primary,
      },
    },

    "&.Mui-disabled": {
      "& .MuiInputBase-input": { backgroundColor: theme.palette.grey.cultured, borderRadius: "0.5rem" },
    },
  },

  "&.densed-field": { "& .MuiInputBase-input": { height: "0.15rem" } },

  "& .MuiFormHelperText-root.Mui-error": {
    margin: "8px 14px 0",
  },

  ...(style === "stacked" && { ...stackStyle(theme) }),
});

const stackStyle = (theme) => ({
  margin: 0,
  "& .MuiInputLabel-root": {
    maxWidth: "initial",
    position: "static",
    marginBottom: 0.5,
    transform: "none",
  },

  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.layout.main,
    borderRadius: 2,

    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
      borderRadius: 2,
      borderColor: theme.palette.grey.brightGray,

      "& legend": { display: "none" },
    },
  },
});
