import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_PRODUCT, GET_PRODUCT_LIST, ADD_PRODUCT, GET_PRODUCT, EDIT_PRODUCT, DELETE_PRODUCT } from "../actions";
import {
  getAllProductSuccess,
  getAllProductError,
  getProductList,
  getProductListSuccess,
  getProductListError,
  addProductSuccess,
  addProductError,
  getProductSuccess,
  getProductError,
  editProductSuccess,
  editProductError,
  deleteProductSuccess,
  deleteProductError,
} from "./action";
import { toaster, parseMessage, handleResponseErrorMessage } from "helpers";
import ProductService from "services/ProductService";

export function* watchgetAllProduct() {
  yield takeEvery(GET_ALL_PRODUCT, getAllProduct);
}

const getAllProductAsync = async () => {
  return ProductService.getAllProduct();
};

function* getAllProduct() {
  try {
    const response = yield call(getAllProductAsync);
    if (response.data) {
      yield put(getAllProductSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getAllProductError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getAllProductError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetProductList() {
  yield takeEvery(GET_PRODUCT_LIST, getProductListAc);
}

const getProductListAsync = async () => {
  return ProductService.getProductList();
};

function* getProductListAc() {
  try {
    const response = yield call(getProductListAsync);
    if (response.data.success) {
      yield put(getProductListSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getProductListError(response.data.message));
    }
  } catch (error) {
    const errMessage = handleResponseErrorMessage(error);
    yield put(getProductListError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchAddProduct() {
  yield takeEvery(ADD_PRODUCT, addProduct);
}

const addProductAsync = async (data) => {
  return ProductService.addProduct(data);
};

function* addProduct({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(addProductAsync, payload.productData);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(addProductSuccess(response.data.success));
      navigate(`/configuration/products`);
    } else {
      toaster("", response.data.message);
      yield put(addProductError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(addProductError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchGetProduct() {
  yield takeEvery(GET_PRODUCT, getProduct);
}

const getProductAsync = async (id) => {
  return ProductService.getProduct(id);
};

function* getProduct({ payload }) {
  try {
    const response = yield call(getProductAsync, payload.productId);
    if (response.data.success) {
      yield put(getProductSuccess(response.data.data));
    } else {
      toaster("", response.data.message);
      yield put(getProductError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(getProductError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchEditProduct() {
  yield takeEvery(EDIT_PRODUCT, editProduct);
}

const editProductAsync = async (data, id) => {
  return ProductService.editProduct(data, id);
};

function* editProduct({ payload }) {
  const { navigate } = payload;
  try {
    const response = yield call(editProductAsync, payload.productData, payload.productId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(editProductSuccess(response.data.success));
      navigate(`/configuration/products`);
    } else {
      toaster("", response.data.message);
      yield put(editSProductError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(editProductError(errMessage));
    toaster("error", errMessage);
  }
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
}

const deleteProductAsync = async (id) => {
  return ProductService.deleteProduct(id);
};

function* deleteProduct({ payload }) {
  try {
    const response = yield call(deleteProductAsync, payload.productId);
    if (response.data.success) {
      toaster("success", response.data.message);
      yield put(deleteProductSuccess(response.data.success));
      yield put(getProductList());
    } else {
      toaster("", response.data.message);
      yield put(deleteProductError(response.data.message));
    }
  } catch (error) {
    const errMessage = parseMessage(handleResponseErrorMessage(error));
    yield put(deleteProductError(errMessage));
    toaster("error", errMessage);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchgetAllProduct),
    fork(watchGetProductList),
    fork(watchAddProduct),
    fork(watchGetProduct),
    fork(watchEditProduct),
    fork(watchDeleteProduct),
  ]);
}
