import React from "react";
import { Box, IconButton, Tooltip, Typography, lighten, useTheme } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { editUserHomeMilestone } from "reduxs/actions";
import useAllIcons from "hooks/useAllIcons";
import useGetStatusColor from "hooks/useGetStatusColor";
import { checkPermission, dateFormat } from "helpers";
import { InstantActionAlert, PopoverDropdown, StatusChip, StyledButton } from "ui";

const ProcessHead = (props) => {
  const { data, isexpanded, statuslist, permissionList, handleeditclick, handleuploadimage, dbParams } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const iconList = useAllIcons();
  const [statusColor] = useGetStatusColor(data.statusConfigChoice.configChoice);

  const updateStatus = (status) => {
    const payload = {
      userHomeId: data?.userHomeId || "",
      milestoneId: data?.milestoneId || "",
      completedAt: status === "completed" ? format(new Date(), "yyyy-MM-dd") : "",
      status: statuslist?.find((val) => val.configChoice === status)?.id || "",
    };
    dispatch(editUserHomeMilestone(data.id, payload, dbParams));
  };

  return (
    <Box sx={{ display: "flex", flex: 1, gap: 2 }}>
      <Box
        component="i"
        sx={{
          width: 36,
          height: 36,
          p: 0.75,
          borderRadius: "50%",
          backgroundColor: isexpanded ? lighten(theme.palette.primary.main, 0.5) : theme.palette.layout.main,
          boxShadow: "0px 2px 4px 1px rgba(198, 198, 198, 0.42)",
          color: isexpanded ? theme.palette.layout.main : lighten(theme.palette.primary.main, 0.5),
          transform: "translateY(-25px)",
          transition: "300ms ease",
        }}
      >
        {React.createElement(iconList?.find((icon) => icon.iconName === data.milestone.icon)?.iconComp, {})}
      </Box>

      <Typography component="h3" sx={{ fontSize: 16, fontWeight: 600, lineHeight: "18px" }}>
        {data.milestone.name}

        <Typography
          component="span"
          sx={{
            display: "block",
            mt: 0.625,
            fontSize: 12,
            fontWeight: 500,
            color: theme.palette.grey.cadet,
            lineHeight: "14px",
          }}
        >
          Completed: {data?.completedAt ? format(new Date(data?.completedAt), dateFormat) : "Not Completed"}
        </Typography>
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: 1.2, marginLeft: "auto" }}>
        {data?.completedAt || !checkPermission(permissionList, "change progress status") ? (
          <StatusChip status={data?.statusConfigChoice?.configChoice} label={data?.statusConfigChoice.displayName} />
        ) : (
          <PopoverDropdown
            isFixedWidth={true}
            dropdownWidth="21.875rem"
            trigger={
              <StyledButton
                color={statusColor}
                sx={{
                  minHeight: 24,
                  padding: "0px 8px",
                  boxShadow: "none",
                  fontSize: "0.75rem",
                  fontWeight: 700,
                }}
              >
                {data.statusConfigChoice.displayName}
                <UnfoldMoreIcon sx={{ mr: -1 }} />
              </StyledButton>
            }
            triggertype="div"
            triggerprops={{
              className: `inline-block`,
            }}
            caret={false}
          >
            <InstantActionAlert
              title="Update status"
              content="Select the status for progress you want to change."
              wrapperProps={{
                sx: {
                  py: 0.5,
                  px: 1.75,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: 0,
                },
              }}
            >
              <StyledButton
                color={theme.palette.info.main}
                sx={{ minHeight: 35, flex: 1 }}
                onClick={() => updateStatus("in-progress")}
                disabled={data?.statusConfigChoice?.configChoice === "in-progress"}
              >
                Inprogress
              </StyledButton>
              <StyledButton
                sx={{ minHeight: 35, flex: 1 }}
                onClick={() => updateStatus("completed")}
                disabled={data.status === 21}
              >
                Mark as complete
              </StyledButton>
            </InstantActionAlert>
          </PopoverDropdown>
        )}

        {checkPermission(permissionList, "upload progress image") && (
          <Tooltip title="Upload image">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleuploadimage(data.id);
              }}
            >
              <AddPhotoAlternateOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}

        {checkPermission(permissionList, "edit progress") && (
          <Tooltip title="Edit Progress">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleeditclick(data.id);
              }}
            >
              <DriveFileRenameOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default ProcessHead;
