import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ViewCozyOutlinedIcon from "@mui/icons-material/ViewCozyOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUser,
  getProductList,
  getAllUserHomeCustomization,
  addUserHomeCustomization,
  deleteUserHomeCustomization,
} from "reduxs/actions";
import { BreadcrumbContainer, StyledButton } from "ui";
import { HomeCustomizationView } from "shared/modules";

const CustomerHome = () => {
  const theme = useTheme();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userHomeId = params.userHomeId;

  const { userData } = useSelector((state) => state.user);
  const { userHomeCustomizations, loading: actionLoading } = useSelector((state) => state.userHomeCustomization);

  const homeData = React.useMemo(() => userData?.userHomes?.find((x) => x.id === userHomeId).home || null, [userData]);

  const onSelect = (categoryId, parentCategoryId) => {
    dispatch(
      addUserHomeCustomization({
        userHomeId: userHomeId,
        parentProductId: parentCategoryId,
        productId: categoryId,
      })
    );
  };

  const onDelete = (id) => {
    if (id) dispatch(deleteUserHomeCustomization(id, userHomeId));
  };

  useEffect(() => {
    // Fetch nested categories for tree view
    dispatch(getProductList());
    if (params.id) dispatch(getUser(params.id));

    if (userHomeId) {
      dispatch(getAllUserHomeCustomization({ userHomeId: userHomeId }));
    }
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <BreadcrumbContainer
          title={`Customizing ${userData?.userHomes?.find((x) => x.id === userHomeId)?.home?.name || ""}`}
          paths={[
            {
              title: "Customer",
              page: `/user-management/customer`,
            },
            {
              title: "View",
              page: `/user-management/customer/view/${userData?.id}`,
            },
            {
              title: "Home customization",
            },
          ]}
        />

        <StyledButton
          color={theme.palette.info.main}
          variant="outlined"
          size="small"
          sx={{ minHeight: 28, px: 1.5, py: 0.35 }}
          onClick={() => navigate(`/configuration/home/view/${userHomeId}`)}
        >
          <ViewCozyOutlinedIcon sx={{ width: 18, height: 18, color: "#777777" }} /> View home
        </StyledButton>
      </Box>

      <Typography component="h3" fontSize={18} fontWeight={600} mb={2.5}>
        Let&apos;s start customization for <span className="text-primary-500">{homeData?.name || ""}</span>
      </Typography>

      <HomeCustomizationView
        homeid={homeData?.id}
        selectionlist={userHomeCustomizations}
        selectionloading={actionLoading}
        onselect={onSelect}
        onremove={onDelete}
      />
    </>
  );
};

export default CustomerHome;
