import { lighten } from "@mui/material";
import uploadGraphics from "assets/images/dropzone-graphics.svg";

export default (theme) => ({
  [theme.breakpoints.down("lg")]: {
    flexWrap: "wrap",
    justifyContent: "center",
  },

  "& .filepond--wrapper": {
    width: "100%",

    "& .filepond--credits": {
      display: "none",
    },

    "& .filepond--root": {
      "& .filepond--drop-label": {
        minHeight: "9.75em",
        display: "flex",
        alignItems: "center",
        gap: "2.1875rem",
        padding: ".625rem",
        border: `1px dashed ${
          theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.5) : theme.palette.primary.dark
        }`,
        borderRadius: "0.5rem",
        backgroundColor:
          theme.palette.mode === "light" ? lighten(theme.palette.primary.main, 0.97) : theme.palette.layout.main,
        cursor: "pointer",

        "&:before": {
          width: "10.9375rem",
          height: "7.9375rem",
          content: '""',
          background: `url(${uploadGraphics}) no-repeat`,
        },

        "& label": {
          padding: 0,
          fontSize: "1.25rem",
          fontWeight: 700,
          textAlign: "left",
          color: theme.palette.text.primary,

          "& .content": {
            display: "block",
            color: theme.palette.grey.dark,
            fontSize: "0.875rem",
            fontWeight: "400",

            "& small": { color: "#199B5A" },
          },
        },
      },

      "& .filepond--list ": {
        top: "1.2rem",

        "& .filepond--item": { marginTop: "0.4rem" },
      },

      "& .filepond--panel .filepond--panel-root": { backgroundColor: "transparent" },
    },
  },
});
