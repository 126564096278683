import React from "react";
import {
  styled,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Typography,
  List,
  Divider,
  ListSubheader,
  MenuItem,
  useTheme,
  IconButton,
  Badge,
} from "@mui/material";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationList, readNotification } from "reduxs/actions";
import useGetNotificationByDate from "hooks/useGetNotificationByDate";
import { getModuleIcon } from "helpers/getModuleIcon";
import { PopoverDropdown } from "ui";

const NotificationItem = styled(ListItemButton)(({ theme }) => ({
  alignItems: "flex-start",
  gap: "1rem",
  marginBottom: ".15rem",
  padding: "12px 20px",
}));

const StickyHeader = styled(ListSubheader)(({ theme }) => ({
  padding: "12px 20px",
  fontSize: "0.75rem",
  fontWeight: "400",
  color: theme.palette.grey.dark,
  lineHeight: 1,
  textTransform: "uppercase",
}));

const NotificationItemText = styled(ListItemText)(({ theme }) => ({
  margin: "0",
  "& .MuiListItemText-primary": {
    color: theme.palette.grey.dark,
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: 1.57143,
  },
}));

const NotificationItemIcon = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: "initial",
  width: "2.5rem",
  height: "2.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: 0,
  padding: "0.5rem",
  borderRadius: "50%",
  background: theme.palette.grey.cultured,
  "& svg": { fill: theme.palette.primary.main, color: theme.palette.primary.main },
}));

const NOTIFICATION_PARAMS = { page: 1, pageSize: 50, isPagination: false };

const NotificationDropdown = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { dropdownNotificationList, isReadLoading, unread } = useSelector((state) => state.notification);

  const handleClickNotfication = (id) => {
    const payload = {
      ids: [id],
    };
    dispatch(readNotification(payload));
  };

  React.useEffect(() => {
    dispatch(getNotificationList(NOTIFICATION_PARAMS));
  }, [isReadLoading]);

  const allNotification = useGetNotificationByDate(dropdownNotificationList || []);

  return (
    <>
      <PopoverDropdown
        isFixedWidth={true}
        dropdownWidth="21.875rem"
        trigger={
          <Badge
            component="span"
            color="error"
            // variant="dot"
            invisible={unread === 0}
            badgeContent={unread}
            max={99}
            classes={{ badge: "font-light text-[11px] p-1 px-1.5", dot: "top-1 right-2" }}
          >
            <NotificationsNoneTwoToneIcon />
          </Badge>
        }
        triggertype={IconButton}
        iconColor={theme.palette.grey.dark}
      >
        <Typography
          component="span"
          variant="subtitle2"
          sx={{
            display: "block",
            mt: "-0.5rem",
            p: "0.75rem 1.25rem",
            color: theme.palette.text.primary,
          }}
        >
          Notifications
          <Typography component="small" variant="body2" display="block" color={theme.palette.grey.dark}>
            You have {unread || 0} unread messages
          </Typography>
        </Typography>

        <Divider sx={{ mb: "0.5rem", borderBottom: "thin dashed rgba(145, 158, 171, 0.24)" }} />

        {allNotification?.length ? (
          <List sx={{ maxHeight: 500, position: "relative", p: 0, overflow: "auto" }} subheader={<li />}>
            {allNotification?.map((group) => {
              return (
                <li key={group.title}>
                  <ul className="p-0">
                    {group?.notification?.length ? <StickyHeader>{group.title}</StickyHeader> : null}

                    {group?.notification?.length
                      ? group?.notification?.map((notify) => (
                          <NotificationItem
                            key={notify.id}
                            sx={{ bgcolor: !notify.isRead ? "rgba(145, 158, 171, 0.16)" : "" }}
                            onClick={() => handleClickNotfication(notify.id)}
                          >
                            <NotificationItemIcon>{getModuleIcon(notify?.module)}</NotificationItemIcon>
                            <NotificationItemText
                              primary={
                                <>
                                  <Typography
                                    component="span"
                                    sx={{ color: theme.palette.text.primary, fontSize: "0.875rem", fontWeight: "600" }}
                                  >
                                    {notify?.action}
                                  </Typography>{" "}
                                  <Typography
                                    component="span"
                                    sx={{ fontSize: 13, lineHeight: 1.2 }}
                                    className="line-clamp-2"
                                  >
                                    {notify.title}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <Typography
                                  component="span"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                    color: theme.palette.grey.cadet,
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  <AccessTimeIcon fontSize="22px" />

                                  {formatDistanceToNow(new Date(notify?.createdAt), { addSuffix: true })}
                                </Typography>
                              }
                            />
                          </NotificationItem>
                        ))
                      : null}
                  </ul>
                </li>
              );
            })}
          </List>
        ) : (
          <EmptyNotification color={theme.palette.grey.cadet} />
        )}

        <Divider sx={{ mt: "0.5rem", borderBottom: "thin dashed rgba(145, 158, 171, 0.24)" }} />

        <MenuItem
          component={Link}
          to="/notifications"
          sx={{ justifyContent: "center", mt: "1rem", color: theme.palette.primary.main }}
        >
          View All
        </MenuItem>
      </PopoverDropdown>
    </>
  );
};

export default NotificationDropdown;

const EmptyNotification = (color) => {
  return (
    <Typography
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 20px",
        fontSize: 13,
        lineHeight: 2,
        color: color,
        textAlign: "center",
      }}
    >
      <NotificationsPausedIcon />
      There are no new notifications.
    </Typography>
  );
};
