import React, { useState, useEffect, useMemo } from "react";
import { Box, IconButton, Typography, alpha, useTheme } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddPhotoAlternateRoundedIcon from "@mui/icons-material/AddPhotoAlternateRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import SpeakerNotesOffRoundedIcon from "@mui/icons-material/SpeakerNotesOffRounded";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { composeMessage, deleteMessage, editMessage, getMessage, resetMessage } from "reduxs/actions";
import { AdvButton, AlertDialog, Editor, InputDateTimepickerField, InputField, StyledButton, StyledDialog } from "ui";
import { checkPermission, convertLocalToUTC, getFileType } from "helpers";
import { IconFiles } from "icons";
import { useMessage } from "./MessageContext";
import UploadFileModal from "./UploadFileModal";
import MessageToSelect from "./MessageToSelect";
import MessageGroupSelect from "./MessageGroupSelect";
import { StaticDateTimePicker, renderMultiSectionDigitalClockTimeView } from "@mui/x-date-pickers";

const ComposeNewMessage = (props) => {
  const { messageId, onsuccess } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentMenu, userInfo } = useMessage();

  const { singleChoiceList: messageStatusList, permissionList } = useSelector((state) => state.shared);
  const { messageData, success, delLoading, frmLoading, frmSucess } = useSelector((state) => state.message);

  const [openDialog, setOpenDialog] = useState({ open: false, fileType: undefined });
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [expandAttachment, setExpandAttachment] = useState(true);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [submitAction, setSubmitAction] = useState();

  const getOptionIcons = (name) => {
    let icon = <></>;
    switch (name) {
      case "draft":
        icon = <ArticleRoundedIcon />;
        break;
      case "scheduled":
        icon = <ScheduleSendIcon />;
        break;

      default:
        break;
    }

    return icon;
  };

  const messageSendOptions = useMemo(() => {
    const options = [];

    if (messageStatusList?.length) {
      messageStatusList?.map((option) => {
        if (option.configChoice === "sent") {
          setSubmitAction(option.id);
        } else {
          options.push({
            ...option,
            name: messageId && option.configChoice === "draft" ? "Update draft" : option.displayName,
            icon: getOptionIcons(option.configChoice),
          });
        }
      });
    }

    return options;
  }, [messageStatusList]);

  const initialValues = {
    title: messageId ? messageData?.title || "" : "",
    description: messageId ? messageData?.description || "" : "",
    messageFrom: userInfo?.id,
    messageTo: messageId ? messageData?.messageTo?.map((user) => user.messageTo) || [] : [],
    messageGroupId: messageId ? messageData?.messageGroupId || "" : "",
    media: messageId ? messageData?.media || [] : [],
    status: messageId ? messageData?.status || "" : "",
    scheduledTime: messageId ? messageData?.scheduledTime || "" : "", // yyyy-mm-dd hh:mm:ss
  };

  const schema = Yup.object().shape({
    title: Yup.string().required("Subject is required."),

    messageTo: Yup.array().test({
      test: function (value) {
        const messageGroupId = this.resolve(Yup.ref("messageGroupId"));
        if ((!value || value.length === 0) && (!messageGroupId || messageGroupId.length === 0)) {
          return this.createError({
            path: this.path,
            message: "Please specify at least one recipient recipient.",
          });
        }

        return true;
      },
    }),
    messageGroupId: Yup.string().test({
      test: function (value) {
        const messageTo = this.resolve(Yup.ref("messageTo"));
        if (!messageTo || messageTo.length === 0) {
          return this.createError({
            path: this.path,
            message: "Please specify at least one recipient group.",
          });
        }

        return true;
      },
    }),
  });

  const onSubmit = (values) => {
    const payload = {
      ...values,
      status: submitAction,
      scheduledTime: values.scheduledTime ? convertLocalToUTC(values.scheduledTime) : null,
    };

    if (!frmLoading) {
      const filters = { currentMenu: currentMenu, userId: userInfo?.id };
      if (messageId) {
        dispatch(editMessage(messageId, payload, filters));
      } else {
        dispatch(composeMessage(payload, filters));
      }
    }
  };

  const handleOnDelete = () => {
    const filters = { currentMenu: currentMenu, userId: userInfo?.id };
    dispatch(deleteMessage(messageId, filters));
  };

  useEffect(() => {
    if (!frmLoading && frmSucess) {
      onsuccess();

      setTimeout(() => dispatch(resetMessage()), 50);
    }
  }, [frmLoading, frmSucess]);

  useEffect(() => {
    if (messageId) dispatch(getMessage(messageId));
  }, [messageId]);

  return (
    <>
      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
        {({ values, setFieldValue, submitForm }) => (
          <Box
            component={Form}
            sx={{ width: "calc(100% - 5px)", height: "100%", display: "flex", flex: 1, flexDirection: "column" }}
          >
            <MessageToSelect />

            {checkPermission(permissionList, "list message group") && <MessageGroupSelect />}

            <InputField name="title" type="text" variant="standard" placeholder="Subject" />

            <Box sx={{ flex: 1, my: "16px", px: "16px", overflow: "hidden", maxHeight: "calc(100% - 210px)" }}>
              <Editor name="description" wrapperProps={{ className: "h-full" }} />
            </Box>

            {!!values?.media?.length && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  mt: 3,
                  mx: "16px",
                  py: 1,
                  px: 2,
                  borderRadius: 1,
                  backgroundColor: theme.palette.blue.shadowBlue,
                }}
              >
                <Box
                  component="span"
                  sx={{ width: "100%", display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}
                  onClick={() => setExpandAttachment(!expandAttachment)}
                >
                  <AttachFileIcon />
                  {values?.media?.length} attachments
                  <KeyboardArrowDownRoundedIcon className={expandAttachment ? "rotate-180" : ""} />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: expandAttachment ? "flex" : "none",
                    flexWrap: "wrap",
                    gap: 1.75,
                    mt: 1.625,
                    p: 0,
                    listStyle: "none",
                  }}
                  component="div"
                >
                  {values.media.map((file, index) => (
                    <Box
                      // onClick={() => setViewDocument([{ uri: doc?.media?.[0].url }])}
                      key={index}
                      sx={{
                        width: "40px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: 1,
                        backgroundColor: alpha(theme.palette.blue.yankeesBlue, 0.25),
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      className="group"
                    >
                      {getFileType(file?.name || "") === "image" ? (
                        <img src={file.url} className="object-cover w-full h-full" />
                      ) : (
                        <IconFiles filetype={getFileType(file?.name || "")} />
                      )}

                      <IconButton
                        type="button"
                        size="sm"
                        sx={{ position: "absolute", top: 0, right: 0, color: theme.palette.error.main }}
                        className="opacity-0 group-hover:opacity-80 group-hover:bg-white/80"
                        onClick={() => {
                          setFieldValue(
                            "media",
                            values.media.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        <DeleteRoundedIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            <Box
              width="100%"
              display="flex"
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
              justifyContent={{ sm: "flex-end" }}
              gap={0.75}
              mt="auto"
              px="16px"
              pt={4}
            >
              <IconButton
                type="button"
                size="small"
                sx={{ width: "35px", height: "35px", flexShrink: 0, color: theme.palette.text.main }}
                onClick={() => setOpenDialog({ open: true, fileType: "image/*" })}
              >
                <AddPhotoAlternateRoundedIcon />
              </IconButton>

              <IconButton
                type="button"
                size="small"
                sx={{ width: "35px", height: "35px", flexShrink: 0, color: theme.palette.text.main, mr: "auto" }}
                onClick={() => setOpenDialog({ open: true, fileType: undefined })}
              >
                <AttachFileIcon />
              </IconButton>

              {messageId && (
                <StyledButton
                  type="button"
                  color={theme.palette.error.main}
                  variant="outlined"
                  size="sm"
                  disabled={delLoading || frmLoading}
                  isloading={delLoading}
                  onClick={() => setOpenDeleteAlert(true)}
                >
                  Delete <SpeakerNotesOffRoundedIcon sx={{ width: 18 }} />
                </StyledButton>
              )}

              <AdvButton
                isloading={frmLoading || delLoading}
                styleButtonProps={{
                  type: "submit",
                  size: "sm",
                  onClick: () => setSubmitAction(messageStatusList.find((status) => status.configChoice === "sent").id),
                }}
                options={messageSendOptions}
                handleClickOptions={(action) => {
                  setSubmitAction(action.id);
                  if (action.configChoice === "scheduled" && values?.messageTo?.length > 0 && values?.title) {
                    setOpenScheduleDialog(true);
                  } else {
                    submitForm();
                  }
                }}
              >
                Send
              </AdvButton>
            </Box>

            {openDialog && (
              <UploadFileModal
                openDialog={openDialog}
                handleClose={() => setOpenDialog({ open: false, fileType: undefined })}
              />
            )}

            <StyledDialog open={openScheduleDialog} hideclose={true} handleclose={() => setOpenScheduleDialog(false)}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: { xs: "column-reverse", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: 3,
                  mt: 2,
                  mb: 4.5,
                }}
              >
                <Typography component="h4" sx={{ fontSize: 20, fontWeight: 600 }}>
                  Select message schedule
                </Typography>
              </Box>

              <InputDateTimepickerField
                pickerView={StaticDateTimePicker}
                label="Schedule date & time"
                name="scheduledTime"
                disablePast={true}
                ampm={false}
                // orientation="landscape"
                views={["year", "month", "day", "hours", "minutes"]}
                viewRenderers={{
                  hours: renderMultiSectionDigitalClockTimeView,
                  minutes: renderMultiSectionDigitalClockTimeView,
                  seconds: renderMultiSectionDigitalClockTimeView,
                }}
                isloading={frmLoading}
                handleSelect={() => submitForm()}
                handleCancel={() => setOpenScheduleDialog(false)}
              />
            </StyledDialog>
          </Box>
        )}
      </Formik>

      <AlertDialog
        open={openDeleteAlert}
        handleCancel={() => setOpenDeleteAlert(false)}
        handleAction={handleOnDelete}
        title="Delete"
        info="Are you sure to permanently delete selected message?"
        loadingInfo="Message is deleting..."
        actionLabel="Delete"
        loading={delLoading}
        success={success}
        reset={() => {
          dispatch(resetMessage());

          onsuccess(true);
        }}
      />
    </>
  );
};

export default React.memo(ComposeNewMessage);
