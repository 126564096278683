import {
  GET_ALL_MESSAGE_GROUP,
  GET_ALL_MESSAGE_GROUP_SUCCESS,
  GET_ALL_MESSAGE_GROUP_ERROR,
  GET_MESSAGE_GROUP_LIST,
  GET_MESSAGE_GROUP_LIST_SUCCESS,
  GET_MESSAGE_GROUP_LIST_ERROR,
  ADD_MESSAGE_GROUP,
  ADD_MESSAGE_GROUP_SUCCESS,
  ADD_MESSAGE_GROUP_ERROR,
  GET_MESSAGE_GROUP,
  GET_MESSAGE_GROUP_SUCCESS,
  GET_MESSAGE_GROUP_ERROR,
  EDIT_MESSAGE_GROUP,
  EDIT_MESSAGE_GROUP_SUCCESS,
  EDIT_MESSAGE_GROUP_ERROR,
  DELETE_MESSAGE_GROUP,
  DELETE_MESSAGE_GROUP_SUCCESS,
  DELETE_MESSAGE_GROUP_ERROR,
  RESET_MESSAGE_GROUP,
} from "reduxs/actions";

const INIT_STATE = {
  dbParam: null,
  allMessageGroups: null,
  messageGroupList: null,
  metaData: null,
  messageGroupData: null,
  messageGroupId: null,
  success: false,
  loading: false,
  delLoading: false,
  error: null,
};

const messageGroupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALL_MESSAGE_GROUP:
      return {
        ...state,
        loading: true,
        allMessageGroups: null,
        error: null,
      };
    case GET_ALL_MESSAGE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        allMessageGroups: action.payload,
        error: null,
      };
    case GET_ALL_MESSAGE_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        allMessageGroups: null,
        error: action.payload,
      };
    case GET_MESSAGE_GROUP_LIST:
      return {
        ...state,
        loading: true,
        messageGroupData: null,
        messageGroupId: null,
        error: null,
      };
    case GET_MESSAGE_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        messageGroupList: action.payload.messageGroupList,
        metaData: action.payload.metaData,
        error: null,
      };
    case GET_MESSAGE_GROUP_LIST_ERROR:
      return {
        ...state,
        loading: false,
        messageGroupList: null,
        error: action.payload,
      };
    case ADD_MESSAGE_GROUP:
      return { ...state, loading: true, error: null };
    case ADD_MESSAGE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case ADD_MESSAGE_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GET_MESSAGE_GROUP:
      return { ...state, error: null };
    case GET_MESSAGE_GROUP_SUCCESS:
      return {
        ...state,
        messageGroupData: action.payload,
        error: null,
      };
    case GET_MESSAGE_GROUP_ERROR:
      return {
        ...state,
        messageGroupData: null,
        error: action.payload,
      };
    case EDIT_MESSAGE_GROUP:
      return { ...state, loading: true, error: null };
    case EDIT_MESSAGE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null,
      };
    case EDIT_MESSAGE_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case DELETE_MESSAGE_GROUP:
      return { ...state, delLoading: true, error: null };
    case DELETE_MESSAGE_GROUP_SUCCESS:
      return {
        ...state,
        delLoading: false,
        success: action.payload,
        error: null,
      };
    case DELETE_MESSAGE_GROUP_ERROR:
      return {
        ...state,
        delLoading: false,
        success: false,
        error: action.payload,
      };
    case RESET_MESSAGE_GROUP:
      return {
        ...state,
        loading: false,
        delLoading: false,
        success: false,
        error: null,
      };
    default:
      return { ...state };
  }
};
export default messageGroupReducer;
