import React from "react";
import { Box, Typography, alpha, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useDashboard } from "./DashboardContext";
import { StatusChip, StyledButton, StyledCard } from "ui";
import graphics from "assets/images/home-placeholder.jpg";

const Slider = styled(Splide)(({ theme }) => ({
  borderRadius: 16,

  "& .splide__arrows": {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: 20,
    right: 20,
    zIndex: 1,
  },

  "& .splide__arrow": {
    height: 12,
    position: "static",
    padding: 0,
    backgroundColor: "transparent",
    cursor: "pointer",

    "& svg": {
      width: 12,
      height: 12,
      fill: "#fff",
    },
  },

  "& .splide__pagination": {
    display: "inline-flex",
    gap: 5,
    position: "absolute",
    bottom: 20,
    right: 20,
    padding: 0,
  },

  "& .splide__pagination__page": {
    borderRadius: "50%",
    background: "rgba(91, 228, 155, 0.5)",
    cursor: "pointer",

    "&.is-active": { backgroundColor: "#5BE49B" },
  },
}));

const HomeCard = styled(SplideSlide)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  borderRadius: 16,
  overflow: "hidden",
}));

const HomeCardContent = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  position: "absolute",
  top: 0,
  left: 0,
  padding: "20px",
  color: "#fff",
  background: "linear-gradient(180deg, rgba(15, 43, 46, 0.31) 0%, #021E20 100%, #021E20 100%) no-repeat",

  "& .stat": {
    display: "block",
    color: "#EEFEC2",
    fontSize: 32,
    fontWeight: 700,
  },
  "& .types": {
    display: "flex",
    gap: 15,
    margin: "15px 0 auto",
  },
}));

const HomeSlideCard = () => {
  const { userInfo, configChoice } = useDashboard();
  const navigate = useNavigate();

  const homesliderOption = {
    type: "slide",
    perPage: 1,
    pagination: true,
    arrows: true,
    autoplay: true,
    rewind: true,
    speed: 800,
  };

  const getHomeProgress = (milestones) => {
    if (milestones) {
      const totalCompleted = milestones?.filter((complete) => complete.status === 25)?.length;
      return ((totalCompleted / milestones?.length) * 100).toFixed(0);
    }

    return 0;
  };

  const totalDocuments = (selectedItems) => {
    let totalDocumentsCount = 0;
    let totalDocumentsCountWithStatus21 = 0;

    selectedItems?.forEach((item) => {
      if (item.documents && item.documents.length > 0) {
        totalDocumentsCount += item.documents.length;

        item.documents.forEach((document) => {
          if (document.status === 21) {
            totalDocumentsCountWithStatus21 += 1;
          }
        });
      }
    });

    return {
      total: totalDocumentsCount || 0,
      completed: totalDocumentsCountWithStatus21 || 0,
    };
  };

  return (
    <StyledCard sx={{ height: 290 }}>
      <Slider options={homesliderOption} className="flex h-full">
        {userInfo?.userHomes.map((userHome) => (
          <HomeCard key={userHome.id}>
            <img className="w-full h-full object-cover" src={userHome?.home?.media?.[0]?.url || graphics} />

            <HomeCardContent className="backdrop-blur-sm">
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, marginBottom: 1.5 }}>
                <Typography component="h5" sx={{ fontWeight: 600, fontSize: 20, transition: "300ms ease" }}>
                  {userHome?.home?.name}
                </Typography>

                <StatusChip
                  status={65}
                  label={configChoice?.find((config) => config?.id === userHome?.plan)?.displayName}
                />
              </Box>

              <Typography component="h3" className="stat animate__animated animate__fadeInRight animate__faster">
                {getHomeProgress(userHome?.userHomeMilestones)}%{" "}
                <Typography component="small" sx={{ color: "#EEEEEE", fontSize: 12, fontWeight: 400 }}>
                  Completed
                </Typography>
              </Typography>

              <Box className="types animate__animated animate__fadeInUp animate__faster">
                <Typography sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}>
                  {userHome?.userHomeMilestones?.length || 0}
                  <Typography
                    component="small"
                    sx={{ display: "block", color: "#EEEEEE", fontSize: 12, fontWeight: 400 }}
                  >
                    Milestones
                  </Typography>
                </Typography>

                <Typography sx={{ fontSize: 18, fontWeight: 600, textAlign: "center" }}>
                  {totalDocuments(userHome?.userHomeMilestones).total || 0}
                  <Typography
                    component="small"
                    sx={{ display: "block", color: "#EEEEEE", fontSize: 12, fontWeight: 400 }}
                  >
                    Documents
                  </Typography>
                </Typography>
              </Box>

              <StyledButton
                color="#000"
                sx={{
                  borderRadius: 30,
                  "&:hover": {
                    color: "#fff",
                    borderColor: "#fff",
                    backgroundColor: alpha("#fff", 0.16),
                  },
                }}
                onClick={() => navigate(`/my-home/building-progress/${userHome.id}`)}
              >
                Progress <ArrowCircleRightIcon />
              </StyledButton>
            </HomeCardContent>
          </HomeCard>
        ))}
      </Slider>
    </StyledCard>
  );
};

export default HomeSlideCard;
