import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, styled } from "@mui/material";

const AccordionRoot = styled(Accordion, { shouldForwardProp: (prop) => prop !== "color" })(
  ({ theme, color, variant }) => ({
    "&.MuiAccordion-root": {
      margin: 0,
      borderRadius: 8,

      "&:before": { display: "none" },

      "&.Mui-expanded": {
        margin: 0,
      },
    },
  })
);

const AccordionHeader = styled(AccordionSummary, { shouldForwardProp: (prop) => prop !== "color" })(
  ({ theme, color, variant }) => ({
    minHeight: "initial",
    padding: "18px 22px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "&.Mui-expanded": {
      minHeight: "initial",

      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
    },
  })
);

const AccordionBody = styled(AccordionDetails, { shouldForwardProp: (prop) => prop !== "color" })(
  ({ theme, color, variant }) => ({})
);

const StyledAccordion = (props) => {
  const { wrapperprops, label, labelprops, contentprops, children } = props;

  return (
    <AccordionRoot {...wrapperprops}>
      <AccordionHeader {...labelprops}>{label}</AccordionHeader>

      <AccordionBody {...contentprops}>{children}</AccordionBody>
    </AccordionRoot>
  );
};

export default StyledAccordion;
