import React from "react";
import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

const TogglerWrap = styled(ToggleButtonGroup)(({ theme }) => ({
  height: 40,
  padding: 5,
  borderRadius: 20,
  background: theme.palette.grey.gainsBoro,
}));

const TogglerButton = styled(ToggleButton)(({ theme }) => ({
  minWidth: 90,
  height: "100%",
  flex: 1,
  padding: "0 16px",
  border: "none",
  borderRadius: "20px !important",
  textTransform: "none",

  "&.Mui-selected": {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.layout.main,
  },
}));

const TemplateSwitch = (props) => {
  const { children, ...other } = props;

  return (
    <TogglerWrap exclusive {...other}>
      {children}
    </TogglerWrap>
  );
};

const SwitchButton = ({ children, ...props }) => {
  return <TogglerButton {...props}>{children}</TogglerButton>;
};

TemplateSwitch.Button = SwitchButton;

export default TemplateSwitch;
