import React from "react";
import { Paper, styled } from "@mui/material";

const Card = styled(Paper)(({ theme }) => ({
  borderRadius: "1rem",
  boxShadow:
    theme.palette.mode === "light"
      ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
      : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 12px 24px -4px",
  background: theme.palette.background.paper,
}));

const StyledCard = (props) => {
  return <Card {...props}>{props.children}</Card>;
};

export default StyledCard;
