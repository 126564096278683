import React, { useState, useEffect, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Drawer,
  styled,
  Badge,
  Box,
  useMediaQuery,
  alpha,
  useTheme,
  Skeleton,
  Typography,
  Tooltip,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getMessage, getMessageList, updateMessageStatus } from "reduxs/actions";
import useGetColorByLetter from "hooks/useGetColorByLetter";
import { StyledSearch } from "ui";
import { useMessage } from "./MessageContext";
import useGetMessageStatus from "./hooks/useGetMessageStatus";
import emptyChat from "assets/images/Chat-empty.svg";
import cycanBlur from "assets/images/cyan-blur.png";
import redBlur from "assets/images/red-blur.png";
import { checkPermission } from "helpers";
import { MessageNavOptions } from "./MessageConfig";

const UserNavDrawer = styled(Drawer)(({ theme, open }) => ({
  minWidth: 300,
  width: 300,
  flexShrink: 0,

  "& .MuiDrawer-paper": {
    maxHeight: useMediaQuery(theme.breakpoints.up("lg")) ? window.innerHeight * 0.85 : "inherit",
    minWidth: 300,
    width: 300,
    position: "static",
    padding: 14,
    border: "none",
    borderRadius: 12,
    backgroundColor: theme.palette.background.paper,
    boxSizing: "border-box",
    zIndex: useMediaQuery(theme.breakpoints.up("lg")) ? 1 : "",

    "@media (max-width: 1023px)": {
      borderRadius: 0,
      backgroundColor: alpha(theme.palette.background.paper, 0.8),
      backgroundImage: `url(${cycanBlur}), url(${redBlur})`,
      backgroundRepeat: "no-repeat, no-repeat",
      backgroundPosition: "right top, left bottom",
      backgroundSize: "50%, 50%",
    },
  },
}));

const UserMessage = styled(ListItem)(({ theme, isread = false }) => ({
  padding: 0,
  fontSize: 14,

  "& .MuiListItemButton-root": {
    alignItems: "flex-start",
    padding: "8px 6px !important",
    borderRadius: 6,
    gap: 16,
    color: theme.palette.grey.dark,
  },

  "& .MuiListItemText-primary": {
    color: isread ? theme.palette.grey.dark : theme.palette.text.primary,
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    color: isread ? theme.palette.grey.cadet : theme.palette.text.primary,
    fontWeight: 300,
  },

  "& .MuiListItemButton-root.Mui-selected": {
    color: theme.palette.text.primary,
    backgroundColor: alpha(theme.palette.grey.cadet, 0.16),

    "& .MuiListItemText-primary": {
      color: theme.palette.text.primary,
    },

    "+ .MuiListItemSecondaryAction-root": { color: theme.palette.background.button },
  },
}));

const MessageUserList = (props) => {
  const { onCompose } = props;

  const theme = useTheme();
  const dispatch = useDispatch();
  const getLetterColor = useGetColorByLetter();
  const getMessageStatus = useGetMessageStatus();

  const { currentMenu, userInfo, selectedMessage, isMobileUserListOpen, updateContext } = useMessage();
  const { permissionList } = useSelector((state) => state.shared);
  const { messageList, messageData, loading: isMessageLoading } = useSelector((state) => state.message);

  const shouldReadMessage = useMemo(
    () => !isMessageLoading && currentMenu !== "sent" && currentMenu !== "draft",
    [currentMenu, isMessageLoading]
  );

  const [allMessages, setAllmessages] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);

  const getMessageType = (messageStatus) => {
    if (messageStatus) {
      const { name, icon } = MessageNavOptions.find((menu) => menu.status === messageStatus);
      return { title: name, icon };
    }

    return null;
  };

  const getMessageData = (messageId) => dispatch(getMessage(messageId));

  const recipentsNames = (recipents) => recipents?.map((receiver) => receiver?.messageToUser?.name || "");

  const handleOnSelectMessage = (message) => {
    if (message.status === 27) {
      onCompose(message.id);
      return;
    }

    if (!checkPermission(permissionList, "view message")) return;
    if (selectedMessage?.id === message?.id) return;

    if (message?.messageFrom !== userInfo?.id && shouldReadMessage && !getMessageStatus(message?.messageTo).isRead) {
      const status = { ...getMessageStatus(message.messageTo), isRead: 1, messageTo: userInfo?.id };
      const filters = { currentMenu: currentMenu, userId: userInfo?.id };

      dispatch(updateMessageStatus(message.id, status, filters));
    }

    getMessageData(message.id);

    updateContext({ isMobileUserListOpen: false }); // Close Mobile UserList Menu
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const val = event.target.value;
    const searchFields = `${
      currentMenu === "all" ? "messageFromUser.name:like;messageTo.messageToUser.name:like;" : ""
    }${currentMenu === "inbox" ? "messageFromUser.name:like;" : ""}${
      currentMenu === "sent" ? "messageTo.messageToUser.name:like;" : ""
    }title:like;`;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        dispatch(
          getMessageList({
            ...(currentMenu === "all" && {}), // Get's All Messages
            ...(currentMenu === "sent" && { messageFrom: userInfo?.id }), // Get's Sent Messages
            ...(currentMenu === "inbox" && { messageTo: userInfo?.id }), // Get's Inbox Messages
            search: val,
            searchFields: searchFields,
          })
        );
      }, 1000)
    );
  };

  useEffect(() => {
    if (!!messageList?.length) {
      setAllmessages((prev) => {
        if (prev?.length !== messageList?.length) {
          return messageList;
        }

        return messageList;
      });

      if (currentMenu === "draft") return; // ### Hide message preview for draft
      getMessageData(messageList[0].id);
    } else {
      setAllmessages(null);
      updateContext({ selectedMessage: null });
    }
  }, [messageList, currentMenu]);

  useEffect(() => {
    //## Set new message preview
    updateContext({ selectedMessage: messageData });
  }, [messageData]);

  return (
    <UserNavDrawer
      classes={{ paper: "custom-scrollbar backdrop-blur-[20px] xl:backdrop-blur-0" }}
      variant={useMediaQuery(theme.breakpoints.down(1024)) ? "temporary" : "permanent"}
      open={isMobileUserListOpen}
      anchor="left"
      onClose={() => updateContext({ isMobileUserListOpen: false })}
    >
      {useMediaQuery(theme.breakpoints.down(1024)) ? (
        <Typography component="h4" sx={{ mb: 2, fontSize: "16px", fontWeight: 600, textTransform: "capitalize" }}>
          {currentMenu}
        </Typography>
      ) : (
        <Box className="-mt-3.5 -mx-3.5 px-3.5 pt-3.5 mb-4  sticky -top-5 z-[2] backdrop-blur">
          <StyledSearch handleSearch={(event) => handleSearch(event)} />
        </Box>
      )}

      <List component="ul" sx={{ display: "flex", flexDirection: "column", padding: 0, gap: 0.625 }}>
        {allMessages?.map((chat, index) => (
          <Badge
            component="li"
            key={index}
            color="info"
            variant="dot"
            invisible={
              chat?.messageFrom === userInfo?.id ? true : !shouldReadMessage || getMessageStatus(chat.messageTo).isRead
            }
            classes={{ dot: "top-9 right-1" }}
          >
            <UserMessage component="div" isread={getMessageStatus(chat.messageTo).isRead ? 1 : 0}>
              <ListItemButton selected={chat?.id === selectedMessage?.id} onClick={() => handleOnSelectMessage(chat)}>
                <ListItemAvatar sx={{ minWidth: "initial" }}>
                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      src={chat?.messageFromUser?.media?.[0]?.url || "abc.jpg"}
                      alt={chat?.messageFromUser?.name || ""}
                      size="small"
                      sx={{
                        width: 42,
                        height: 42,
                        bgcolor: getLetterColor(chat?.messageFromUser?.name.charAt(0).toUpperCase()),
                        "& img": { objectFit: "cover" },
                      }}
                    />

                    {chat?.messageFrom === userInfo?.id && (
                      <Tooltip title={`${getMessageType(chat?.status)?.title} Message`}>
                        <Box
                          sx={{
                            width: 18,
                            height: 18,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            bottom: -2,
                            right: -2,
                            borderRadius: "50%",
                            backgroundColor: theme.palette.slate.A100,
                          }}
                          className="children:w-4 chil w-4"
                        >
                          <Box
                            component={getMessageType(chat?.status)?.icon}
                            sx={{
                              color: theme.palette.primary.dark,
                              fontSize: 12,
                              ml: 0.5,
                            }}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <span className="flex items-start gap-2">
                      <span className="flex-1 line-clamp-1">
                        {shouldReadMessage && chat?.messageFrom !== userInfo?.id
                          ? chat.messageFromUser.name
                          : recipentsNames(chat?.messageTo)?.join(", ")}
                      </span>
                      <Box
                        component="span"
                        className="flex-shrink-0 ml-auto text-xs font-light"
                        sx={{ color: theme.palette.grey.dark }}
                      >
                        {formatDistanceToNow(new Date(chat?.createdAt), { addSuffix: false })}
                      </Box>
                    </span>
                  }
                  secondary={chat.title}
                  sx={{ margin: 0 }}
                  classes={{
                    secondary: `w-[95%] line-clamp-2 ${
                      chat?.messageFrom !== userInfo?.id || !getMessageStatus(chat?.messageTo).isRead
                        ? `!font-medium`
                        : ""
                    }`,
                  }}
                />
              </ListItemButton>
            </UserMessage>
          </Badge>
        ))}
      </List>

      {/* ### Skeleton Loader */}
      {isMessageLoading &&
        [...Array(6)].map((_, index) => (
          <Box key={index} sx={{ width: "100%", display: "flex", padding: "8px 6px" }}>
            <Skeleton animation="wave" variant="circular" width={42} height={42} sx={{ flexShrink: 0 }} />
            <Box sx={{ width: "100%", ml: 1.85 }}>
              <Skeleton animation="wave" height={18} width="80%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={12} width="40%" />
            </Box>
          </Box>
        ))}

      {!isMessageLoading && !allMessages && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={emptyChat} />
          <Typography sx={{ mt: 1 }} color={theme.palette.grey.cadet}>
            Your message list is empty.
          </Typography>
        </Box>
      )}
    </UserNavDrawer>
  );
};

export default React.memo(MessageUserList);
