import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { StyledButton } from "ui";

const DocumentHead = (props) => {
  const { data, showbutton, handleclickadd } = props;

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", alignItems: "center", justifyContent: "space-between", pr: 3 }} className="flex gap-3">
      <Typography component="h5" fontSize="1rem">
        {data?.milestone?.name || ""}
        <Typography component="span" sx={{ display: "block", color: theme.palette.primary.main }}>
          {data?.documents?.length || 0} files added
        </Typography>
      </Typography>

      {showbutton && (
        <StyledButton
          sx={{ minHeight: "30px", padding: "7px 10px" }}
          color={theme.palette.background.button}
          onClick={(e) => {
            e.stopPropagation();
            handleclickadd(e);
          }}
        >
          <FileUploadOutlinedIcon />
          Add new
        </StyledButton>
      )}
    </Box>
  );
};

export default DocumentHead;
