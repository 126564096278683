import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { StyledCard } from "ui";

const InstantActionAlert = (props) => {
  const { title, content, children, wrapperProps, footerProps } = props;

  return (
    <StyledCard sx={{ py: 1.5, px: 1.75 }} {...wrapperProps}>
      <Typography component="h3" fontSize={14} fontWeight={600}>
        {title}
      </Typography>

      <Divider sx={{ mt: 1.2, mb: 1.75, mx: -1.75, borderBottom: `1px solid #F4F6F7` }} />

      <Typography component="p">{content}</Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2.5, gap: 1.5 }} {...footerProps}>
        {children}
      </Box>
    </StyledCard>
  );
};

export default InstantActionAlert;
