import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import RoofingIcon from "@mui/icons-material/Roofing";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HouseIcon from "@mui/icons-material/House";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserHomeMilestone, getMultipleConfigChoice, getUserHomeMilestoneList } from "reduxs/actions";
import { FloatingForm, StyledAccordion, StyledDialog, StyledSpinner } from "ui";
import MilestoneTypeCard from "./MilestoneTypeCard";
import ProgressHead from "./ProgressHead";
import ProgressContent from "./ProgressContent";
import BuildingProgressForm from "./Form";
import DocViewer from "@cyntler/react-doc-viewer";
import UploadImageModal from "./UploadImageModal";

const MILESTONE_PARAMS = { orderBy: "order", sortedBy: "asc" };

const BuildingProgress = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const params = useParams();

  const { userHomeMilestones, userHomeMilestoneList, loadingList } = useSelector((state) => state.userHomeMilestone);
  const { multipleChoiceList, permissionList } = useSelector((state) => state.shared);

  const [activeTab, setActiveTab] = useState();
  const [expanded, setExpanded] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [editData, setEditData] = useState();
  const [viewDocument, setViewDocument] = useState([]);
  const [openImageDialog, setOpenImageDialog] = useState(false);

  const [dbParams, setDbParams] = useState();

  const generateTypeIcons = (typeName) => {
    if (typeName === "pre-construction") {
      return <RoofingIcon />;
    } else if (typeName === "post-construction") {
      return <HouseIcon />;
    }
  };

  const getData = (tab) => {
    const filterParams = {
      ...MILESTONE_PARAMS,
      userHomeId: params.userHomeId,
      type: tab || multipleChoiceList?.milestoneType?.[0]?.id,
    };
    setDbParams(filterParams);
    dispatch(getUserHomeMilestoneList(filterParams));

    dispatch(
      getAllUserHomeMilestone({
        userHomeId: params.userHomeId,
      })
    );
  };

  const getTotals = (type) => {
    const selectedItems = userHomeMilestones?.filter((process) => process.milestone.type === type);
    const totalCompleted = selectedItems?.filter((complete) => complete.status === 25)?.length;
    return {
      items: selectedItems?.length || 0,
      completed: totalCompleted || 0,
      percent: (totalCompleted / selectedItems?.length) * 100 || 0,
    };
  };

  const handleExpandToggle = (panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const handleChangeTab = (type) => {
    const active = multipleChoiceList?.milestoneType.find((item) => item.id === type)?.id;
    setActiveTab(active);
    getData(active);
  };

  const resetAll = () => {
    setEditData(null);
    setOpenForm(false);
    setOpenImageDialog(false);
  };

  const mileStoneTabs = React.useMemo(() => {
    let types = [];
    if (multipleChoiceList?.milestoneType?.length) {
      if (!activeTab) {
        setActiveTab(multipleChoiceList?.milestoneType?.[0].id); // Set defulat active tab
      }

      types = multipleChoiceList?.milestoneType?.map((type) => ({
        ...type,
        icon: generateTypeIcons(type.configChoice),
        total: getTotals(type.id),
      }));
    }

    return types;
  }, [multipleChoiceList, userHomeMilestones]);

  React.useEffect(() => {
    dispatch(getMultipleConfigChoice(["milestoneType", "milestoneStatus", "homeMilestoneStatus"]));
  }, []);

  React.useEffect(() => {
    if (multipleChoiceList?.milestoneType?.length) {
      getData();
    }
  }, [multipleChoiceList]);

  return (
    <>
      <Box sx={{ display: "flex", gap: 2.5, mb: 4 }}>
        {mileStoneTabs.map((type) => {
          return (
            <MilestoneTypeCard
              key={type.id}
              data={type}
              isactive={activeTab === type.id ? 1 : 0}
              onClick={() => handleChangeTab(type.id)}
            />
          );
        })}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 4.375 }}>
        {userHomeMilestoneList?.map((milestone) => {
          return (
            <StyledAccordion
              key={milestone.id}
              label={
                <ProgressHead
                  data={milestone}
                  isexpanded={expanded === milestone.id}
                  statuslist={multipleChoiceList?.homeMilestoneStatus}
                  handleeditclick={() => {
                    setEditData(milestone);
                    setOpenForm(true);
                  }}
                  handleuploadimage={() => {
                    setEditData(milestone);
                    setOpenImageDialog(true);
                  }}
                  permissionList={permissionList}
                  dbParams={dbParams}
                />
              }
              labelprops={{
                onClick: () => {
                  handleExpandToggle(milestone.id);
                },
                expandIcon: <ExpandMoreIcon />,
                sx: {
                  padding: "13px 30px",
                  "&.Mui-expanded": { borderBottom: `1px solid ${theme.palette.grey.brightGray}` },
                },
              }}
              wrapperprops={{
                expanded: expanded === milestone.id,
                sx: {
                  position: "relative",
                  boxShadow: "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
                  "&:not(:last-child):after": {
                    width: 3,
                    height: 40,
                    content: "''",
                    position: "absolute",
                    top: "100%",
                    left: 47,
                    backgroundColor: theme.palette.grey.cadet,
                  },
                },
              }}
              contentprops={{ sx: { py: 3, px: 4.75 } }}
            >
              <ProgressContent
                dbParams={dbParams}
                data={milestone}
                handleviewdocument={(docLink) => setViewDocument(docLink)}
              />
            </StyledAccordion>
          );
        })}
      </Box>

      {/* Floating Form */}
      <FloatingForm open={openForm} handleclose={() => resetAll()} title={`Edit ${editData?.milestone?.name}`}>
        <BuildingProgressForm
          handleClose={(submit) => {
            resetAll();
            if (submit) {
              getData(activeTab);
            }
          }}
          editData={editData}
          dbParams={dbParams}
        />
      </FloatingForm>

      <StyledDialog
        open={!!viewDocument?.length}
        handleclose={() => setViewDocument([])}
        dialogProps={{
          fullWidth: true,
          maxWidth: "lg",
        }}
      >
        <DocViewer documents={viewDocument} />
      </StyledDialog>

      {openImageDialog && (
        <UploadImageModal
          openDialog={openImageDialog}
          editData={editData}
          handleClose={() => resetAll()}
          dbParams={dbParams}
        />
      )}

      {loadingList && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            backgroundColor: theme.palette.backdrop,
          }}
          className="backdrop-blur-sm "
        >
          <StyledSpinner />
        </Box>
      )}
    </>
  );
};

export default BuildingProgress;
