import React from "react";
import { createTheme, useMediaQuery } from "@mui/material";
import theme from "configs/Theme";
import { Light, Dark } from "configs/Palette";
import { defaultThemeMode } from "configs/LayoutConfig";

const useThemeSwitcher = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)") ? "dark" : "light"; // Get user browser scheme
  const [mode, setMode] = React.useState(localStorage.getItem("theme") || defaultThemeMode);

  const themeMode = React.useMemo(
    () => ({
      toggleColorMode: (selected) => {
        setMode(selected === "system" ? prefersDarkMode : selected);
      },
    }),
    [prefersDarkMode]
  );

  const newTheme = React.useMemo(() => {
    const themeMode = localStorage.getItem("theme") === "system" ? prefersDarkMode : mode;

    return createTheme({
      ...theme,
      palette: {
        mode: themeMode,
        ...(themeMode === "dark" ? Dark : Light),
      },
    });
  }, [mode, prefersDarkMode]);

  return { newTheme, themeMode };
};

export default useThemeSwitcher;
