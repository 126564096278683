import { alpha } from "@mui/material";

export default (theme) => ({
  "& .MuiPaper-root": {
    borderRadius: "1rem",
    boxShadow:
      theme.palette.mode === "light"
        ? "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px"
        : "rgb(0 0 0 / 24%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) -20px 20px 40px -4px",
  },

  "& .MuiBackdrop-root": {
    backgroundColor: alpha(theme.palette.backdrop, 0.8),
  },
});
