export const GET_ALL_HOME_CUSTOMIZATION = "GET_ALL_HOME_CUSTOMIZATION";
export const GET_ALL_HOME_CUSTOMIZATION_SUCCESS = "GET_ALL_HOME_CUSTOMIZATION_SUCCESS";
export const GET_ALL_HOME_CUSTOMIZATION_ERROR = "GET_ALL_HOME_CUSTOMIZATION_ERROR";
export const ADD_HOME_CUSTOMIZATION = "ADD_HOME_CUSTOMIZATION";
export const ADD_HOME_CUSTOMIZATION_SUCCESS = "ADD_HOME_CUSTOMIZATION_SUCCESS";
export const ADD_HOME_CUSTOMIZATION_ERROR = "ADD_HOME_CUSTOMIZATION_ERROR";
export const DELETE_HOME_CUSTOMIZATION = "DELETE_HOME_CUSTOMIZATION";
export const DELETE_HOME_CUSTOMIZATION_SUCCESS = "DELETE_HOME_CUSTOMIZATION_SUCCESS";
export const DELETE_HOME_CUSTOMIZATION_ERROR = "DELETE_HOME_CUSTOMIZATION_ERROR";
export const RESET_HOME_CUSTOMIZATION = "RESET_HOME_CUSTOMIZATION";

export const getAllHomeCustomization = (filterParam) => ({
  type: GET_ALL_HOME_CUSTOMIZATION,
  payload: { filterParam },
});

export const getAllHomeCustomizationSuccess = (homeCustomizations) => ({
  type: GET_ALL_HOME_CUSTOMIZATION_SUCCESS,
  payload: homeCustomizations,
});

export const getAllHomeCustomizationError = (error) => ({
  type: GET_ALL_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const addHomeCustomization = (homeCustomizationData) => ({
  type: ADD_HOME_CUSTOMIZATION,
  payload: { homeCustomizationData },
});

export const addHomeCustomizationSuccess = (success) => ({
  type: ADD_HOME_CUSTOMIZATION_SUCCESS,
  payload: success,
});

export const addHomeCustomizationError = (error) => ({
  type: ADD_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const deleteHomeCustomization = (homeCustomizationId, homeId) => ({
  type: DELETE_HOME_CUSTOMIZATION,
  payload: { homeCustomizationId, homeId },
});

export const deleteHomeCustomizationSuccess = (success) => ({
  type: DELETE_HOME_CUSTOMIZATION_SUCCESS,
  payload: success,
});

export const deleteHomeCustomizationError = (error) => ({
  type: DELETE_HOME_CUSTOMIZATION_ERROR,
  payload: error,
});

export const resetHomeCustomization = () => ({
  type: RESET_HOME_CUSTOMIZATION,
  payload: {},
});
